import React, { useEffect, useState } from 'react';
import axios from 'config/axios';

import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { theme } from 'assets/customisedMUI';
import isEmpty from 'validation/isEmpty';
import { showNotification } from 'store/actions/uiActions';
import { connect } from 'react-redux';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import InformationRow from 'components/Common/InformationRow';
import { saveAs } from 'file-saver';

const SalesReports = (props) => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const getReports = () => {
        setLoading(true);
        axios.get(`/api_v2/admin/report`).then((res) => {
            setReports(res.data);
            setLoading(false);
            props.doShowNotification('success', `List refreshed successfully`);
        });
    };

    const generateReport = () => {
        axios.post(`/api_v2/admin/report`, { startDate, endDate }).then((res) => {
            if (res.data.success) {
                props.doShowNotification(
                    'success',
                    `New report successfully requested. Please allow a few minutes for it to be available online.`
                );
            } else {
                props.doShowNotification('danger', `Failed to generate new report`);
            }
        });
    };

    useEffect(() => {
        getReports();
    }, []);

    const mapReportsToRows = isEmpty(reports) ? (
        <TableRow>
            <TableCell colSpan={4}>
                <span>{loading ? 'LOADING' : 'No reports available'}</span>
            </TableCell>
        </TableRow>
    ) : (
        reports.map((r) => {
            return (
                <InformationRow
                    noselect
                    id={`report-data-${r.filename}`}
                    information={{
                        'Report Name': r.filename,
                    }}
                    tableRowStyle={{ backgroundColor: '#ccc' }}
                    editing={false}
                    onRedirect={() => window.open(r.url)}
                    selected={false}
                    isHover={true}
                />
            );
        })
    );

    /* const resourceReport = async () => {
        await axios
            .get('/dynamoReport', { responseType: 'arraybuffer' })
            .then((result) => {
                if (result.data) {
                    console.log(typeof result.data);
                    let blob = new Blob([result.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    saveAs(blob, 'ResourceReport.xlsx');
                }
            })
            .catch((err) => {
                console.log('Error on processing Dynamo Repot', err.message || '');
            });
    }; */

    return (
        <>
            <Button variant='contained' color='primary' onClick={generateReport}>
                Generate New Report
            </Button>
            <Button variant='contained' onClick={getReports}>
                <i className={`fa fa-refresh mr-2 ${loading ? 'fa-spin' : ''}`}></i>Refresh List
            </Button>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    label='Start Date'
                    value={startDate}
                    onChange={(e) => setStartDate(e)}
                    autoOk
                    maxDate={endDate}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    label='End Date'
                    minDate={startDate}
                    value={endDate}
                    onChange={(e) => setEndDate(e)}
                    autoOk
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
            {/* <br />
            <Button variant='contained' color='secondary' onClick={resourceReport}>
                Resource Report
            </Button> */}
            <Table stickyHeader size='small' aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={theme.tableHeadCell.style} />
                        <TableCell style={theme.tableHeadCell.style}>Report Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{mapReportsToRows}</TableBody>
            </Table>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        doShowNotification: (type, content) => dispatch(showNotification(type, content)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesReports);
