import React, { Component, Fragment } from 'react';
import { withRouter, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLessonById } from '../../store/actions/lessonActions';
import { removeLesson } from '../../store/actions/adminActions';
import Loader from '../../components/UI/Spinner/Spinner';
import GRADES from '../../constants/grades';
import ChooseActivities from '../../containers/Lessons/ChooseActivities';
import LessonDetails from '../../containers/Lessons/LessonDetails';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Lesson extends Component {
    constructor(props) {
        super(props);

        this.gradeOptions = GRADES.map((grade) => {
            return { label: grade.value, value: grade.id };
        });

        this.state = {
            loading: true,
            lesson: null,
            preview: null,
            selected: null,
            gradeId: this.gradeOptions[0].value,
        };

        this.updateLesson = this.updateLesson.bind(this);
        this.activityAddedCallback = this.activityAddedCallback.bind(this);
        this.removeLessonConfirm = this.removeLessonConfirm.bind(this);
    }

    componentDidMount() {
        this.updateLesson();
    }

    componentDidUpdate(prevProps, prevState) {
        const prevPath = prevProps.location.path;
        const currentPath = this.props.location.path;
        const { lessonId } = this.props.match.params;

        if (prevPath === `/admin/lesson/${lessonId}/activities` && prevPath !== currentPath) {
        }
    }

    removeLessonConfirm() {
        confirmAlert({
            title: 'Remove Lesson',
            message: 'Are you sure to remove this lesson?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.removeLesson(),
                },
                {
                    label: 'No',
                    onClick: () => {
                        return null;
                    },
                },
            ],
        });
    }

    /**
     * Delete lesson
     * @param {int} focusId - focus id
     * @param {int} areaId - area id
     * @param {object} lesson - lesson
     */
    removeLesson = () => {
        const { lesson } = this.state;

        const { focus } = lesson;

        const lessonsData = {
            lessonId: lesson.lessonId,
            focusId: focus.focusId,
            areaId: focus.areaId,
        };

        this.props.onRemoveLesson(lessonsData);

        this.props.history.goBack();
    };

    async updateLesson() {
        const { lessonId } = this.props.match.params;

        const lesson = await this.props.getLesson(lessonId);

        this.setState({
            lesson: lesson,
        });
    }

    activityAddedCallback() {
        this.updateLesson();
    }

    render() {
        if (this.state.lesson === null && <Loader />) {
            return <Loader />;
        }

        const { lesson } = this.state;
        const { focus } = lesson;

        return (
            <Fragment>
                <div className='row'>
                    <div className='col-12'>
                        <div className='page-title-box'>
                            <h4 className='page-title'>Lesson Management</h4>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to='/admin/learning'>Learning</Link>
                                </li>
                                <li className='breadcrumb-item active'>{focus.focusName}</li>
                                <li className='breadcrumb-item active'>
                                    <Link to={`${this.props.match.url}`}>{lesson.lessonName}</Link>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <Route
                    exact
                    path={`${this.props.match.url}`}
                    render={() => {
                        return <LessonDetails lesson={lesson} />;
                    }}
                />
                <Route
                    path={`${this.props.match.url}/activities/:activityType?`}
                    render={() => {
                        return <ChooseActivities lesson={lesson} callback={this.activityAddedCallback} />;
                    }}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    ui: state.ui,
    errors: state.errors,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getLesson: (lessonId) => dispatch(getLessonById(lessonId)),
        onRemoveLesson: (lessonData) => dispatch(removeLesson(lessonData)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRouter(Lesson)));
