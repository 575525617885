import React, { Component } from 'react';
import isEmpty from '../../validation/isEmpty';
import Editable from '../Common/Editable';
import Lesson from '../Admin/Lesson';

import GRADES from '../../constants/grades';

import LessonForm from '../Admin/LessonForm';

class Focus extends Component {
  /**
   * @constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.gradeOptions = GRADES.map(grade => {
      return { label: grade.value, value: grade.id };
    });

    this.state = {
      newLessonName: '',
      gradeId: this.gradeOptions[0].value,
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Handle student class form fields
   * @param e {object} - event object
   * @return {void}
   */
  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  /**
   * Map focuses list to lesson components
   * @return {array<Lesson>}
   */
  mapLessons = () => {
    const { LessonGroups, focusName } = this.props;

    //return no data message (this one should be a component)
    if (isEmpty(LessonGroups)) {
      return (
        <p>
          There is no lessons set up for <b>{focusName}</b> one yet, click the
          "Add Lesson" button
        </p>
      );
    }

    //get the lessons list
    return LessonGroups.map(lessonGroups => {
      return lessonGroups && lessonGroups.lessons.map(lesson => {
        return (
          <Lesson
            key={lesson.lessonId}
            {...lesson}
            displayName={lessonGroups.displayName}
            // saveLessonName={this.props.saveLessonName.bind(
            //     this,
            //     lesson
            // )}
          />
        );
      })
    });
  };

  /**
   * Add new lesson
   * 1. Get the data
   * 2. Call parent method
   * @returns {void}
   */
  addLesson = data => {
    this.props.addLesson(data);
  };

  /**
   * Main render method
   * @return {jsx}
   */
  render() {
    const lessons = this.mapLessons();

    return (
      <div className="col-lg-6 col-md-12 mt-4">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">
              <Editable
                title={this.props.focusName}
                onEditSave={this.props.saveFocusName}
                onRemoveClick={this.props.removeFocus}
              />
            </h5>
          </div>
          <div className="card-body">
            <LessonForm addLesson={this.addLesson} />

            <ul className="list-group">{lessons}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Focus;
