import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import GRADES from '../../constants/grades.js';
import { TYPES } from '../../constants/activityTypes';
import LoadingDots from '../../components/UI/LoadingDots';

import {
    getSimilarActivities,
    getEndLessonActivities,
    updateEndLessonActivities
} from '../../store/actions/adminActions';

class EndLessonActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            endActivities: [null, null, null],
            gameActivities: []
        };
        this.activityByGrades = this.activityByGrades.bind(this);
        this.loadAllGames = this.loadAllGames.bind(this);
        this.loadEndActivity = this.loadEndActivity.bind(this);
        this.updateSelectChange = this.updateSelectChange.bind(this);
        this.gameActivitySelect = this.gameActivitySelect.bind(this);
        this.updateEndActivities = this.updateEndActivities.bind(this);
    }

    componentDidMount() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        this.loadAllGames();
        this.loadEndActivity();
    }

    updateSelectChange(event, gradeId) {
        let { endActivities } = this.state;

        endActivities[gradeId] = event.target.value;
        this.setState({ endActivities });
    }

    gameActivitySelect(gradeId) {
        const { gameActivities, endActivities } = this.state;
        if (gameActivities.length === 0) {
            return;
        }

        const optionsArray = gameActivities[gradeId];
        const selectOptions = optionsArray.map(option => (
            <option key={`${gradeId}-${option.activityId}`} value={option.activityId}>
                {option.content.title} ( {option.activityId} )
            </option>
        ));
        const selectedValue = endActivities[+gradeId - 1] !== null ? endActivities[+gradeId - 1] : '';
        return (
            <select
                value={selectedValue}
                className='form-control'
                onChange={e => {
                    this.updateSelectChange(e, +gradeId - 1);
                }}
            >
                <option value=''>Select Game</option>
                {selectOptions}
            </select>
        );
    }

    async loadAllGames() {
        const allGames = await this.props.getSimilarActivities(TYPES.GAME.ID);
        let gameActivities = [];

        for (let g in GRADES) {
            const grade = GRADES[g];
            if (gameActivities[grade.id] === undefined) {
                gameActivities[grade.id] = [];
            }
            const gameForGrade = allGames.filter(game => game.gradeId === grade.id);
            gameActivities[grade.id] = gameForGrade;
        }

        this.setState({ gameActivities });
    }

    async loadEndActivity() {
        let endActivities;
        await this.props.getEndLessonActivities().then(result => {
            endActivities = result ? result.option : [null, null, null];
        });

        this.setState({ endActivities, isLoading: false });
    }

    async updateEndActivities() {
        const { endActivities } = this.state;
        await this.props.updateEndLessonActivities(endActivities);
    }

    activityByGrades() {
        return GRADES.map(grade => {
            if (grade.id !== 0) {
                return (
                    <div className='grade-level' key={grade.id}>
                        <p>
                            Grade: {grade.value} {this.gameActivitySelect(grade.id)}
                        </p>
                    </div>
                );
            }

            return null;
        });
    }

    render() {
        const { isLoading } = this.state;

        return (
            <Fragment>
                <div className='row mb-4'>
                    <div className='col-12'>
                        <div className='page-title-box'>
                            <h4>End Lesson Activity</h4>
                        </div>
                    </div>
                </div>
                {isLoading && <LoadingDots />}
                <div className='row'>
                    <div className='col-12'>
                        <div className='card-box'>
                            <p>Manage end lesosn activity for each grade levels</p>
                            {this.activityByGrades()}
                            <button className='btn btn-primary waves-effect' onClick={this.updateEndActivities}>
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    ui: state.ui
});

const mapDispatchToProps = dispatch => {
    return {
        getSimilarActivities: activityData => dispatch(getSimilarActivities(activityData)),
        getEndLessonActivities: () => dispatch(getEndLessonActivities()),
        updateEndLessonActivities: updateData => dispatch(updateEndLessonActivities(updateData))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndLessonActivity);
