import React, {useState, useEffect} from 'react';
import Loadable from 'react-loadable';
import Loader from 'components/UI/Spinner/Spinner';
import {Typography, Button} from '@material-ui/core';
import NewNotificationForm from 'containers/Notifications/NewNotificationForm';
import ExistingNotificationForm from 'containers/Notifications/ExistingNotificationForm';

const PlatformNotifications = (props) => {
    const [formNo, setFormNo] = useState(0);

    const formList = [
        {
            title: 'Create Notification',
            step: 0,
            component: NewNotificationForm,
        },
        {
            title: 'Send Existing Notification',
            step: 1,
            component: ExistingNotificationForm,
        }
    ];

    const CurrentStepComponent = formList[formNo].component;

    return (
        <>
        <div>
            <Typography variant='h6'>
                Choose from the following actions:
            </Typography>
            <div style={{margin:10}} className='row'>
            <button className="btn btn-custom waves-effect pull-right" style={{margin:10}} onClick={() => setFormNo(0)}>
                Create New Notification
            </button>
            <button className="btn btn-custom waves-effect pull-right" style={{margin:10}} onClick={() => setFormNo(1)}>
                Send Existing Notification
            </button>
            </div>
            <CurrentStepComponent />
        </div>
        </>
    )
}

// Pull used redux state into props
const mapStateToProps = (state) => {
    return {
    };
};

// Setup dispatch for redux actions and set as functions on props
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

// Export component connected to redux, including the Formik and Yup validation. Connect is called outside of Fromik to allow redux state to be used in Formik.
export default PlatformNotifications;