import React, { useState, useEffect } from 'react';
import axios from 'config/axios';

import { connect } from 'react-redux';

import LoadingDots from 'components/UI/LoadingDots';
import AddNewReleaseForm from './AddNewReleaseForm';
import ListReleases from './ListReleases';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { showNotification } from 'store/actions/uiActions';

import './ReleaseGuide.scss';

function ReleaseGuide(props) {
    const [releases, setReleases] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editing, setEditing] = useState(null);

    useEffect(() => {
        loadAllReleases();
        //eslint-disable-next-line
    }, []);

    const loadAllReleases = async () => {
        setLoading(true);
        await axios
            .get('/api/admin/release/all')
            .then((response) => {
                setReleases(response.data);
            })
            .catch((err) => {
                console.error(err);
            });

        setLoading(false);
    };

    const submitNewRelease = async (values) => {
        await axios
            .post('/api/admin/release/new', values)
            .then((res) => {
                //alert success and
                setReleases((existing) => {
                    return [res.data, ...existing];
                });
                setShowForm(false);
                props.showNotification('success', `Created new release v${values.version}`);
                return;
            })
            .catch((err) => {
                props.showNotification('warning', 'Failed to create new release.');
                if (err.message) {
                    console.error(err.message);
                } else if (err.response.data) {
                    console.error(err.response.data);
                }
            });
    };

    const updateRelease = async (values) => {
        /* console.log('Editing Values: ', values);
        return; */
        await axios
            .put(`/api/admin/release/${values.version}`, values)
            .then((res) => {
                console.log(res.data);
                //alert success and
                setReleases((existing) => {
                    const updatedRelease = [...existing];
                    const updateIndex = existing.findIndex((el) => el.version === values.version);

                    updatedRelease[updateIndex] = res.data;
                    return updatedRelease;
                });

                setShowForm(false);

                props.showNotification('success', `Updated release v${values.version}`);
                return;
            })
            .catch((err) => {
                props.showNotification('warning', 'Failed to update new release.');
                if (err.message) {
                    console.error(err.message);
                } else if (err.response.data) {
                    console.error(err.response.data);
                }
            });
    };

    const removeRelease = async (ver) => {
        // console.log('Removing version: ', ver);
        // return;

        await axios.delete('/api/admin/release/' + ver).then(() => {
            setReleases((existing) => {
                const removed = existing.filter((rs) => rs.version !== ver);
                return removed;
            });
        });
    };

    return (
        <div className='row '>
            <div className='col-12'>
                <div className='page-title-box mb-3'>
                    <h4 className='page-title'>Manage Release Slides</h4>
                </div>
                <div className='release-content'>
                    <div className='mb-2'>
                        <button
                            className='btn btn-primary'
                            onClick={() => {
                                setShowForm(true);
                            }}
                        >
                            Add New Release
                        </button>
                        <button className='btn btn-secondary ml-2' onClick={loadAllReleases}>
                            {' '}
                            Reload <i className='ml-2 fa fa-refresh' />{' '}
                        </button>
                    </div>
                    {loading ? (
                        <LoadingDots />
                    ) : (
                        <ListReleases
                            releases={releases}
                            removeRelease={removeRelease}
                            setEditing={setEditing}
                            showForm={() => {
                                setShowForm(true);
                            }}
                        />
                    )}
                </div>

                <Dialog
                    open={showForm}
                    onClose={() => {
                        setShowForm(false);
                        setEditing(null);
                    }}
                    maxWidth='md'
                    fullWidth={true}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >
                    <DialogTitle>{editing === null ? 'Add new' : 'Update'} relase details.</DialogTitle>
                    <DialogContent>
                        <AddNewReleaseForm
                            submitNew={editing === null ? submitNewRelease : updateRelease}
                            {...editing}
                            isEditing={editing !== null}
                        />
                    </DialogContent>
                    <DialogActions>
                        <button
                            className='btn btn-outline'
                            onClick={() => {
                                setShowForm(false);
                                setEditing(null);
                            }}
                        >
                            Cancel
                        </button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        showNotification: (type, content) => dispatch(showNotification(type, content)),
    };
};

export default connect(null, mapDispatchToProps)(ReleaseGuide);
