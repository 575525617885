import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableRow, Typography, TableHead, TableBody } from '@material-ui/core';
import axios from 'config/axios';
import { withFormik, Form, Field } from 'formik';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { theme } from 'assets/customisedMUI';
import isEmpty from 'validation/isEmpty';

const RedisManager = (props) => {
    const [redisHashes, setRedisHashes] = useState([]);
    const [redisData, setRedisData] = useState({});
    const [redisHashData, setRedisHashData] = useState([]);
    const [viewAllHashData, setViewAllHashData] = useState(false);
    const [viewAllKeyData, setViewAllKeyData] = useState(false);
    const [deleteKeyDataView, setDeleteKeyDataView] = useState(false);
    const { touched, handleSubmit, isSubmitting, errors, values, setFieldValue, setValues, setData } = props;

    useEffect(() => {
        const fetchRedisHashes = async () => {
            const hashes = await axios.get(`api_v2/admin/redis/getRedisHashes`);
            hashes.data && setRedisHashes(hashes.data.redisHashes);
        };
        fetchRedisHashes();
    }, []);

    useEffect(() => {
        const fetchRedisHashData = async () => {
            const hashData = await axios.get(`api_v2/admin/redis/getRedisHashData?redisHash=${values.redisHash}`);
            !isEmpty(hashData.data) && !isEmpty(hashData.data.redisHashData)
                ? setRedisHashData(Object.entries(hashData.data.redisHashData))
                : setRedisHashData([]);
        };
        fetchRedisHashData();
    }, [viewAllHashData, values.redisHash]);

    useEffect(() => {
        const fetchRedisData = async () => {
            if (props.values && props.values.result) {
                setRedisData(props.values.result);
            }
        };
        fetchRedisData();
    }, [props.values]);

    return (
        <>
            <div>
                <Typography variant='h5'>Redis Manager</Typography>
            </div>
            <div style={{ width: '50vw' }}>
                <div className='form-group row'>
                    <div className='col-sm-8 col-12'>
                        <Form>
                            <div className='form-group row'>
                                <label className='col-3 col-form-label'>Select redis hash * :</label>
                                <div className='col'>
                                    <Field
                                        component='select'
                                        name='redisHash'
                                        className={classnames('form-control', {
                                            'is-invalid': touched.redisHash && errors.redisHash,
                                        })}
                                    >
                                        {redisHashes.map((hash) => {
                                            return (
                                                <option key={hash} value={hash}>
                                                    {hash}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    {touched.redisHash && errors.redisHash && (
                                        <div className='invalid-feedback'>{errors.redisHash}</div>
                                    )}
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className='row' style={{ marginBottom: 20 }}>
                    <div className='col-4'>
                        <button
                            onClick={(e) => {
                                setViewAllHashData(true);
                                setViewAllKeyData(false);
                                setDeleteKeyDataView(false);
                            }}
                            className='btn btn-info waves-effect pull-right'
                        >
                            View All Hash Data
                        </button>
                    </div>
                    <div className='col-4'>
                        <button
                            onClick={(e) => {
                                setViewAllKeyData(true);
                                setViewAllHashData(false);
                                setDeleteKeyDataView(false);
                            }}
                            className='btn btn-info waves-effect pull-right'
                        >
                            View All Key Data
                        </button>
                    </div>
                    <div className='col-4'>
                        <button
                            onClick={(e) => {
                                setDeleteKeyDataView(true);
                                setViewAllKeyData(false);
                                setViewAllHashData(false);
                            }}
                            className='btn btn-info waves-effect pull-right'
                        >
                            Delete Key Data
                        </button>
                    </div>
                </div>
                {viewAllHashData && (
                    <>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={theme.tableHeadCell.style}>Key</TableCell>
                                        <TableCell style={theme.tableHeadCell.style}>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {redisHashData &&
                                        redisHashData.map((key, index) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{key[0]}</TableCell>
                                                        <TableCell>{key[1]}</TableCell>
                                                    </TableRow>
                                                </>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                    </>
                )}
                {viewAllKeyData && (
                    <>
                        <div className='form-group row'>
                            <div className='col-sm-8 col-12'>
                                <Form>
                                    <div className='form-group row'>
                                        <label className='col-3 col-form-label'>Redis Key * :</label>
                                        <div className='col'>
                                            <Field
                                                className={classnames('form-control', {
                                                    'is-invalid': touched.redisKey && errors.redisKey,
                                                })}
                                                name='redisKey'
                                                type='text'
                                            />
                                            {touched.redisKey && errors.redisKey && (
                                                <div className='invalid-feedback'>{errors.redisKey}</div>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className='form-group row'>
                            <div className='col-sm-8 col-12'>
                                <Form>
                                    <div className='form-group row'>
                                        <label className='col-3 col-form-label'>Joining symbol * :</label>
                                        <div className='col'>
                                            <Field
                                                className={classnames('form-control', {
                                                    'is-invalid': touched.joiningSymbol && errors.joiningSymbol,
                                                })}
                                                name='joiningSymbol'
                                                type='text'
                                            />
                                            {touched.joiningSymbol && errors.joiningSymbol && (
                                                <div className='invalid-feedback'>{errors.joiningSymbol}</div>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className='form-group row'>
                            <div className='col-sm-8 col-12'>
                                <Form>
                                    <div className='form-group row'>
                                        <label className='col-3 col-form-label'>Redis Key Id * :</label>
                                        <div className='col'>
                                            <Field
                                                className={classnames('form-control', {
                                                    'is-invalid': touched.redisKeyId && errors.redisKeyId,
                                                })}
                                                name='redisKeyId'
                                                type='text'
                                            />
                                            {touched.redisKeyId && errors.redisKeyId && (
                                                <div className='invalid-feedback'>{errors.redisKeyId}</div>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-8'>
                                <button
                                    disabled={isSubmitting}
                                    onClick={async (e) => {
                                        handleSubmit(e);
                                    }}
                                    className='btn btn-primary waves-effect pull-right'
                                    type='submit'
                                >
                                    View data
                                </button>
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-3 col-form-label'>Redis Data: </label>
                            <div className='col-form-label'>
                                <h4>{JSON.stringify(redisData)}</h4>
                            </div>
                        </div>
                        <div className='form-group row'>
                            <div className='col-sm-8 col-md-12 col-12'>
                                <Form>
                                    <div className='form-group row'>
                                        <label className='col-3 col-form-label'>Edit Redis Data :</label>
                                        <div className='col'>
                                            <Field
                                                className={classnames('form-control', {
                                                    'is-invalid': touched.rData && errors.rData,
                                                })}
                                                name='rData'
                                                type='text'
                                            />
                                            {touched.rData && errors.rData && (
                                                <div className='invalid-feedback'>{errors.rData}</div>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <button
                                    disabled={isSubmitting}
                                    onClick={async (e) => {
                                        await setFieldValue('isChanged', true);
                                        handleSubmit(e);
                                    }}
                                    className='btn btn-success waves-effect pull-right'
                                    type='submit'
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </>
                )}
                {deleteKeyDataView && (
                    <>
                        <div className='form-group row'>
                            <div className='col-sm-8 col-12'>
                                <Form>
                                    <div className='form-group row'>
                                        <label className='col-3 col-form-label'>Setting to be deleted * :</label>
                                        <div className='col'>
                                            <Field
                                                className={classnames('form-control', {
                                                    'is-invalid': touched.deleteSetting && errors.deleteSetting,
                                                })}
                                                name='deleteSetting'
                                                type='text'
                                            />
                                            {touched.deleteSetting && errors.deleteSetting && (
                                                <div className='invalid-feedback'>{errors.deleteSetting}</div>
                                            )}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-8'>
                                <button
                                    disabled={isSubmitting}
                                    onClick={async (e) => {
                                        await setFieldValue('isDeleted', true);
                                        handleSubmit(e);
                                    }}
                                    className='btn btn-danger waves-effect pull-right'
                                    type='submit'
                                >
                                    Delete Setting
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

// Pull used redux state into props
const mapStateToProps = (state) => {
    return {};
};

// Setup dispatch for redux actions and set as functions on props
const mapDispatchToProps = (dispatch) => {
    return {};
};

// Export component connected to redux, including the Formik and Yup validation. Connect is called outside of Fromik to allow redux state to be used in Formik.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withFormik({
        mapPropsToValues({ redisHash, redisKey, redisKeyId, rData, joiningSymbol, deleteSetting }) {
            return {
                redisHash: redisHash || 'ACCOUNT_SETTINGS',
                redisKey: redisKey || 'teacherId',
                redisKeyId: redisKeyId || 0,
                rData: rData,
                joiningSymbol: joiningSymbol || ':',
                deleteSetting: deleteSetting,
            };
        },
        async handleSubmit(values, { resetForm, setErrors, setSubmitting, setFieldValue, props }) {
            setSubmitting(true);

            if (values.isChanged && values.rData && values.redisKeyId && !values.isDeleted) {
                const saveChanges = await axios.put(
                    `api_v2/admin/redis?redisHash=${values.redisHash}&redisKey=${
                        values.redisKey + values.joiningSymbol
                    }&redisKeyId=${values.redisKeyId}`,
                    { rData: JSON.parse(values.rData) }
                );
                if (saveChanges.data && saveChanges.data.success) {
                    await setFieldValue('result', JSON.parse(values.rData));
                    await setFieldValue('isChanged', false);
                    setSubmitting(false);
                }
            } else if (values.isDeleted && values.deleteSetting && !values.isChanged) {
                const deleteData = await axios.put(
                    `api_v2/admin/redis?redisHash=${values.redisHash}&deleteSetting=${values.deleteSetting}&redisKey=${
                        values.redisKey + values.joiningSymbol
                    }&redisKeyId=${values.redisKeyId}`
                );
            } else {
                const result = await axios.get(
                    `api_v2/admin/redis/getRedisData?redisHash=${values.redisHash}&redisKey=${
                        values.redisKey + values.joiningSymbol
                    }&redisKeyId=${values.redisKeyId}`
                );
                if (result) {
                    await setFieldValue('result', result.data);
                    await setFieldValue('rData', JSON.stringify(result.data));
                    setSubmitting(false);
                }
            }
        },
        enableReinitialize: true,
    })(RedisManager)
);
