import axios from 'config/axios';
import { GET_ERRORS, PUSH_SEEDS, INIT_CLASSES } from './actionTypes';
import { showNotification } from './uiActions';
import Notification from '../../components/UI/Notification';
import { saveAs } from 'file-saver';
import cloneDeep from 'lodash/cloneDeep';
/**
 * Get all the learningAreas
 * 1. Get two level array from server
 * 2. Dispatch to redux
 * @return {void}
 */
export const getLessonById = (lessonId) => async (dispatch) => {
    try {
        //const result = await axios.get('/api/lessons/' + lessonId);
        console.log('GETTING LESSON');
        const result = await axios.get('/api/lessons/' + lessonId);
        console.log('RESULT', result);
        if (result) {
            return result.data;
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

/**
 * Get lesson as a demo for a teacher
 * @return {Promise<Lesson>}
 * @return {void}
 */
export const getClassLesson = (token) => async (dispatch) => {
    //return new Promise(async (resolve, reject) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`/api/lessons/teacher/${token}`);
            if (result) {
                resolve(result.data);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            reject(err);
        }
    });
};

/**
 * Get lesson content for a student
 * @return {Promise<Lesson>}
 */
export const getStudentLesson = (studentId) => async (dispatch) => {
    //return new Promise(async (resolve, reject) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`/api/lessons/student/${studentId}`);
            if (result) {
                resolve(result.data);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            reject(err);
        }
    });
};

/**
 *
 * @param data
 * @return {function(*): Promise}
 */
export const updateLessonSettings = (data) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(`/api/lessons/settings`, data);
            if (result) {
                //show notification
                dispatch(showNotification(Notification.SUCCESS, 'Weather Report info updated'));
                resolve(result.data);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            reject(err);
        }
    });
};

/**
 *
 * @param data
 * @return {function(*): Promise}
 */
export const pushAnalytics = (data) => async (dispatch) => {
    try {
        let endGameResponse = null;
        let resp = null;
        await axios.post('/api/lessons/analytics', data).then((response) => {
            if (response.data.activityType === 301) {
                endGameResponse = response.data;
            } else {
                resp = response;
            }
        });

        if (endGameResponse !== null) {
            return endGameResponse;
        }
        return resp;
    } catch (err) {
        console.error(err);
    }
};

export const pushSeeds = (data) => async (dispatch) => {
    try {
        const result = await axios.post('/api/lessons/pushSeeds', data);

        dispatch({
            type: PUSH_SEEDS,
            payload: result.data.result,
        });
    } catch (err) {
        console.error(err);
    }
};

export const pushDrawing = (data) => (dispatch) => {
    try {
        axios.post('/api/lessons/pushDrawing', data);
    } catch (err) {
        console.error(err);
    }
};

export const unitReportUpdateTime = (unitId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`/api/teachers/lastUpdated/${unitId}`);
            if (result) {
                resolve(result.data);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            dispatch(showNotification(Notification.DANGER, 'Failed to export unit data'));
            reject(err);
        }
    });
};

export const exportUnitCurriculumReportCSV = (unitId, fileName) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`/api/teachers/unitCurriculum/${unitId}`, {
                responseType: 'arraybuffer',
            });
            if (result.data === null || result.data === '') {
                dispatch(showNotification(Notification.DANGER, 'Failed to export unit data'));
                resolve(false);
            } else {
                let blob = new Blob([result.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    // endings: 'native'
                });

                saveAs(blob, fileName);
                dispatch(showNotification(Notification.SUCCESS, 'Unit curriculum report exported successfully'));
                resolve(true);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            dispatch(showNotification(Notification.DANGER, 'Failed to export unit data'));
            reject(err);
        }
    });
};
/**
 * Add units updated curriculum info as xlsx to redis
 * @param {int} unitId
 */
export const updateUnitCurriculumRedis = (unitId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`/api/teachers/py/${unitId}`);

            if (result.data) {
                dispatch(showNotification(Notification.SUCCESS, 'Unit curriculum report updated'));
                resolve(true);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            dispatch(showNotification(Notification.DANGER, 'Failed to update unit curriculum data'));
            reject(err);
        }
    });
};

/**
 * Update a student Activity with provided new data for recordId
 * @param data
 * @return {function(*): Promise}
 */
export const updateAnalytics = (data, recordId) => async (dispatch) => {
    try {
        await axios.post(`/api/lessons/UpdateAnalytics/${recordId}`, data);
    } catch (err) {
        console.error(err);
    }
};

/**
 * Add lessonDone date to lessons
 * @param lessonId int
 * @param unitId int
 */
export const setLessonComplete = (lessonId, unitId) => async (dispatch) => {
    try {
        await axios.post(`/api/lessons/setLessonComplete`, {
            lessonId,
            unitId,
        });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Get Lesson Activity for lesson type 2
 * @param activityId
 */

export const getActivityById = (activityId) => async (dispatch) => {
    try {
        return await axios.get('/api/admin/activityById/' + activityId).then((res) => {
            return res.data;
        });
    } catch (err) {
        console.log('Error getting activityId', err);
    }
};
