/**-------------------- REACT AND REDUX IMPORTS --------------------**/
import React, { useEffect, useState } from 'react';

/**-------------------- FORMIK, FORM FIELDS AND YUP VALIDATION --------------------**/
import SelectListGroup from 'components/Common/SelectListGroup';
import TextFieldGroup from 'components/Common/TextFieldGroup';
import TextAreaFieldGroup from 'components/Common/TextAreaFieldGroup';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

/**-------------------- UTILITIES --------------------**/
import { numbersToSelectOptions } from '@lsgo/lsgo-common';

const UnitOverviewStep = (props) => {
    // Destructure props
    const { errors, values } = props;
    const { maxUnitWeeks, maxUnitLessons } = props;

    const lessonsOptions = numbersToSelectOptions(maxUnitLessons);
    const weekOptions = numbersToSelectOptions(maxUnitWeeks);

    /**-------------------- ASYNC SIDE EFFECT HANDLING --------------------**/
    // When unit name is invalid, call the parent function to disable the progression of setup.
    useEffect(() => {
        props.setUnitDataValid(errors.unitName ? 0 : 1);
        //eslint-disable-next-line
    }, [errors.unitName]);

    return (
        <div className='col-lg-5 card-box'>
            <Form>
                <TextFieldGroup
                    name='unitName'
                    label='Unit name'
                    value={props.unit.unitName}
                    error={errors.unitName}
                    loading={props.loading}
                    onChange={(e) => {
                        props.setValues({ ...values, unitName: e.target.value });
                        props.changeOfValue(e);
                    }}
                />
                <SelectListGroup
                    label='Number of weeks'
                    name='unitDuration'
                    value={props.unit.unitDuration}
                    loading={props.loading}
                    onChange={(e) => {
                        props.setValues({ ...values, unitDuration: e.target.value });
                        props.changeOfValue(e);
                    }}
                    options={weekOptions}
                />
                <SelectListGroup
                    label='Number of lessons per week'
                    name='lessonsAmount'
                    value={props.unit.lessonsAmount}
                    loading={props.loading}
                    onChange={(e) => {
                        const newLessonDay = [...props.unit.lessonDay.filter((item, index) => index < e.target.value)];
                        props.setValues({ ...values, lessonsAmount: e.target.value });
                        const modifyObject = { lessonDay: newLessonDay, [e.target.name]: e.target.value };
                        props.changeUnitData(modifyObject);
                    }}
                    options={lessonsOptions}
                />
                <TextAreaFieldGroup
                    label='Description'
                    name='description'
                    value={props.unit.description}
                    loading={props.loading}
                    onChange={(e) => {
                        props.setValues({ ...values, description: e.target.value });
                        props.changeOfValue(e);
                    }}
                    options={lessonsOptions}
                />
            </Form>
        </div>
    );
};

export default withFormik({
    mapPropsToValues(props) {
        return {
            unitName: props.unit.unitName || 'Unit',
            unitDuration: props.unit.unitDuration || 5,
            lessonsAmount: props.unit.lessonsAmount || 2,
            description: props.unit.description || '',
        };
    },
    validationSchema: Yup.object().shape({
        unitName: Yup.string()
            .max(60, 'Unit name must be at most 30 characters')
            .required('Unit name is a required field'),
    }),
})(UnitOverviewStep);
