import React, { useState, useRef } from 'react';

export default function ReleaseCarousel({ data, createdAt, ...props }) {
    const [activeSlide, setActiveSlide] = useState(0);
    // const

    const changeSlide = (opt) => {
        const lastSlide = data.length - 1;
        let newIndex = activeSlide;
        switch (opt) {
            case 'prev':
                newIndex = newIndex === 0 ? lastSlide : newIndex - 1;
                break;
            case 'next':
                newIndex = newIndex === lastSlide ? 0 : newIndex + 1;
                break;
        }

        setActiveSlide(newIndex);
    };

    return (
        <div className='release-carousel'>
            {data.map((rs, index) => (
                <div
                    className={`slides fading ${index === activeSlide ? 'active' : ''}`}
                    key={`release-${createdAt}-${index}`}
                >
                    {rs.image && (
                        <div className='image-container'>
                            <img src={rs.image} alt={rs.title} loading='lazy' />
                        </div>
                    )}
                    <div className=''>
                        <h4>{rs.title}</h4>
                        <p style={{ whiteSpace: 'break-spaces' }}>{rs.desc}</p>
                    </div>
                </div>
            ))}
            <div className='slides-count-indicator'>
                <span>
                    {activeSlide + 1}/{data.length}
                </span>
            </div>
            <div
                className='prev carousel-control'
                onClick={() => {
                    changeSlide('perv');
                }}
            >
                <i className='fa fa-chevron-left' />
            </div>
            <div
                className='next carousel-control'
                onClick={() => {
                    changeSlide('next');
                }}
            >
                <i className='fa fa-chevron-right' />
            </div>
        </div>
    );
}
