import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { TextField, Button, MenuItem } from '@material-ui/core';
import Wysiwug from 'components/UI/Wysiwyg';

const TrialSettings = (props) => {
    const trialData = props.options ? JSON.parse(props.options.option) : {};

    const formik = useFormik({
        initialValues: {
            dialog1_content: trialData.dialog1_content || '',
            dialog1_action: trialData.dialog1_action || '',
            dialog1_action_text: trialData.dialog1_action_text || '',
            dialog1_image: trialData.dialog1_image || '',
            dialog1_size: trialData.dialog1_size || '',
            dialog2_content: trialData.dialog2_content || '',
            dialog2_action: trialData.dialog2_action || '',
            dialog2_action_text: trialData.dialog2_action_text || '',
            dialog2_image: trialData.dialog2_image || '',
            dialog2_size: trialData.dialog2_size || '',
            dialog3_content: trialData.dialog3_content || '',
            dialog3_action: trialData.dialog3_action || '',
            dialog3_action_text: trialData.dialog3_action_text || '',
            dialog3_image: trialData.dialog3_image || '',
            dialog3_size: trialData.dialog3_size || '',
        },
        validationSchema: yup.object({
            // : yup.string().required('Name is required'),
        }),
        onSubmit: async (values) => {
            // console.log('Form Values:', values);
            const updated = await props.updateOption({
                option: JSON.stringify(values),
                optionId: props.options.optionId,
            });

            console.log('Updated: ', updated);
            formik.setSubmitting(false);
        },
    });

    //setting formikl value with html content from wysiwyg editor
    const setWelcomeDialogContent = (newContent) => {
        formik.setFieldValue('dialog1_content', newContent);
    };

    //setting formikl value with html content from wysiwyg editor for dialog2
    const setWelcomeDialogContent2 = (newContent) => {
        formik.setFieldValue('dialog2_content', newContent);
    };

    //setting formikl value with html content from wysiwyg editor
    const setWelcomeDialogContent3 = (newContent) => {
        formik.setFieldValue('dialog3_content', newContent);
    };

    const dialogSizes = [
        { name: 'Small', id: 'sm' },
        { name: 'Medium', id: 'md' },
        { name: 'large', id: 'lg' },
    ];

    const dialogActions = [
        { name: 'Close Dialog', id: 'dialogClose' },
        { name: 'Send verification Email', id: 'sendVerificationEmail' },
    ];

    return (
        <div className='w-100'>
            <form onSubmit={formik.handleSubmit}>
                <div className='my-4'>
                    <h5>Welcome Dialog</h5>
                    <hr />
                    <TextField
                        className='mr-2'
                        style={{ width: 320 }}
                        name='dialog1_size'
                        label='Welcome dialog size'
                        value={formik.values.dialog1_size}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog1_size && Boolean(formik.errors.dialog1_size)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog1_size && formik.errors.dialog1_size}
                        select={true}
                    >
                        {dialogSizes.map((ds) => (
                            <MenuItem key={`dialog1-size-item-${ds.id}`} value={ds.id}>
                                {ds.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        className='mr-2'
                        style={{ width: 320 }}
                        name='dialog1_action'
                        label='Welcome dialog action'
                        value={formik.values.dialog1_action}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog1_action && Boolean(formik.errors.dialog1_action)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog1_action && formik.errors.dialog1_action}
                        select={true}
                    >
                        {dialogActions.map((ds) => (
                            <MenuItem key={`dialog1-action-item-${ds.id}`} value={ds.id}>
                                {ds.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        style={{ width: 320 }}
                        name='dialog1_action_text'
                        label='Action Button text'
                        value={formik.values.dialog1_action_text}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog1_action_text && Boolean(formik.errors.dialog1_action_text)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog1_action_text && formik.errors.dialog1_action_text}
                    />
                    <Wysiwug
                        htmlContent={formik.values.dialog1_content}
                        previewTitle='Welcome Content'
                        callback={setWelcomeDialogContent}
                    />
                </div>
                <div className='my-4'>
                    <h5>Verify account dialog.</h5>
                    <hr />
                    <TextField
                        className='mr-2'
                        style={{ width: 320 }}
                        name='dialog2_size'
                        label='Verify dialog size'
                        value={formik.values.dialog2_size}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog2_size && Boolean(formik.errors.dialog2_size)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog2_size && formik.errors.dialog2_size}
                        select={true}
                    >
                        {dialogSizes.map((ds) => (
                            <MenuItem key={`dialog2-size-item-${ds.id}`} value={ds.id}>
                                {ds.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        className='mr-2'
                        style={{ width: 320 }}
                        name='dialog2_action'
                        label='Welcome dialog action'
                        value={formik.values.dialog2_action}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog2_action && Boolean(formik.errors.dialog2_action)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog2_action && formik.errors.dialog2_action}
                        select={true}
                    >
                        {dialogActions.map((ds) => (
                            <MenuItem key={`dialog2-action-item-${ds.id}`} value={ds.id}>
                                {ds.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        style={{ width: 320 }}
                        name='dialog2_action_text'
                        label='Action Button text'
                        value={formik.values.dialog2_action_text}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog2_action_text && Boolean(formik.errors.dialog2_action_text)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog2_action_text && formik.errors.dialog2_action_text}
                    />
                    <Wysiwug
                        htmlContent={formik.values.dialog2_content}
                        previewTitle='Welcome Content'
                        callback={setWelcomeDialogContent2}
                    />
                </div>
                <div className='my-4'>
                    <h5>Welcome Dialog</h5>
                    <hr />
                    <TextField
                        className='mr-2'
                        style={{ width: 320 }}
                        name='dialog3_size'
                        label='Welcome dialog size'
                        value={formik.values.dialog3_size}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog3_size && Boolean(formik.errors.dialog3_size)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog3_size && formik.errors.dialog3_size}
                        select={true}
                    >
                        {dialogSizes.map((ds) => (
                            <MenuItem key={`dialog3-size-item-${ds.id}`} value={ds.id}>
                                {ds.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        className='mr-2'
                        style={{ width: 320 }}
                        name='dialog3_action'
                        label='Welcome dialog action'
                        value={formik.values.dialog3_action}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog3_action && Boolean(formik.errors.dialog3_action)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog3_action && formik.errors.dialog3_action}
                        select={true}
                    >
                        {dialogActions.map((ds) => (
                            <MenuItem key={`dialog3-action-item-${ds.id}`} value={ds.id}>
                                {ds.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        style={{ width: 320 }}
                        name='dialog3_action_text'
                        label='Action Button text'
                        value={formik.values.dialog3_action_text}
                        onChange={formik.handleChange}
                        error={formik.touched.dialog3_action_text && Boolean(formik.errors.dialog3_action_text)}
                        InputLabelProps={{ shrink: true }}
                        helperText={formik.touched.dialog3_action_text && formik.errors.dialog3_action_text}
                    />
                    <Wysiwug
                        htmlContent={formik.values.dialog3_content}
                        previewTitle='Welcome Content'
                        callback={setWelcomeDialogContent3}
                    />
                </div>

                <div className='my-4 text=-right'>
                    <Button variant='contained' color='primary' type='submit'>
                        Update{formik.isSubmitting && <i className='ml-2 fa fa-spinner fa-spin' />}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default TrialSettings;
