import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ReactDataGrid from "react-data-grid";
import isEmpty from "validation/isEmpty";

const DataGrid = (props) => {
  const [rows, setRows] = useState(props.rows);
  const [columns, setColumns] = useState(props.columns);
  const { validations } = props;

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const onGridRowsUpdate = ({ fromRow, toRow, updated }) => {
    let data = updated;
    for (const [k, v] of Object.entries(data)) {
      if (typeof v === "object") {
        data[k] = typeof data[k] === "object" ? data[k].label : data[k];
        // data[k] = JSON.stringify(v).replace(/['"]+/g, "");
      }
      if (validations && !isEmpty(validations)) {
        const field = Object.keys(validations).find((key) => key === k);
        if (field && typeof v === validations[field][0]) {
          if (validations[field][1] && !validations[field][1].test(v)) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    const newRows = rows.slice();
    for (let i = fromRow; i <= toRow; i++) {
      newRows[i] = { ...newRows[i], ...data };
    }
    return setRows(newRows);
  };

  return (
    <div className="text-center">
      <ReactDataGrid
        columns={columns.map((c) => ({ ...c, resizable: true }))}
        rowGetter={(i) => rows[i]}
        rowsCount={rows.length}
        onGridRowsUpdated={onGridRowsUpdate}
        enableCellSelect={true}
      />
      <div style={{ margin: 20 }}>
        <Button
          onClick={() => props.onClose()}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => props.updateData(rows)}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default DataGrid;
