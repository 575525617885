import {
  GET_ERRORS,
  ADMIN_ADD_LEARNING_FOCUS,
  ADMIN_EDIT_LEARNING_FOCUS,
  ADMIN_DELETE_LEARNING_FOCUS,
  ADMIN_ADD_LESSON,
  ADMIN_EDIT_LESSON,
  ADMIN_DELETE_LESSON,
  ADMIN_FOCUS_LOADER,
} from "./actionTypes";
import axios from "config/axios";
import Notification from "../../components/UI/Notification";
import { showNotification } from "./uiActions";
import { isEmpty, reject } from "lodash";

/**
 * Dispatch new focus action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const addFocus = (result, areaId) => (dispatch) => {
  dispatch({
    type: ADMIN_ADD_LEARNING_FOCUS,
    payload: { focus: result.data, areaId: areaId },
  });
};

export const focusLoader = () => (dispatch) => {
  dispatch({
    type: ADMIN_FOCUS_LOADER,
  });
};

/**
 * Create new focus record
 * 1. send data to the server
 * 2. update redux
 * 3. show notification
 * @param  {object} focusData
 * @return {void}
 */
export const createFocus = (focusData) => async (dispatch) => {
  try {
    const result = await axios.post("/api/admin/focus/create", focusData);

    if (result) {
      //add new focus
      dispatch(addFocus(result, focusData.areaId));

      //show notification
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "New quality was created successfully"
        )
      );
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**
 * Dispatch student remove to redux
 * @param {object} student - student
 * @return {void}
 */
export const removeFocusRedux = (focusId, areaId) => (dispatch) => {
  dispatch({
    type: ADMIN_DELETE_LEARNING_FOCUS,
    payload: { focusId: focusId, areaId: areaId },
  });
};

/**
 * Remove student
 * 1.delete request to the student api
 * 2.dispatch to redux
 * 3.notify
 * @param student
 * @return {void}
 */
export const removeFocus = (focusId, areaId) => async (dispatch) => {
  try {
    const result = await axios.delete("/api/admin/focus/remove/" + focusId);

    if (result) {
      //remove from redux
      dispatch(removeFocusRedux(focusId, areaId));

      //notify
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "Quality was removed successfully"
        )
      );
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**
 * edit focus record
 * 1. send data to the server
 * 2. update redux
 * 3. show notification
 * @param  {object} classData
 * @return {void}
 */
export const editFocus = (focusId, areaId, focusName) => async (dispatch) => {
  try {
    const result = await axios.post("/api/admin/focus/edit", {
      focusId: focusId,
      focusName: focusName,
    });

    if (result) {
      //add new class
      dispatch(editFocusList(focusId, areaId, focusName));

      //show notification
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "Quality details were saved successfully"
        )
      );
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const editFocusList = (focusId, areaId, focusName) => (dispatch) => {
  dispatch({
    type: ADMIN_EDIT_LEARNING_FOCUS,
    payload: { focusId: focusId, areaId: areaId, focusName: focusName },
  });
};

/**************************************************************************************/
/*Lessons******************************************************************************/
/**************************************************************************************/
/**
 * Dispatch new focus action to the reducer
 * @param {object} result - server data
 * @param {int} focusId - focusId
 * @param {int} areaId - areaId
 * @return {void}
 */
export const addLesson = (result, focusId, areaId) => (dispatch) => {
  dispatch({
    type: ADMIN_ADD_LESSON,
    payload: { lesson: result.data, focusId: focusId, areaId: areaId },
  });
};

/**
 * Create new lesson record
 * 1. send data to the server
 * 2. update redux
 * 3. show notification
 * @param  {object} lessonData
 * @return {void}
 */
export const createLesson = (lessonData) => async (dispatch) => {
  try {
    const result = await axios.post("/api/admin/lesson/create", lessonData);

    if (result) {
      //add new focus
      dispatch(addLesson(result, lessonData.focusId, lessonData.areaId));

      //show notification
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "New lesson was created successfully"
        )
      );
    }
  } catch (err) {
    if (err.response && err.response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  }
};

/**
 * edit lesson record
 * 1. send data to the server
 * 2. update redux
 * 3. show notification
 * @param  {object} classData
 * @return {void}
 */
export const editLesson = (lessonData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = null;
      await axios
        .post("/api/admin/lesson/edit", {
          lessonId: lessonData.lessonId,
          lessonName: lessonData.lessonName,
          outcome: lessonData.outcome,
          gradeId: lessonData.gradeId,
          type: lessonData.type,
          structure: lessonData.structure,
          lesson_curriculum_outcomes: lessonData.lesson_curriculum_outcomes,
        })
        .then(async (res) => {
          result = res.data;
          await axios.get(`/api/admin/pdf/${lessonData.lessonId}`);
        });

      if (result) {
        //add new class
        dispatch(editLessonsList(lessonData));

        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Lesson details were saved successfully"
          )
        );

        resolve(result);
      }
    } catch (err) {
      console.log("error on saving lesson: \n", err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * edit lesson record
 * 1. send data to the server
 * 2. update redux
 * 3. show notification
 * @param  {object} classData
 * @return {void}
 */
export const updateActivitiesOrderByLesson = (orderData) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(
        "/api/admin/lesson/activitiesOrder",
        orderData
      );

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Activities order was saved successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const editLessonsList = (lessonData) => (dispatch) => {
  dispatch({
    type: ADMIN_EDIT_LESSON,
    payload: lessonData,
  });
};

/**
 * Dispatch lesson remove to redux
 * @param {object} lessonData - object {lessonId, areaId, focusId}
 * @return {void}
 */
export const removeLessonRedux = (lessonData) => (dispatch) => {
  dispatch({
    type: ADMIN_DELETE_LESSON,
    payload: lessonData,
  });
};

/**
 * Remove lesson
 * 1.delete request to the lessons api
 * 2.dispatch to redux
 * 3.notify
 * {object} lessonData - object {lessonId, areaId, focusId}
 * @return {void}
 */
export const removeLesson = (lessonData) => async (dispatch) => {
  try {
    const result = await axios.delete(
      "/api/admin/lesson/remove/" + lessonData.lessonId
    );

    if (result) {
      //remove from redux
      dispatch(removeLessonRedux(lessonData));

      //notify
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "Lesson was removed successfully"
        )
      );
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**************************************************************************************/
/*Lessons******************************************************************************/
/**************************************************************************************/

/**
 * Create new activity record
 * 1. send data to the server
 * 2. show notification
 * @param  {object} data - activity data
 */
export const createActivity = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post("/api/admin/activity/create", data);

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "New Activity was created successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * @desc Connect activity to a lesson
 * @param {object} data -
 * @return {function(*): Promise}
 */
export const addActivityToLesson = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post("/api/admin/activity/toLesson", data);

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Activity was added to the lesson successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 *
 * @param data
 * @return {function(*): Promise}
 */
export const editActivity = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { activityId } = data;

      const result = await axios.post(
        `/api/admin/activity/update/${activityId}`,
        data
      );

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Activity was updated successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * Get classes by teacher id from the server
 * Dispatch GET_ACTIVITIES_BY_TYPE action to the reducer
 * @param {object} activityData - activity
 * @return {void}
 */
export const getSimilarActivities = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get("/api/admin/activity/" + id);

      if (result) {
        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * Get activity data by id
 * @param {int} activityId - activity id
 * @return {function(*): Promise}
 */
export const getActivityById = (activityId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get("/api/admin/activityById/" + activityId);

      if (result) {
        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * Remove lesson
 * 1.delete request to the lessons api
 * 2.dispatch to redux
 * 3.notify
 * {int} activityId - activity id
 * @return {void}
 */
export const removeActivityFromServer = (activityId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.delete(
        "/api/admin/activity/remove/" + activityId
      );

      if (result) {
        //notify
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Activity was removed successfully"
          )
        );

        resolve(result);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * Remove lesson
 * 1.delete request to the lessons api
 * 2.dispatch to redux
 * 3.notify
 * {int} activityId - activity id
 * @return {void}
 */
export const disconnectActivityFromLesson =
  (activityId, lessonId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.delete(
          `/api/admin/lesson/removeActivity/${activityId}/${lessonId}`
        );

        if (result) {
          //notify
          dispatch(
            showNotification(
              Notification.SUCCESS,
              "Activity was removed successfully"
            )
          );

          resolve(result);
        }
      } catch (err) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });

        reject(err);
      }
    });
  };

/**
 * Get schools
 * @return {object} schools array
 */
export const getSchools = () => async (dispatch) => {
  try {
    const result = await axios.get(`/api/int/schools?all=true`);

    return result.data;
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**
 * Get schools
 * @return {object} schools array
 */
export const getSchool = (schoolId, userType) => async (dispatch) => {
  try {
    const result = await axios.get(`/api/int/schools/${schoolId}`);
    return result.data;
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**
 * Create new school record
 * 1. send data to the server
 * 2. show notification
 * @param  {object} data - school data
 * @return {void}
 */
export const addSchool = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post("/api/int/schools/activate-school", data);

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "New school was created successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

export const createNewGroup = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(
        `/api/v3/admin/school/${data.schoolId}/group`,
        data
      );

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "New group was created successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

export const deleteGroup = (schoolId, groupId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.delete(
        `/api/v3/admin/school/${schoolId}/group/${groupId}`
      );

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "New group was created successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * Update school record
 * 1. send data to the server
 * 2. show notification
 * @param  {object} data - school data
 * @return {void}
 */
export const updateSchool = (data) => async (dispatch) => {
  try {
    const result = await axios.post(
      `/api/admin/schools/edit/${data.schoolId}`,
      data
    );

    if (result) {
      //show notification
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "School was updated successfully"
        )
      );

      return result.data;
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const sendInvite = (data) => async (dispatch) => {
  try {
    ///teacher/updateEmail/
    const result = await axios.post("/api/admin/teacher/sendInvite", data);

    if (result) {
      //show notification
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "Administrator was updated successfully"
        )
      );

      return result.data;
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**************************************************************************************/
/*Admin Resources**********************************************************************/
/**************************************************************************************/

/**
 * Create new resource
 * @param  {object} data - resource data
 */
export const createResource = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post("/api/admin/resource/create", data);

      if (result.status === "OK") {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "New Resource was created successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * get resources
 * @param  {object} type - type of resource to get
 */
export const getResources = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(
        `/api/admin/resource/${type ? "?type=" + type : ""}`
      );
      resolve(result.data);
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * get resources
 * @param  {object} type - type of resource to get
 */
export const getResourcesAsAdmin = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(
        `/api_v2/admin/resource/${type ? "?type=" + type : ""}`
      );
      resolve(result.data);
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * get resources by ID
 * @param  {object} type - type of resource to get
 */
export const getResourceById = (id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(`/api/admin/resource/id/${id}`);
      resolve(result.data);
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * update resources
 * @param  {object} type - type of resource to get
 */
export const updateResource = (data) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { resourceId } = data;

      const result = await axios.post(
        `/api/admin/resource/update/${resourceId}`,
        data
      );

      if (result) {
        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Resource was updated successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

/**
 * remove resources
 * @param  {object} type - type of resource to get
 */
export const removeResource = (resourceId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.delete(
        "/api/admin/resource/remove/" + resourceId
      );

      if (result) {
        //notify
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Resource was removed successfully"
          )
        );

        resolve(result);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(Notification.DANGER, "Failed to create resource")
      );
      reject(err);
    }
  });
};

/**************************************************************************************/
/*Admin Course Manager*****************************************************************/
/**************************************************************************************/
export const getCourses = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const courses = await axios.get("/api_v2/curriculum/school/0");

      resolve(courses.data);
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(Notification.DANGER, "Failed to get default courses")
      );

      reject(err);
    }
  });
};

export const getCourseById = (courseId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const courses = await axios.get(`/api/admin/course/${courseId}`);

      resolve(courses.data);
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(Notification.DANGER, "Failed to get default courses")
      );

      reject(err);
    }
  });
};
// Check for unitId in data and add if new or update existing
export const updateCourse = (courseData, id) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      let action = "created new course";
      let axiosLink = "/api_v2/curriculum/school/create/0";
      if (id !== undefined) {
        axiosLink = "/api_v2/curriculum/school/edit/" + id;
        delete courseData.unitId;
        action = "updated";
      }

      const course = await axios.post(axiosLink, { unitData: courseData });
      if (course) {
        dispatch(
          showNotification(Notification.SUCCESS, "Successfully " + action)
        );

        resolve(course);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(Notification.DANGER, "Failed to update courses")
      );

      reject(err);
    }
  });
};

export const deleteCourse = (courseId) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.delete("/api/admin/course/remove/" + courseId);

      if (result) {
        //notify
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Course was removed successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(Notification.DANGER, "Failed to delete course")
      );
      reject(err);
    }
  });
};

/**************************************************************************************/
/*Admin Course Manager*****************************************************************/
/**************************************************************************************/
export const getEndLessonActivities = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const activities = await axios.get("/api/admin/endLessonActivity");

      resolve(activities.data);
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(Notification.DANGER, "Failed to get default courses")
      );

      reject(err);
    }
  });
};

export const updateEndLessonActivities = (updateData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(
        "triggered update end lesson activity with data: ",
        updateData
      );
      const activities = await axios.post(
        "/api/admin/endLessonActivity",
        updateData
      );
      //show notification
      dispatch(
        showNotification(Notification.SUCCESS, "Updated end lesson activities")
      );
      resolve(true);
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(
          Notification.DANGER,
          "Failed to update end lesson activity"
        )
      );

      reject(err);
    }
  });
};

export const sendSchoolNotifications = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(values, "values");
      values?.school?.schoolId &&
        values?.group?.groupId &&
        (await axios
          .post(
            `/api/int/schools/${values?.school?.schoolId}/groups/${values?.group?.groupId}/send-notification`,
            values
          )
          .then((res) => {
            dispatch(
              showNotification(
                Notification.SUCCESS,
                "Notification sent to school"
              )
            );
            resolve(res);
          }));
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(
          Notification.DANGER,
          "Failed to send notification to school"
        )
      );

      reject(err);
    }
  });
};

export const sendSchoolEmail = (values) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (values?.school?.schoolId && values?.group?.groupId) {
        await axios
          .post(
            `/api/int/schools/${values?.school?.schoolId}/groups/${values?.group?.groupId}/send-email`,
            values
          )
          .then((res) => {
            dispatch(
              showNotification(Notification.SUCCESS, "Email sent to school")
            );
            resolve(res);
          });
      } else if (Array.isArray(values?.school) && !isEmpty(values?.school)) {
        await axios
          .post(`/api/int/schools/send-email-to-schools`, values)
          .then((res) => {
            res.status === 200
              ? dispatch(
                  showNotification(Notification.SUCCESS, "Email sent to school")
                )
              : dispatch(
                  showNotification(Notification.WARNING, "Failed request")
                );
            resolve(res);
          })
          .catch((e) => {
            dispatch(showNotification(Notification.WARNING, "Failed request"));
            resolve(e);
          });
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
      dispatch(
        showNotification(
          Notification.DANGER,
          "Failed to send notification to school"
        )
      );

      reject(err);
    }
  });
};

export const sendTeacherNotifications = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .post(`/api_v2/teachers/notification`, {
          teacherId: values.teacherId,
          notificationObject: values.notificationObject,
          notificationData: values.notificationData,
        })
        .then((res) => {
          dispatch(
            showNotification(
              Notification.SUCCESS,
              "Notification sent to teacher"
            )
          );
          resolve(res);
        });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(
          Notification.DANGER,
          "Failed to send notification to teacher"
        )
      );

      reject(err);
    }
  });
};

export const createNewNotification = (values) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .post(`api/int/schools/notification`, {
          notificationTitle: values.notificationTitle,
          notificationMessage: values.notificationMessage,
        })
        .then((res) => {
          dispatch(
            showNotification(Notification.SUCCESS, "Notification created")
          );
        });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        showNotification(Notification.DANGER, "Failed to create notification")
      );

      reject(err);
    }
  });
};
