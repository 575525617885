import React from 'react';
import { gradeToColor, gradeToStringFromId } from '../../utils';

/**
 *@returns JSX
 */
const GradeBudge = ({ gradeId }) => {
    const gradeStr = gradeToStringFromId(gradeId);
    const gradeBadgeColor = gradeToColor(gradeId);

    return (
        <span className={`badge ${gradeBadgeColor} pull-right mt-2 mr-3`}>
            {gradeStr}
        </span>
    );
};

export default GradeBudge;
