export default function CustomColors(color, shade) {
  if (color === "black") {
    return shade ? `hsl(0, 0%, ${shade}%)` : `hsl(0, 0%, 0%)`; //000000
  }
  if (color === "grey") {
    // return shade ? `hsl(120,0%,${shade}%)` : `hsl(120,0%,40%)`; //#666766
    if (shade === "light") return `hsl(120,0%,70%)`;
    else if (shade === "dark") return `hsl(120,0%,30%)`;
    else return `hsl(120,0%,40%)`;
  }
  if (color === "blue") {
    // return shade ? `hsl(195,85%,${shade}%)` : `hsl(195,85%,50%)`; //#13B5EA
    if (shade === "light") return `hsl(195,85%,75%)`;
    else if (shade === "dark") return `hsl(195,85%,40%)`;
    else return `hsl(195,85%,50%)`;
  }
  if (color === "pink") {
    // return shade ? `hsl(329,99%,${shade}%)` : `hsl(329,99%,45%)`; // #e20177
    if (shade === "light") return `hsl(329,99%,70%)`;
    else if (shade === "dark") return `hsl(329,99%,35%)`;
    else return `hsl(329,99%,45%)`;
  }
  if (color === "orange") {
    // return shade ? `hsl(31,93%,${shade}%)` : `hsl(31,93%,54%)`; //#f78e1e
    if (shade === "light") return `hsl(31,93%,75%)`;
    else if (shade === "dark") return `hsl(31,93%,45%)`;
    else return `hsl(31,93%,54%)`;
  }
  if (color === "green") {
    // return shade ? `hsl(68,68%,${shade}%)` : `hsl(68,68%,52%)`; //#C1D82F
    if (shade === "light") return `hsl(68,68%,75%)`;
    else if (shade === "dark") return `hsl(68,68%,45%)`;
    else return `hsl(68,68%,52%)`;
  }
  if (color === "mustard") {
    // return shade ? `hsl(53,71%,${shade}%)` : `hsl(53,71%,59%)`; //#E1D04D
    if (shade === "light") return `hsl(53,71%,75%)`;
    else if (shade === "dark") return `hsl(53,71%,50%)`;
    else return `hsl(53,71%,59%)`;
  }
  if (color === "teal") {
    // return shade ? `hsl(173,42%,${shade}%)` : `hsl(173,42%,51%)`; //#4FB7AA
    if (shade === "light") return `hsl(173,42%,75%)`;
    else if (shade === "dark") return `hsl(173,42%,45%)`;
    else return `hsl(173,42%,51%)`;
  }

  if (color === "white") {
    return "hsl(0,0%,100%)";
  }
}
