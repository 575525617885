/**-------------------- REACT AND REDUX IMPORTS --------------------**/

import React, { useState } from 'react';
import { connect } from 'react-redux';

/**-------------------- RENDERING COMPONENTS --------------------**/

import AreaMenu from 'components/LearningContent/AreaMenu';
import Focus from 'components/LearningContent/Focus';
import SearchContent from 'components/Teacher/SearchContent';

/**-------------------- CUSTOM THEMING --------------------**/

import { Card, Typography } from '@material-ui/core';
import { CustomColors } from 'assets/customisedMUI';

/**-------------------- UTILITIES --------------------**/

import isEmpty from 'validation/isEmpty';
import { withFormik } from 'formik';

/**
 * Render a list of areas and individual foci with available lessons. Allow users to select which lessons will be part of the new/edited unit.
 * @param {*} props
 */
const UnitLessonsStep = (props) => {
    const [activeArea, setActiveArea] = useState(props.learningContent.areas[0]);
    const [searchResults, setSearchResults] = React.useState({
        keyLessons: [],
        keyCurrics: [],
        keyResources: [],
        keyGroups: [],
    });
    const [searchTerm, setSearchTerm] = React.useState('');

    const updateResults = (results, term) => {
        setSearchResults(results);
        setSearchTerm(term);
    };

    const mapFocus = () => {
        if (!isEmpty(activeArea)) {
            const { focus } = activeArea;
            return focus.map((item) => {
                if (isEmpty(searchResults.keyGroups) && !isEmpty(searchTerm)) {
                    item = { ...item, LessonGroups: searchTerm.keyGroups };
                } else if (!isEmpty(searchResults.keyGroups)) {
                    const new_lessonGroups = item.LessonGroups.filter((f) =>
                        searchResults.keyGroups.includes(f.groupId)
                    );
                    item = { ...item, LessonGroups: new_lessonGroups };
                }

                return !isEmpty(item.LessonGroups) ? (
                    <Focus
                        {...item}
                        key={item.focusId}
                        handleLessonsSelection={props.handleLessonsSelection}
                        checkDisabled={props.checkDisabled}
                        checkLesson={props.checkLesson}
                    />
                ) : null;
            });
        }
    };

    // Show the current count of lessons selected vs the maximum
    const showLessonsCount = () => {
        const { unit } = props;
        if (isEmpty(unit)) {
            return;
        }

        // Calculate the required number of lessons and the current number
        const requiredLessonsNum = Number(unit.unitDuration, '') * Number(unit.lessonsAmount, '');
        const currentLessonsNum = unit.LessonGroups.length;

        // Final message displayed to user
        const res =
            currentLessonsNum === 0
                ? `Please choose ${requiredLessonsNum} ` +
                  (requiredLessonsNum === 1 ? `lesson` : 'lessons') +
                  ` from the learning areas above.`
                : currentLessonsNum > requiredLessonsNum
                ? `Please unselect ${currentLessonsNum - requiredLessonsNum} ` +
                  (currentLessonsNum - requiredLessonsNum === 1 ? `lesson.` : `lessons.`)
                : `${currentLessonsNum} ` +
                  (currentLessonsNum === 1 ? `lesson` : `lessons`) +
                  ` chosen${
                      (currentLessonsNum < requiredLessonsNum &&
                          `, add ${requiredLessonsNum - currentLessonsNum} more.`) ||
                      ''
                  }`;

        return res;
    };
    const { areas } = props.learningContent;

    // Render component
    return (
        <>
            <div className='no-padding'>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant='h4' style={{ fontFamily: 'Museo700' }}>
                        <span>Unit of work: </span>
                        <span style={{ fontWeight: 'bold' }}>{props.unit.unitName}</span>
                    </Typography>
                    <br />
                </div>
                <div className='row'>
                    <AreaMenu
                        areas={areas || []}
                        activeArea={activeArea || { areaId: 1 }}
                        onLearningAreaClick={(area) => setActiveArea(area)}
                    />
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='lessons-count text-center mt-3 mb-2'>
                    <Card
                        style={{
                            backgroundColor: `${CustomColors('blue')}`,
                            marginTop: '30px',
                            marginBottom: '20px',
                        }}
                    >
                        <Typography style={{ fontSize: '20px', color: `${CustomColors('white')}` }}>
                            {showLessonsCount()}
                        </Typography>
                    </Card>
                    <div className='card-box' style={{ marginBottom: '20px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: 'bold', color: `${CustomColors('blue')}` }}>
                            Search lessons with a keyword
                        </Typography>
                        <SearchContent results={updateResults} check={isEmpty(searchTerm)} />
                    </div>
                </div>
            </div>

            <div className='col-lg-7'>{mapFocus()}</div>
        </>
    );
};

const mapStateToProps = (state) => ({
    learningContent: state.learningContent,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withFormik({
    mapPropsToValues(props) {
        return {
            searchTerm: '',
        };
    },
})(connect(mapStateToProps, mapDispatchToProps)(UnitLessonsStep));
