import {
  ADMIN_ADD_ACTIVITY,
  GET_ACTIVITIES_BY_TYPE,
  HIDE_ADD_ACTIVITY_LOADER,
  SHOW_ADD_ACTIVITY_LOADER,
} from '../actions/actionTypes';

const initialState = {
  types: [],
  menuLoading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITIES_BY_TYPE: {
      const types = { ...state.types };

      const { id, data } = action.payload;

      if (types[id] === undefined) {
        types[id] = data;
      }

      return {
        ...state,
        types,
      };
    }
    case ADMIN_ADD_ACTIVITY: {
      const types = { ...state.types };
      const { storage } = action.payload;

      if (types[storage.typeId] === undefined) {
        types[storage.typeId] = [];
      }

      types[storage.typeId].push(storage);

      return {
        ...state,
        menuLoading: false,
        types,
      };
    }

    case SHOW_ADD_ACTIVITY_LOADER: {
      return {
        ...state,
        menuLoading: true,
      };
    }

    case HIDE_ADD_ACTIVITY_LOADER: {
      return {
        ...state,
        menuLoading: false,
      };
    }
    default:
      return state;
  }
}
