import React, { useState, useEffect } from "react";
import axios from "config/axios";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import {
  sendSchoolNotifications,
  sendTeacherNotifications,
} from "store/actions/adminActions";
import { connect } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

const ExistingNotificationForm = (props) => {
  const [notificationList, setNotificationList] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const {
    touched,
    handleSubmit,
    isSubmitting,
    errors,
    values,
    setFieldValue,
    setValues,
  } = props;

  useEffect(() => {
    const fetchAllNotifications = async () => {
      const notifications = await axios.get(`api/int/schools/notification`);
      setNotificationList(notifications.data);
    };
    fetchAllNotifications();
  }, []);

  const displayNotificationMessage = async (notificationId) => {
    return await axios
      .get(`api/int/schools/notification/${notificationId}`)
      .then((res) => {
        setNotificationTitle(res.data.notificationTitle);
        setNotificationMessage(res.data.notificationMessage);
        setFieldValue("notificationObject", res.data);
      });
  };

  const sendToAllSchoolsConfirm = () => {
    confirmAlert({
      title: "Send notification to all schools",
      customUI: ({ title, onClose }) => {
        return (
          <div className={`react-confirm-alert-body`}>
            <h1>{title}</h1>
            <div>
              <>
                <p>
                  Are you sure you wish to send notification "
                  {notificationTitle}" to all schools?
                </p>
              </>
            </div>
            <div className="react-confirm-alert-button-group">
              <button
                onClick={async () => {
                  await props.sendSchoolNotifications(values);
                  onClose();
                }}
              >
                Yes
              </button>
              <button onClick={onClose}>No</button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="form-group row">
      <div className="col-sm-12 col-12">
        <Form>
          <div className="form-group row">
            <div className="col">
              <Field
                component="select"
                name="notificationTitle"
                onChange={(e) => {
                  displayNotificationMessage(e.target.value);
                }}
                className={classnames("form-control", {
                  "is-invalid":
                    touched.notificationTitle && errors.notificationTitle,
                })}
              >
                <option id="">Select Notification Title</option>
                {notificationList.map((notification) => {
                  return (
                    <option
                      key={notification.notificationId}
                      value={notification.notificationId}
                    >
                      {notification.notificationTitle}
                    </option>
                  );
                })}
              </Field>
              {touched.notificationTitle && errors.notificationTitle && (
                <div className="invalid-feedback">
                  {errors.notificationTitle}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Notification Title</label>
            <div className="col">{notificationTitle}</div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Notification Message</label>
            <div className="col">{notificationMessage}</div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Notification Data</label>
            <div className="col">
              <Field
                className={classnames("form-control", {
                  "is-invalid":
                    touched.notificationData && errors.notificationData,
                })}
                name="notificationData"
                type="text"
              />
              {touched.notificationData && errors.notificationData && (
                <div className="invalid-feedback">
                  {errors.notificationData}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                disabled={isSubmitting}
                onClick={async (e) => {
                  await setFieldValue("isSchool", true);
                  handleSubmit(e);
                }}
                className="btn btn-primary waves-effect pull-right"
                type="submit"
              >
                Send Notification To School
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

// Pull used redux state into props
const mapStateToProps = (state) => {
  return {};
};

// Setup dispatch for redux actions and set as functions on props
const mapDispatchToProps = (dispatch) => {
  return {
    sendSchoolNotifications: (values) =>
      dispatch(sendSchoolNotifications(values)),
    sendTeacherNotifications: (values) =>
      dispatch(sendTeacherNotifications(values)),
  };
};

// Export component connected to redux, including the Formik and Yup validation. Connect is called outside of Fromik to allow redux state to be used in Formik.
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    mapPropsToValues({
      notificationTitle,
      notificationData,
      schoolId,
      teacherId,
    }) {
      return {
        notificationTitle: notificationTitle || 0,
        notificationData: notificationData || {},
        schoolId: schoolId || 0,
        teacherId: teacherId || 0,
      };
    },
    validationSchema: Yup.object().shape({
      notificationTitle: Yup.string().required("Select notificationId"),
      notificationData: Yup.object(),
      schoolId: Yup.number(),
    }),
    handleSubmit(
      values,
      { resetForm, setErrors, setSubmitting, setFieldValue, props }
    ) {
      setSubmitting(true);
      if (values.isSchool && !values.allSchools) {
        props.sendSchoolNotifications({
          ...values,
          school: props.school,
          group: props.group,
        });
      }
    },
    enableReinitialize: true,
  })(ExistingNotificationForm)
);
