import React, { Component } from "react";
import { Switch } from "react-router-dom";

import AdminLayout from "./hoc/AdminLayouts/AdminLayout";
import EmptyLayout from "./hoc/AdminLayouts/EmptyLayout";
import store from "./store";
import { logOutTeacher, setCurrentTeacher } from "./store/actions/authActions";
import jwtDecode from "jwt-decode";
import { parseJwt } from "utils";
import Activity from "./containers/Admin/Activity";
import Learning from "./containers/Admin/Learning";
import Lesson from "./containers/Lessons/Lesson";
import Schools from "./containers/Schools/Schools";
import SchoolDetails from "./containers/Schools/SchoolDetails";
import adminResource from "./containers/Admin/Resource";
import adminCourse from "./containers/Admin/Course";
import EndLessonActivity from "./containers/Admin/EndLessonActivity";
import WeatherReportManager from "components/Activities/WeatherReport/WeatherReportManager";
import Login from "containers/Auth/Login";
import Options from "containers/Options";
import SalesReports from "containers/SalesReports/SalesReports";
import EmailSchedule from "containers/EmailSchedule";
// import UserReports from 'containers/UserReports';
import LessonGroupManager from "containers/Admin/LessonGroupManager";
import LessonGroupEditor from "containers/Admin/LessonGroupEditor";
import SearchTeacher from "containers/Teacher/SearchTeacher";
import PlatformNotifications from "containers/Notifications/PlatformNotifications";
import RedisManager from "containers/RedisManager";
import ReleaseGuide from "containers/ReleaseGuide/ReleaseGuide";
import UsageReport from "containers/UsageReport";
import SchoolStudents from "containers/Schools/Students";
import ExecutiveReport from "containers/ExecutiveReport/ExecutiveReport";
import Zeemaps from "containers/Tools/Zeemaps";
import TeacherProfile from "containers/Teacher/TeacherProfile";
import OTP from "components/2FA/OTP";

import Auth from "Api/Auth";

class AdminApp extends Component {
  componentDidMount() {
    const fetchApiToken = () => {
      console.log("Reauthenticating with API");
      Auth.userV2({}).then(async (token) => {
        // console.log(token, 'decoded2');
        if (token) {
          const decoded = await parseJwt(token.replace("Bearer ", ""));
          store.dispatch(setCurrentTeacher(decoded));
        } else if (store.getState().auth.isAuthenticated)
          store.dispatch(logOutTeacher());
      });
    };
    fetchApiToken();
    this.authenticationLoop = setInterval(() => {
      if (store.getState().auth.isAuthenticated) fetchApiToken();
    }, 4 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.authenticationLoop);
  }

  render() {
    return (
      <Switch>
        <EmptyLayout path="/login" exact component={Login} />
        <EmptyLayout path="/" exact component={Login} />
        <AdminLayout blank path="/otp" exact component={OTP} />
        <AdminLayout
          path="/admin/activity/endLessonActivity"
          exact
          component={EndLessonActivity}
        />
        <AdminLayout
          path="/admin/activity/weather-report"
          exact
          component={WeatherReportManager}
        />
        <AdminLayout path="/admin/activity/:activityId?" component={Activity} />
        <AdminLayout exact path="/admin/learning" component={Learning} />
        <AdminLayout
          exact
          path="/admin/lessongroups"
          component={LessonGroupManager}
        />
        <AdminLayout
          exact
          path="/admin/lessongroups/:groupId"
          component={LessonGroupEditor}
        />
        <AdminLayout path="/admin/lesson/:lessonId/" component={Lesson} />

        <AdminLayout exact path="/admin/schools" component={Schools} />

        <AdminLayout
          exact
          path="/admin/school/:schoolId"
          component={SchoolDetails}
        />
        <AdminLayout
          exact
          path="/admin/teacher/:id"
          component={TeacherProfile}
        />
        <AdminLayout exact path="/admin/students" component={SchoolStudents} />

        {/* page For resources mangement  */}
        <AdminLayout path="/admin/resource" component={adminResource} />

        {/* page For resources mangement  */}
        <AdminLayout path="/admin/course" component={adminCourse} />

        {/* page for lSGO Options */}
        <AdminLayout path="/admin/options" component={Options} />

        <AdminLayout path="/admin/sales-reports" component={SalesReports} />
        <AdminLayout path="/admin/email-schedule" component={EmailSchedule} />
        <AdminLayout path="/admin/usage-reports" component={UsageReport} />
        {/* <AdminLayout path='/admin/user-reports' component={UserReports} /> */}
        <AdminLayout path="/admin/search-teacher" component={SearchTeacher} />
        <AdminLayout
          path="/admin/platform-notifications"
          component={PlatformNotifications}
        />
        <AdminLayout path="/admin/redis-manager" component={RedisManager} />
        <AdminLayout path="/admin/release-manager" component={ReleaseGuide} />
        <AdminLayout path="/admin/tools/zeemaps" component={Zeemaps} />
        <AdminLayout path="/admin/exec-report" component={ExecutiveReport} />
      </Switch>
    );
  }
}

export default AdminApp;
