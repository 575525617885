import React, { useState, useEffect } from "react";
import axios from "config/axios";
import {
  Tabs,
  Tab,
  Paper,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Button,
  FormHelperText,
} from "@material-ui/core";

import isEmpty from "validation/isEmpty";
import setAuthToken from "utils/setAuthToken";

import LoadingDots from "components/UI/LoadingDots";
import AllOptions from "./AllOptions";
import TrialSettings from "./TrialSettings";
import DealOptions from "./DealOptions";

const AvailableTabs = [
  {
    component: TrialSettings,
    name: "Trial Settings",
    id: "trialSettings",
    fields: "trial-settings",
  },
  {
    component: AllOptions,
    name: "All Options",
    id: "allOptions",
    fields: "all",
  },
  {
    component: DealOptions,
    name: "Deal Settings",
    id: "dealSettings",
    fields: "deals",
  },
];

const Options = (props) => {
  const [options, setOptions] = useState(null);
  const [viewTab, setViewTab] = useState(() => {
    return AvailableTabs[0].id;
  });
  const [viewingYear, setViewingYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const year =
      props.auth && props.auth.teacher
        ? props.auth.teacher.databaseYear
        : new Date().getFullYear();
    setViewingYear(year);
  }, [props.auth]);

  //component Did Mount
  useEffect(() => {
    async function loadOptions() {
      await axios
        .get("/api_v2/options/all")
        .then((res) => setOptions(res.data));
    }

    loadOptions();
  }, []);

  const updateOption = async (updateData) => {
    // let result = null;
    const data = { ...updateData };
    delete data.optionId;

    //reutrn updated true/false
    let updated = false;
    try {
      if (updateData.optionId === "") {
        await axios.post("/api_v2/options/create", data).then((res) => {
          const newOption = res.data;
          setOptions([...options, newOption]);
          updated = true;
        });
      } else {
        await axios
          .put(`/api_v2/options/update/${updateData.optionId}`, data)
          .then((res) => {
            //find index to update on current options list
            const NewOptionIndex = options.findIndex((opt, i) => {
              return opt.optionId === updateData.optionId;
            });

            //update with new data on list
            const newOptions = [...options];
            newOptions[NewOptionIndex] = res.data;

            // console.log(NewOptionIndex, newOptions);
            setOptions(newOptions);
            updated = true;
          });
      }
    } catch (err) {
      console.log(
        "Error updating option - ",
        err.message || "No error details!"
      );
    }

    return updated;
  };

  const removeOption = async (removeId) => {
    if (window.confirm("Confirm you want to delete the option?")) {
      await axios.delete(`api_v2/options/${removeId}`).then((res) => {
        if (res.data === true) {
          //find index to update on current options list
          const deletedOptionIndex = options.findIndex((opt, i) => {
            return opt.optionId === removeId;
          });

          //update with new data on list
          const newOptions = [...options];
          delete newOptions[deletedOptionIndex];

          setOptions(newOptions);
        }
      });
    }
  };

  const viewingTab = AvailableTabs.find((at) => at.id === viewTab);
  const TabView = viewingTab.component;

  let passingOptions = [];

  if (!isEmpty(options)) {
    if (typeof viewingTab.fields === "object") {
      if (Array.isArray(viewingTab.fields)) {
        passingOptions = options.filter((opt) =>
          viewingTab.fields.includes(opt.title)
        );
      }
    } else if (typeof viewingTab.fields === "string") {
      if (viewingTab.fields === "all") {
        passingOptions = options;
      } else {
        passingOptions = options.find((opt) => viewingTab.fields === opt.title);
      }
    }
  }

  return (
    <div className="row">
      <div className="col">
        <div className="col-12">
          <div className="card-box shadow">
            <Typography variant="h5">Access Archives</Typography>
            <FormControl>
              <Select
                className="grade-filter"
                value={viewingYear}
                onChange={(e) => {
                  setViewingYear(e.target.value);
                }}
              >
                <MenuItem value="2020">2020</MenuItem>
                <MenuItem value="2021">2021</MenuItem>
              </Select>
              <FormHelperText variant="outlined">
                Select a year to view
              </FormHelperText>
            </FormControl>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                axios
                  .post(`/api_v2/auth/refresh`, {
                    ...props.auth.teacher,
                    databaseYear: viewingYear,
                  })
                  .then((res) => {
                    setAuthToken(res.data.token);
                    window.location.reload();
                  });
              }}
            >
              <Typography variant="body1">Update</Typography>
            </Button>
          </div>
        </div>
      </div>
      <div className="col-12 mb-4">
        <div className="page-title-box">
          <h4 className="page-title">LSGO APP Options</h4>
        </div>
      </div>
      <div className="col-12">
        {isEmpty(options) ? (
          <LoadingDots />
        ) : (
          <Paper>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={viewTab}
              onChange={(event, changeTab) => {
                // console.log(newIndex);
                setViewTab(changeTab);
              }}
              style={{ backgroundColor: "rgb(238, 242, 243)" }}
            >
              {AvailableTabs.map((tb) => (
                <Tab
                  key={`tab-selector-${tb.id}`}
                  label={tb.name}
                  value={tb.id}
                />
              ))}
              {/* <Tab label='All Options' id='tab-1' />
                    <Tab label='Trial Settings' id='tab-2' /> */}
            </Tabs>
            <div className="tab-content-view p-4">
              <TabView
                updateOption={updateOption}
                removeOption={removeOption}
                options={passingOptions}
              />
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
};
export default Options;
