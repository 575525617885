export default {
  login: {
    path: "/teacher/login",
  },
  register: {
    path: "/teacher/register/:token",
  },
  landing: {
    path: "/",
  },
  teacher: {
    path: "/admin/learning",
  },
  profile: {
    path: "/teacher/profile",
  },
  classes: {
    path: "/teacher",
  },
  curriculum: {
    path: "/teacher/curriculum/",
  },
  lessons: {
    path: "/teacher/lessons",
  },
  reporting: {
    path: "/teacher/reporting",
  },
  activities: {
    path: "/admin/activity",
  },
  learning: {
    path: "/admin/learning",
  },
  lessongroups: {
    path: "/admin/lessongroups",
  },
  studentLogin: {
    path: "/student/login",
  },
  schools: {
    path: "/admin/schools",
  },
  schoolManage: {
    path: "/teacher/school",
  },
  resource: {
    pathCards: "/teacher/resource/cards",
    pathVideos: (albumId) => `/teacher/resource/videos/${albumId}`,
  },
  resourceManager: {
    path: "/admin/resource/cards",
  },
  courseManager: {
    path: "/admin/course",
  },
  weatherReport: {
    path: "/admin/activity/weather-report",
  },
  endLessonActivity: {
    path: "/admin/activity/endLessonActivity",
  },
  teacherWellbeing: {
    path: "/teacher/wellbeing",
  },

  sales_reports: {
    path: "/admin/sales-reports",
  },
  user_reports: {
    path: "/admin/user-reports",
  },
  usage_reports: {
    path: "/admin/usage-reports",
  },
  email_schedule: {
    path: "/admin/email-schedule",
  },
};
