/**-------------------- REACT AND REDUX IMPORTS --------------------**/
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { toggleModal } from 'store/actions/uiActions';
import { withRouter } from 'react-router-dom';

/**-------------------- RENDERING COMPONENTS --------------------**/
import LoadingDots from 'components/UI/LoadingDots';
import TemplateUnit from './TemplateUnit';
import { getCourses } from 'store/actions/adminActions';

const TemplateUnits = props => {
    const [loading, setLoading] = useState(false);
    const [templateUnits, setTemplateUnits] = useState([]);
    const [showCourse, setShowCourse] = useState(null);
    const [refreshList, setRefreshList] = useState(false);

    useEffect(() => {
        const fetchTemplateUnits = async () => {
            const units = await props.getDefaultCourses();
            setTemplateUnits(units);
            setRefreshList(false);
            setLoading(false);
        };
        fetchTemplateUnits();
    }, [props.auth.teacher.schoolId, props.getTemplateUnits, refreshList]);

    // Preview a course by setting the current view state and showing the modal
    const viewCourse = (course) => {
        setShowCourse(course);
        props.onShowModal(true, 'courseDetailsModal');
    };

    const mapCourses = () => {
        return templateUnits
            .map((course) => {
                return (
                    <TemplateUnit
                        key={`template-${course.unitId}`}
                        course={course}
                        auth={props.auth}
                        viewCourse={viewCourse}
                        setRefreshList={(action) => setRefreshList(action)}
                    />
                );
            });
    };

    return loading ? (
        <LoadingDots />
    ) : (
        <div className='row'>
            <div className='col-12 text-center'>
                <div className='row'>
                        <div className='col-md-4 col-sm-6' key={`course-build`}>
                            <div className='card m-b-30 build-your-unit'>
                                <div className='card-body shadow' style={{ border: '1px dashed #4eb7eb' }}>
                                    <Typography variant='h6' style={{ fontFamily: 'Museo700' }}>
                                        Create New Unit
                                    </Typography>
                                    <div className='card-btns mt-2'>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => props.showBuildUnit('createnew')}
                                        >
                                            Create Unit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {mapCourses()}
                    {/* <UnitDetailsModal showCourse={showCourse} areas={areas} completedGroups={props.completedGroups} /> */}
                </div>
            </div>
        </div>
    );
};

// Return redux state on props
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        ui: state.ui,
        areas: state.learningContent.areas,
    };
};

// Return redux dispatch actions on props/
const mapDispatchToProps = (dispatch) => {
    return {
        onShowModal: (modalState, modalName) => dispatch(toggleModal(modalState, modalName)),
        getDefaultCourses: () => dispatch(getCourses()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateUnits));