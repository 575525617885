import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSimilarActivities, addActivityToLesson } from '../../store/actions/adminActions';

import SelectListGroup from '../../components/Common/SelectListGroup';

import { TYPES } from '../../constants/activityTypes';

import isEmpty from '../../validation/isEmpty';
import Loader from '../../components/UI/Spinner/Spinner';
import GradeBudge from '../../components/Common/GradeBudge';

class ChooseActivities extends Component {
    constructor(props) {
        super(props);

        this.quizOptions = [
            {
                label: TYPES.QUIZ_MULTI_SINGLE.TITLE,
                value: TYPES.QUIZ_MULTI_SINGLE.ID
            },
            {
                label: TYPES.QUIZ_MULTI_MULTI.TITLE,
                value: TYPES.QUIZ_MULTI_MULTI.ID
            },
            {
                label: TYPES.QUIZ_TEXT_FIELD.TITLE,
                value: TYPES.QUIZ_TEXT_FIELD.ID
            },
            {
                label: TYPES.QUIZ_DRAW_RESPONSE.TITLE,
                value: TYPES.QUIZ_DRAW_RESPONSE.ID
            }
        ];

        this.activitiesOptions = [
            { label: TYPES.QUIZ.TITLE, value: TYPES.QUIZ.ID },
            { label: TYPES.GAME.TITLE, value: TYPES.GAME.ID },
            { label: TYPES.ANIMATION.TITLE, value: TYPES.ANIMATION.ID },
            { label: TYPES.VIDEO.TITLE, value: TYPES.VIDEO.ID },
            { label: TYPES.WEATHERREPORT.TITLE, value: TYPES.WEATHERREPORT.ID },
            {
                label: TYPES.WEATHERCHECKIN.TITLE,
                value: TYPES.WEATHERCHECKIN.ID
            }
        ];

        const { activityType } = this.props.match.params;

        const isExist = this.activitiesOptions.filter(item => {
            return Number(item.value) === Number(activityType);
        }).length;

        this.state = {
            activityType: !isEmpty(activityType) && isExist ? activityType : this.activitiesOptions[0].value,
            quizType: this.quizOptions[0].value,
            items: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.addToLesson = this.addToLesson.bind(this);
    }

    componentDidMount() {
        this.updateList();
    }

    async addToLesson(activity) {
        const { activityId } = activity;
        const { lessonId } = this.props.lesson;

        await this.props.addActivityToLesson({
            activityId,
            lessonId
        });

        this.props.callback();

        this.props.history.push(`/admin/lesson/${lessonId}`);
    }

    componentDidUpdate(props, prevState) {
        if (Number(prevState.quizType) !== Number(this.state.quizType)) {
            return this.updateList();
        }

        if (Number(prevState.activityType) !== Number(this.state.activityType)) {
            this.updateList();
        }
    }

    async updateList() {
        const { activityType, quizType } = this.state;

        let typeToGet = activityType;

        if (Number(activityType) === Number(TYPES.QUIZ.ID)) {
            typeToGet = quizType;
        }

        //get list of quizzes
        const items = await this.props.getSimilarActivities(typeToGet);

        this.setState({
            items
        });
    }

    /**
     * Handle create class form fields
     * @param e {object} - event object
     * @return {void}
     */
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    mapActivities() {
        const { items } = this.state;

        if (items === null) {
            return <Loader />;
        }

        return items.map(activity => {
            const { content, gradeId } = activity;

            return (
                <div className="col-3 mt-4" key={activity.activityId} onClick={this.addToLesson.bind(this, activity)}>
                    <div className="type-menu-box activities-box" style={{ height: 150 }}>
                        <span className="pull-left">{content.title}</span>
                        {gradeId > -1 && <GradeBudge gradeId={gradeId} />}
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <Fragment>
                <div className="row mt-4">
                    <div className="col-2">
                        <SelectListGroup
                            name="activityType"
                            value={this.state.activityType}
                            onChange={this.handleChange}
                            options={this.activitiesOptions}
                        />
                    </div>
                    {Number(this.state.activityType) === Number(TYPES.QUIZ.ID) && (
                        <div className="col-2">
                            <SelectListGroup
                                name="quizType"
                                value={this.state.quizType}
                                onChange={this.handleChange}
                                options={this.quizOptions}
                            />
                        </div>
                    )}
                </div>
                <div className="row mt-4">{this.mapActivities()}</div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    ui: state.ui
});

const mapDispatchToProps = dispatch => {
    return {
        getSimilarActivities: activityData => dispatch(getSimilarActivities(activityData)),
        addActivityToLesson: data => dispatch(addActivityToLesson(data))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ChooseActivities));
