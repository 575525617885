import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initLearningAreas } from '../store/actions/learningActions';
import isEmpty from '../validation/isEmpty';

const WithLearningContent = WrappedComponent => {
  class learningContent extends Component {
    componentDidMount() {
      if (isEmpty(this.props.learningContent.areas)) {
        this.props.onInitLearningAreas();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => ({
    learningContent: state.learningContent,
  });

  const mapDispatchToProps = dispatch => {
    return {
      onInitLearningAreas: () => dispatch(initLearningAreas()),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(learningContent);
};

export default WithLearningContent;
