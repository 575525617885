import React, { useEffect, useState, useRef } from 'react';
import axios from 'config/axios';
// import isEmpty from 'validation/isEmpty';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import S3FileView from './S3FileView';

import './usageReport.scss';

const UsageReport = (props) => {
    const [startDate, setStartDate] = useState(() => {
        // start 7 days before
        return new Date(new Date().setDate(new Date().getDate() - 5));
    });
    const [endDate, setEndDate] = useState(new Date());
    const [latestDump, setLatestDump] = useState(null);
    const emailTextInfo = useRef(null);

    useEffect(() => {
        loadLatestDump();
        //eslint-disable-next-line
    }, []);

    async function loadLatestDump() {
        await axios
            .get('/api_v2/admin/usage/latestAvailable')
            .then((res) => {
                if (res.data) {
                    setLatestDump(res.data);
                }
            })
            .catch((err) => {
                console.error(
                    'Error: failed to get latest dynamo dumpo available.',
                    err.message || 'No error details.'
                );
            });
    }

    async function generateReport() {
        await axios
            .post('/api_v2/admin/usage/newReport', { startDate, endDate })
            .then((res) => {
                console.log('New Report Response: ', res.data);
                emailTextInfo.current.classList.remove('hidden');
            })
            .catch((err) => {
                console.error('Error: ', err.message || err.response.data.message || ' - ');
            });
    }

    return (
        <div className='row usage-report'>
            <div className='col-12'>
                <div className='page-title-box'>
                    <h4 className='page-title'>Usage Report</h4>
                </div>
            </div>
            <div className='generate-report col-12 card-box my-4'>
                <h4>Generate New Report</h4>
                {latestDump && (
                    <p className='text-info'>
                        Latest analytics available from "<b>{latestDump}</b>"
                    </p>
                )}
                <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginRight: 8 }}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant='inline'
                            format='dd/MM/yyyy'
                            margin='normal'
                            label='Start Date'
                            value={startDate}
                            onChange={(e) => setStartDate(e)}
                            autoOk
                            maxDate={endDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <span style={{ width: 16, display: 'inline-flex' }}></span>
                        <KeyboardDatePicker
                            disableToolbar
                            variant='inline'
                            format='dd/MM/yyyy'
                            margin='normal'
                            label='End Date'
                            minDate={startDate}
                            value={endDate}
                            onChange={(e) => setEndDate(e)}
                            autoOk
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Button
                        onClick={generateReport}
                        style={{ marginLeft: 16, verticalAlign: 'bottom' }}
                        variant='contained'
                        color='primary'
                    >
                        New Report
                    </Button>
                    <p className='text-info hidden' ref={emailTextInfo} style={{ margin: '8px auto' }}>
                        <i className='fa fa-info-circle mr-2' />
                        You will receive an email on completion of the report generation.
                    </p>
                </div>
            </div>
            <div className='col-12 card-box'>
                <h5>Reports:</h5>
                <div>
                    <p className='text-muted text-small'>Click on a report to download that file.</p>

                    {process.env.NODE_ENV === 'development' && (
                        <p className='alert-danger p-3 text-secondary'>
                            For local development environment, the report will be save to local folder
                            "server/tmp/...xlsx" and "server/tmp_pdf/usage_pdfs/....pdf". The list below is for staging
                            reports.
                            <br />
                            Production servers will list files from separate folder on S3 bucket.
                        </p>
                    )}
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <S3FileView title={'XLSX Reports'} type='xlsx' />
                    </div>
                    <div className='col-md-6'>
                        <S3FileView title={'PDF Reports'} type='pdf' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsageReport;
