import { createStore, createHook } from "react-sweet-state";
import Api from "Api/Constants";
import { defaults } from "react-sweet-state";
defaults.devtools = true;

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    stages: {
      0: "F",
      1: "1-2",
      2: "3-4",
      3: "5-6",
    },
    grades: [
      { label: "All Grades", value: -1 },
      { label: "F", value: 0 },
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
      { label: 6, value: 6 },
    ],
    loaded: false,
    loading: false,
  },

  // actions that trigger store mutation
  actions: {
    load:
      () =>
      async ({ setState, getState }) => {
        if (getState().loading === true || getState().loaded) return;

        setState({
          loading: true,
        });

        const grades = await Api.getGrades();
        let newGrades = getState().grades;
        newGrades.splice(
          newGrades.indexOf(newGrades.find((grade) => grade.value === 0)),
          1,
          {
            label: grades.data[0],
            value: 0,
          }
        );

        setState({
          loading: false,
          loaded: true,
          stages: grades.data,
          grades: newGrades,
        });
      },
  },
  // optional, mostly used for easy debugging
  name: "grades",
});

const useGrades = createHook(Store);

export default useGrades;
