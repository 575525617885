import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';

import Loadable from 'react-loadable';

const Cards = Loadable({
  loader: () => import('../../containers/Admin/Resource/Cards/Cards'),
  loading: () => {
    return <div>....Loading</div>;
  },
});

class AdminResource extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   * @return {jsx}
   */
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Resource Manager</h4>
            </div>
          </div>
        </div>
        <Route path="/admin/resource/cards" component={Cards} />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  ui: state.ui,
});

const mapDispatchToProps = dispatch => {
  return {
    // addActivity: (activityData, history) =>
    // 	dispatch(createActivity(activityData, history)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminResource));
