import React, { useState, useEffect } from 'react';
import OptionsForm from 'components/Options/OptionsForm';

const Options = ({ options, updateOption, removeOption, ...props }) => {
    const [editing, setEditing] = useState({});
    const mapOptions = () => {
        if (options.length === 0) {
            return null;
        }

        return options.map((opt) => (
            <tr key={opt.optionId}>
                <td>{opt.optionId}</td>
                <td>{opt.title}</td>
                <td>
                    <code style={{ maxHeight: 40, overflow: 'hidden ' }}>{opt.option}</code>
                </td>
                <td>
                    <button
                        className='btn btn-light mr-2'
                        onClick={() => {
                            setEditing(opt);
                        }}
                        title='Edit'
                    >
                        <i className='fa fa-edit cursor-pointer' />
                    </button>

                    <button
                        className='btn btn-danger'
                        title='Delete'
                        onClick={() => {
                            removeOption(opt.optionId);
                        }}
                    >
                        <i className='fa fa-trash cursor-pointer' />
                    </button>
                </td>
            </tr>
        ));
    };

    return (
        <>
            <div className='w-100'>
                <OptionsForm
                    updateOption={updateOption}
                    {...editing}
                    clearEdit={() => {
                        setEditing([]);
                    }}
                />
            </div>
            <div className='w-100'>
                <h4>Options Available</h4>
                <div className='table-responsive '>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>#Id</th>
                                <th style={{ width: '20%' }}>Title</th>
                                <th style={{ width: '60%' }}>Option</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {options.length > 0 ? (
                                mapOptions()
                            ) : (
                                <tr>
                                    <td colSpan='4' className='text-center'>
                                        No Data
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
export default Options;
