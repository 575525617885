import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LocalNotifications extends Component {
  static DANGER = 'danger';
  static PRIMARY = 'primary';
  static SECONDARY = 'secondary';
  static SUCCESS = 'success';
  static INFO = 'info';
  static WARNING = 'warning';
  static LIGHT = 'light';
  static DARK = 'dark';

  render() {
    return (
      <div className={`alert alert-${this.props.type}`} role="alert">
        {this.props.message}
      </div>
    );
  }
}

LocalNotifications.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    LocalNotifications.DANGER,
    LocalNotifications.PRIMARY,
    LocalNotifications.SECONDARY,
    LocalNotifications.SUCCESS,
    LocalNotifications.WARNING,
    LocalNotifications.INFO,
    LocalNotifications.LIGHT,
    LocalNotifications.DARK,
  ]),
};

export default LocalNotifications;
