import React, { useState, useEffect } from 'react';

import UnitOverviewStep from 'components/AdminCourse/UnitOverviewStep';
import UnitLessonsStep from 'components/AdminCourse/UnitLessonsStep';
import SummaryOfUnitStep from 'components/AdminCourse/SummaryOfUnitStep';
import TemplateUnits from 'components/AdminCourse/TemplateUnits';
import moment from 'moment';
import WithLearningContent from '../../hoc/WithLearningContent';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleModal } from 'store/actions/uiActions';

import { Button } from '@material-ui/core';

import isEmpty from 'validation/isEmpty';
import { getCourseById, updateCourse } from 'store/actions/adminActions';

const CurriculumManager = props => {
    const [loading, setLoading] = useState(false);
    const [createNewUnit, setCreateNewUnit] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [unitDataValid, setUnitDataValid] = useState(true);
    const [type, setType] = useState("");
    const [disableActionButton, setDisableActionButton] = useState(false);
    const { level, courseId } = props.match.params;
    const { units, getUnitById } = props;

    useEffect(() => {
        const setAction = () => {
            if(props.location.pathname.includes('edit')) {
                setType("edit")
            } else {
                setType("new")
            }
        }
        setAction();
    },[props.location.pathname])
    
    //set default description for school units if left empty by user/admin
    const defaultSchoolUnitDescription =
        'Suggested unit created by Life Skills GO.';

    const [unitData, setUnitData] = useState({
        unitName: 'Unit',
        unitDuration: '5',
        lessonsAmount: '2',
        LessonGroups: [],
        grade: 0,
        description: '',
        lessonDay: [],
    });    
    
    useEffect(() => {
        const loadUnitData = async () => {
            if (type === 'edit') {
                const editUnit = await getUnitById(courseId);
                
                if (editUnit) {
                    const LessonGroups = editUnit.LessonGroups.map((lg) => {
                                  return { groupId: lg.groupId, lessonStart: lg.UnitLessonGroup.lessonStart };
                              });
                    const { unitName, lessonsAmount, unitDuration, grade, description, lessonDay } = editUnit;

                    setUnitData({
                        unitName,
                        unitDuration,
                        lessonsAmount,
                        LessonGroups,
                        grade,
                        description,
                        lessonDay: lessonDay ? lessonDay : [],
                    });
                }
            }

            setLoading(false);
        };

        loadUnitData();
    }, [level, type, courseId, units, getUnitById]);

    const handleUnitDataChange = (e) => {
        if (e.target && e.target.name) {
            const { name, value } = e.target;
            setUnitData({ ...unitData, [name]: value });
        } else {
            const { LessonGroups } = unitData;
            setUnitData({
                ...unitData,
                LessonGroups: LessonGroups.map((lesson) => {
                    const newLesson = { ...lesson };
                    if (+newLesson.groupId === +e.groupId) {
                        newLesson.lessonStart = e.lessonStart;
                    }
                    return newLesson;
                }),
            });
        }
    };

    const checkLesson = (groupId) => {
        let id = groupId * 1;
        const { LessonGroups } = unitData;
        return (
            LessonGroups.map((e) => {
                return e.groupId;
            }).indexOf(id) !== -1
        );
    };

    //get the required number of lessons
    const requiredLessonsNum = Number(unitData.unitDuration) * Number(unitData.lessonsAmount);

    //get number of lessons
    const currentLessonsNum = unitData.LessonGroups.length;

    const checkDisabled = (groupId) => {
        return !checkLesson(groupId) && requiredLessonsNum <= currentLessonsNum;
    };

    const handleLessonsSelection = async (e) => {
        const { value } = e.target;
        const { LessonGroups } = unitData;
        let groupId = 1 * value;
        let lesson = { groupId: groupId, lessonStart: moment() };
        const index = LessonGroups.map((e) => {
            return e.groupId;
        }).indexOf(groupId);

        // if not on the list
        if (index === -1) {
            LessonGroups.push(lesson);
        } else {
            LessonGroups.splice(index, 1);
        }

        setUnitData({ ...unitData, LessonGroups });
    };

    //changing the lessonDays
    const changeUnitData = (newData) => {
        setUnitData({ ...unitData, ...newData });
    };

    const curriculumSaveWrapper = async (id, unit) => {
        const unitDescription = unit.description === '' ? defaultSchoolUnitDescription : unit.description;

        const result = await props.updateCourse({ ...unit, description: unitDescription, isActive: 1, schoolId: 0 }, id);
        if (result) {
            setDisableActionButton(true)
        }
    };

    const lessonsToSummary = () => {
        let selectedAreas = [],
            selectedFocus = [];

        const { areas } = props.learningContent;

        if (isEmpty(areas)) {
            return;
        }

        const { LessonGroups } = unitData;

        // Filter the area and focus names by finding which selected lessons are in which category.
        areas.forEach((area) => {
            let areaSelected = false;
            const foci = area.focus;
            foci.forEach((focus) => {
                const selectedLessons = focus.LessonGroups.filter(
                    (group) =>
                        LessonGroups.map((l) => {
                            return l.groupId;
                        }).indexOf(group.groupId) !== -1
                );
                if (selectedLessons.length > 0) {
                    selectedFocus.push(focus.focusName);
                    areaSelected = true;
                }
            });
            if (areaSelected) {
                selectedAreas.push(area.areaName);
            }
        });

        return (
            <>
                Learning Areas: <i>{selectedAreas.join(', ')}</i> <br /> Focus Areas: <i>{selectedFocus.join(', ')}</i>{' '}
                <br />
                <i>{LessonGroups.length} lessons chosen</i>
            </>
        );
    };

    const steps = [
        {
            title: 'General',
            step: 1,
            description: 'We would like to know more about you and your students.',
            component: UnitOverviewStep,
        },
        {
            title: 'Learning',
            step: 2,
            description: 'Which personal quality would you like to focus on.',
            component: UnitLessonsStep,
        },
        {
            title: 'Summary',
            step: 3,
            description: "Let's set up the frequency and class duration for this learning area.",
            component: SummaryOfUnitStep,
        },
    ];

    const stepsList = steps.map((item, index) => {
        const status =
            currentStep === item.step
                ? 'active'
                : currentStep > item.step
                ? 'complete cursor-pointer'
                : type === 'edit'
                ? 'cursor-pointer'
                : '';
        let className = 'list-inline-item ' + status + ' content-' + (index + 1);
        return (
            <li
                key={index}
                className={className}
                onClick={() => {
                    if (
                        ((type === 'new' && currentStep > item.step) ||
                        (type === 'edit' && !isEmpty(unitData.LessonGroups))) && !disableActionButton
                    ) {
                        setCurrentStep(item.step);
                    }
                }}
            >
                <br /> <span>{item.title}</span>
            </li>
        );
    });

    const CurrentStepComponent = steps[currentStep - 1].component;

    const saveCurrentProgress = async () => {
        setCurrentStep(currentStep + 1);
    };

    const nextButton = (
        <Button
            variant='contained'
            color='primary'
            onClick={() => currentStep < 3 && saveCurrentProgress()}
            disabled={
                currentStep === steps.length ||
                (unitData.LessonGroups.length === 0 && currentStep === 2) ||
                (currentStep === 1 && !unitDataValid) ||
                (currentStep === 2 && currentLessonsNum > requiredLessonsNum)
            }
        >
            Next
        </Button>
    );

    const saveButton = (
        <Button variant='contained' color='primary' onClick={() => curriculumSaveWrapper(courseId, unitData)} disabled={disableActionButton}>
            {type === 'edit' ? 'Update' : 'Create my unit'}
        </Button>
    );

    return (
        <>
            {type === 'new' && !createNewUnit ? (
                <>
                    <TemplateUnits
                        showBuildUnit={async (unitType) => {
                            // index: 0 = unit name, 1 = unit duration, 2 = lessons amount, 3 = lessons groups, 4 = current page
                                setUnitData({
                                    unitName: 'Unit',
                                    unitDuration: '5',
                                    lessonsAmount: '2',
                                    LessonGroups: [],
                                    grade: 0,
                                    description: '',
                                    lessonDay: [],
                                });
                                setCurrentStep(1);
                                setCreateNewUnit(true);
                        }}
                        saveCurriculum={curriculumSaveWrapper}  
                    />
                </>
            ) : (
                <>
                    <div className='row'>
                        <div className='col-12'>
                            <ul className='wizard-menu list-inline'>{stepsList}</ul>
                        </div>
                    </div>
                    <div className='row justify-content-center mt-4'>
                        <CurrentStepComponent
                            changeOfValue={(e) => handleUnitDataChange(e)}
                            checkLesson={checkLesson}
                            checkDisabled={checkDisabled}
                            handleLessonsSelection={handleLessonsSelection}
                            unit={unitData}
                            setStep={(step) => {
                                setCurrentStep(step);
                            }}
                            maxUnitWeeks={20}
                            maxUnitLessons={3}
                            setUnitDataValid={(v) => setUnitDataValid(v)}
                            loading={loading}
                            changeUnitData={changeUnitData}
                            type={type}
                            disableActionButton={disableActionButton}
                        >
                            {currentStep === 3 && lessonsToSummary()}
                        </CurrentStepComponent>
                    </div>
                    <div className='row justify-content-center'>
                        {currentStep === 3 && (
                            <div className='col-lg-8 text-center'>
                                <p>
                                    This unit will be assigned as the current unit so your students can begin working
                                    from it immediately.
                                </p>
                            </div>
                        )}
                        <div className='col-12 text-center'>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    if (type === 'new') {
                                        if (currentStep === 1) {
                                            setCreateNewUnit(false);
                                            return;
                                        }
                                    }

                                    currentStep > 1 && setCurrentStep(currentStep - 1);
                                }}
                                disabled={(currentStep === 1 && type === 'edit') || disableActionButton}
                                size='medium'
                            >
                                Prev
                            </Button>
                            {currentStep !== steps.length ? nextButton : saveButton}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    learningContent: state.learningContent,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onShowModal: (modalState) => dispatch(toggleModal(modalState)),        
        updateCourse: (unit, id) => dispatch(updateCourse(unit, id)),
        getUnitById: (unitId) => dispatch(getCourseById(unitId)),
    };
};

export default withRouter(WithLearningContent(connect(mapStateToProps, mapDispatchToProps)(CurriculumManager)));