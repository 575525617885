import {
  TableCell,
  TableRow,
  Checkbox,
  Button,
  Typography,
  Chip,
  TextField,
} from "@material-ui/core";
import { IconButton } from "assets/customisedMUI";
import React, { useEffect, useState } from "react";
import isEmpty from "validation/isEmpty";
import TextFieldGroup from "components/Common/TextFieldGroup";
import SelectListGroup from "components/Common/SelectListGroup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import DateFnsUtils from "@date-io/date-fns";

import { withFormik } from "formik";
import "index.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomColors from "assets/customisedMUI/CustomColors";

const InformationRow = React.memo(
  (props) => {
    const [settingErrors, setSettingErrors] = useState(false);
    const {
      information,
      onRedirect,
      tableRowStyle,
      editing,
      selected,
      values,
      errors,
      setValues,
      resetForm,
      onEditSave,
      checkCallBack,
      editViewOptions,
      editCallback,
      isHover,
      tags,
      actionButtons,
      id,
      setErrors,
      accordion,
    } = props;

    useEffect(() => {
      for (const [k, v] of Object.entries(values)) {
        if (isEmpty(v)) {
          return setErrors({ ...errors, [k]: `${k} is required` });
        }
      }
    }, [settingErrors]);

    const mapActionButtons = () => {
      let data = [];
      for (const [k, v] of Object.entries(actionButtons)) {
        if (v[2] && v[2].type === "icon-button") {
          data.push(
            <Tooltip
              placement="top"
              title={
                <div style={{ width: "100%", fontSize: 14 }}>
                  {v[2].tooltipMessage}
                </div>
              }
            >
              <span className="table-action-button">
                <IconButton
                  variant="outlined"
                  icon={v[2].icon}
                  onClick={() => v[1]()}
                  key={k}
                ></IconButton>
              </span>
            </Tooltip>
          );
        } else {
          data.push(
            <Button
              variant="contained"
              color="secondary"
              onClick={() => v[1]()}
              key={k}
            >
              {v[0]}
            </Button>
          );
        }
      }
      return data;
    };

    //renders information of table row
    const informationView = () => {
      let data = [];
      for (const [k, v] of Object.entries(information)) {
        data.push(
          v && v.editing ? (
            <TableCell>
              <SelectListGroup
                options={editViewOptions[k] && editViewOptions[k][1]}
                name={k}
                value={
                  !isEmpty(values[k])
                    ? typeof values[k] === "object"
                      ? values[k].value
                      : values[k]
                    : "Select"
                }
                error={errors[`${k}`]}
                onChange={(e) => {
                  v.onUpdate({
                    [k]: e.target.value === "Select" ? null : e.target.value,
                  });
                }}
              />
            </TableCell>
          ) : v && v.checkbox ? (
            <TableCell
              onClick={() => onRedirect && onRedirect()}
              key={k}
              style={{ cursor: onRedirect ? "pointer" : "" }}
            >
              <Checkbox checked={v.value} disabled />
            </TableCell>
          ) : (
            <TableCell
              onClick={() => onRedirect && onRedirect()}
              key={k}
              style={{ cursor: onRedirect ? "pointer" : "" }}
            >
              <Typography
                variant={props.child ? "body1" : "h6"}
                style={{ fontWeight: props.child ? "bold" : "" }}
              >
                {v && Array.isArray(v)
                  ? v
                  : typeof v === "object" && v
                  ? v.label
                  : v}
              </Typography>
            </TableCell>
          )
        );
      }
      return data;
    };

    const editView = () => {
      let data = [];
      for (const [k, v] of Object.entries(information)) {
        data.push(
          <>
            <TableCell>
              {v && v.checkbox ? (
                <TableCell
                  onClick={() => onRedirect && onRedirect()}
                  key={k}
                  style={{ cursor: onRedirect ? "pointer" : "" }}
                >
                  <Checkbox checked={v.value} disabled />
                </TableCell>
              ) : editViewOptions[k] &&
                editViewOptions[k][0] === "text-field" ? (
                <TextFieldGroup
                  name={k}
                  value={values[k]}
                  error={errors[k]}
                  onChange={(e) =>
                    setValues({ ...values, [k]: e.target.value })
                  }
                  validate={() => setSettingErrors(!settingErrors)}
                />
              ) : editViewOptions[k] &&
                editViewOptions[k][0] === "select-list" ? (
                <SelectListGroup
                  options={editViewOptions[k] && editViewOptions[k][1]}
                  name={k}
                  value={
                    !isEmpty(values[k])
                      ? typeof values[k] === "object"
                        ? values[k].value
                        : values[k]
                      : "Select"
                  }
                  error={errors[`${k}`]}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [k]: e.target.value === "Select" ? null : e.target.value,
                    });
                  }}
                />
              ) : editViewOptions[k] &&
                editViewOptions[k][0] === "date-field" ? (
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label={k}
                      value={values[k]}
                      onChange={(e) => setValues({ ...values, [k]: e })}
                      autoOk
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </>
              ) : editViewOptions[k] && editViewOptions[k][0] === "number" ? (
                <input
                  type="number"
                  value={values[k]}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      [k]: e.target.value,
                    });
                  }}
                ></input>
              ) : editViewOptions[k] &&
                editViewOptions[k][0] === "multi-select" ? (
                <>
                  <Autocomplete
                    multiple
                    id="tags=standard"
                    options={editViewOptions[k] && editViewOptions[k][1]}
                    getOptionLabel={(option) => option.label}
                    defaultValue={
                      editViewOptions[k] &&
                      values[k] &&
                      editViewOptions[k][1].filter((pl) =>
                        values[k].map((item) => item.id).includes(pl.id)
                      )
                    }
                    value={
                      editViewOptions[k] &&
                      values[k] &&
                      editViewOptions[k][1].filter((pl) =>
                        values[k].map((item) => item.id).includes(pl.id)
                      )
                    }
                    onChange={(e, val) => setValues({ ...values, [k]: val })}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Chip
                          label={option.label}
                          {...getTagProps({ index })}
                          style={{
                            backgroundColor: CustomColors("teal"),
                            margin: 4,
                            textTransform: "capitalize",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Groups"
                        placeholder="Select groups"
                        onError={(e) => console.log(e, "e")}
                      />
                    )}
                  />
                </>
              ) : (
                v
              )}
            </TableCell>
          </>
        );
      }
      return data;
    };
    return (
      <TableRow
        key={id}
        style={tableRowStyle}
        className={`information-row ${accordion}`}
        hover={isHover}
      >
        <TableCell style={{ width: 25, padding: 6 }}>
          {!props.noselect && (
            <Checkbox
              checked={selected}
              onChange={() => {
                checkCallBack();
              }}
            />
          )}
          {props.child && (
            <div style={{ display: "inline-block", marginLeft: 20 }}>
              <i className="fas fa-chevron-right mr-3" />
            </div>
          )}
        </TableCell>
        {editing && selected ? editView() : informationView()}
        {editing && selected ? (
          <TableCell>
            <IconButton
              variant="outlined"
              icon="fa fa-check"
              onClick={async () => {
                if (isEmpty(errors)) {
                  const result = await onEditSave(values);
                  if (result) {
                    if (result.error) {
                      setErrors({ ...errors, ...result.error });
                    } else {
                      resetForm();
                      editCallback && editCallback();
                    }
                  }
                }
              }}
            ></IconButton>
            <IconButton
              variant="outlined"
              icon="fa fa-undo"
              onClick={() => {
                resetForm();
                editCallback && editCallback();
              }}
            ></IconButton>
          </TableCell>
        ) : (
          <TableCell>
            {!isEmpty(actionButtons) && mapActionButtons()}
            {props.parent && (
              <div
                style={{ display: "inline-block", marginLeft: 20 }}
                onClick={() => props.setShowChildren()}
              >
                <i
                  className={`fa fa-chevron-${
                    props.showChildren ? "down" : "right"
                  } mr-3 cursor-pointer`}
                />
              </div>
            )}
          </TableCell>
        )}
      </TableRow>
    );
  },
  (prevProps, nextProps) => {
    if (
      prevProps.values !== nextProps.values ||
      prevProps.errors !== nextProps.errors
    ) {
      return false;
    }
    if (prevProps.editing !== nextProps.editing) {
      return false;
    }
    if (prevProps.actionButtons !== nextProps.actionButtons) {
      return false;
    }
    if (prevProps.showChildren !== nextProps.showChildren) {
      return false;
    }
    if (prevProps.child !== nextProps.child) {
      return false;
    }
    return prevProps.selected === nextProps.selected;
  }
);

export default withFormik({
  mapPropsToValues({ information }) {
    let newInfo = {};
    for (const [k, v] of Object.entries(information)) {
      if (v !== null) {
        if (typeof v === "object") {
          newInfo[k] = v.value;
        } else newInfo[k] = v;
      }
    }
    return newInfo;
  },
  enableReinitialize: true,
})(InformationRow);
