import React, { Fragment, useState, useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

import logo from "assets/images/logo.png";
import bg from "assets/images/teacher_login_bg.jpg";
import Version from "constants/version";
import NotificationBar from "../../components/UI/Notifications/NotificationBar";
import { isIE } from "@lsgo/lsgo-common";
import axios from "config/axios";

import NotificationPopOver from "../../components/UI/Notifications/NotificationPopOver";

const downloadProps = {
  notifications: [
    {
      id: 1,
      text: "Install the Life Skills GO app to your device",
      hide: false,
      buttons: [
        {
          text: "Install LSGO",
          label: "Install to your device",
          href: "#",
          onClick: () => window.MyLib.defPrompt.prompt(),
        },
      ],
    },
  ],

  color: "#fdf3d1",
};

/**
 *@returns JSX
 */
const EmptyLayout = ({ component: Component, ...rest }) => {
  //notify the master template to the page type
  const [versionNumber, setVersionNumber] = useState("0.0.0");
  document.body.classList.toggle("account-pages", true);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    async function fetchVersion() {
      await axios.get(`/api_v2/options/version`).then((res) => {
        try {
          const v = res.data?.option;
          if (v) {
            setVersionNumber(v);
          }
        } catch (e) {
          console.log(`W: failed to update version number ${e}`);
        }
      });
    }
    fetchVersion();
  }, []);

  return (
    <Fragment>
      {isIE(document) && (
        <div className="notification-popover">
          <NotificationPopOver
            title={
              <>
                <i className="mdi mdi-alert" /> Unsupported Browser
              </>
            }
            message="The version of Internet Explorer you are using is not supported by Life Skills GO. We recommend using Chrome or any modern internet browser."
            childrenElement={
              <>
                <a
                  className="btn btn-danger"
                  target="_blank"
                  href="https://www.microsoft.com/en-au/microsoft-365/windows/end-of-ie-support"
                  rel="noopener noreferrer"
                >
                  More information
                </a>
              </>
            }
            alertStyle="danger"
            alertWidth={500}
          />
        </div>
      )}
      {/* {isIE && <NotificationBar data={pretendProps} />} */}
      {window.MyLib && window.MyLib.show && (
        <NotificationBar data={downloadProps} />
      )}
      <div
        className="accountbg"
        style={{
          backgroundImage: "url(" + bg + ")",
          backgroundSize: "cover",
        }}
      ></div>

      <div className="wrapper-page account-page-full teacher-login-interface">
        <div className="card">
          <div className="card-block">
            <div className="account-box">
              <div className="card-box p-5">
                <h2 className="text-uppercase text-center pb-4">
                  <a href="/" className="text-success">
                    <span>
                      <img src={logo} alt="" height="100" />
                    </span>
                  </a>
                </h2>
                <Route {...rest} render={(props) => <Component {...props} />} />
              </div>
            </div>
            <div className="account-copyright row">
              <div id="versionNumber" className="col-sm-6">
                <a
                  href="https://help.lifeskillsgroup.com.au/life-skills-go-change-list"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "block" }}
                >
                  <span>Version: v{versionNumber}</span>
                </a>
                <span style={{ display: "block" }}>
                  {currentYear} &copy; Life Skills Group Online Pty Ltd.
                </span>
              </div>

              <div className="col-sm-6">
                <a
                  href="https://www.facebook.com/groups/GOusers/"
                  className="cursor"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-facebook-official"
                    style={{ fontSize: 18 }}
                  />{" "}
                  &nbsp; Join our teacher community!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

EmptyLayout.propTypes = {
  component: PropTypes.object.isRequired,
};

export default EmptyLayout;
