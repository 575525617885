import React from 'react';
import 'rc-tooltip/assets/bootstrap.css';
/**-------------------- CUSTOM THEMING --------------------**/
import {
  Button,
  ListItem,
  List,
  Checkbox,
  ListItemText,
  FormControlLabel,
  ListItemSecondaryAction,
  Chip,
  Collapse,
  Typography,
} from '@material-ui/core';

/**
 *@returns JSX
 */
const Lesson = props => {
  const lessonId = 'lesson-' + props.groupId;
  return (
    <li className="list-group-item lesson">
      <div className="pull-left">
      <FormControlLabel
        className='lesson-label'
        control={
        <Checkbox
          checked={props.checkLesson(props.groupId)}
          disabled={props.checkDisabled(props.groupId)}
          name={props.displayName}
          id={lessonId}
          color='primary'
          onChange={props.handleLessonsSelection}
          value={props.groupId}
        />
      }
      label={
          <span>
              {props.displayName}
          </span>
      }
  ></FormControlLabel>
        {/* <p style={{ display: 'block', fontSize: '0.8em' }}>
          <b>
            {props.lesson_curriculum_outcomes &&
              props.lesson_curriculum_outcomes
                .map(outcome => {
                  return outcome.code;
                })
                .join(', ')}
          </b>
        </p> */}
      </div>
      <a
        target="blank"
        href={`/teacher/reporting/lesson/${props.groupId}`}
        className="btn btn-outline-info waves-effect waves-light pull-right"
      >
        Lesson Details
      </a>
    </li>
  );
};

export default Lesson;
