import React, { Fragment, useState } from 'react';

const NotificationBar = ({ data }) => {
  const [show] = useState(true);
  const [notifs, setNotifs] = useState([...data.notifications]);

  const handleCloseNotification = id => {
    let newNotifs = [...notifs];
    newNotifs.find(notif => notif.id === id).hide = true;
    setNotifs(newNotifs);
    localStorage.setItem(`hideNotification`, true);
  };

  if (localStorage.getItem(`hideNotification`) !== null) {
    return null;
  }

  return (
    <Fragment>
      {show && !notifs.every(notif => notif.hide === true) && (
        <div
          className="alert alert-info notification-main"
          style={{
            backgroundColor: data.color,
            borderColor: data.color,
            color: '#000',
          }}
        >
          {notifs.map(notif => {
            return (
              <Fragment key={`notification-${notif.id}`}>
                {!notif.hide && (
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      <span
                        className="mt-2"
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        {notif.text}
                      </span>
                      <button
                        // style={notifButtonStyle}
                        className="pull-right ml-4 hidden"
                        aria-label="Close Notification"
                        onClick={() => {
                          handleCloseNotification(notif.id);
                        }}
                      >
                        <i className="fa fa-times" />
                      </button>
                      {notif.buttons.map((button, index) => (
                        <a
                          // style={buttonStyle}
                          aria-label={button.label}
                          onClick={button.onClick}
                          className="btn btn-outline-danger pull-right"
                          href={button.href}
                          key={`alert-button-${index}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {button.text}
                        </a>
                      ))}
                    </p>
                  </div>
                )}
              </Fragment>
            );
          })}
          {/* {data.links.map(link => (
            <a
              key={link.text}
              style={buttonStyle}
              href={link.link}
              className="btn btn-outline-danger"
            >
              {link.text}
            </a>
          ))} */}
          {/* {
            <a
              style={buttonStyle}
              onClick={handleClick}
              className="btn btn-outline-danger"
            >
              Dismiss All
            </a>
          } */}
        </div>
      )}
    </Fragment>
  );
};

export default NotificationBar;
