import React from 'react';
import { withRouter } from 'react-router-dom';
import GradeBudge from '../Common/GradeBudge';

/**
 *@returns JSX
 */
const lesson = (props) => {
    return (
        <li className='list-group-item lh-35'>
            {props.lessonId}--{props.displayName}
            <button
                onClick={() => {
                    props.history.push('/admin/lesson/' + props.lessonId);
                }}
                className='btn pull-right btn-light waves-effect'
            >
                <span>Edit Lesson</span>
            </button>
            <GradeBudge gradeId={props.LessonGroupLessons.gradeId} />
        </li>
    );
};

export default withRouter(lesson);
