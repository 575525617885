import {
  INIT_STUDENTS,
  ADD_STUDENT,
  REMOVE_STUDENT,
  EDIT_STUDENT,
  ADD_STUDENTS,
  STUDENT_DONATED,
  ADD_STUDENTS_TO_STATE,
} from '../actions/actionTypes';

const initialState = {
  students: [],
};

export default function(state = initialState, action) {
  var _students;

  switch (action.type) {
    case ADD_STUDENT:
      const newStudent = action.payload;

      //get copy of the current student list
      _students = [...state.students];

      //add the new class to the begging of the list
      _students.unshift(newStudent);

      return {
        ...state,
        students: _students,
      };
    case ADD_STUDENTS:
      _students = action.payload;

      return {
        ...state,
        students: state.students.concat(_students),
      };

    case INIT_STUDENTS:
      const students = action.payload.Students;
      return {
        ...state,
        students,
      };
    case ADD_STUDENTS_TO_STATE:
      _students = action.payload;
      return {
        ...state,
        students: state.students.concat(_students),
      };
    case EDIT_STUDENT:
      //get copy of the current student list
      _students = [...state.students];

      //get student id and the value to update
      const { studentId } = action.payload;

      //run trough the list and update where needed
      for (let studentIndex in _students) {
        if (_students[studentIndex]['studentId'] === studentId) {
          // student.name = name;
          _students[studentIndex] = action.payload;
          break;
        }
      }

      return {
        ...state,
        students: _students,
      };
    case REMOVE_STUDENT: {
      //get the id
      const studentId = action.payload;

      //get copy of the current student list
      _students = state.students.slice();

      //remove the student from the list
      const removeIndex = _students
        .map(item => item.studentId)
        .indexOf(studentId);

      if (removeIndex !== -1) {
        _students.splice(removeIndex, 1);
      }

      return {
        ...state,
        students: _students,
      };
    }
    case STUDENT_DONATED: {
      return {
        ...state,
        seedsCount: action.payload.data.studentSeeds,
        data: action.payload,
      };
    }
    default:
      return state;
  }
}
