import { ADD_ALERT, REMOVE_ALERT } from '../actions/actionTypes';

const initialState = {
  messages: [],
};

let _messages = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT:
      _messages = [...state.messages];

      if (action.payload.hasOwnProperty('voice')) {
        _messages.push({
          time: Date.now(),
          text: action.payload.text,
          voice: action.payload.voice,
        });
      } else {
        _messages.push({
          time: Date.now(),
          text: action.payload,
          voice: 'Justin',
        });
      }

      return {
        ...state,
        messages: _messages,
      };

    case REMOVE_ALERT:
      if (_messages.length > 0) {
        _messages = [...state.messages];
        _messages.shift();
        return {
          ...state,
          messages: _messages,
        };
      }
      return { ...state };

    default:
      return state;
  }
}
