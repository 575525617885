import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import axios from "config/axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import isEmpty from "validation/isEmpty";

const EmailSchedule = (props) => {
  const [emailSchedule, setEmailSchedule] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailDetailsView, setEmailDetailsView] = useState({});

  const fetchSchedule = async () => {
    const sched = await axios
      .get("/api/int/schools/email-schedule-for-schools")
      ?.then((r) => r?.data);
    console.log(sched);
    if (!isEmpty(sched)) setEmailSchedule(sched);
    else setEmailSchedule([]);
  };

  useEffect(() => {
    fetchSchedule();
  }, []);

  const cancelEmail = async () => {
    const cancelled = await axios.post(
      "/api/int/schools/cancel-email-schedule-for-schools",
      selectedEmails
    );

    setEmailSchedule([
      ...emailSchedule?.filter((es) => !selectedEmails.includes(es.id)),
    ]);
    setSelectedEmails([]);
    return true;
  };

  const viewEmailDetails = async (e) => {
    setEmailDetailsView(e);
  };

  const cancelScheduledEmail = async (e) => {
    const cancelled = await axios.post(
      "/api/int/schools/cancel-scheduled-emails",
      [e?.batchId]
    );

    return true;
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-8">
            <Typography variant="h4">Email Schedule</Typography>
          </div>
          <div className="col-4" style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={isEmpty(selectedEmails)}
              onClick={() => cancelEmail()}
            >
              Cancel Email
            </Button>
          </div>
        </div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>School Id</TableCell>
                <TableCell>School Name</TableCell>
                <TableCell>Started On</TableCell>
                <TableCell>Last Renewal On</TableCell>
                <TableCell>Next Renewal On</TableCell>
                <TableCell>Email Interval Name</TableCell>
                <TableCell>
                  Email Scheduled For <i class="fa fas fa-arrow-down"></i>
                </TableCell>
                <TableCell>Email Scheduled?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailSchedule
                ?.sort((a, b) =>
                  moment(a?.scheduledFor) < moment(b.scheduledFor) ? -1 : 1
                )
                ?.map((es) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          onChange={(e) =>
                            selectedEmails?.find((s) => s === es.id)
                              ? setSelectedEmails([
                                  ...selectedEmails?.filter(
                                    (se) => se !== es.id
                                  ),
                                ])
                              : setSelectedEmails([...selectedEmails, es.id])
                          }
                        />
                      </TableCell>
                      <TableCell>{es?.AllSchool?.schoolId}</TableCell>
                      <TableCell>
                        <a
                          href={`https://app.hubspot.com/contacts/4596264/record/0-2/${es?.AllSchool?.crmId}`}
                          target="_blank"
                        >
                          {es?.AllSchool?.schoolName}
                        </a>
                      </TableCell>
                      <TableCell>{es?.AllSchool?.startedOn}</TableCell>
                      <TableCell>{es?.AllSchool?.lastRenewedOn}</TableCell>
                      <TableCell>{es?.AllSchool?.nextRenewalOn}</TableCell>
                      <TableCell>{es?.emailIntervalLabel}</TableCell>
                      <TableCell>
                        {es?.metadata?.scheduledFor
                          ? es?.metadata?.scheduledFor
                          : es?.scheduledFor}
                      </TableCell>
                      <TableCell>
                        {es?.emailScheduled ? (
                          <div>
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            />
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => viewEmailDetails(es)}
                            >
                              View Details
                            </Button>
                          </div>
                        ) : (
                          <span>-</span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </div>
      <Dialog
        open={!isEmpty(emailDetailsView)}
        onClose={() => {
          setEmailDetailsView({});
        }}
        maxWidth="md"
        fullWidth={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          {emailDetailsView?.AllSchool?.schoolName} -{" "}
          {emailDetailsView?.emailIntervalLabel}
        </DialogTitle>
        <DialogContent>
          <div>
            <div>Email Name - {emailDetailsView?.templateLabel}</div>
            <div>
              Recipients - {emailDetailsView?.metadata?.recipients?.join(", ")}
            </div>
            <div>Batch Id - {emailDetailsView?.batchId}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="btn btn-outline"
            onClick={() => {
              cancelScheduledEmail(emailDetailsView);
              setEmailDetailsView({});
            }}
          >
            Cancel Email
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className="btn btn-outline"
            onClick={() => {
              setEmailDetailsView({});
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailSchedule;
