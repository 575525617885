import {
  SET_CURRENT_USER,
  SET_CURRENT_STUDENT,
  PASSWORD_RESET,
  PASSWORD_RESETED,
  SET_UNAUTHORIZED_TEACHER,
} from '../actions/actionTypes';
import isEmpty from '../../validation/isEmpty';

const initialState = {
  isAuthenticated: false,
  teacher: {},
  unauthorized: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        teacher: action.payload,
      };
    case SET_CURRENT_STUDENT:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        student: action.payload,
      };

    case PASSWORD_RESET:
      return {
        ...state,
        reset_message:
          'We’ve sent you instructions on how to reset your password. Please check your email.',
      };
    case PASSWORD_RESETED:
      return {
        ...state,
        returnToLogin: true,
        passwordChanged: 'The password was changed successfully.',
      };
    case SET_UNAUTHORIZED_TEACHER:
      return {
        ...state,
        unauthorized: Boolean(action.payload),
      };
    default:
      return state;
  }
}
