import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  MenuItem,
  MenuList,
  Paper,
  Typography,
  useMediaQuery,
  Button,
  FormControl,
  Select,
  Input,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PlatformWRSummary from "./PlatformWRSummary";
import axios from "config/axios";
import moment from "moment";
import { useGrades } from "hooks";
import isEmpty from "validation/isEmpty";
import { getSchools } from "store/actions/adminActions";
import AccordionUI from "components/UI/Accordion/Accordion";
import CustomColors from "assets/customisedMUI/CustomColors";
import queryString from "query-string";
import SearchFieldFilter from "components/Common/SearchFieldFilter";

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ExecutiveReport = (props) => {
  const params = queryString.parse(props.location.search);
  const [wrHistory, setWRHistory] = useState({});
  const [loadingPreviousReports, setLoading] = useState(false);
  const [loadedData, setLoadedData] = useState([]);
  const [viewSchool, setViewSchool] = useState({ schoolId: 0, schoolName: "" });
  const [startDate, setStartDate] = useState(
    params.start || moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    params.end || moment().format("YYYY-MM-DD")
  );
  const [selectedGrades, setSelectedGrades] = useState([-1]);
  const [schoolList, setSchoolList] = useState([]);
  const [schoolData, setSchoolData] = useState(null);
  const [searchName, setSearchName] = useState({ searchTerm: "" });
  const [searchState, setSearchState] = useState([
    "'NSW'",
    "'VIC'",
    "'QLD'",
    "'NT'",
    "'ACT'",
    "'SA'",
    "'WA'",
    "'TAS'",
    "'OTH'",
    "''",
  ]);
  const [search, setSearch] = useState(false);
  const [grades, actions] = useGrades();
  const isSmallDevice = useMediaQuery("(max-width: 991px)");
  const stateList = [
    "NSW",
    "VIC",
    "QLD",
    "NT",
    "ACT",
    "SA",
    "WA",
    "TAS",
    "OTH",
  ];

  if (!grades.loaded) {
    actions.load();
  }

  const styleClasses = useStyles();

  const availableSchools =
    schoolData &&
    schoolData.filter((s) => {
      return (
        (!searchName ||
          searchName.searchTerm === "" ||
          s.parent.searchTerm
            .toLowerCase()
            .includes(searchName.searchTerm.toLowerCase())) &&
        (!searchName ||
          !searchName.schoolId ||
          searchName.schoolId === s.parent.schoolId)
      );
    });

  useEffect(() => {
    if (!isEmpty(params.schoolId) && params.schoolId !== viewSchool.schoolId) {
      const findSchool =
        schoolList && schoolList.find((s) => +s.schoolId === +params.schoolId);
      findSchool
        ? setViewSchool({
            schoolId: findSchool.schoolId,
            schoolName: findSchool.schoolName,
          })
        : props.history.push(
            `/admin/exec-report?start=${params.start}&end=${params.end}`
          );
    }
  }, [schoolList]);

  useEffect(() => {
    loadWRHistory();
  }, [viewSchool, searchState]);

  const loadWRHistory = async (start = null, end = null) => {
    if (isEmpty(start)) {
      start = startDate;
    }

    if (isEmpty(end)) {
      end = endDate;
    }

    setLoading(true);
    console.log({ startDate, endDate });
    const rawData = await axios.get(
      `/api_v2/schools/executive-report?start=${startDate}&end=${endDate}&schoolId=${viewSchool.schoolId}&states=${searchState}`
    );
    const tempData = rawData.data && rawData.data.weather_report;
    setLoadedData(tempData);
    setLoading(false);
  };

  useEffect(() => {
    const filteredData = {
      count: loadedData && loadedData.records && loadedData.records.length,
      records:
        loadedData &&
        loadedData.records &&
        loadedData.records.filter((record) => {
          return (
            selectedGrades.includes(+record.student_grade) ||
            selectedGrades.includes(-1)
          );
        }),
      startDate: loadedData && loadedData.startDate,
      endDate: loadedData && loadedData.endDate,
    };
    filteredData ? setWRHistory(filteredData) : setWRHistory({});
  }, [loadedData, selectedGrades]);

  useEffect(() => {
    const fetchSchoolData = async () => {
      const result = await props.getSchools("adminView");
      setSchoolList(
        result.map((school) => {
          return {
            ...school,
            searchTerm:
              school.schoolName + ", " + school.suburb + ", " + school.state,
          };
        })
      );
      organiseSchoolList(result);
    };
    fetchSchoolData();
  }, []);

  const organiseSchoolList = (list) => {
    const childSchools =
      list &&
      list
        .filter(
          (childSchool) =>
            +!isEmpty(childSchool.parentSchoolId) &&
            childSchool.viewSchool !== 0
        )
        .map((school) => {
          return {
            ...school,
            searchTerm:
              school.schoolName + ", " + school.suburb + ", " + school.state,
          };
        });
    const parentSchools =
      list &&
      list
        .filter(
          (parentSchool) =>
            !childSchools
              .map((s) => +s.schoolId)
              .includes(+parentSchool.schoolId) && parentSchool.viewSchool !== 0
        )
        .map((school) => {
          return {
            ...school,
            searchTerm:
              school.schoolName + ", " + school.suburb + ", " + school.state,
          };
        });
    const organisedSchoolList =
      parentSchools &&
      parentSchools.map((parentSchool) => {
        return {
          parent: parentSchool,
          children: childSchools.filter(
            (s) => +s.parentSchoolId === +parentSchool.schoolId
          ),
        };
      });
    setSchoolData(organisedSchoolList);
  };

  const handleChange = (event) => {
    if (event.target.value[event.target.value.length - 1] === -1) {
      setSelectedGrades([-1]);
    } else {
      setSelectedGrades(
        event.target.value
          .filter((grade) => grade !== -1)
          .sort((a, b) => (a < b ? -1 : 1))
      );
    }
  };

  return (
    <>
      <Paper className="card-box">
        <Typography variant="h5">School Executive Reports</Typography>
        <div className="mt-4">
          <div>
            <Typography variant="body1">
              View executive report data for
            </Typography>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={`mt-2 ${isSmallDevice ? "mobile-date" : ""}`}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id={`date-picker-startdate`}
              maxDate={new Date()}
              value={startDate}
              onChange={(date) => {
                const newDate = moment(date)
                  .startOf("day")
                  .format("YYYY-MM-DD");
                props.history.push(
                  `/admin/exec-report?${
                    params.schoolId ? `schoolId=${params.schoolId}` : ""
                  }&start=${newDate}&end=${endDate}`
                );
                setStartDate(newDate);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              id="start-date"
            />
          </MuiPickersUtilsProvider>
          <span
            className="mx-2 mt-4"
            style={{ fontWeight: 700, fontSize: "0.875", lineHeight: "1.75" }}
          >
            -
          </span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={`mt-2 ${isSmallDevice ? "mobile-date" : ""}`}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id={`date-picker-enddate`}
              maxDate={new Date()}
              value={endDate}
              onChange={(date) => {
                const newDate = moment(date).endOf("day").format("YYYY-MM-DD");
                props.history.push(
                  `/admin/exec-report?${
                    params.schoolId ? `schoolId=${params.schoolId}` : ""
                  }&start=${startDate}&end=${newDate}`
                );
                setEndDate(newDate);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              id="end-date"
            />
          </MuiPickersUtilsProvider>
          <Button
            onClick={async () => {
              await loadWRHistory(startDate, endDate);
            }}
            className="ml-4 mt-2"
            variant="contained"
            color="primary"
            style={{ minWidth: 60, marginTop: 0 }}
            title="Update"
          >
            <i
              className="fa fa-search"
              style={{ fontSize: "1rem", lineHeight: "1.1875em" }}
            />
          </Button>
        </div>
        <div className="mt-4">
          <Typography variant="body1">Filter by grade</Typography>
          <FormControl className={styleClasses.formControl}>
            <Select
              id="grades-selector-multiple"
              multiple
              value={selectedGrades}
              onChange={handleChange}
              input={<Input />}
              renderValue={(selectedGrades) =>
                selectedGrades
                  .map((grade) => {
                    return grades.grades.find((obj) => obj.value === grade)
                      .label;
                  })
                  .join(", ")
              }
              MenuProps={MenuProps}
            >
              {grades.grades.map((grade) => (
                <MenuItem key={grade.value} value={grade.value}>
                  <Checkbox
                    checked={selectedGrades.indexOf(grade.value) > -1}
                  />
                  <ListItemText primary={grade.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Paper>
      <Paper className="mt-4 p-4">
        <div className="row">
          <div className="col-lg-3 col-md-12 text-center mb-4">
            <div className="row" style={{ justifyContent: "center" }}>
              {!search ? (
                <>
                  <Typography variant="h5">View School</Typography>
                  <Button
                    onClick={() => setSearch(true)}
                    variant="contained"
                    color="primary"
                    title="Search School"
                    style={{ minWidth: 60, marginTop: 0 }}
                  >
                    <i
                      className="fa fa-search"
                      style={{ fontSize: "1rem", lineHeight: "1.1875em" }}
                    />
                  </Button>
                </>
              ) : (
                <>
                  <SearchFieldFilter
                    options={schoolList}
                    nameParameter="searchTerm"
                    placeholder="Search schools..."
                    setSearchValue={setSearchName}
                    renderOption={(option) => {
                      const optionName = option.searchTerm;
                      if (typeof option === "object") {
                        if (option.inputValue) {
                          return (
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                                padding: "8px",
                              }}
                            >
                              <span
                                style={{
                                  float: "left",
                                  color: "#13b5ea",
                                }}
                              >
                                {optionName} dd
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                                padding: "8px",
                              }}
                            >
                              <span
                                style={{ float: "left", marginRight: "16px" }}
                              >
                                {optionName}
                              </span>
                            </div>
                          );
                        }
                      } else {
                        return optionName;
                      }
                    }}
                  ></SearchFieldFilter>
                </>
              )}
            </div>
            <div style={{ overflowY: "scroll", maxHeight: "800px" }}>
              <MenuList>
                <MenuItem
                  style={{
                    justifyContent: "center",
                    backgroundColor: CustomColors("mustard"),
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setViewSchool({ schoolId: 0, schoolName: "" });
                    props.history.push(
                      `/admin/exec-report?schoolId=${0}&start=${startDate}&end=${endDate}`
                    );
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Whole Platform
                  </Typography>
                </MenuItem>
                {stateList.map((state) => {
                  return (
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", display: "inline-block" }}
                    >
                      <Checkbox
                        value={state}
                        onChange={(e) => {
                          e.target.checked
                            ? setSearchState(
                                searchState.concat("'" + state + "'")
                              )
                            : setSearchState(
                                searchState.replace("'" + state + "'", "")
                              );
                        }}
                        color="primary"
                        defaultChecked
                      />
                      {state}
                    </Typography>
                  );
                })}
                {availableSchools &&
                  availableSchools.map((s) => {
                    return (
                      <>
                        <AccordionUI
                          informationRowData={{
                            parent: !isEmpty(s.children),
                            id: s.parent ? s.parent.schoolId : s.schoolId,
                            information: {
                              schoolId: s.parent
                                ? s.parent.schoolId
                                : s.schoolId,
                              schoolName: s.parent
                                ? s.parent.schoolName
                                : s.schoolName,
                            },
                            tableRowStyle: {
                              backgroundColor:
                                s.parent && s.parent.paymentCategory
                                  ? +s.parent.paymentCategory === 1
                                    ? CustomColors("teal")
                                    : +s.parent.paymentCategory === 0
                                    ? CustomColors("orange")
                                    : +s.parent.paymentCategory === 2
                                    ? CustomColors("blue")
                                    : CustomColors("grey")
                                  : CustomColors("white"),
                            },
                            onRedirect: () => {
                              setViewSchool({
                                schoolId: s.parent
                                  ? s.parent.schoolId
                                  : s.schoolId,
                                schoolName: s.parent
                                  ? s.parent.schoolName
                                  : s.schoolName,
                              });
                              props.history.push(
                                `/admin/exec-report?schoolId=${
                                  s.parent ? s.parent.schoolId : s.schoolId
                                }&start=${startDate}&end=${endDate}`
                              );
                            },
                            isHover: true,
                            noselect: true,
                            children:
                              s.children &&
                              s.children.map((child) => {
                                return {
                                  id: child.schoolId,
                                  information: {
                                    schoolId: child.schoolId,
                                    schoolName: child.schoolName,
                                  },
                                  tableRowStyle: {
                                    backgroundColor:
                                      child && child.paymentCategory
                                        ? +child.paymentCategory === 1
                                          ? CustomColors("teal")
                                          : +child.paymentCategory === 0
                                          ? CustomColors("orange")
                                          : +child.paymentCategory === 2
                                          ? CustomColors("blue")
                                          : CustomColors("grey")
                                        : CustomColors("white"),
                                  },
                                  onRedirect: () => {
                                    setViewSchool({
                                      schoolId: child.schoolId,
                                      schoolName: child.schoolName,
                                    });
                                    props.history.push(
                                      `/admin/exec-report?schoolId=${child.schoolId}&start=${startDate}&end=${endDate}`
                                    );
                                  },
                                  isHover: true,
                                  noselect: true,
                                };
                              }),
                          }}
                        />
                      </>
                    );
                  })}
              </MenuList>
            </div>
          </div>
          <div className="col-lg-9 col-md-12" style={{ overflowY: "scroll" }}>
            <div className="mb-4">
              <PlatformWRSummary
                history={{ ...wrHistory }}
                grades={selectedGrades}
                loading={loadingPreviousReports}
                school={viewSchool}
                access={
                  props.auth &&
                  props.auth.teacher &&
                  +props.auth.teacher.role === 1
                }
              />
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSchools: (queryType) => dispatch(getSchools(queryType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ExecutiveReport));
