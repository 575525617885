import React, { Component, Fragment, createRef, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import axios from 'config/axios';
import './Common.scss';

export default class FileUpload extends Component {
    static propTypes = {
        folder: PropTypes.string, // folder where it will get saved on server
        filename: PropTypes.string,
        fieldName: PropTypes.string,
        maxFileSizeMB: PropTypes.number,
        prompt: PropTypes.string,
        onUploadStart: PropTypes.func,
        onUploadComplete: PropTypes.func,
        onChange: PropTypes.func, // called if the file was uploaded
    };

    constructor(props) {
        super(props);

        this.state = {
            uploading: false,
            uploaded: false,
            error: null,
            filename: this.props.filename || null,
        };

        this.toInitialState = this.toInitialState.bind(this);
    }

    onDrop(files) {
        if (!files.length) {
            return;
        }

        const formData = new FormData();
        formData.append(this.props.folder, files[0]);
        this.setState({ uploading: true, filename: files[0].name });
        if (this.props.onUploadStart) {
            this.props.onUploadStart(files[0].name);
        }

        axios
            .post(this.props.uploadPath || '/api/admin/upload', formData)
            .then((res) => {
                const { Location } = res.data;

                if (this.props.onUploadComplete) {
                    this.props.onUploadComplete(files[0].name);
                }

                if (this.props.onChange) {
                    this.props.onChange(this.props.fieldName, Location);
                }

                this.setState({
                    filename: Location,
                    uploaded: true,
                    uploading: false,
                });
            })
            .catch(function (err) {
                console.error(err);
            });
    }

    toInitialState() {
        this.setState({
            filename: null,
            uploaded: false,
            uploading: false,
        });
    }

    onDropRejected() {
        this.setState({
            error: `File must be less than ${this.props.maxFileSizeMB}MB (jpg, png, gif, doc, pdf)`,
        });
    }

    render() {
        const { uploaded, uploading, filename } = this.state;
        const { existingFile } = this.props;

        const maxFileSizeMB = this.props.maxFileSizeMB ? this.props.maxFileSizeMB : 10;

        const dropzoneRef = createRef();
        return (
            <Dropzone
                accept={
                    this.props.uploadAllowList ||
                    'video/*, image/jpeg, image/png, image/gif, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf'
                }
                onDropRejected={this.onDropRejected.bind(this)}
                maxSize={maxFileSizeMB * 1000000}
                className='nothing_just_override'
                onDrop={this.onDrop.bind(this)}
                multiple={false}
                ref={dropzoneRef}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <div
                            style={{
                                fontSize: 16,
                                color: '#333',
                                border: '2px dashed #999',
                                borderRadius: 5,
                                textAlign: 'center',
                                padding: 10,
                                cursor: 'pointer',
                            }}
                        >
                            <input {...getInputProps()} />
                            {(uploaded || existingFile) && (
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <div>
                                            {filename && (
                                                <img src={filename} alt='' width='80' style={{ maxWidth: 80 }} />
                                            )}
                                            {existingFile && !filename && (
                                                <img src={existingFile} alt='' width='80' style={{ maxWidth: 80 }} />
                                            )}
                                        </div>
                                        <div style={{ fontSize: 12 }}>{this.props.prompt}</div>
                                    </div>
                                </div>
                            )}
                            {!uploaded && (
                                <div>
                                    {uploading && (
                                        <span
                                            style={{
                                                textAlign: 'center',
                                                display: 'inline-block',
                                            }}
                                        >
                                            <i className='fa fa-spinner fa-pulse fa-2x' />
                                            <div style={{ marginTop: 5 }}>Uploading</div>
                                        </span>
                                    )}
                                    {!uploading && (
                                        <div style={{ textAlign: 'center' }}>
                                            <div>{this.props.prompt}</div>
                                            {!existingFile && (
                                                <Fragment>
                                                    <i
                                                        className='material-icons'
                                                        style={{
                                                            fontFamily: 'Material Icons',
                                                        }}
                                                    >
                                                        cloud_upload
                                                    </i>
                                                    <div style={{ fontSize: 12 }}>
                                                        Max file size {maxFileSizeMB}
                                                        MB
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {this.state.error && (
                            <div
                                style={{
                                    marginTop: 10,
                                    fontSize: 12,
                                    color: '#ff8888',
                                    textAlign: 'center',
                                }}
                            >
                                {this.state.error}
                            </div>
                        )}
                    </div>
                )}
            </Dropzone>
        );
    }
}

export const ExcelUpload = ({ upload, title }) => {
    const [file, setFile] = useState(null);
    const input = useRef();
    const accepetedTypes = ['xls', 'xlsx', 'csv'];

    //check if valid file type
    function checkValid() {
        if (file) {
            const nameSplit = file.name.split('.');
            const extension = nameSplit[nameSplit.length - 1].toLowerCase();

            if (accepetedTypes.includes(extension)) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }

    return (
        <div className='xls-import'>
            {title && <h4>{title}</h4>}
            <label htmlFor='studentfile' className='text-muted'>
                <input
                    type='file'
                    ref={input}
                    name='studentfile'
                    id='studentfile'
                    onChange={(e) => {
                        setFile(e.target.files[0]);
                    }}
                    accept='.xls, .xlsx, .csv'
                />
                {file ? file.name : 'Select XLS student list'}
            </label>
            <button
                type='button'
                className='btn btn-info waves-effect waves-light'
                disabled={input.current && input.current.value ? false : true}
                onClick={() => {
                    const data = new FormData();
                    data.append('file', file);
                    upload(data);
                    setFile(null);
                    input.current.value = null;
                }}
            >
                Upload
            </button>
            <span className='fileFormatMsg text-warning'>{!checkValid() && 'Invalid File type selected'}</span>
        </div>
    );
};
