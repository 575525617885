import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import CurriculumManager from 'components/AdminCourse/CurriculumManager';

class AdminCourse extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   *
   * @return {jsx}
   */
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Course Manager</h4>
            </div>
          </div>
        </div>
        <Route path="/admin/course" exact component={CurriculumManager} />
        <Route
          path="/admin/course/edit/:courseId"
          exact
          component={CurriculumManager}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  ui: state.ui,
});

const mapDispatchToProps = dispatch => {
  return {
    // getDefaultCourses: () => dispatch(getDefaultCourses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminCourse));
