import React, { useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';

const ZeeMaps = (props) => {
    const [schoolData, setSchoolData] = useState(null);
    const [dealData, setDealData] = useState(null);
    const [csvData, setCsvData] = useState(null);

    useEffect(() => {
        if (schoolData && dealData) {
            console.log('LOADING DATA');
            calculateCsvData();
        }
    }, [schoolData, dealData]);

    const dataLoaded = (dataType) => (data, fileInfo) => {
        switch (dataType) {
            case 'school':
                setSchoolData(data);
                break;
            case 'deal':
                setDealData(data);
                break;
            default:
                return;
        }
    };

    const calculateCsvData = () => {
        let data = [];
        schoolData.forEach((school) => {
            const schoolId = school['Company ID'];
            const associatedDeals = dealData.filter((deal) => deal['Associated Company ID'] === schoolId);
            if (associatedDeals && associatedDeals.length > 0)
                associatedDeals.forEach((deal) => {
                    data.push({ ...school, ...deal });
                });
            else
                data.push({
                    ...school,
                    ...{
                        'Deal ID': 'N/A',
                        'Deal Name': 'N/A',
                        'Deal Stage': 'N/A',
                        'Close Date': 'N/A',
                        'Deal owner': 'N/A',
                        Amount: 0,
                        'Type of programs': 'No Deals',
                        'Associated Contact IDs': 'N/A',
                        'Associated Contacts': 'N/A',
                    },
                });
        });
        setCsvData(data);
    };

    return (
        <>
            <CSVReader
                label='Select CSV with school records'
                onFileLoaded={dataLoaded('school')}
                onError={() => console.log('Error on upload')}
                inputId='schoolDataInput'
                parserOptions={{ header: true }}
                inputStyle={{ color: 'red' }}
            />
            <CSVReader
                label='Select CSV with closed deal records'
                onFileLoaded={dataLoaded('deal')}
                onError={() => console.log('Error on upload')}
                inputId='dealDataInput'
                parserOptions={{ header: true }}
                inputStyle={{ color: 'red' }}
            />
            {csvData && <CSVLink data={csvData}>Download me</CSVLink>}
        </>
    );
};

export default ZeeMaps;
