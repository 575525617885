import axios from "config/axios";

const setAuthToken = (authToken) => {
  //Apply on every request
  if (authToken) {
    window.localStorage.setItem("authToken", authToken);
    axios.defaults.headers.common["Authorization"] = authToken;
  } else {
    //Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
