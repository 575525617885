import React from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { createNewNotification } from 'store/actions/adminActions'
import classnames from 'classnames';

const NewNotificationForm = (props) => {
    const { touched, handleSubmit, isSubmitting, errors } = props;

    return (
        <div className="form-group row">
        <div className="col-4">
            <Form>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Notification Title*</label>
                    <div className="col">
                        <Field
                            className={classnames('form-control', {
                                'is-invalid': touched.notificationTitle && errors.notificationTitle
                            })}
                            name="notificationTitle"
                            type="text"
                        />
                        {touched.notificationTitle && errors.notificationTitle && (
                            <div className="invalid-feedback">{errors.notificationTitle}</div>
                        )}
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Notification Message*</label>
                    <div className="col">
                        <Field
                            className={classnames('form-control', {
                                'is-invalid': touched.notificationMessage && errors.notificationMessage
                            })}
                            name="notificationMessage"
                            type="text"
                        />
                        {touched.notificationMessage && errors.notificationMessage && (
                            <div className="invalid-feedback">{errors.notificationMessage}</div>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                            className="btn btn-primary waves-effect pull-right"
                        >
                            Create Notification
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    </div>
    )
}

// Pull used redux state into props
const mapStateToProps = (state) => {
    return {
    };
};

// Setup dispatch for redux actions and set as functions on props
const mapDispatchToProps = (dispatch) => {
    return {
        createNewNotification: (values) => dispatch(createNewNotification(values))
    };
};

// Export component connected to redux, including the Formik and Yup validation. Connect is called outside of Fromik to allow redux state to be used in Formik.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withFormik({
    mapPropsToValues({ notificationTitle, notificationMessage}) {
        return {
            notificationTitle: notificationTitle || '',
            notificationMessage: notificationMessage || '',
        };
    },
    validationSchema: Yup.object().shape({
        notificationTitle: Yup.string().required('Notification title cannot be empty'),
        notificationMessage: Yup.string().required('Notification message cannot be empty'),
    }),
    handleSubmit(values, { resetForm, setErrors, setSubmitting, setFieldValue, props }) {
        setSubmitting(true);
        props.createNewNotification(values)
    },
    enableReinitialize: true
})(NewNotificationForm));