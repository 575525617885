import React, { Component, Fragment } from 'react';
import isEmpty from '../../validation/isEmpty';

class Editable extends Component {
    /**
     * @constructor
     * @param {object} props
     */
    constructor(props) {
        super(props);

        //save the name for future reference
        this._title = this.props.title;

        //set state
        this.state = {
            title: this.props.title,
            edit: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Return to initial state
     * @return {void}
     */
    onUndo = () => {
        this.setState({
            edit: false,
            title: this._title
        });
    };

    /**
     * Handle student class form fields
     * @param e {object} - event object
     * @return {void}
     */
    handleChange(e) {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    /**
     * Toggle edit mode
     * @return {void}
     */
    onEditClick = () => {
        this.setState({
            edit: !this.state.edit
        });
    };

    /**
     * Save changes
     * 1.exit the edit mode
     * 2.pass saved name through
     * @return {void}
     */
    onSave = () => {
        this.setState({
            edit: false
        });

        this.props.onEditSave(this.state.title);
    };
    /**
     * Get editable representation
     * @return {jsx}
     * @private
     */
    _editable = () => {
        return (
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-light"
                        type="button"
                        disabled={isEmpty(this.state.title)}
                        onClick={this.onSave}
                    >
                        <i className="fa fa-check" />
                    </button>
                    <button
                        className="btn btn-light"
                        type="button"
                        onClick={this.onUndo}
                    >
                        <i className="fa fa-undo" />
                    </button>
                </div>
            </div>
        );
    };

    /**
     * Get regular student representation
     * @return {jsx}
     * @private
     */
    _view = () => {
        const editClassArr = [
            'fa fa-edit pull-right m-t-1 m-r-2 cursor-pointer'
        ];

        if (this.props.iconsLeft) {
            editClassArr.push('pull-left');
        }

        return (
            <Fragment>
                <span className="pull-left">{this.state.title}</span>
                {this.props.onRemoveClick && (
                    <i
                        className="fa fa-trash pull-right cursor-pointer"
                        onClick={this.props.onRemoveClick}
                    />
                )}
                <i
                    className={editClassArr.join(' ')}
                    onClick={this.onEditClick}
                />
            </Fragment>
        );
    };

    render() {
        const name = this.state.edit ? this._editable() : this._view();
        return name;
    }
}

export default Editable;
