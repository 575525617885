import React from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';

/**
 *@returns JSX
 */
const teacherLoginForm = ({
  values,
  touched,
  handleChange,
  email,
  password,
  isSubmitting,
  isValid,
  loginTeacher,
  errors,
}) => {
  return (
    <Form className="form-signin">
      <div className="form-group row">
        <div className="col">
          <Field
            className={classnames('form-control', {
              'is-invalid': touched.email && errors.email,
            })}
            name="email"
            type="email"
            placeholder="Email Address"
          />
          {touched.email && errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col">
          <Field
            className={classnames('form-control', {
              'is-invalid': touched.password && errors.password,
            })}
            name="password"
            type="password"
            placeholder="Password"
          />
          {touched.password && errors.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col checkbox checkbox-info text-left">
          <input id="save-teacher-email" type="checkbox" className="ml-2" />
          <label htmlFor="save-teacher-email">Remember my email</label>
        </div>
      </div>
      <button
        className="btn mb-5 btn-lg btn-primary btn-block"
        disabled={isSubmitting}
      >
        Sign In
      </button>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues({ email, password, loginTeacher }) {
    return {
      email: email || '',
      password: password || '',
      loginTeacher: loginTeacher,
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Email not valid')
      .required('Email is a required field'),
    password: Yup.string()
      .min(6, 'The password value is too short')
      .required('Password is a required field'),
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    props.loginTeacher(values);
    if (document.getElementById('save-teacher-email').checked === true) {
      localStorage.setItem('teacherEmail', values.email);
    }
    setSubmitting(false);
    resetForm();
  },
})(teacherLoginForm);
