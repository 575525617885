import React from 'react';
import Button from '@material-ui/core/Button';
import CustomColors from './CustomColors';

export default function IconButton(props) {
  return (
    <Button
      variant={props.variant}
      style={{
        backgroundColor: CustomColors(props.backgroundColor, props.backgroundColorShade),
        color: CustomColors(props.color, props.shade),
        border: `${props.border} solid ${CustomColors(props.color)}`,
        textTransform: 'none',
        fontFamily: props.font || 'Museo700',
        marginRight: '10px',
        fontWeight: 'bold',
        float: props.align
      }}
      onClick={props.onClick}
      color={props.inbuiltColor}
    >
      <i className={props.icon} style={{ fontSize: '20px', marginRight: props.label && (props.marginRight || 4) }}></i>
      {props.label}
    </Button>
  );
}
