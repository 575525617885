import activityTypes from "../constants/activityTypes";
import GRADES from "../constants/grades";

import isEmpty from "../validation/isEmpty";

export const getActivityByType = (type, key = null) => {
  const data = activityTypes
    .filter((activity) => {
      return activity._id === type;
    })
    .shift();

  if (data && key) {
    return data[key];
  } else {
    return data;
  }
};

export const getCurrentClass = (classes, classId) => {
  if (classes && classes.length > 0) {
    return classes
      .filter((c) => {
        return Number(c.classId) === Number(classId);
      })
      .shift();
  }

  return null;
};

export const getVideoIdFromVimeoUri = (uri) => {
  if (isEmpty(uri)) {
    return null;
  }

  // eslint-disable-next-line
  const [empty, url, videoId] = uri.uri.split("/");

  return !isEmpty(videoId) ? videoId : null;
};

export const gradeToStringFromId = (gradeId) => {
  if (gradeId > -1 && GRADES) {
    const lookup = GRADES.filter((grade) => {
      return Number(grade.id) === Number(gradeId);
    }).shift();

    if (!isEmpty(lookup)) {
      return lookup.value;
    }
  }
  return null;
};

export const gradeToColor = (gradeId) => {
  const color = {
    0: "badge-warning",
    1: "badge-pink",
    2: "badge-info",
    3: "badge-success",
  };

  return color[gradeId];
};

export const parseJwt = (token) => {
  let base64Payload;
  let payload;
  return new Promise((resolve, reject) => {
    try {
      base64Payload = token.split(".")[1];
      payload = Buffer.from(base64Payload, "base64");
      return resolve(JSON.parse(payload.toString()));
    } catch (e) {
      return reject(null);
    }
  });
};

export default {
  getActivityByType: getActivityByType,
};
