import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions,
} from "@material-ui/core";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import isEmpty from "validation/isEmpty";

const DateRangeSelector = (props) => {
  const [startDate, updateStartDate] = useState(() => {
    return props.startDate || moment().subtract(7, "days").format("YYYY-MM-DD");
  });
  const [endDate, updateEndDate] = useState(() => {
    return props.endDate || moment().format("YYYY-MM-DD");
  });

  useEffect(() => {
    if (!isEmpty(props.startDate)) {
      updateStartDate(props.startDate);
    }

    if (!isEmpty(props.endDate)) {
      updateEndDate(props.endDate);
    }
  }, [props.startDate, props.endDate]);

  return (
    <div>
      <Dialog open={props.open} maxWidth="md" fullWidth>
        <DialogTitle className="text-center">
          Download Usage Report for Group Id: {props.groupId}
        </DialogTitle>
        <DialogContent>
          <div className="text-center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mt-2"
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id={`date-picker-startdate`}
                maxDate={moment().endOf("day")}
                value={startDate}
                onChange={(date) =>
                  updateStartDate(
                    moment(date).startOf("day").format("YYYY-MM-DD")
                  )
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <span
              className="mx-2 mt-4"
              style={{ fontWeight: 700, fontSize: "0.875", lineHeight: "1.75" }}
            >
              -
            </span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="mt-2"
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                id={`date-picker-enddate`}
                maxDate={moment().endOf("day")}
                value={endDate}
                onChange={(date) =>
                  updateEndDate(moment(date).endOf("day").format("YYYY-MM-DD"))
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setSelectedDates(startDate, endDate);
            }}
            className="ml-4 mt-2"
            variant="contained"
            color="primary"
            style={{ minWidth: 60, marginTop: 0 }}
            title="Update"
          >
            <i
              className="fa fa-download"
              style={{ fontSize: "1rem", lineHeight: "1.1875em" }}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DateRangeSelector;
