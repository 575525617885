import React from 'react';
import WeatherReportActivity from 'components/StudentView/WeatherReportActivity';
import 'containers/Student/student.scss';
import { useWeatherReport } from 'hooks';

const WeatherReportManager = (props) => {
    const { WR_CATEGORIES, WR_EMOTIONS } = useWeatherReport();
    return (
        <div className='student-main-background container-fluid studentv2'>
            <div id='canvas-wrapper2'>
                <div className='student-box2'>
                    <WeatherReportActivity standAlone activityObj={{ gradeId: 3 }} />
                </div>
            </div>
            <div className='row p-3' style={{ width: '80%', minHeight: 1000 }}>
                <div className='col-12'>
                    {WR_EMOTIONS.map((emotion, index) => {
                        return (
                            <div className='weather-activity-item' key={index} style={{ display: 'inline-block' }}>
                                <img alt='' key={emotion.id} width={80} height={80} src={emotion.image} />
                                <div
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    {emotion.title}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default WeatherReportManager;
