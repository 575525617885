import React, { useEffect, useState } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Notification from "../../components/UI/Notification";
import { logOutTeacher } from "../../store/actions/authActions";
import Sidebar from "../../components/Navigation/Sidebar/SidebarNew";
import { ROLE_LABELS } from "constants/loginTypes";
import axios from "config/axios";
import { withRouter } from "react-router-dom";

import {
  setCurrentClass,
  initClasses,
  addClass,
  hideLoad,
  setClass,
} from "store/actions/classActions";

import logo from "assets/images/logo.png";

/**
 *@returns JSX
 */
const AdminLayout = ({
  component: Component,
  teacherClasses,
  auth,
  blank,
  history,
  ...rest
}) => {
  const [versionNumber, setVersionNumber] = useState("0.0.0");
  const [open, setOpen] = useState(true);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    async function fetchVersion() {
      await axios.get(`/api_v2/options/version`).then((res) => {
        const v = res.data?.option;
        if (v) {
          setVersionNumber(v);
        }
      });
    }
    fetchVersion();
  }, []);

  //add Event Listenet for toggles sidebar menu automatically
  useEffect(() => {
    function toggleSidebar() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        document.body.classList.add("enlarged");
      } else {
        document.body.classList.remove("enlarged");
      }
    }

    toggleSidebar();
    window.addEventListener("resize", toggleSidebar);

    return () => {
      window.removeEventListener("resize", toggleSidebar);
    };
  });
  useEffect(() => {
    if (!auth.teacher.tfaAuthenticated) {
      console.log(rest, "rst");
      const searchParams = new URLSearchParams(rest.location.search);
      console.log(searchParams.get("redirectUri"));
      history.push(
        `/otp?${
          searchParams.get("redirectUri")
            ? `redirectUri=${searchParams.get("redirectUri")}`
            : rest.location.pathname
            ? `redirectUri=${rest.location.pathname}`
            : ""
        }`
      );
    }
  }, [auth.teacher.tfaAuthenticated]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.unauthorized === true) {
          return (
            <div id="wrapper">
              <div className="content">
                <div className="container-fluid">
                  <div className="col-sm-6 offset-sm-3">
                    <div className="mt-4 alert alert-danger">
                      <h2>
                        <span className="mr-4">
                          <img src={logo} alt="Life Skills GO" height="60px" />
                        </span>
                        Error: Invalid Token
                      </h2>
                      <p>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => {
                            rest.onLogOutTeacher();
                          }}
                        >
                          Log in again
                        </button>
                      </p>
                      <p>
                        There is an issue with your teacher account, please try
                        logging in again, if this problem persists, we suggest
                        the following:
                      </p>
                      <ul>
                        <li>
                          Please speak with your school admin and verify that
                          your teacher account has not been deleted,
                        </li>

                        <li>
                          Or contact Life Skills directly on{" "}
                          <a href="mailto:support@lifeskillsgroup.com.au">
                            support@lifeskillsgroup.com.au
                          </a>
                          &nbsp;or&nbsp;
                          <a href="tel:1300908051" className="">
                            1300 908 051
                          </a>
                          .
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return (
          auth.isAuthenticated === true && (
            <div id="wrapper">
              {/*  Left Sidebar Start  */}
              {!blank && <Sidebar {...props} open={open} setOpen={setOpen} />}
              {/* Left Sidebar End */}

              {/* Start right Content here */}
              <div
                className="content-page"
                style={{ marginLeft: open ? "197px" : "" }}
              >
                {/*Top Bar Start*/}
                <div className="topbar">
                  <nav className="navbar-custom">
                    <ul
                      className="list-inline menu-left mb-0"
                      style={{ height: "60px" }}
                    >
                      <li className="float-left">
                        <button
                          className="button-menu-mobile open-left disable-btn"
                          onClick={() => {
                            document.body.classList.toggle("enlarged");
                          }}
                        >
                          <i className="dripicons-menu" />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/*Top Bar End*/}
                {/* Start content */}
                <div className="content">
                  <div className="container-fluid">
                    {/*Page Title */}
                    <Component {...props} />
                    {/* end row */}
                  </div>{" "}
                  {/* container */}
                </div>{" "}
                {/* content */}
                <footer className="footer text-right">
                  <div id="versionNumber" className="pull-left">
                    <a href="https://help.lifeskillsgroup.com.au/life-skills-go-change-list">
                      <span>Version: v{versionNumber}</span>
                    </a>
                    <br />
                    <span>
                      {currentYear} &copy; Life Skills Group Online Pty Ltd.
                    </span>
                  </div>
                  <div className="pull-right">
                    <a
                      href="https://www.facebook.com/groups/GOusers/"
                      className="cursor"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-facebook-official"
                        style={{ fontSize: 18 }}
                      />{" "}
                      &nbsp;Join our teacher community!
                    </a>
                  </div>
                </footer>
                <div id="notification">
                  <Notification />
                </div>
              </div>
              {/* End Right content here */}
            </div>
          )
        );
      }}
    />
  );
};

AdminLayout.propTypes = {
  auth: PropTypes.object.isRequired,
  teacherClasses: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  teacherClasses: state.classes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLogOutTeacher: () => dispatch(logOutTeacher()),
    setCurrentClass: (classId) => dispatch(setCurrentClass(classId)),
    doInitClasses: (teacherId) => dispatch(initClasses(teacherId)),
    addClassToStore: (clss) => dispatch(addClass(clss)),
    onLoaded: () => dispatch(hideLoad),
    setClassData: (clss) => dispatch(setClass(clss)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminLayout));
