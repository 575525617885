export const TYPES = {
    //QUIZ TYPES
    QUIZ: {
        ID: 100,
        TYPE: 'QUIZ',
        TITLE: 'Quiz',
        COMPONENT: 'Quiz',
    },
    QUIZ_MULTI_SINGLE: {
        ID: 101,
        TITLE: 'Single Answer',
        TYPE: 'QUIZ_MULTI_SINGLE',
        COMPONENT: 'Quiz',
    },
    QUIZ_MULTI_MULTI: {
        ID: 102,
        TITLE: 'Multiple Answer',
        TYPE: 'QUIZ_MULTI_MULTI',
        COMPONENT: 'Quiz',
    },
    QUIZ_TEXT_FIELD: {
        ID: 103,
        TITLE: 'Open ended',
        TYPE: 'QUIZ_TEXT_FIELD',
        COMPONENT: 'Quiz',
    },
    QUIZ_DRAW_RESPONSE: {
        ID: 104,
        TITLE: 'Draw response',
        TYPE: 'QUIZ_DRAW_RESPONSE',
        COMPONENT: 'Quiz',
    },
    //ANIMATIONS
    ANIMATION: {
        ID: 200,
        TITLE: 'Animation',
        TYPE: 'ANIMATION',
    },
    //GAMES
    GAME: {
        ID: 300,
        TYPE: 'GAME',
        TITLE: 'Games',
        COMPONENT: 'Game',
    },

    //VIDEOS
    VIDEO: {
        ID: 400,
        TITLE: 'Videos',
        TYPE: 'VIDEO',
        COMPONENT: 'Video',
    },
    //STATIC VIDEOS
    STATIC_VIDEO: {
        ID: 800,
        TITLE: 'Videos',
        TYPE: 'STATIC_VIDEO',
        COMPONENT: 'Video',
    },
    WEATHERREPORT: {
        ID: 500,
        TITLE: 'Weather Report',
        TYPE: 'WEATHERREPORT',
        COMPONENT: 'WeatherReport',
    },
    WEATHERCHECKIN: {
        ID: 600,
        TITLE: 'Weather Check In',
        TYPE: 'WEATHERCHECKIN',
        COMPONENT: 'WeatherCheckin',
    },
};

export const WEATHER_REPORT = [
    {
        cat: 'Storm',
        id: 1,
        image: 'Storm',
        pos: 280,
        items: [
            {
                id: 801,
                title: 'Frustrated',
                image: 'Storm_Frustration',
                video: [356531219, 356311898],
            },
            {
                id: 802,
                title: 'Angry',
                image: 'Storm_Argumentative',
                video: [356535027, 356316010],
            },
            // {
            //   id: 820,
            //   title: "Scheming",
            //   image: "Stormy_Scheming_Sly",
            //   video: [293496879, 329243908]
            // },
            {
                id: 829,
                title: 'Ashamed',
                image: 'Stormy_Ashamed',
                video: [356530471, 356311178],
            },
            {
                id: 831,
                title: 'Disappointed',
                image: 'Stormy_Disappointed',
                video: [356533297, 356314075],
            },
            {
                id: 832,
                title: 'Frightened',
                image: 'Stormy_Frightened',
                video: [356531219, 356311898],
            },
            {
                id: 837,
                title: 'Sad',
                image: 'Rainfall_Sad',
                video: [356536697, 356317650],
            },
            {
                id: 836,
                title: 'Lonely',
                image: 'Rainfall_Lonely',
                video: [356538252, 356319279],
            },
        ],
    },
    {
        cat: 'Rainfall',
        id: 2,
        image: 'Rainfall',
        pos: 320,
        items: [
            {
                id: 803,
                title: 'Anxious',
                image: 'Rainfall_Anxious_Trepidatious',
                video: [356531219, 356311898],
            },
            {
                id: 804,
                title: 'Hurt',
                image: 'Rainfall_Hurt',
                video: [356530471, 356311178],
            },
            {
                id: 805,
                title: 'Miserable',
                image: 'Rainfall_Miserable',
                video: [356536697, 356317650],
            },
            {
                id: 806,
                title: 'Nervous',
                image: 'Rainfall_Nervous',
                video: [356531219, 356311898],
            },
            {
                id: 821,
                title: 'Impatient',
                image: 'Rainfall_Impatient',
                video: [356543298, 356324981],
            },
            {
                id: 822,
                title: 'Tired',
                image: 'Rainfall_Tired',
                video: [356534341, 356315137],
            },
            {
                id: 834,
                title: 'Annoyed',
                image: 'Rainfall_Annoyed',
                video: [356326715, 356326715],
            },
            {
                id: 835,
                title: 'Embarrassed',
                image: 'Rainfall_Embarassed',
                video: [356326715, 356326715],
            },
            {
                id: 838,
                title: 'Scared',
                image: 'Rainfall_Scared',
                video: [356530471, 356311178],
            },
            // {
            //   id: 839,
            //   title: "Uncomfortable",
            //   image: "Rainfall_Uncomfortable",
            //   video: 293498824
            // },
            {
                id: 840,
                title: 'Worried',
                image: 'Rainfall_Worried',
                video: [356532428, 356313170],
            },
        ],
    },
    {
        cat: 'Cloudy',
        id: 3,
        image: 'Cloudy',
        pos: 440,
        items: [
            {
                id: 807,
                title: 'Bored',
                image: 'Cloudy_Bored',
                video: [356536059, 356317070],
            },
            {
                id: 808,
                title: 'Cautious',
                image: 'Cloudy_Cautious',
                video: [356539796, 356321142],
            },
            {
                id: 823,
                title: 'Quiet',
                image: 'Partly_Quiet',
                video: [356534341, 356315137],
            },
            {
                id: 824,
                title: 'Busy',
                image: 'Partly_Rainfall_Stormy_Busy',
                video: [356532428, 356313170],
            },
            {
                id: 841,
                title: 'Peaceful',
                image: 'Partly_Peaceful',
                video: [293500098, 329243908],
            },
            {
                id: 844,
                title: 'Shy',
                image: 'Partly_Shy',
                video: [356541348, 356322804],
            },
            {
                id: 833,
                title: 'Jealous',
                image: 'Stormy_Jealous',
                video: [356542207, 356323847],
            },
            {
                id: 811,
                title: 'Arrogant',
                image: 'Rainbow_Arrogance',
                video: [356538252, 356319279],
            },
        ],
    },
    {
        cat: 'Sun',
        id: 4,
        image: 'Sun',
        pos: 440,
        items: [
            {
                id: 809,
                title: 'Happy',
                image: 'Sun_Happy',
                video: [356542207, 356323847],
            },
            {
                id: 810,
                title: 'Content',
                image: 'Sun_Content',
                video: [356535027, 356316010],
            },
            // {
            //     id: 825,
            //     title: 'Loved',
            //     image: 'Sunny_Loved',
            //     video: 293496330
            // },
            {
                id: 826,
                title: 'Calm',
                image: 'Rainbow_Sunny_Partly_Calm',
                video: [356544039, 356325877],
            },
            {
                id: 845,
                title: 'Brave',
                image: 'Sunny_Brave',
                video: [356537454, 356318497],
            },
            {
                id: 846,
                title: 'Comfortable',
                image: 'Sunny_Comfortable',
                video: [356536697, 356317650],
            },
            {
                id: 847,
                title: 'Friendly',
                image: 'Sunny_Friendly',
                video: [356537454, 356318497],
            },
            {
                id: 848,
                title: 'Grateful',
                image: 'Sunny_Grateful',
                video: [356541348, 356322804],
            },
            {
                id: 849,
                title: 'Kind',
                image: 'Sunny_Kind',
                video: [356540507, 356321989],
            },
            {
                id: 843,
                title: 'Safe',
                image: 'Partly_Safe',
                video: [356542207, 356323847],
            },
        ],
    },
    {
        cat: 'Rainbow',
        id: 5,
        image: 'Rainbow',
        pos: 300,
        items: [
            {
                id: 812,
                title: 'Energized',
                image: 'Rainbow_Energized',
                video: [356544039, 356325877],
            },
            {
                id: 813,
                title: 'Excited',
                image: 'Rainbow_Excited_Awe',
                video: [356533297, 356314075],
            },
            {
                id: 814,
                title: 'Proud',
                image: 'Rainbow_Pride',
                video: [356538252, 356319279],
            },
            {
                id: 815,
                title: 'Silly',
                image: 'Rainbow_Silly',
                video: [356540507, 356321989],
            },
            {
                id: 827,
                title: 'Confident',
                image: 'Rainbow_confident',
                video: [356544039, 356325877],
            },
            {
                id: 850,
                title: 'Loved',
                image: 'Rainbow_Loved',
                video: [356542207, 356323847],
            },
            {
                id: 851,
                title: 'Overjoyed',
                image: 'Rainbow_Overjoyed',
                video: [356536059, 356317070],
            },
            {
                id: 852,
                title: 'Surprised',
                image: 'Rainbow_Surprised',
                video: [356536059, 356317070],
            },
            {
                id: 853,
                title: 'Cheerful',
                image: 'Rainbow_Cheerful',
                video: [356543298, 356324981],
            },
            {
                id: 842,
                title: 'Hopeful',
                image: 'Partly_Hopeful',
                video: [356533297, 356314075],
            },
        ],
    },
];

export const WHEATHER_REPORT_DB_ID = 999999999998;
export const WHEATHER_CHECKIN_DB_ID = 999999999999;

export const ENDGAME_ID = 301;

export const ACTIVITY_SUMMARY = {
    activityId: 999999999997,
    title: 'Summary',
    sliderImage: 'activity_summary',
    content: {},
    activityType: 'Summary',
};

export const WEATHER_REPORT_COMPLETION = [
    {
        id: 0,
        action: 'Pause',
        description: 'You have selected to halt student lesson progression after completing this weather report.',
    },
    {
        id: 1,
        action: 'Continue',
        description: 'Students go to default course after completion of the weather report.',
    },
];

export const GAME_TYPES = [
    { id: 300, type: 'General' },
    { id: 310, type: 'Scenario Based' },
];

//THIS STRUCTURE USED IN THE ACTIVITY MANAGER
export default [
    {
        _id: TYPES.QUIZ.ID,
        type: TYPES.QUIZ.TYPE,
        title: 'Quiz',
        icon: 'dripicons-checklist',
        component: 'Quiz',
        url: '/admin/activity/quiz',
        parent: null,
    },
    {
        _id: TYPES.QUIZ_MULTI_SINGLE.ID,
        type: TYPES.QUIZ_MULTI_SINGLE.TYPE,
        title: 'Single Answer',
        description: 'Multiple choice single answer',
        icon: 'dripicons-checklist',
        component: 'Quizzes/QuizMultiSingle',
        studentComponent: 'StudentView/QuizSingleAnswer',
        sliderImage: 'quiz_multi_single',
        celebration: true,
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Multiple choice, single answer',
    },
    {
        _id: TYPES.QUIZ_MULTI_MULTI.ID,
        type: TYPES.QUIZ_MULTI_MULTI.TYPE,
        title: 'Multiple Answer',
        description: 'Multiple choice multiple answer',
        icon: 'dripicons-view-list',
        component: 'Quizzes/QuizMultiMulti',
        studentComponent: 'StudentView/QuizMultiAnswer',
        sliderImage: 'quiz_multi_multi',
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Multiple choice, multiple answer',
    },
    {
        _id: TYPES.QUIZ_TEXT_FIELD.ID,
        type: TYPES.QUIZ_TEXT_FIELD.TYPE,
        title: 'Text Field',
        description: 'Open-ended text field',
        icon: 'dripicons-blog',
        component: 'Quizzes/QuizTextField',
        studentComponent: 'StudentView/QuizOpenEnded',
        sliderImage: 'quiz_text_response',
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Open response',
    },
    {
        _id: TYPES.QUIZ_DRAW_RESPONSE.ID,
        type: TYPES.QUIZ_DRAW_RESPONSE.TYPE,
        title: 'Draw Response',
        description: 'Open-ended draw response',
        icon: 'dripicons-pencil',
        component: 'Quizzes/QuizDrawResponse',
        studentComponent: 'StudentView/QuizDrawResponse',
        sliderImage: 'quiz_draw_response',
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Draw Response',
    },
    {
        _id: TYPES.GAME.ID,
        type: TYPES.GAME.TYPE,
        title: 'Game',
        icon: 'dripicons-gaming',
        component: 'Game',
        studentComponent: 'StudentView/GameActivity',
        url: '/admin/activity/games',
        sliderImage: 'game',
        reportTitle: 'Game',
        parent: null,
    },
    {
        _id: TYPES.VIDEO.ID,
        type: TYPES.VIDEO.TYPE,
        title: 'Video',
        icon: 'dripicons-camcorder',
        component: 'Video',
        studentComponent: 'StudentView/VideoActivity',
        url: '/admin/activity/videos',
        sliderImage: 'character_animations',
        parent: null,
    },
    {
        _id: TYPES.STATIC_VIDEO.ID,
        type: TYPES.STATIC_VIDEO.TYPE,
        title: 'Video',
        icon: 'dripicons-camcorder',
        component: 'Videos',
        studentComponent: 'StudentView/StaticVideoActivity',
        url: '/admin/activity/videos',
        sliderImage: 'character_animations',
        parent: TYPES.VIDEO.COMPONENT,
    },
    {
        _id: TYPES.WEATHERREPORT.ID,
        type: TYPES.WEATHERREPORT.TYPE,
        title: 'Weather Report',
        icon: 'dripicons-cloud',
        component: 'WeatherReport',
        studentComponent: 'StudentView/WeatherReportActivity',
        url: '/admin/activity/weather-report',
        sliderImage: 'weather_report',
        parent: 1,
    },
    {
        _id: TYPES.WEATHERCHECKIN.ID,
        type: TYPES.WEATHERCHECKIN.TYPE,
        title: 'Weather Check-in',
        icon: 'dripicons-cloud-upload',
        component: 'WeatherCheckin',
        url: '/admin/activity/weather-check-in',
        sliderImage: 'weather_check_in',
        parent: 1,
    },
];

/**
 * This module concerns the 'User_Actions' table in DynamoDB.
 *
 * We index items in this table using a 'userId' (teacher ID) and 'timestamp' pair.
 *
 * Items must also contain an 'area' indicating what part of the platform the action affected,
 * e.g. lesson, unit, student, teacher, and so on.
 *
 * An 'action' description indicates what the action accomplished, e.g. delete, update, or create
 * a record for the previous area.
 */

const units = [
    'ASSIGN_DEFAULT_UNIT', // Teacher assigned a default unit to their class.
    'ASSIGN_SCHOOL_UNIT', // Teacher assigned a school-wide unit to their class.
    'ASSIGN_CUSTOM_UNIT', // Teacher assigned a custom unit to their class.

    'REMOVE_UNIT', // Teacher removed a unit from their class.
    'UPDATE_UNIT', // Teacher updated a unit assigned to their class.
    'CHANGE_UNIT_VIEW', // Teacher changed their current unit.
    'ASSIGN_EXISTING_UNIT_BUTTON',

    'CREATE_SCHOOL_UNIT', // School administrator created a school-wide unit.
    'EDIT_SCHOOL_UNIT', // School administrator modified a school-wide unit.
    // 'REMOVE_SCHOOL_UNIT' // School administrator removed a school-wide unit.
    'SELECT_CLASS',
    'UNIT_DETAILS_BUTTON',
    'EDIT_UNIT_BUTTON',
    'ADD_NEW_UNIT_BUTTON',
    'REMOVE_UNIT_BUTTON',
    'REPORTING_DASHBOARD',
];

const classes = [
    'CREATE_CLASS', // Teacher created a new class.
    'UPDATE_CLASS', // Teacher updated their own class (e.g. changed grade, name).
    'DELETE_CLASS', // Teacher deleted their own class.

    'SHARE_CLASS', // Teacher shared their class with another teacher.
    'RECEIVED_CLASS', // Teacher had a class shared with them.
];

const lessons = [
    'START_LESSON', // Teacher started a new lesson.
    'PAUSE_LESSON', // Teacher set a started lesson to 'paused'.
    'STOP_LESSON', // Teacher set a started or paused lesson to 'stopped'.

    'VIEW_LESSON_CONTENT', // Teacher viewed PDF of lesson content (See: link in lesson blocks under units section in dashboard).

    'PREVIEW_LESSON_TEACHER', // Teacher previewed a lesson in teacher mode.
    'PREVIEW_ACTIVITY_TEACHER', // Teacher previewed an activity in a lesson on the teacher portal.
    'CHANGE_LESSON_DATE',
];

const template_units = [
    'PURCHASE_TEMPLATE_UNIT_BUTTON',
    'ASSIGN_TEMPLATE_UNIT_BUTTON',
    'UNIT_DETAILS_BUTTON',
    'CREATE_NEW_UNIT_BUTTON',
    'CONTINUE_NEW_UNIT_BUTTON',
];

const create_unit = [
    'CHANGE_LESSON_DATE',
    'LESSON_SELECT',
    'LESSON_DESELECT',
    'BACK_TO_LESSONS_STEP',
    'BACK_TO_DETAILS_STEP',
    'LESSON_CONTENT_REPORT',
    'LESSON_DETAILS_VIEW',
    'LESSON_DETAILS_HIDE',
];

const students = [
    'CREATE_STUDENT', // New student was created.
    'UPDATE_STUDENT', // Existing student was updated.
    'DELETE_STUDENT', // Existing student was deleted.
    'STUDENT_QR_VIEW',
    'AWARD_BADGE_STUDENT',
    'LOGIN_AS_STUDENT',
    'UPLOAD_CLASS_LIST',
    'SELECT_CLASS',
    'ASSIGN_STUDENTS_SUBMIT',
    'MANAGE_STUDENTS_BUTTON',
    'CLASS_LOGIN_PRINT',
    'CLASS_QR_PRINT',
    'STUDENT_PROFILE',
];

const teachers = [
    'CREATE_TEACHER', // New teacher was created.
    'UPDATE_TEACHER', // Existing teacher was updated.
    'DELETE_TEACHER', // Existing teacher was deleted.

    'LOG_IN_AS_TEACHER', // School administrator logged in as another teacher.
    'LOG_IN_AS_STUDENT', // Teacher logged in as one of their students.
];

const self = [
    'RESET_PASSWORD', // Teacher attempted to reset their own password.
    'CHANGE_PASSWORD', // Teacher changed their password in-platform.
    'CHANGE_EMAIL', // Teacher changed their email in-platform.
];

const auth = [
    'LOG_IN_TOKEN_NEW', // Teacher logged in successfully via username/password.
    'LOG_IN_TOKEN_EXISTING', // Teacher accessed the platform using a valid JWT token.
    'LOG_IN_FAILED', // Teacher attempted to log in via username/password (incorrect credentials).
    'LOG_IN_EXPIRED', // Teacher attempted to access the platform using an expired/invalid JWT token.
    'LOG_OUT', // Teacher explicitly logged out of the platform (i.e. clicked the button).
];

const resource = [
    'TEACHER_WELLBEING', // Teacher accessed a wellbeing resource.
    'TEACHER_CURRICULUM_MAP', // Teacher accessed the curriculum map section.
    'TEACHER_PROFESSIONAL_DEVELOPMENT', // Teacher accessed the professional development section.

    'CLASS_PRINTABLE', // Teacher accessed a printable class resource.
    'CLASS_BRAIN_BREAKS', // Teacher accessed a brain break video.
    'CLASS_GUIDED_BREATHING', // Teacher accessed a guided breathing video.
    'CLASS_GUIDED_VISUALISATIONS', // Teacher accessed a guided visualization video.
    'CLASS_MINDFUL_EXERCISES', // Teacher accessed a mindful exercises video.
    'CLASS_RELAXATION_STORIES', // Teacher accessed a relaxation story video.
];

const reporting = [
    'DOWNLOAD_LESSON_REPORT', // Teacher downloaded a lesson report.
    'DOWNLOAD_UNIT_CURRICULUM_REPORT', // Teacher downloaded a unit curriculum report.
    'MARK_STUDENT_RESPONSE', // Teacher marked a student response.
    'SELECT_CLASS',
    'BACK_TO_DASHBOARD',
    'DOWNLOAD_DRAWING_ACTIVITY',
    'VIEW_LESSON_RESPONSE',
    'AWARD_BADGE_STUDENT',
];

const weather_report = [
    'WEATHER_REPORT_QUICK', // Teacher started a quick weather report.
    'WEATHER_REPORT_ROLLCALL', // Teacher started a weather report rollcall.
    'CLASS_SELECT',
    'TOGGLE_CLASS_WEATHER_REPORT',
    'BACK_TO_DASHBOARD',
];

// User click interactions by page
const sidebar = [
    'DASHBOARD',
    'MY_SCHOOL',
    'EXECUTIVE_REPORT',
    'APPCUES_VIEW',
    'CONTRACT',
    'EXPAND',
    'CLASSROOM_RESOURCES',
    'PRINTABLE_RESOURCES',
    'VALUE_POSTERS',
    'VIDEO_RESOURCES',
    'TEACHER_RESOURCES',
    'TEACHER_WELLBEING',
    'TEACHER_RESOURCE_CURRICULUM_MAP',
    'SCHOOL_LEADERBOARD',
    'HELP',
    'KNOWLEDGE_BASE',
    'QUICK_START_GUIDE',
    'PROFILE_MENU',
    'PROFILE_VIEW',
    'PROFILE_LOGOUT',
    'DASHBOARD_LOGO',
    'SCHOOL_PURCHASE',
    'NOTIFICATIONS',
    'ACCOUNT_EXPIRY',
    'TEACHER_PURCHASE',
    'WEATHER_REPORT_QUICK',
];
const dashboard = [
    'WEATHER_REPORT_DASHBOARD',
    'REPORTING_DASHBOARD',
    'CURRICULUM_DAQSHBOARD',
    'STUDENT_DASHBOARD',
    'DELETE_CLASS_BUTTON',
    'NEW_CLASS_BUTTON',
    'EDIT_CLASS_BUTTON',
    'WIZARD_COMPLETE_SETUP',
    'CLASS_SELECT',
    'WIZARD_START_SETUP',
];
const footer = [
    'SUPPORT_EMAIL',
    'SUPPORT_PHONE',
    'VERSION_NUMBER_CHANGELIST',
    'TERMS_AND_CONDITIONS',
    'FACEBOOK_GROUP',
];
const wizard = [
    'PRINT_STUDENT_LIST_BUTTON',
    'PRINT_STUDENT_QR_BUTTON',
    'SAVE_AND_EXIT',
    'CREATE_CLASS',
    'PURCHASE_TEMPLATE_UNIT_BUTTON',
    'ASSIGN_TEMPLATE_UNIT_BUTTON',
    'UNIT_DETAILS_BUTTON',
    'ASSIGN_STUDENTS_SUBMIT',
];
const my_school = [
    'REMOVE_TEACHER_BUTTON',
    'RESEND_INVITE_BUTTON',
    'LOGIN_AS_TEACHER',
    'ASSIGN_CLASSES_BUTTON',
    'EDIT_SCHOOL_UNIT_BUTTON',
    'DELETE_SCHOOL_UNIT_BUTTON',
];
const session = ['SESSION_START', 'SESSION_END'];

export const teacherActions = {
    UNITS: units,
    CLASSES: classes,
    LESSONS: lessons,
    STUDENTS: students,
    TEACHERS: teachers,
    SELF: self,
    AUTH: auth,
    RESOURCE: resource,
    REPORTING: reporting,
    WEATHER_REPORT: weather_report,
    SIDEBAR: sidebar,
    SESSION: session,
    DASHBOARD: dashboard,
    FOOTER: footer,
    WIZARD: wizard,
    TEMPLATE_UNITS: template_units,
    CREATE_UNIT: create_unit,
    MY_SCHOOL: my_school,
};
