import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  loginTeacher,
  forgotPassword,
  resetPassword,
} from "store/actions/authActions";
import { cleanError } from "store/actions/uiActions";

import TeacherLoginForm from "components/Auth/TeacherLoginForm";
import LocalNotifications from "components/UI/Notifications/LocalNotifications";
import isEmpty from "validation/isEmpty";

const TeacherLogin = (props) => {
  if (props.auth.isAuthenticated) {
    props.history.push("/admin/schools");
  }

  const { errors } = props;
  const passwordChanged = props.auth.passwordChanged;
  const reset_password_message = props.auth.reset_message;

  return (
    <div className="text-center">
      <div className="display-5 mb-4">Admin sign in</div>
      {!isEmpty(errors) && !isEmpty(errors.errors) && (
        <LocalNotifications
          message="Incorrect sign in details"
          type={LocalNotifications.DANGER}
        />
      )}

      {!isEmpty(reset_password_message) && (
        <LocalNotifications
          message={reset_password_message}
          type={LocalNotifications.SUCCESS}
        />
      )}
      {!isEmpty(passwordChanged) && (
        <LocalNotifications
          message={passwordChanged}
          type={LocalNotifications.SUCCESS}
        />
      )}
      <TeacherLoginForm
        loginTeacher={(authData) => props.loginTeacher(authData, props.history)}
        email={localStorage.getItem("teacherEmail") || null}
      />

      <div>
        <a className="other-login" href="/users/forgotPassword">
          I've forgotten or do not know my password.
        </a>
        <a className="other-login to-student mt-2" href="/student">
          I'm a student.
        </a>
      </div>
    </div>
  );
};

TeacherLogin.propTypes = {
  loginTeacher: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginTeacher,
  forgotPassword,
  resetPassword,
  cleanError,
})(withRouter(TeacherLogin));
