import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  IconButton,
  TablePagination,
  IconButton as MuiIB,
  Tooltip as TTip,
  Grow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { getSchool, getSchools } from "store/actions/adminActions";
import { connect } from "react-redux";
import axios from "config/axios";
import isEmpty from "../../validation/isEmpty";
import { reSendInvite, removeTeacher } from "store/actions/teacherActions";
import { loginAs } from "store/actions/authActions";
import { confirmAlert } from "react-confirm-alert";
import { toggleModal } from "../../store/actions/uiActions";
import Modal from "components/UI/Modal/Modal";
import InformationRow from "components/Common/InformationRow";
import { ROLE_LABELS } from "constants/loginTypes";
import ConfirmDialog from "components/UI/ConfirmAlert";
import jwtDecode from "jwt-decode";
import { parseJwt } from "utils";
import moment from "moment";
import NewTeacher from "containers/Schools/NewTeacher";
import { theme } from "assets/customisedMUI";
import DataGrid from "components/UI/DataGrid";
import { Editors } from "react-data-grid-addons";
import DatePicker from "components/UI/DatePicker";

const SearchTeacher = (props) => {
  const [teacher, setTeacher] = useState();
  const [school, setSchool] = useState();
  const [campaign, setCampaign] = useState(null);
  const [teacherData, setTeacherData] = useState([]);
  const [signUpLink, setSignUpLink] = useState({ loading: false, link: null });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [editing, setEditing] = useState(false);
  const [toUpdate, setToUpdate] = useState(null);
  const [accessData, setAccessData] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [createTeacher, setCreateTeacher] = useState(false);
  const [showDataGrid, setShowDataGrid] = useState(false);

  const { DropDownEditor } = Editors;

  useEffect(() => {
    async function fetchTeacherData() {
      await axios
        .get(
          `/api_v2/teachers?searchTerm=${teacher || ""}&schoolId=${
            school || ""
          }&campaign=${campaign || ""}`
        )
        .then((res) => {
          if (res.data) {
            //if teacher name is entered to search for
            setTeacherData(res.data);
          }
        });
    }
    fetchTeacherData();
  }, [teacher, school, campaign]); // Separate useEffect callback to handle state update (useCallback had memoised state and would reinstate initial values)
  useEffect(() => {
    if (toUpdate) {
      const teachersToUpdate = Array.isArray(toUpdate) ? toUpdate : [toUpdate];
      const alreadySelected = teachersToUpdate.filter((t) =>
        selectedTeachers.includes(t)
      );
      const newSelected = teachersToUpdate.filter(
        (t) => !selectedTeachers.includes(t)
      );
      if (!isEmpty(newSelected)) {
        setSelectedTeachers([...selectedTeachers, ...newSelected]);
      }
      if (!isEmpty(alreadySelected)) {
        setSelectedTeachers(
          selectedTeachers.filter((tid) => !alreadySelected.includes(tid))
        );
      }
      setToUpdate(null);
    }
  }, [toUpdate]);

  const updateState = React.useCallback(
    (teacherId) => {
      setToUpdate(teacherId);
    },
    [setToUpdate]
  );

  useEffect(() => {
    if (isEmpty(selectedTeachers)) {
      setEditing(false);
    }
  }, [selectedTeachers]);

  useEffect(() => {
    const fetchLink = async () => {
      if (!isEmpty(accessData)) {
        const token = !isEmpty(accessData)
          ? await axios.get(`api/users/signUpLink/${accessData.teacherId}`)
          : "";
        if (isEmpty(token.data)) {
          const newLink = await axios.get(
            `/api_v2/admin/signupToken/${accessData.teacherId}`
          );
          const expiryTime = await decodeToken(newLink.data.token);

          !isEmpty(newLink.data)
            ? setSignUpLink({
                link: newLink.data ? newLink.data.link : "",
                timeLeft: expiryTime,
              })
            : setSignUpLink({ link: "", timeLeft: "" });
        } else {
          const expiryTime = await decodeToken(token.data);
          setSignUpLink({
            link:
              "https://teacher.lifeskillsgo.com/teacher/register/" + token.data,
            timeLeft: expiryTime,
          });
        }
      }
    };
    fetchLink();
  }, [accessData]);

  const decodeToken = async (token) => {
    const decoded = await parseJwt(token.replace("Bearer ", ""));
    const currentTime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
    const expiryTime = moment(new Date(decoded.exp * 1000)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const timeLeft = moment(expiryTime).diff(moment(currentTime), "hours");
    return timeLeft;
  };

  const AccessAreas = [
    { id: "cards", name: "Printable Resource Cards" },
    { id: "videos", name: "Videos" },
    { id: "qwr", name: "Quick Weather Report" },
    { id: "wrh", name: "Weather Report & History" },
    { id: "ur", name: "Unit Reports" },
    { id: "er", name: "Executive Reports" },
    { id: "curriculum", name: "Access to Standard Life Skills GO Curriculum" },
  ];

  const Features = {
    all: ["cards", "videos", "qwr", "wrh", "ur", "er", "curriculum"],
    tier1: ["cards", "videos", "qwr", "curriculum"],
    tier2: ["cards", "videos", "qwr", "wrh", "curriculum"],
    tier3: ["cards", "videos", "qwr", "wrh", "ur", "er", "curriculum"],
  };

  const closeModal = () => {
    props.toggleModal(false);
    setAccessData([]);
    setUpdateData([]);
    setSignUpLink({ link: "", timeLeft: "" });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let modalContent = () => {
    const TeacherAvailableFeatures =
      !isEmpty(accessData) && accessData.features
        ? Features[accessData.features[0]]
        : [];

    return (
      <div>
        {!isEmpty(accessData) && (
          <div>
            <h3>
              <b>
                {accessData.firstName} {accessData.lastName}
              </b>{" "}
              <small> - Access Control</small>
            </h3>
            <hr />
            <div>
              {AccessAreas.map((acc) => {
                let accessControlDisabled = false;
                let accessControlAvailable = true;

                //check access available to user
                if (
                  accessData.access &&
                  acc.id in accessData.access &&
                  accessData.access[acc.id] === false
                ) {
                  accessControlAvailable = false;
                }

                //check tier / feature of user
                if (
                  !isEmpty(TeacherAvailableFeatures) &&
                  !TeacherAvailableFeatures.includes(acc.id)
                ) {
                  accessControlDisabled = true;
                  accessControlAvailable = false;
                }

                return (
                  <div
                    className="checkbox checkbox-info"
                    key={`access-${acc.id}`}
                  >
                    <input
                      type="checkbox"
                      id={acc.id}
                      disabled={accessControlDisabled}
                      onChange={(e) => {
                        const inputState = e.target.checked;
                        addUpdateData(acc.id, inputState);
                      }}
                      defaultChecked={accessControlAvailable}
                    />
                    <label htmlFor={acc.id}>
                      <span>{acc.name}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            <Button
              color="primary"
              variant="contained"
              disabled={isEmpty(updateData)}
              onClick={(e) => {
                doUpdateAccessData(e.target);
                e.target.innerHTML = "Updating ...";
              }}
            >
              <Typography>Update</Typography>
            </Button>
          </div>
        )}

        <hr />
        <hr />
        <div>
          <h3>
            <b>Sign Up Link</b>
          </h3>
          <Typography variant="h6" style={{ wordBreak: "break-word" }}>
            {!isEmpty(signUpLink.link) && (
              <>
                <b>Link:</b> {signUpLink.link}
                <br />
                <br />
                <b>Token expires in:</b>{" "}
                {signUpLink.timeLeft >= 0
                  ? signUpLink.timeLeft + " hours"
                  : "Expired"}
                <br />
                <br />
                <div className="text-center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => generateSignupLink(accessData.teacherId)}
                  >
                    Generate New Token
                  </Button>
                </div>
              </>
            )}
          </Typography>
        </div>
      </div>
    );
  };

  const addUpdateData = (access, checkboxState) => {
    const newUpdateData = { ...updateData };

    newUpdateData[access] = checkboxState;

    setUpdateData(newUpdateData);
  };

  /** element is upload button passed tp show success or failed message */
  const doUpdateAccessData = async (element) => {
    element.disabled = true;
    await axios
      .post("api/teachers/updateAccess/" + accessData.teacherId, updateData)
      .then((result) => {
        element.innerHTML = result.data ? "Success" : "Failed";
        element.disabled = false;
        setTimeout(() => {
          element.innerHTML = "Update";
        }, 5000);

        //update stat data so admin gets actual data wirthout refreshing page
        if (result.data) {
          const newRowData = teacher.map((rd) => {
            if (+rd.teacherId !== +accessData.teacherId) {
              return rd;
            } else {
              return { ...rd, features: [...rd.features], access: updateData };
            }
          });

          setTeacherData(newRowData);
        }
      });
  };

  const generateSignupLink = async (teacherId) => {
    setSignUpLink({ isLoading: teacherId, link: null });
    await axios
      .get(`/api_v2/admin/signupToken/${teacherId}`)
      .then((res) => {
        const { link, token } = res.data;
        decodeToken(token).then((res) => {
          setSignUpLink({ isLoading: false, link: link, timeLeft: res });
          props.toggleModal(true);
        });
      })
      .catch((err) => {
        console.error("Error getting signupData");
      });
  };

  const roleOptions = [
    {
      id: 3,
      value: 3,
      label: "School Admin",
      variant: "role",
    },
    {
      id: 4,
      value: 4,
      label: "Teacher",
      variant: "role",
    },
  ];

  const postEditData = async (data) => {
    try {
      const dataArray = Array.isArray(data) ? data : [data];
      let updatedDataArray = [];
      dataArray.forEach((d) => {
        const name = d.name ? d.name.split(/(\s+)/) : "";
        const lastName = d.lastName
          ? d.lastName
          : d.name
          ? name[name.length - 1] || d.name
          : "";
        const firstName = d.firstName
          ? d.firstName
          : d.name
          ? name.filter((n) => n !== " " && n !== lastName).join(" ") || d.name
          : "";

        const updateData = {
          teacherId: d.teacherId,
          firstName: firstName,
          lastName: lastName,
          email: d.email,
          role:
            d.role === "LS Admin"
              ? 1
              : d.role === "School Admin"
              ? 3
              : +d.role === 3
              ? 3
              : 4,
        };

        d.expiryDate !== "Invalid date" &&
          d.expiryDate !== "N/A" &&
          d.expiryDate !== "Invalid Date" &&
          Object.assign(updateData, {
            expiryDate: moment(d.expiryDate, "DD/MM/YYYY", true).format(),
          });

        updatedDataArray.push(updateData);
      });

      const result = await axios.post(
        `/api/teachers/bulk-update`,
        updatedDataArray
      );

      let teacherList = teacherData;

      if (!isEmpty(result.data)) {
        const response = Array.isArray(result.data)
          ? result.data
          : [result.data];
        response.forEach((res) => {
          const targetTeacher = teacherList.find(
            (t) => t.teacherId === res.teacherId
          );
          const newTeacher = {
            ...targetTeacher,
            ...(res.firstName != null && {
              firstName: res.firstName,
            }),
            ...(res.lastName != null && {
              lastName: res.lastName,
            }),
            ...(res.email != null && { email: res.email }),
            ...(res.expiryDate != null && {
              expiryDate: res.expiryDate,
            }),
            ...(res.role != null && {
              role: res.role,
            }),
          };
          const newRows = [...teacherList];
          newRows.splice(teacherList.indexOf(targetTeacher), 1, newTeacher);
          teacherList = newRows;
        });
      }
      setTeacherData(teacherList);
      setSelectedTeachers([]);

      return true;
    } catch (err) {
      console.log("Error updating teacher data");
    }
  };

  const saveNewTeacher = async (data) => {
    if (teacher.find((t) => t.email === data.email)) {
      return {
        error: "Email already exists. Please try another email address.",
      };
    } else {
      setTeacher(
        [...teacher, data].sort((a, b) =>
          a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
        )
      );
      setCreateTeacher(false);
    }
  };

  const mapTeachersToRows = () => {
    if (!isEmpty(teacherData)) {
      const sliceEnd =
        rowsPerPage === -1
          ? teacherData.length + 1
          : page * rowsPerPage + rowsPerPage;
      return teacherData.slice(page * rowsPerPage, sliceEnd).map((t) => {
        const teacherRole = roleOptions.find((r) => +r.value === +t.role) || "";

        return (
          <InformationRow
            id={t.teacherId}
            information={{
              teacherId: t.teacherId,
              name: t.firstName + " " + t.lastName,
              email: t.email,
              role:
                t.role === 1
                  ? "LS Admin"
                  : t.role === 2
                  ? "Sales"
                  : teacherRole,
              expiryDate: t.expiryDate
                ? moment(t.expiryDate).format("YYYY-MM-DD")
                : `N/A`,
              lastLogIn: t.lastLogIn
                ? moment(t.lastLogIn).format("YYYY-MM-DD")
                : `N/A`,
              schoolId: t.schools && t.schools.schoolId,
              schoolName: t.schools && t.schools.schoolName,
              groupId: t.groups && t.groups && t.groups.groupId,
            }}
            editing={editing}
            editViewOptions={{
              name: ["text-field"],
              email: ["text-field"],
              role:
                t.role === "LS Admin" || ROLE_LABELS[t.role] === "Author"
                  ? []
                  : ["select-list", roleOptions],
            }}
            selected={selectedTeachers.includes(t.teacherId)}
            onEditSave={(data) =>
              postEditData({ teacherId: t.teacherId, ...data })
            }
            checkCallBack={() => updateState(t.teacherId)}
            editCallback={() => updateState(t.teacherId)}
            isHover={true}
            onRedirect={() => {
              setAccessData(t);
              setUpdateData(t.access);
              props.toggleModal(true);
            }}
            actionButtons={{
              loginAs: [
                "Login As",
                () => props.loginAs(t.teacherId, t.role),
                {
                  type: "icon-button",
                  tooltipMessage: `Login as ${t.firstName + " " + t.lastName}`,
                  icon: "fa fa-sign-in",
                },
              ],
            }}
          />
        );
      });
    } else {
      return (
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>No record found</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      );
    }
  };

  const UnselectedHeader = () => {
    return (
      <>
        <TableCell style={{ width: 25, padding: 6 }}>
          <Checkbox
            checked={!isEmpty(selectedTeachers)}
            onChange={() => updateState(teacherData.map((t) => t.teacherId))}
          />
        </TableCell>
        <TableCell style={theme.tableHeadCell.style}>Teacher Id</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Name</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Email</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Role</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Expiry Date</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Last Login</TableCell>
        <TableCell style={theme.tableHeadCell.style}>School Id</TableCell>
        <TableCell style={theme.tableHeadCell.style}>School Name</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Group Id</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Login As</TableCell>
      </>
    );
  };

  const SelectedHeader = () => {
    return (
      <>
        <TableCell style={{ width: 25, padding: 6 }}>
          <Checkbox
            checked={!isEmpty(selectedTeachers)}
            onChange={() => updateState(teacherData.map((t) => t.teacherId))}
          />
        </TableCell>
        <TableCell style={theme.tableHeadCell.style} colspan={7}>
          <span>
            {selectedTeachers.length} teacher
            {selectedTeachers.length > 1 ? "s" : ""} selected
          </span>
          <span>
            <Button
              onClick={() => setShowDataGrid(!showDataGrid)}
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-pencil mr-2"></i>{" "}
              Edit Details
            </Button>
          </span>
          <span>
            <Button
              onClick={() => {
                axios
                  .post(`/api/admin/teachers/sendInvites`, {
                    teacherIds: selectedTeachers,
                  })
                  .then((res) => {
                    props.doShowNotification(
                      "success",
                      `${selectedTeachers.length} invites sent successfully`
                    );
                    setSelectedTeachers([]);
                  })
                  .catch((err) => console.log(err));
              }}
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-envelope mr-2" />{" "}
              Resend Invites
            </Button>
          </span>
          <span>
            <Button
              onClick={() => {
                setShowDeleteAlert(true);
              }}
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-trash mr-2"></i>{" "}
              Delete Teacher{selectedTeachers.length > 1 ? "s" : ""}
            </Button>
          </span>
          <span>
            <Button
              onClick={() => setSelectedTeachers([])}
              variant="contained"
              color="secondary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-close mr-2"></i>{" "}
              Cancel
            </Button>
          </span>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          overflowY: "scroll",
          width: "100%",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[50, 100, 250, { value: -1, label: "All" }]}
          component="div"
          count={teacherData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${to === -1 ? "All" : from + "-" + to} of ${
              count !== -1 ? count : 0
            }`
          }
        />
        <Table
          stickyHeader
          size="small"
          aria-label="simple table"
          overflow="scroll"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Search by schoolId:</TableCell>
              <TableCell>
                <TextField
                  label="Enter schoolId"
                  value={school}
                  onChange={(e) => {
                    setSchool(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  value="2"
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setCampaign(e.target.checked && e.target.value);
                      }}
                      color="primary"
                    />
                  }
                  label="Tier 0 Only"
                  labelPlacement="start"
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell colSpan={2}>Search by teacherId or name</TableCell>
              <TableCell colSpan={3}>
                <TextField
                  label="Enter number or name"
                  value={teacher}
                  onChange={(e) => {
                    setTeacher(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colspan={10}>
                Bulk edit, resend invitations and more actions are available by
                selecting the checkboxes next to the teacher name.
              </TableCell>
            </TableRow>
            <TableRow>
              {isEmpty(selectedTeachers) ? (
                <UnselectedHeader />
              ) : (
                <SelectedHeader />
              )}
            </TableRow>
          </TableHead>

          <TableBody>{mapTeachersToRows()}</TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250, { value: -1, label: "All" }]}
          component="div"
          count={teacherData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${to === -1 ? "All" : from + "-" + to} of ${
              count !== -1 ? count : 0
            }`
          }
        />
      </div>
      <Modal closeCallback={closeModal}>{modalContent()}</Modal>
      <NewTeacher
        open={createTeacher}
        onClose={() => setCreateTeacher(false)}
        saveTeacher={saveNewTeacher}
        onAddCallback={(data) => {
          setTeacherData(
            [...teacherData, data].sort((a, b) =>
              a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
            )
          );
        }}
      />

      <div className="row">
        <ConfirmDialog
          severity="error"
          question={`Delete teacher${
            selectedTeachers.length === 1 ? "" : "s"
          }?`}
          message={`Deleting ${
            selectedTeachers.length === 1 ? "this teacher" : "these teachers"
          } will remove their access to Life Skills GO.`}
          open={showDeleteAlert ? true : false}
          onClose={() => setShowDeleteAlert(false)}
          confirmCallback={() => {
            axios
              .put(`api/v3/users/delete`, selectedTeachers)
              .then((res) => {
                setTeacherData(
                  teacherData.filter((t) => !res.data.includes(t.teacherId))
                );
                setSelectedTeachers([]);
                setShowDeleteAlert(false);
              })
              .catch((err) => console.log(err));
          }}
          confirmName="Delete"
          dismissName="Cancel"
        ></ConfirmDialog>
        <Modal closeCallback={closeModal}>{modalContent()}</Modal>
        <Dialog
          maxWidth="lg"
          className="badges-dialog"
          open={showDataGrid}
          fullWidth={true}
        >
          <DialogTitle>
            <span
              className="close-dialog pull-right"
              onClick={() => {
                setShowDataGrid(false);
              }}
            >
              <i className="fa fa-times-circle" />
            </span>
            <div className="text-center">
              <Typography variant="h6">
                Note: Double click on cells under Role and Expiry Date columns
                to edit them.
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="container view-course text-left">
              <DataGrid
                columns={[
                  { key: "teacherId", name: "Teacher Id", editable: false },
                  { key: "firstName", name: "First Name", editable: true },
                  { key: "lastName", name: "Last Name", editable: true },
                  { key: "email", name: "Email", editable: true },
                  {
                    key: "role",
                    name: "Role",
                    editable: true,
                    editor: (
                      <DropDownEditor
                        options={[
                          {
                            id: 3,
                            value: "School Admin",
                          },
                          {
                            id: 4,
                            value: "Teacher",
                          },
                        ]}
                      />
                    ),
                  },
                  {
                    key: "expiryDate",
                    name: "Expiry Date",
                    editable: true,
                    editor: <DatePicker />,
                  },
                ]}
                rows={teacherData
                  .filter((t) => selectedTeachers.includes(t.teacherId))
                  .map((t) => {
                    return {
                      teacherId: t.teacherId,
                      firstName: t.firstName,
                      lastName: t.lastName,
                      email: t.email,
                      role: ROLE_LABELS[t.role],
                      expiryDate: moment(t.expiryDate).format("DD/MM/YYYY"),
                    };
                  })}
                updateData={(data) => {
                  setShowDataGrid(false);
                  return postEditData(data);
                }}
                validations={{
                  firstName: ["string"],
                  lastName: ["string"],
                  email: ["string", /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/],
                  role: ["string", /\b(?:School Admin|Teacher)\b/],
                  expiryDate: [
                    "string",
                    /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/,
                  ],
                }}
                onClose={() => setShowDataGrid(false)}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    getSchool: (schoolId) => dispatch(getSchool(schoolId)),
    getSchools: () => dispatch(getSchools()),
    sendInvite: (data) => dispatch(reSendInvite(data)),
    loginAs: (teacherId, teacherRole) =>
      dispatch(loginAs(teacherId, teacherRole)),
    removeTeacher: (teacherId) => dispatch(removeTeacher(teacherId)),
    toggleModal: (state) => dispatch(toggleModal(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTeacher);
