import React, { useEffect, useState, useRef } from "react";
import axios from "config/axios";
import DataGrid from "components/UI/DataGrid";
import { Editors } from "react-data-grid-addons";
import SearchFieldFilter from "components/Common/SearchFieldFilter";
import { debounce } from "lodash";
import { Button } from "@material-ui/core";
import { ExcelUpload } from "components/Common/FileUpload";

const { DropDownEditor } = Editors;

const SchoolStudents = (props) => {
  const [students, setStudents] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchQuery, setSearchQuery] = useState({ limit: 50, offset: 0 });

  const uploadRef = useRef();

  useEffect(() => {
    setSearchQuery({ limit: 50, offset: 0 });
  }, [searchName]);

  useEffect(() => {
    axios
      .get(
        `/api_v2/admin/students?limit=${searchQuery.limit}&offset=${searchQuery.offset}&name=${searchName}`
      )
      .then((res) => {
        console.log(searchQuery.offset === 0, res.data.rows, students);
        setStudents(
          searchQuery.offset === 0
            ? [
                ...res.data.rows.map((s) => {
                  return {
                    ...s,
                    groups: s.group,
                  };
                }),
              ]
            : [
                ...students,
                ...res.data.rows.map((s) => {
                  return {
                    ...s,
                    groups: s.group,
                  };
                }),
              ]
        );
      });
  }, [searchQuery]);

  const debounceCallback = debounce((v) => {
    setSearchName(v);
  }, 500);

  return (
    <>
      <SearchFieldFilter
        options={students}
        nameParameter="name"
        placeholder="Search students..."
        setSearchValue={(v) => {
          setSearchName(v);
        }}
        debounceSearch={(e, v) => {
          debounceCallback(v);
        }}
        // onAddClick={(inputValue) => {
        //     setShowCreateStudentModal({ studentName: inputValue, grade: 0 });
        // }}
        renderOption={(option) => {
          const optionName = option.name;
          if (typeof option === "object") {
            if (option.inputValue) {
              return (
                <div
                  style={{ display: "block", width: "100%", padding: "8px" }}
                >
                  <span
                    style={{
                      float: "left",
                      color: "#13b5ea",
                    }}
                  >
                    {optionName}
                  </span>
                </div>
              );
            } else {
              return (
                <div
                  style={{ display: "block", width: "100%", padding: "8px" }}
                >
                  <span style={{ float: "left", marginRight: "16px" }}>
                    {optionName}
                  </span>
                </div>
              );
            }
          } else {
            return optionName;
          }
        }}
      ></SearchFieldFilter>
      <DataGrid
        columns={[
          { key: "studentId", name: "Student ID", editable: false },
          { key: "name", name: "Name", editable: true },
          { key: "schoolName", name: "School", editable: false },
          { key: "username", name: "Username", editable: false },
          { key: "password", name: "Password", editable: true },
          {
            key: "gradeId",
            name: "Grade",
            editable: true,
            editor: (
              <DropDownEditor
                options={[
                  { id: 0, value: "Kindergarten" },
                  { id: 1, value: 1 },
                  { id: 2, value: 2 },
                  { id: 3, value: 3 },
                  { id: 4, value: 4 },
                  { id: 5, value: 5 },
                  { id: 6, value: 6 },
                ]}
              />
            ),
          },
          { key: "groups", name: "Group", editable: false },
        ]}
        rows={students}
        updateData={(data) => {
          // return postEditData(data);
        }}
        validations={{
          name: ["string"],
          // lastName: ['string'],
          // email: ['string', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/],
          // role: ['string', /\b(?:School Admin|Teacher)\b/],
          // expiryDate: ['string', /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/],
        }}
      />
      <Button
        onClick={() => {
          setSearchQuery({
            ...searchQuery,
            offset: searchQuery.offset + searchQuery.limit,
          });
        }}
      >
        Load More
      </Button>

      <ExcelUpload
        ref={uploadRef}
        upload={(data) => axios.post(`/api/admin/excel`, data)}
      />
    </>
  );
};

export default SchoolStudents;
