import {
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import isEmpty from "validation/isEmpty";
import { useForm } from "react-hook-form";
import axios from "config/axios";

const EditSchoolForm = (props) => {
  const school = !isEmpty(props.school) ? props.school : {};
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [salesTeam, setSalesTeam] = useState([]);
  const paymentStatusOptions = [
    { label: "paid", value: "paid" },
    { label: "free", value: "free" },
    { label: "expired", value: "expd" },
  ];
  const onSubmit = (data) => props.addSchool({ ...school, ...data });

  useEffect(() => {
    const fetchSalesTeam = async () => {
      const options = await axios.get("/api_v2/options/all");
      const team =
        options &&
        options.data &&
        options.data.find((opt) => opt.title === "salesTeam");
      setSalesTeam(JSON.parse(team.option));
    };
    fetchSalesTeam();
  }, []);

  return (
    <>
      <DialogTitle className="text-center">
        Edit <b>{school.schoolName}</b>
        <span
          style={{
            position: "absolute",
            right: "8px",
            top: 0,
            cursor: "pointer",
          }}
          onClick={props.handleClose}
        >
          <i className="far fa-times-circle" />
        </span>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col text-center">
            <TextField
              type="text"
              placeholder="School name"
              {...register("schoolName", { required: true, maxLength: 80 })}
              value={school.schoolName}
              style={{ width: "100%" }}
            />
            <div className="form-group row" style={{ marginTop: 20 }}>
              <div className="col">
                <select
                  className="form-control"
                  name={"paymentStatus"}
                  {...register("paymentStatus")}
                  defaultValue={school.paymentStatus}
                >
                  {paymentStatusOptions.map((option) => (
                    <option key={option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: 20 }}>
              <div className="col">
                <select
                  className="form-control"
                  name={"salesTeamMember"}
                  value={
                    school.salesTeamMember
                  }
                  {...register("salesTeamMember")}
                >
                  {salesTeam.map((option) => (
                    <option key={option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row" style={{ marginTop: 20 }}>
              <div className="col">
                <TextField
                  type="text"
                  placeholder="Max students"
                  {...register("maxStudents")}
                  value={school.maxStudents}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <Button variant="contained" type="submit">
              Update
            </Button>
          </div>
        </form>
      </DialogContent>
    </>
  );
};

export default EditSchoolForm;
