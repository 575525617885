import React from 'react';
import ZeeMaps from 'components/Schools/ZeeMaps';

const ZeemapsTool = (props) => {
    return (
        <div className='col-12' style={{ marginBottom: 30 }}>
            <h6 className='page-title'>Zeemaps Data Generator</h6>
            <ZeeMaps />
        </div>
    );
};
export default ZeemapsTool;
