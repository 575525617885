import { PUSH_SEEDS } from '../actions/actionTypes';

const initialState = {
  seedsCount: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PUSH_SEEDS: {
      return {
        ...state,
        seedsCount: action.payload.seeds,
      };
    }

    default:
      return state;
  }
}
