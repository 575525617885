import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { loginTeacher } from "store/actions/authActions";

import isEmpty from "validation/isEmpty";

import axios from "config/axios";
import { useGrades } from "hooks";

import "./SchoolForm.scss";

const AustralianStates = [
  "NSW",
  "ACT",
  "TAS",
  "QLD",
  "WA",
  "VIC",
  "SA",
  "NT",
].sort();

const SchoolFormNew = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [schools, setSchools] = useState([]);
  const [filteredSchoolList, setfilteredSchools] = useState([]);
  const [newSchool, setNewSchool] = useState(false);

  //component did mount load initial school list
  useEffect(() => {
    async function loadSchools() {
      const list = await axios.get("/api/schools/list");

      setSchools(
        list &&
          !isEmpty(list.data) &&
          list.data.map((school) => {
            const name = `${school.schoolName}, ${school.suburb}, ${school.postcode}`;
            return {
              ...school,
              searchableName: name.toLowerCase(),
              schoolName: name,
              school: school.schoolName,
            };
          })
      );
      // actions.increment();
    }

    loadSchools();
    //eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      schoolName: "",
      state: "",
      address: "",
      suburb: "",
      postcode: "",
      industry: "",
      sector: "",
      crmId: "",
      maxStudents: "",
      schoolId: null,
    },
    validationSchema: yup.object({
      schoolName: yup.string().required("School name is required"),
      state: yup
        .string()
        .required(
          "Select from Australian states or Other form available values"
        ),
      address: yup.string(),
      suburb: yup.string(),
      postcode: yup.string(),
      industry: yup.string(),
      sector: yup.string(),
      maxStudents: yup.string(),
    }),
    onSubmit: async (values) => {
      // console.log("form values: ", values);
      setSubmitting(true);
      await props.addSchool(values).then((r) => {
        console.log(r);
        return r.error ? "" : props.handleClose();
      });
      setSubmitting(false);
    },
  });

  const stateChange = (newValue) => {
    formik.setFieldValue("state", newValue);

    //filter schoolsList based on state
    setfilteredSchools(() => {
      // console.log('filtering schools started');
      const updatedList = schools.filter((sch) => sch.state === newValue);

      // clear school if not in the list of filteredschools on state change
      if (!isEmpty(formik.values.schoolName)) {
        const schoolinList = updatedList.find((sch) =>
          sch.school
            .toLowerCase()
            .includes(formik.values.schoolName.toLowerCase())
        );

        formik.setFieldValue(
          "schoolName",
          schoolinList ? schoolinList.school : ""
        );

        formik.setFieldValue(
          "schoolId",
          schoolinList ? schoolinList.schoolId : ""
        );
      }

      return updatedList;
    });
  };

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth>
      <DialogTitle className="text-center">
        Create a new school
        <span
          style={{
            position: "absolute",
            right: "8px",
            top: 0,
            cursor: "pointer",
          }}
          onClick={props.handleClose}
        >
          <i className="far fa-times-circle" />
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="trial-register mb-4 ">
          <form className="p-4" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <Autocomplete
                  id="state-selector"
                  options={[...AustralianStates, "Other"]}
                  onChange={(event, newValue) => {
                    stateChange(newValue);
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="state"
                      name="state"
                      label="State"
                      placeholder="State"
                      type="text"
                      value={formik.values.state}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={formik.touched.state && formik.errors.state}
                      variant="outlined"
                      autoComplete="off"
                      required
                      onChange={params.onChange}
                    />
                  )}
                />
              </div>

              <div className="col-md-8">
                <Autocomplete
                  freeSolo
                  id="school-selector-autocomplete"
                  filterOptions={createFilterOptions({
                    limit: 50,
                    stringify: (sch) => sch.searchableName,
                  })}
                  options={filteredSchoolList}
                  getOptionLabel={(sch) => sch.schoolName}
                  onChange={(event, newValue) => {
                    //only passing schoolname to server
                    formik.setFieldValue("schoolName", newValue.school);
                    formik.setFieldValue("schoolId", newValue.schoolId);
                    formik.setValues({ ...newValue });
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="schoolName"
                      name="schoolName"
                      label="School Name"
                      placeholder="Awesome school, suburb"
                      type="text"
                      value={formik.values.schoolName}
                      onChange={(e) => {
                        formik.setFieldValue("schoolName", e.target.value);
                      }}
                      error={
                        formik.touched.schoolName &&
                        Boolean(formik.errors.schoolName)
                      }
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                  )}
                />
              </div>
            </div>
            {newSchool && (
              <>
                <TextField
                  fullWidth
                  id="address"
                  name="address"
                  placeholder="Address"
                  label="Address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  variant="outlined"
                  autoComplete="address"
                />
                <div className="row">
                  <div className="col-6">
                    <TextField
                      fullWidth
                      id="suburb"
                      name="suburb"
                      placeholder="Suburb"
                      label="Suburb"
                      value={formik.values.suburb}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.suburb && Boolean(formik.errors.suburb)
                      }
                      helperText={formik.touched.suburb && formik.errors.suburb}
                      variant="outlined"
                      autoComplete="suburb"
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      fullWidth
                      id="postcode"
                      name="postcode"
                      label="Postcode"
                      placeholder="Postcode"
                      value={formik.values.postcode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postcode &&
                        Boolean(formik.errors.postcode)
                      }
                      helperText={
                        formik.touched.postcode && formik.errors.postcode
                      }
                      variant="outlined"
                      autoComplete="postcode"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <TextField
                      fullWidth
                      id="industry"
                      name="industry"
                      label="Industry"
                      placeholder="Industry"
                      value={formik.values.industry}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.industry &&
                        Boolean(formik.errors.industry)
                      }
                      helperText={
                        formik.touched.industry && formik.errors.industry
                      }
                      variant="outlined"
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      fullWidth
                      id="sector"
                      name="sector"
                      label="Sector"
                      placeholder="Sector"
                      value={formik.values.sector}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.sector && Boolean(formik.errors.sector)
                      }
                      helperText={formik.touched.sector && formik.errors.sector}
                      variant="outlined"
                      autoComplete="sector"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      id="maxStudents"
                      name="maxStudents"
                      label="Maximum Students"
                      placeholder="30"
                      value={formik.values.maxStudents}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.maxStudents &&
                        Boolean(formik.errors.maxStudents)
                      }
                      helperText={
                        formik.touched.maxStudents && formik.errors.maxStudents
                      }
                      variant="outlined"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      fullWidth
                      id="crmId"
                      name="crmId"
                      label="Hubspot Id"
                      placeholder="0123456789"
                      value={formik.values.crmId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.crmId && Boolean(formik.errors.crmId)
                      }
                      helperText={formik.touched.crmId && formik.errors.crmId}
                      variant="outlined"
                    />
                  </div>
                </div>
              </>
            )}
            <FormControl
              error={
                formik.touched.additionalInfo &&
                Boolean(formik.errors.additionalInfo)
              }
            >
              <FormControlLabel
                label={<span>Additional Info</span>}
                control={
                  <Checkbox
                    checked={formik.values.additionalInfo}
                    onChange={(e) => {
                      const newState = e.target.checked;
                      setNewSchool(newState);
                      formik.setFieldValue("additionalInfo", newState);
                    }}
                    color="primary"
                  />
                }
              />
              {formik.touched.additionalInfo &&
                Boolean(formik.errors.additionalInfo) && (
                  <FormHelperText id="my-helper-text">
                    {formik.errors.additionalInfo}
                  </FormHelperText>
                )}
            </FormControl>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  loginTeacher,
})(withRouter(SchoolFormNew));
