import React, { useRef, useState } from "react";

import {
  IconButton as MuiIB,
  TextField,
  Popover,
  Tooltip,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

const SearchFieldFilter = ({
  options,
  children,
  nameParameter,
  placeholder,
  setSearchValue,
  debounceSearch,
  renderOption,
  onAddClick,
}) => {
  const textFieldRef = useRef();
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const handleShowFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  return (
    <>
      <Autocomplete
        id="state"
        options={options}
        style={{ width: "100%", display: "block", margin: "0 auto" }}
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.unshift({
              inputValue: params.inputValue,
              [nameParameter]: `Contains "${params.inputValue}"`,
              control: "contains",
            });
            filtered.push({
              inputValue: params.inputValue,
              [nameParameter]: `Create "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setSearchValue({ searchTerm: newValue });
          } else if (newValue && newValue.inputValue) {
            if (newValue.control !== "contains") {
              onAddClick(newValue.inputValue);
            } else {
              setSearchValue({ [nameParameter]: newValue.inputValue });
            }
          } else {
            setSearchValue(newValue);
          }
        }}
        onInputChange={debounceSearch}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth
              name={`autocomplete-filter-${nameParameter}`}
              placeholder={placeholder}
              type="text"
              variant="outlined"
              autoComplete="off"
              onChange={params.onChange}
              ref={textFieldRef}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {children && (
                      <>
                        <Tooltip
                          title={
                            <div style={{ width: "100%", fontSize: 14 }}>
                              Show more filters
                            </div>
                          }
                        >
                          <span>
                            <MuiIB onClick={handleShowFilterOptions}>
                              <i className="fas fa-sliders-h"></i>
                            </MuiIB>
                          </span>
                        </Tooltip>
                      </>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        renderOption={renderOption}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option[nameParameter]) {
            return option[nameParameter];
          }
          return option.inputValue;
        }}
      />
      <Popover
        id={"filter-options"}
        open={showFilterOptions}
        anchorEl={textFieldRef.current}
        onClose={handleShowFilterOptions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            width:
              textFieldRef.current &&
              textFieldRef.current.getBoundingClientRect().width,
            backgroundColor: "white",
            padding: "16px",
          }}
        >
          {children}
        </div>
      </Popover>
    </>
  );
};

export default SearchFieldFilter;
