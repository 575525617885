export default {
    TEACHER: 'TEACHER',
    STUDENT: 'STUDENT',
};

export const ROLES = {
    ADMIN: 1,
    SALES: 2,
    SCHOOL_ADMIN: 3,
    TEACHER: 4,
};

export const ROLE_LABELS = {
    1: 'LS Admin',
    2: 'Author',
    3: 'School Admin',
    4: 'Teacher',
    5: 'Parent',
};

export const ROLE_NAME = {
    'LS Admin': 1,
    Author: 2,
    'School Admin': 3,
    Teacher: 4,
    Parent: 5,
};
