import React, { useState, useEffect } from "react";
import moment from "moment";
import isEmpty from "../../validation/isEmpty";
import { Paper, Typography, Tooltip } from "@material-ui/core";
import { CustomColors } from "assets/customisedMUI";
import { Skeleton } from "@material-ui/lab";
import { Bar } from "react-chartjs-2";
import { useWeatherReport } from "hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { theme } from "assets/customisedMUI";

const ZONE_COLORS = {
  Blue: ["hsl(195, 85%, 50%)", "hsl(195, 85%, 50%)", "hsl(195, 85%, 50%)"],
  Green: [
    "hsl(173, 42%, 51%)",
    "hsl(173, 42%, 51%)",
    "hsl(173, 42%, 51%)",
    "hsl(173, 42%, 51%)",
  ],
  Yellow: ["#ffd700 ", "#ffd700 ", "#ffd700"],
  Red: ["#d73816", "#d73816", "#d73816"],
  Purple: ["#6a0dad", "#6a0dad", "#6a0dad"],
};

const ZONE_LABELS = {
  Blue: "Not quite ready to learn",
  Green: "Ready to learn",
  Yellow: "In a state of processing",
  Red: "Emotional overload",
  Purple: "Heightened state of emotion",
};

const PlatformWRSummary = (props) => {
  const { WR_CATEGORIES, WR_EMOTIONS } = useWeatherReport();
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const access = props.access;

  const startDate = !isEmpty(props.history)
    ? props.history.startDate
    : moment().subtract(7, "days").format("YYYY-MM-DD");
  const endDate = !isEmpty(props.history)
    ? props.history.endDate
    : moment().format("YYYY-MM-DD");

  const getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const dateArray = getDaysArray(
    new Date(moment(startDate).format("YYYY-MM-DD")),
    new Date(moment(endDate).format("YYYY-MM-DD"))
  ).map((v) => v.toISOString().slice(0, 10));

  const filterWeatherData = (data) => {
    try {
      let processedData = {
        Red: Array.apply(null, Array(dateArray.length)).map((v) => {
          return { amount: 0 };
        }),
        Blue: Array.apply(null, Array(dateArray.length)).map((v) => {
          return { amount: 0 };
        }),
        Purple: Array.apply(null, Array(dateArray.length)).map((v) => {
          return { amount: 0 };
        }),
        Green: Array.apply(null, Array(dateArray.length)).map((v) => {
          return { amount: 0 };
        }),
        Yellow: Array.apply(null, Array(dateArray.length)).map((v) => {
          return { amount: 0 };
        }),
      };

      if (!isEmpty(data)) {
        data.forEach((record) => {
          const recordDate = moment(record.activityDate).format("YYYY-MM-DD");
          const weather_category = WR_CATEGORIES.find(
            (wrc) => +wrc.id === +record.data.categoryId
          );
          const zone =
            weather_category &&
            WR_EMOTIONS.find((wr) => +wr.id === +record.data.moodId).zor;
          if (
            processedData[zone] &&
            processedData[zone][dateArray.indexOf(recordDate)]
          ) {
            processedData[zone][dateArray.indexOf(recordDate)].amount += 1;
          }
        });
      }
      setLoading(false);
      return processedData;
    } catch (err) {
      console.log("Error processing history:", err);
    }
  };

  useEffect(() => {
    setFilteredData(filterWeatherData(props.history.records));
  }, [props.history]);

  return (
    <div className="w-100">
      <div className="text-center">
        {props.loading ? (
          <Skeleton variant="rect" width={"100%"} height={500} />
        ) : (
          <div className="">
            {access ? (
              <>
                {loading ? (
                  <Skeleton variant="rect" width={"100%"} height={500} />
                ) : (
                  <div className="table-responsive">
                    {!isEmpty(filteredData) ? (
                      <Paper>
                        <Typography variant="h5">
                          {props.school && props.school.schoolName !== ""
                            ? `School: ${props.school.schoolName}`
                            : "Whole Platform Weather Report Graph"}
                        </Typography>
                        {props.schoolView && (
                          <>
                            <Typography className="mt-2" variant="h6">
                              Weather Report Graph
                            </Typography>
                          </>
                        )}
                        <Typography
                          variant="subtitle2"
                          style={{ fontStyle: "italic" }}
                        >
                          Current Filter(s):{" "}
                          {props.grades
                            .map((grade) => {
                              if (grade === -1) {
                                return "All Grades";
                              } else {
                                return grade;
                              }
                            })
                            .join(", ")}
                        </Typography>
                        <Typography variant="subtitle1">
                          Number of Weather Reports completed:{" "}
                          {props.history.records &&
                            props.history.records.length}{" "}
                          <Tooltip
                            placement="right"
                            arrow
                            enterTouchDelay={0}
                            title={
                              <div style={{ width: "100%", fontSize: 14 }}>
                                How is this number calculated?
                                <br />A total of all Weather Report activity
                                completed. This number pulls data from the
                                weather report Roll call, a prepare for learning
                                weather report response that has been assigned
                                to a student, In-lesson weather report and the
                                stand alone weather report on the student
                                dashboard.
                              </div>
                            }
                          >
                            <span>
                              <i
                                className="fas fa-info-circle ml-2 cards-tooltip mr-2"
                                style={{ fontSize: 14, lineHeight: "1" }}
                              />
                            </span>
                          </Tooltip>
                        </Typography>
                        <div style={{ maxHeight: 800 }}>
                          <Bar
                            height={smallScreen ? 300 : 500}
                            width={smallScreen ? 600 : 1000}
                            data={{
                              labels: dateArray.map((d) =>
                                moment(d).format("DD/MM/YYYY")
                              ),
                              datasets: Object.keys(filteredData).map(
                                (zone) => {
                                  return {
                                    label: ZONE_LABELS[zone],
                                    backgroundColor: ZONE_COLORS[zone][0],
                                    borderColor: ZONE_COLORS[zone][1],
                                    borderWidth: 1,
                                    hoverBackgroundColor: ZONE_COLORS[zone][2],
                                    hoverBorderColor: ZONE_COLORS[zone][0],
                                    data: filteredData[zone].map(
                                      (obj) => obj.amount
                                    ),
                                    labelAddOn: filteredData[zone].map(
                                      (obj) => obj.amount
                                    ),
                                  };
                                }
                              ),
                            }}
                            options={{
                              type: "bar",
                              legend: {
                                display: true,
                                position: smallScreen ? "top" : "right",
                                labels: {
                                  boxWidth: smallScreen ? 10 : 20,
                                },
                              },
                              maintainAspectRatio: false,
                              scales: {
                                yAxes: [
                                  {
                                    scaleLabel: {
                                      display: true,
                                      labelString: "Number of responses",
                                    },
                                    ticks: { stepSize: 1 },
                                  },
                                ],
                                xAxes: [
                                  {
                                    scaleLabel: {
                                      display: true,
                                      labelString: "Date",
                                    },
                                  },
                                ],
                              },
                              tooltips: {
                                callbacks: {
                                  label: function (tooltipItem, data) {
                                    var label =
                                      data.datasets[tooltipItem.datasetIndex]
                                        .label +
                                      "\n: " +
                                      data.datasets[tooltipItem.datasetIndex]
                                        .labelAddOn[tooltipItem.index];

                                    return label;
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </Paper>
                    ) : (
                      <Typography style={{ fontFamily: "Museo700" }}>
                        You do not have access to this feature.
                      </Typography>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div
                className="disabled-wrh text-center"
                style={{
                  backgroundColor: CustomColors("grey", "light"),
                  minHeight: 200,
                  position: "relative",
                }}
              >
                <p
                  className="text-center"
                  style={{
                    width: "80%",
                    margin: "0 auto",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: CustomColors("black"),
                  }}
                >
                  You do not have access to this feature.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlatformWRSummary;
