import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

const NotificationPopOver = props => {
  const contentStyle = {
    color: '#000',
    width: props.alertWidth ? props.alertWidth : 400,
    textAlign: 'center',
  };

  if (props.color) {
    contentStyle['backgroundColor'] = props.color;
    contentStyle['borderColor'] = props.color;
  }

  const alertStyle = props.alertStyle ? props.alertStyle : 'info';

  function openAlert() {
    const buttons = props.buttons ? props.buttons : [];
    confirmAlert({
      title: props.title,
      message: props.message,
      buttons,
      childrenElement: () => props.childrenElement,
      customUI: ({ title, message, onClose }) => (
        <div
          className={`lsgo-alert alert alert-${alertStyle}`}
          style={contentStyle}
        >
          <h1>{title}</h1>
          <p>{message}</p>
          <div className="children">{props.childrenElement}</div>
          <div className="action-buttons">
            {buttons.map((button, i) => (
              <button
                className="btn btn-info"
                key={i}
                onClick={() => onClose(button)}
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      ),
    });
  }

  return (
    <button
      className="btn btn-primary"
      style={{ display: 'none' }}
      onClick={openAlert()}
    >
      Notification PopOver
    </button>
  );
};

export default NotificationPopOver;
