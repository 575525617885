import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import isEmpty from 'validation/isEmpty';

import Button from '@material-ui/core/Button';

import { htmlDecode, htmlEncode } from 'js-htmlencode';
export default function Wysiwyg({ htmlContent, callback, previewTitle }) {
    const [editorState, setEditorState] = useState(() =>
        isEmpty(htmlContent)
            ? RichTextEditor.createEmptyValue()
            : RichTextEditor.createValueFromString(htmlDecode(htmlContent), 'html')
    );
    const [editing, setEditing] = useState(false);

    const getHtml = () => {
        return editorState.toString('html');
    };

    const saveAndReturn = () => {
        //return htmlon callback
        const htmlData = htmlEncode(getHtml());
        callback(htmlData); //return html content to parent component

        //back to previewing
        setEditing(false);
    };

    return (
        <div className='wysiwyg-editor'>
            {editing ? (
                <div className='editing-view'>
                    <RichTextEditor value={editorState} onChange={setEditorState} autoFocus={true} />
                    <Button varaint='contained' color='primary' onClick={saveAndReturn}>
                        Confirm Changes
                    </Button>
                </div>
            ) : (
                <div className='content-preview'>
                    <h6>
                        {previewTitle || 'Preview'}
                        <i className='ml-2 fa fa-edit cursor-pointer' onClick={() => setEditing(true)} />
                    </h6>
                    <div
                        className='p-2'
                        style={{ backgroundColor: 'rgba(204, 204, 204, 0.3)', borderRadius: 8 }}
                        dangerouslySetInnerHTML={{ __html: getHtml() }}
                    />
                </div>
            )}
        </div>
    );
}
