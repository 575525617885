import axios from "config/axios";
import setAuthToken from "utils/setAuthToken";
import store from "../store";
import { GET_ERRORS } from "../store/actions/actionTypes";

class Auth {
  static async checkCurrentAuthToken() {
    await Auth.setNewAuthToken();
  }

  static async setNewAuthToken() {
    return await Auth.userV2({ redirect: false, history: null });
  }

  static async userV2({ redirect, history }) {
    try {
      return await axios.get("/api/v4/auth/user").then((resp) => {
        if (resp && resp.data) {
          if (resp.data.error) {
            throw new Error(resp.data.error);
          }
          const { success, token } = resp.data;
          if (success) {
            setAuthToken(token);
            if (redirect && history) history.push("/");
            if (redirect && !history) window.location.href = "/";
            return token;
          }
        } else if (resp && resp.response && +resp.response.status === 401) {
          localStorage.removeItem("authToken");
        }
      });
    } catch (err) {
      store.dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      return { error: err.message };
    }
  }

  static async login(authData, loginMethod) {
    if (loginMethod === "googleLogin") {
      return await axios.post(
        "/api/v4/auth/google-login",
        "idToken=" + authData
      );
    } else {
      return await axios.post("/api/v4/auth/login", {
        ...authData,
        username: authData.email,
      });
    }
  }

  static async loginAs(teacherId) {
    return await axios.post("/api/teachers/loginAs", { teacherId });
  }

  static async logoutAs() {
    await axios.post("/api/teachers/logoutAs");
  }

  static async userV2({ redirect, history }) {
    return await axios.get("/api/v4/auth/user").then((resp) => {
      if (resp && resp.data) {
        const { success, token } = resp.data;

        if (success) {
          setAuthToken(token);
          if (redirect && history) history.push("/teacher");
          if (redirect && !history) window.location.href = "/teacher";
          return token;
        }
      }
    });
  }

  static async register(userData) {
    return await axios.post("/api/teachers/finishRegister", userData);
  }

  static async validateToken(token) {
    return await axios
      .get(`/api/teachers/verify/${token}`)
      .then((res) => {
        return +res.status === 200 ? true : false;
      })
      .catch(() => {
        //for invalid token handling on frontend
        return false;
      });
  }

  static async verifyUserToken(token) {
    return await axios
      .get(`/api/teachers/verifyFreeTrialUser/${token}`)
      .then((res) => {
        console.log(res.status);
        return +res.status === 200
          ? res.data
          : res.response
          ? res.response
          : false;
      })
      .catch((err) => {
        //for invalid token handling on frontend
        console.log("Error response:", err);
        return false;
      });
  }

  static async forgotPassword(data) {
    return await axios.post("/api/users/forgotPassword", data);
  }

  static async resetPassword(data) {
    return await axios.post("/api/users/resetPassword", data);
  }

  static async trialRegister(registerData) {
    // console.log(registerData, 'register');
    return await axios.post("/api/teachers/register", registerData);
  }

  static async getUserProfile(id, opts = {}) {
    await Auth.checkCurrentAuthToken();
    return await axios.get(`/api/v3/users/${id}?include=${opts.include || ""}`);
  }
}

export default Auth;
