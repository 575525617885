import axios from 'config/axios';

/**
 *
 * @param {object} analyticsData
 * @param {boolean} authorizedLogs
 */
export default (analyticsData, authorized = false) => {
    const analyticsURL = authorized ? '/api_v2/analytics/authorized' : '/api_v2/analytics/unauthorized';

    axios.post(analyticsURL, analyticsData);
};

const clearData = (data) => {};
