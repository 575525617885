import React, { useState, useEffect } from "react";
import axios from "config/axios";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import { sendSchoolEmail } from "store/actions/adminActions";
import { connect } from "react-redux";

import { confirmAlert } from "react-confirm-alert";

const PlatformEmailForm = (props) => {
  const {
    touched,
    handleSubmit,
    isSubmitting,
    errors,
    values,
    setFieldValue,
    setValues,
  } = props;

  return (
    <div className="form-group row">
      <div className="col-sm-12 col-12">
        <Form>
          <div className="form-group row">
            <div className="col">
              <Field
                className={classnames("form-control", {
                  "is-invalid":
                    touched.platformEmailTemplateId &&
                    errors.platformEmailTemplateId,
                })}
                name="platformEmailTemplateId"
                type="text"
              />
              {touched.platformEmailTemplateId &&
                errors.platformEmailTemplateId && (
                  <div className="invalid-feedback">
                    {errors.platformEmailTemplateId}
                  </div>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                disabled={isSubmitting}
                onClick={async (e) => {
                  await setFieldValue("isSchool", true);
                  handleSubmit(e);
                }}
                className="btn btn-primary waves-effect pull-right"
                type="submit"
              >
                Send Email To School
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

// Pull used redux state into props
const mapStateToProps = (state) => {
  return {};
};

// Setup dispatch for redux actions and set as functions on props
const mapDispatchToProps = (dispatch) => {
  return {
    sendSchoolEmail: (values) => dispatch(sendSchoolEmail(values)),
  };
};

// Export component connected to redux, including the Formik and Yup validation. Connect is called outside of Fromik to allow redux state to be used in Formik.
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    mapPropsToValues({ platformEmailTemplateId, schoolId }) {
      return {
        platformEmailTemplateId: platformEmailTemplateId || "",
        schoolId: schoolId || 0,
      };
    },
    validationSchema: Yup.object().shape({
      platformEmailTemplateId: Yup.string().required("Select notificationId"),

      schoolId: Yup.number(),
    }),
    handleSubmit(
      values,
      { resetForm, setErrors, setSubmitting, setFieldValue, props }
    ) {
      setSubmitting(true);
      if (values.isSchool && !values.allSchools) {
        props.sendSchoolEmail({
          ...values,
          school: props.school,
          group: props.group,
        });
      }
    },
    enableReinitialize: true,
  })(PlatformEmailForm)
);
