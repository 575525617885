//https://github.com/vimeo/player.js#ended
import React, { Component } from 'react';

import isEmpty from '../../validation/isEmpty';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { CATEGORIES, EVENTS, STUDENTS } from '../../constants/googleAnalytics';
import Player from '@vimeo/player';

class VideoActivity extends Component {
    constructor(props) {
        super(props);

        this.videoObject = React.createRef();
        this.player = null;
        this.state = {
            loaded: false
        };

        const { demoMode } = this.props;

        if (isEmpty(demoMode)) {
            try {
                ReactGA.pageview(STUDENTS.STUDENT_VIDEO);
            } catch (e) {
                console.error(e);
            }
        }
    }

    componentDidMount() {
        if (this.videoObject.current) {
            const player = new Player(this.videoObject.current);
            this.player = player;

            player.on('play', this.onPlay);

            player.on('ended', this.onEnded);

            player.on('pause', this.onPause);
        }
    }

    onPlay = () => {
        this.setState({
            loaded: true
        });

        try {
            ReactGA.event({
                category: CATEGORIES.STUDENT_ACTIONS,
                action: EVENTS.VIDEO_PLAY
            });
        } catch (e) {
            console.error(e);
        }
    };

    onPause = () => {
        try {
            ReactGA.event({
                category: CATEGORIES.STUDENT_ACTIONS,
                action: EVENTS.VIDEO_PAUSED
            });
        } catch (e) {
            console.error(e);
        }
    };

    onEnded = () => {
        try {
            ReactGA.event({
                category: CATEGORIES.STUDENT_ACTIONS,
                action: EVENTS.VIDEO_ENDED
            });
        } catch (e) {
            console.error(e);
        }

        this.props.activityDone();
    };

    componentDidUpdate(prevProps) {
        const { pauseActivity } = this.props.ui;

        if (pauseActivity !== prevProps.ui.pauseActivity) {
            if (pauseActivity) {
                this.player.pause();
            } else {
                this.player.play();
            }
        }
    }

    render() {
        const { videoUrl } = this.props;

        return (
            <div className='video-wrapper'>
                {videoUrl && (
                    <>
                        {/* <ReactPlayer
                            url={`https://player.vimeo.com/video/${videoUrl}`}
                            playing
                            width={'100%'}
                            height="58vh"
                            onEnded={this.onEnded}
                            onPlay={this.onPlay}
                            onPause={this.onPause}
                        /> */}
                        <iframe
                            title={this.props.title}
                            ref={this.videoObject}
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            src={`https://player.vimeo.com/video/${videoUrl}`}
                            frameBorder='0'
                            allow='autoplay; fullscreen'
                            allowFullScreen
                        ></iframe>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ui: state.ui
});

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoActivity);
