import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import asyncComponent from 'asyncComponent/asyncComponent';
import { withRouter, Route } from 'react-router-dom';

import {
  createActivity,
  getSimilarActivities,
} from '../../store/actions/adminActions';

import Loadable from 'react-loadable';

const Quiz = Loadable({
  loader: () => import('../../containers/Admin/Quiz/Quiz'),
  loading: () => {
    return <div>....Loading</div>;
  },
});

const Games = Loadable({
  loader: () => import('../../containers/Admin/Games/Games'),
  loading: () => {
    return <div>....Loading</div>;
  },
});

const Video = Loadable({
  loader: () => import('../../containers/Admin/Video/Video'),
  loading: () => {
    return <div>....Loading</div>;
  },
});

class Activity extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      currentActivityId: null,
      currentActivity: null,
      activityData: null,
    };
    this.addActivity = this.addActivity.bind(this);
  }

  /**
   * Add Activity
   * @param {object} data
   * @return {void}
   */
  addActivity(data) {
    const { lessonId } = this.props.match.params;

    if (lessonId) {
      data.lessonId = lessonId;
    }

    this.props.addActivity(data, this.props.history);
  }

  /**
   *
   * @return {jsx}
   */
  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Activities</h4>
            </div>
          </div>
        </div>
        <Route path="/admin/activity/quiz" component={Quiz} />
        <Route path="/admin/activity/games" component={Games} />
        <Route path="/admin/activity/videos" component={Video} />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  ui: state.ui,
  activities: state.activities,
});

const mapDispatchToProps = dispatch => {
  return {
    addActivity: (activityData, history) =>
      dispatch(createActivity(activityData, history)),
    getSimilarActivities: activityData =>
      dispatch(getSimilarActivities(activityData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Activity));
