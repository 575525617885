import axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api_v2/options/WEATHER_REPORT';

const useWeatherReport = () => {
    const WEATHER_REPORT = useSelector((state) => state.constants.weather_report);
    const dispatch = useDispatch();
    const [weatherReport, setWeatherReport] = useState({ WR_CATEGORIES: [], WR_EMOTIONS: [] });

    useEffect(() => {
        const fetchData = async () => {
            if (WEATHER_REPORT) {
                setWeatherReport(WEATHER_REPORT);
            } else {
                const response = await axios.get(url);
                const data = JSON.parse(response.data.option);
                dispatch(updateConstant('weather_report', data));
                setWeatherReport(data);
            }
        };

        fetchData();
    }, [WEATHER_REPORT, dispatch]);

    return weatherReport;
};

export default useWeatherReport;
