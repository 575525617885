import React from 'react';
import { withFormik, Form, Field, FieldArray } from 'formik';
import axios from 'config/axios';
import * as Yup from 'yup';

import FileUpload from 'components/Common/FileUpload';

const NewReleaseForm = (props) => {
    const { values, touched, isSubmitting, errors, setFieldValue } = props;

    return (
        <div className='card-box'>
            <h4 className='header-title' id='add-edit-release'>
                Add new relase details.
            </h4>
            <Form>
                <div className='form-group row'>
                    <label className='col-3 col-form-label'>Version *</label>
                    <div className='col'>
                        <Field
                            className={`form-control ${'is-invalid' ? touched.version && errors.version : ''}`}
                            placeholder='e.g. 2.3.0'
                            name='version'
                            type='text'
                            required
                            autofocus
                            disabled={props.isEditing}
                        />
                        {touched.version && errors.version && <div className='invalid-feedback'>{errors.version}</div>}
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='col-3 col-form-label'>Active</label>
                    <div className='col'>
                        <Field
                            name='active'
                            component='select'
                            className={`form-control ${'is-invalid' ? touched.active && errors.active : ''}`}
                        >
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                        </Field>
                        {touched.active && errors.active && <div className='invalid-feedback'>{errors.active}</div>}
                    </div>
                </div>
                <FieldArray
                    name='files'
                    render={(arrayHelpers) => (
                        <div
                            className='mb-2 p-2'
                            style={{ backgroundColor: '#eee', borderRadius: 12, border: '1px solid #ccc' }}
                        >
                            <h5 className='text-bold mb-4'>Manage release files / images. </h5>
                            {values.files && values.files.length > 0 ? (
                                values.files.map((file, index) => (
                                    <div className='form-group row' key={'file-' + index}>
                                        <div className='col-12 mb-2'>
                                            <div className='text-bold'>File #{index}</div>
                                        </div>
                                        <div className='col-4 mb-2'>
                                            <FileUpload
                                                onChange={(file, location) => {
                                                    setFieldValue(`files[${index}].image`, location);
                                                }}
                                                fieldName={`files[${index}].image`}
                                                existingFile={file.image}
                                            />
                                            <br />
                                        </div>
                                        <div className='col-8 mb-2'>
                                            <div className='mb-1'>
                                                <Field
                                                    className='form-control'
                                                    name={`files[${index}].title`}
                                                    required
                                                    placeholder={`Title #${index}`}
                                                />
                                            </div>
                                            <div className='mb-1'>
                                                <Field
                                                    className='form-control'
                                                    component='textarea'
                                                    rows='5'
                                                    name={`files[${index}].desc`}
                                                    required
                                                    placeholder={`Description #${index}`}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-12 pb-2'>
                                            <button
                                                className='btn btn-light mr-2'
                                                type='button'
                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            >
                                                Remove
                                            </button>
                                            <button
                                                className='btn btn-light'
                                                type='button'
                                                onClick={() => arrayHelpers.push({ image: '', desc: '', title: '' })} // insert an empty string at a position
                                            >
                                                Add File
                                            </button>
                                            <hr />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='text-right'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => arrayHelpers.push({ image: '', desc: '', title: '' })}
                                    >
                                        {/* show this when user has removed all friends from the list */}
                                        Add new file
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                />

                <div className='row'>
                    <div className='col'>
                        <button disabled={isSubmitting} type='submit' className='btn btn-primary waves-effect mr-2'>
                            Save
                        </button>
                        <button
                            className='btn btn-outline-secondary'
                            type='button'
                            onClick={() => {
                                props.handleReset();
                            }}
                        >
                            Clear Form
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default withFormik({
    mapPropsToValues({ version, data, active }) {
        return {
            version: version || '',
            files: data || [{ image: '', desc: '', title: '' }],
            active: active || false,
        };
    },
    validationSchema: Yup.object().shape({
        version: Yup.string().required('Version number can not be empty'),
        files: Yup.array().min(1, 'Need at least 1 slide'),
    }),
    async handleSubmit(values, { props, setSubmitting }) {
        setSubmitting(true);
        console.log('Submitting Data:', values);
        // do save function
        await props.submitNew(values);

        setSubmitting(false);
    },
    enableReinitialize: true,
})(NewReleaseForm);
