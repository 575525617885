import React, { useEffect, useState } from 'react';
import axios from 'config/axios';
import isEmpty from 'validation/isEmpty';

const isDev = process.env.NODE_ENV === 'development';

export default function S3FileView({ title, folder, type, ...props }) {
    const [bucketList, setBucketList] = useState([]);
    const [searchDirectory, setSearchDirectory] = useState(null);
    const [displaying, setDisplaying] = useState('');
    const [loading, setLoading] = useState(true);

    //componentDidMount
    useEffect(() => {
        function loadInitialList() {
            loadFromS3();
        }

        loadInitialList();

        //eslint-disable-next-line
    }, []);

    //search in directory
    useEffect(() => {
        async function searchInDirectory() {
            if (!loading && !isEmpty(searchDirectory)) {
                loadFromS3();
            }
        }

        searchInDirectory();
        //eslint-disable-next-line
    }, [searchDirectory]);

    async function downloadFile(file) {
        const fileName = encodeURIComponent(file);
        await axios
            .get('/api_v2/admin/usage/download/' + fileName)
            .then((resp) => {
                const fileLink = resp.data.signedUrl;
                //received signed URL, open in new tab.
                var newWin = window.open(fileLink, '_blank');
                newWin.focus();
            })
            .catch((err) => {
                console.log(err.message || err.response.data.message);
            });
    }

    async function loadFromS3() {
        setLoading(true);
        await axios
            .get(
                `/api_v2/admin/usage/getFiles/${type}/${
                    !isEmpty(searchDirectory) ? encodeURIComponent(searchDirectory) : ''
                }`
            )
            .then((res) => {
                const contents = res.data;
                let bucketData = [];
                if (!isEmpty(contents)) {
                    if (!isEmpty(searchDirectory)) {
                        const changeIndex = bucketList.findIndex((fol) => fol.folderName === searchDirectory);
                        bucketData = [...bucketList];

                        bucketData[changeIndex]['contents'] = contents.map((cont) => cont.Key);
                    } else {
                        bucketData = contents;
                    }
                }
                setBucketList(bucketData);
                if (!isEmpty(searchDirectory) && type === 'pdf') {
                    setDisplaying(`usage/${isDev ? 'staging' : process.env.NODE_ENV}/${searchDirectory}`);
                    setSearchDirectory(null);
                }
            })
            .catch((err) => {
                console.log('Error: ', err.message || err.response.data.message || 'no error message found.');
            });
        setLoading(false);
    }

    function resetContents() {
        if (!isEmpty(displaying)) {
            setDisplaying(null);
        } else {
            setBucketList(null);
            setSearchDirectory(null);
            setDisplaying(null);

            //timeout to allow state changes before reloading
            setTimeout(() => {
                loadFromS3();
            }, 500);
        }
    }

    const pdfListMap = () => {
        if (type === 'pdf' && !isEmpty(displaying)) {
            let pdfList = bucketList.find((bl) => bl.Key === displaying);

            console.log('files in list: ', pdfList);

            if (pdfList && pdfList.contents.length > 0) {
                return pdfList.contents.map((fl) => {
                    const fn = fl.split('/').pop();
                    return (
                        <span
                            title={fn}
                            key={fl}
                            onClick={() => {
                                downloadFile(fl);
                            }}
                        >
                            <i className='fa fa-file-text-o mr-2' />
                            <span className='file-name text-truncate'>{fn}</span>
                        </span>
                    );
                });
            } else {
                return <p className='alert alert-warning p-2'>No files found!</p>;
            }
        }

        return null;
    };

    return (
        <div className='s3file-list'>
            <h5>
                {title}
                {!isEmpty(displaying) ? <span className='mx-2 text-focus'>"{displaying}"</span> : null}
                <i
                    className={`fa fa-refresh cursor-pointer ml-3 ${loading ? 'fa-spin' : ''}`}
                    title='Refresh Reports'
                    disabled={loading}
                    onClick={() => {
                        if (!loading) {
                            resetContents();
                        }
                    }}
                />
            </h5>
            <div className={`view-${type}`}>
                <ul className={`files-list ${isEmpty(displaying) ? 'viewing-folders' : 'viewing-files'}`}>
                    {!isEmpty(bucketList) &&
                        bucketList.map((file) => {
                            const filePart =
                                type === 'xlsx'
                                    ? file.Key.split('/').pop().replace('usage_report_', '')
                                    : file.folderName;

                            return !isEmpty(filePart) ? (
                                <li
                                    key={file.Key}
                                    className='cursor-pointer'
                                    onClick={() => {
                                        if (type === 'xlsx') {
                                            downloadFile(file.Key);
                                        } else {
                                            if (isEmpty(file.contents)) {
                                                setSearchDirectory(filePart);
                                            } else {
                                                setDisplaying(file.Key);
                                            }
                                        }
                                    }}
                                    title={filePart}
                                >
                                    <i className={`mr-2 fa ${type === 'pdf' ? 'fa-folder' : 'fa-table'}`} />
                                    <span className='file-name text-truncate'>{filePart}</span>
                                </li>
                            ) : null;
                        })}
                </ul>

                {type === 'pdf' ? <div className='pdf-files-view'>{pdfListMap()}</div> : ''}
            </div>
        </div>
    );
}
