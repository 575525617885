import React from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';

/**
 *@returns JSX
 */
const FocusForm = ({
  values,
  touched,
  handleChange,
  handleSubmit,
  focusName,
  isSubmitting,
  handleReset,
  isValid,
  errors,
  handleBlur,
  dirty,
  addFocus,
}) => {
  return (
    <Form>
      <div className="form-group row">
        <div className="col">
          <div className="input-group">
            <Field
              className={classnames('form-control', {
                'is-invalid': touched.focusName && errors.focusName,
              })}
              name="focusName"
              onBlur={handleBlur}
              type="text"
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-info waves-effect waves-light"
                onClick={handleSubmit}
                type="button"
                disabled={isSubmitting}
              >
                Add New Quality
              </button>
              <button
                className="btn btn-outline-info waves-effect waves-light"
                onClick={handleReset}
                type="button"
              >
                Clear
              </button>
            </div>
            {touched.focusName && errors.focusName && (
              <div className="invalid-feedback">{errors.focusName}</div>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues({ focusName }) {
    return {
      focusName: focusName || '',
    };
  },
  validationSchema: Yup.object().shape({
    focusName: Yup.string()
      .min('3', 'Quality name must be at least 3 characters')
      .required('Quality name cannot be empty'),
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    setSubmitting(true);
    props.addFocus(values);
    setTimeout(() => {
      setSubmitting(false);
      resetForm();
    }, 2000);
  },
})(FocusForm);
