import { INIT_LEARNING_AREAS } from './actionTypes';
import axios from 'config/axios';
import { showNotification } from 'store/actions/uiActions';
import Notification from '../../components/UI/Notification';

/**
 * Get all the learningAreas
 * 1. Get two level array from server
 * 2. Dispatch to redux
 * @return {void}
 */
export const initLearningAreas = () => async (dispatch) => {
    const result = await axios.get('/api/admin/areas');

    if (result) {
        dispatch({
            type: INIT_LEARNING_AREAS,
            payload: result.data,
        });
    }
};

export const createLessonGroup = (data) => async (dispatch) => {
    const result = await axios.post(`/api_v2/admin/lessongroups`, { displayName: data.name, focusId: data.focus });
    return result;
};

export const updateLessonGroupLessonRelation = (groupId, stageIds) => async (dispatch) => {
    const result = await axios
        .put(`/api_v2/admin/lessongroups/${groupId}`, { lessons: stageIds })
        .then((r) => {
            dispatch(showNotification(Notification.SUCCESS, 'Successfully updated lessons'));
        })
        .catch((e) => {
            dispatch(showNotification(Notification.DANGER, 'An error occurred'));
        });

    return result;
};

export const updateTeacherRole = (data) => async (dispatch) => {
    const result = await axios
        .put(`/api_v2/admin//teacher/${data.teacherId}/update`, { role: data.teacherRole })
        .then((r) => {
            dispatch(showNotification(Notification.SUCCESS, 'Successfully updated teacher role'));
        })
        .catch((e) => {
            dispatch(showNotification(Notification.DANGER, 'An error occurred'));
        });

    return result;
};
