import React, { Component, Fragment } from "react";
import { withFormik, Form, Field, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { Button } from "@material-ui/core";

import classnames from "classnames";
import isEmpty from "validation/isEmpty";
import moment from "moment";

//Make this component as class, get the event from up and run the resetForm
//https://github.com/jaredpalmer/formik/issues/33

class TeacherForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedFromOutside: false,
    };

    this.resetForm = this.resetForm.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  resetForm() {
    this.props.resetForm();
  }

  /**
   *
   */
  componentDidUpdate() {
    const { clickedFromOutside, errors } = this.props;

    if (clickedFromOutside && errors.studentName) {
      this.resetForm();
    }
  }

  handleReset() {
    this.props.handleReset();
  }

  /**
   *
   * @return {jsx}
   */
  render() {
    const {
      touched,
      handleSubmit,
      isSubmitting,
      errors,
      schoolGroups,
      setFieldValue,
      values,
    } = this.props;

    const teachersRoles = [
      {
        id: 4,
        value: "Teacher",
      },
      {
        id: 3,
        value: "Administrator",
      },
    ].map((role) => {
      return (
        <option key={role.id} value={role.id}>
          {role.value}
        </option>
      );
    });

    const teacherCamapign = [
      {
        id: 14,
        value: "Full Product License",
      },
      {
        id: 2,
        value: "Free Trial License",
      },
      {
        id: 6,
        value: "Emotion Check-in License",
      },
    ].map((role) => {
      return (
        <option key={role.id} value={role.id}>
          {role.value}
        </option>
      );
    });

    const groupOptions = schoolGroups.map((s) => {
      return (
        <option key={+s.groupId} value={+s.groupId}>
          {+s.groupId}
        </option>
      );
    });

    return (
      <Fragment>
        <div className="form-group row">
          <div className="col-12">
            <Form>
              <div className="form-group row">
                <label className="col-3 col-form-label">First Name *</label>
                <div className="col">
                  <Field
                    className={classnames("form-control", {
                      "is-invalid": touched.firstName && errors.firstName,
                    })}
                    name="firstName"
                    type="text"
                  />
                  {touched.firstName && errors.firstName && (
                    <div className="invalid-feedback">{errors.firstName}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Last Name *</label>
                <div className="col">
                  <Field
                    className={classnames("form-control", {
                      "is-invalid": touched.lastName && errors.lastName,
                    })}
                    name="lastName"
                    type="text"
                  />
                  {touched.lastName && errors.lastName && (
                    <div className="invalid-feedback">{errors.lastName}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Email *</label>
                <div className="col-9">
                  <Field
                    className={classnames("form-control", {
                      "is-invalid": touched.email && errors.email,
                    })}
                    name="email"
                    type="text"
                  />
                  {touched.email && errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Teacher role *</label>
                <div className="col-9">
                  <Field
                    component="select"
                    name="role"
                    className={classnames("form-control", {
                      "is-invalid": touched.role && errors.role,
                    })}
                  >
                    {teachersRoles}
                  </Field>
                </div>
              </div>

              {/* <div className="form-group row">
                <label className="col-3 col-form-label">Expiry Date *</label>
                <div className="col-9">
                  <DatePicker
                    dateFormat="YYYY-MM-DD"
                    value={values.expiryDate}
                    onChange={(e) => {
                      setFieldValue(
                        "expiryDate",
                        moment(e).format("YYYY-MM-DD")
                      );
                    }}
                  />
                </div>
              </div> */}

              {/* <div className="form-group row">
                <label className="col-3 col-form-label">Account Type</label>
                <div className="col">
                  <Field
                    component="select"
                    name="license"
                    className={classnames("form-control", {
                      "is-invalid": touched.license && errors.license,
                    })}
                  >
                    {teacherCamapign}
                  </Field>
                </div>
              </div> */}
              <div className="form-group row">
                <label className="col-3 col-form-label">School ID *</label>
                <div className="col">
                  <Field
                    className={classnames("form-control", {
                      "is-invalid": touched.schoolId && errors.schoolId,
                    })}
                    name="schoolId"
                    type="text"
                  />
                  {touched.schoolId && errors.schoolId && (
                    <div className="invalid-feedback">{errors.schoolId}</div>
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Group ID *</label>
                <div className="col-9">
                  <Field
                    component="select"
                    name="groupId"
                    className={classnames("form-control", {
                      "is-invalid": touched.groupId && errors.groupId,
                    })}
                  >
                    {[
                      <option key={null} value={null}>
                        Select
                      </option>,
                      ...groupOptions,
                    ]}
                  </Field>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <small>
                    <b>
                      Ensure the email address is correct. The user's invitation
                      and instructions on how to get started with Life Skills GO
                      will be sent to this address.
                    </b>
                  </small>
                </div>
                <div className="col-12">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    className="btn btn-primary waves-effect pull-right"
                  >
                    Send Invitation
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="button"
                    className="btn btn-light waves-effect mr-2 pull-right"
                    onClick={() => {
                      this.handleReset();
                      this.props.closeForm();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withFormik({
  mapPropsToValues({
    firstName,
    lastName,
    email,
    role,
    schoolId,
    license,
    groupId,
    expiryDate,
  }) {
    return {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      role: role || 4,
      schoolId: schoolId || null,
      license: license || 6,
      groupId: groupId,
      expiryDate: expiryDate || moment().format("DD-MM-YYYY"),
    };
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required("First name cannot be empty"),
    lastName: Yup.string().required("Last name cannot be empty"),
    role: Yup.string().required("Teacher role name cannot be empty"),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email address cannot be empty."),
    groupId: Yup.number().required("Group needs to be selected"),
  }),
  handleSubmit(
    values,
    { resetForm, setErrors, setSubmitting, setFieldValue, props }
  ) {
    setSubmitting(true);

    props.closeForm();
    props
      .saveTeacher(values)
      .then(() => {
        setSubmitting(false);
        resetForm();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  },
  enableReinitialize: true,
})(TeacherForm);
