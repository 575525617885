import {
  INIT_LEARNING_AREAS,
  ADMIN_ADD_LEARNING_FOCUS,
  ADMIN_EDIT_LEARNING_FOCUS,
  ADMIN_DELETE_LEARNING_FOCUS,
  ADMIN_ADD_LESSON,
  ADMIN_EDIT_LESSON,
  ADMIN_DELETE_LESSON,
  ADMIN_FOCUS_LOADER,
} from '../actions/actionTypes';

const initialState = {
  areas: [],
  focusLoader: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INIT_LEARNING_AREAS: {
      let areas = action.payload;

      return {
        ...state,
        areas,
      };
    }
    case ADMIN_FOCUS_LOADER: {
      return {
        ...state,
        focusLoader: !state.focusLoader,
      };
    }

    case ADMIN_ADD_LEARNING_FOCUS: {
      const { focus, areaId } = action.payload;

      const areas = [...state.areas];

      areas.map(area => {
        if (area.areaId === areaId) {
          let list = [...area.focus];
          //create lessons member
          focus.lessons = [];
          list.push(focus);
          area.focus = list;
        }
        return area;
      });

      return {
        ...state,
        areas: areas,
      };
    }
    case ADMIN_DELETE_LEARNING_FOCUS: {
      const { focusId } = action.payload;

      const areas = [...state.areas];

      areas.map(area => {
        if (area.areaId === action.payload.areaId) {
          let list = [...area.focus];

          const removeIndex = list.map(item => item.focusId).indexOf(focusId);

          if (removeIndex !== -1) {
            list.splice(removeIndex, 1);
          }

          area.focus = list;
        }
        return area;
      });

      return {
        ...state,
        areas: areas,
      };
    }

    case ADMIN_EDIT_LEARNING_FOCUS: {
      const { focusId, focusName } = action.payload;
      const areas = [...state.areas];
      areas.map(area => {
        if (area.areaId === action.payload.areaId) {
          let list = [...area.focus];

          list.map(focus => {
            if (focus.focusId === focusId) {
              focus.focusName = focusName;
            }
            return list;
          });

          return (area.focus = list);
        }
        return area;
      });

      return {
        ...state,
        areas: areas,
      };
    }
    case ADMIN_ADD_LESSON: {
      const { lesson, areaId, focusId } = action.payload;

      const areas = [...state.areas];

      //go through areas
      areas.map(area => {
        if (area.areaId === areaId) {
          let focusList = [...area.focus];
          //go through focus
          focusList.map(focus => {
            if (focus.focusId === focusId) {
              let lessons = [...focus.lessons];
              //add lesson
              lessons.push(lesson);
              focus.lessons = lessons;
            }
            return focus;
          });
        }
        return area;
      });

      return {
        ...state,
        areas: areas,
      };
    }

    case ADMIN_EDIT_LESSON: {
      var {
        focusId,
        lessonId,
        areaId,
        lessonName,
        gradeId,
        outcome,
      } = action.payload;
      const areas = [...state.areas];

      areas.map(area => {
        if (area.areaId === areaId) {
          let focusList = [...area.focus];
          //go through focus
          focusList.map(focus => {
            if (focus.focusId === focusId) {
              let lessons = [...focus.lessons];
              //find the lesson
              lessons.map(lesson => {
                if (lesson.lessonId === lessonId) {
                  lesson.lessonName = lessonName;
                  lesson.gradeId = gradeId;
                  lesson.outcome = outcome;
                }
                return lesson;
              });

              focus.lessons = lessons;
            }
            return focus;
          });
          area.focus = focusList;
        }
        return area;
      });

      return {
        ...state,
        areas: areas,
      };
    }
    case ADMIN_DELETE_LESSON: {
      let { lessonId, areaId, focusId } = action.payload;

      const areas = [...state.areas];

      areas.map(area => {
        if (area.areaId === areaId) {
          let focusList = [...area.focus];
          //go through focus
          focusList.map(focus => {
            if (focus.focusId === focusId) {
              let lessons = [...focus.lessons].filter(l => {
                return l.lessonId !== lessonId;
              });
              focus.lessons = lessons;
            }
            return focus;
          });
          area.focus = focusList;
        }
        return area;
      });

      return {
        ...state,
        areas: areas,
      };
    }
    default:
      return state;
  }
}
