import InformationRow from "components/Common/InformationRow";
import React, { useRef, useState } from "react";
import isEmpty from "validation/isEmpty";
import "./Accordion.scss";

const Accordion = (props) => {
  const { informationRowData } = props;
  const [setActive, setActiveState] = useState("active");
  const [setHeight, setHeightState] = useState("0px");
  const [showChildren, setShowChildren] = useState(false);

  const child = useRef(null);

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${child.current.scrollHeight}px`
    );
  };

  return (
    <>
      <InformationRow
        id={informationRowData.id}
        information={informationRowData.information}
        editing={informationRowData.editing}
        editViewOptions={informationRowData.editViewOptions}
        selected={informationRowData.selected}
        onRedirect={informationRowData.onRedirect}
        tableRowStyle={informationRowData.tableRowStyle}
        onEditSave={informationRowData.onEditSave}
        checkCallBack={informationRowData.checkCallBack}
        editCallback={informationRowData.editCallback}
        isHover={informationRowData.isHover}
        onMouseEnter={informationRowData.onMouseEnter}
        onMouseLeave={informationRowData.onMouseLeave}
        actionButtons={informationRowData.actionButtons}
        noselect={informationRowData.noselect}
        parent={informationRowData.parent}
        showChildren={showChildren}
        setShowChildren={() => {
          setShowChildren(!showChildren);
        }}
      />
      {showChildren &&
        !isEmpty(informationRowData.children) &&
        informationRowData.children.map((child) => {
          return (
            <InformationRow
              id={child.id}
              information={child.information}
              editing={child.editing}
              editViewOptions={child.editViewOptions}
              selected={child.selected}
              onRedirect={child.onRedirect}
              tableRowStyle={child.tableRowStyle}
              onEditSave={child.onEditSave}
              checkCallBack={child.checkCallBack}
              editCallback={child.editCallback}
              isHover={child.isHover}
              onMouseEnter={child.onMouseEnter}
              onMouseLeave={child.onMouseLeave}
              actionButtons={child.actionButtons}
              noselect={child.noselect}
              child={true}
            />
          );
        })}
    </>
  );
};

export default Accordion;
