const AccessList = [
  { label: "weather_report_trial", value: 2 },
  { label: "weather_report_other", value: 4 },
  { label: "non_weather_report", value: 8 },
];

const PackageList = [
  { id: 1, label: "free_trial", value: 2 },
  { id: 2, label: "weather_report_product", value: 6 },
  { id: 3, label: "full_product", value: 14 },
];

export { AccessList, PackageList };
