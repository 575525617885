import React from 'react';

import physical_awareness from '../../assets/images/cogs/physical_awareness.png';
import positive_relationships from '../../assets/images/cogs/positive_relationships.png';
import self_awareness from '../../assets/images/cogs/self_awareness.png';
import self_management from '../../assets/images/cogs/self_management.png';
import social_awareness from '../../assets/images/cogs/social_awareness.png';
import wise_decisions from '../../assets/images/cogs/wise_decisions.png';

/**
 *@returns JSX
 */
const areaMenu = props => {
  /**
   * Get areas images by area name
   * @type {{physical_awareness, positive_relationships, self_awareness, self_management, social_awareness, wise_decisions}}
   */
  const learningAreaImages = {
    physical_awareness: physical_awareness,
    positive_relationships: positive_relationships,
    self_awareness: self_awareness,
    self_management: self_management,
    social_awareness: social_awareness,
    wise_decisions: wise_decisions
  };

  const { areas } = props;

  const menu = areas.map(item => {
    let boxClass = ['card type-menu-box col-lg-2 col-md-2'];
    if (item.areaId === props.activeArea.areaId) {
      boxClass.push('learning-area-active shadow ');
    }
    return (
      <div className={boxClass.join(' ')} key={item.areaId} onClick={props.onLearningAreaClick.bind(this, item)}>
        <img
          className="mx-auto d-block"
          src={learningAreaImages[item.areaName.replace(' ', '_')]}
          width="100"
          alt={item.areaName}
        />
        <div className="card-body text-center">
          <h5 className="card-title">{item.areaName.toUpperCase()}</h5>
        </div>
      </div>
    );
  });

  return menu;
};

export default areaMenu;
