import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorsReducer from './errorReducer';
import uiReducer from './uiReducer';
import classReducer from './classReducer';
import studentReducer from './studentReducer';
import learningReducer from './learningReducer';
import alertsReducer from './alertsReducer';
import activitiesReducer from './activitiesReducer';
import skillsReducer from './skillsReducer';
import lessonReducer from './lessonReducer';
import constantsReducer from './constantsReducer';

export default combineReducers({
    auth: authReducer,
    errors: errorsReducer,
    ui: uiReducer,
    classes: classReducer,
    students: studentReducer,
    learningContent: learningReducer,
    activities: activitiesReducer,
    alerts: alertsReducer,
    skills: skillsReducer,
    lessons: lessonReducer,
    constants: constantsReducer,
});
