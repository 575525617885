import React from 'react';

import activityTypes from '../../constants/activityTypes';

import { SortableHandle } from 'react-sortable-hoc';

/**
 *@returns JSX
 */
const activityListItem = ({
  activityId,
  activityType,
  content,
  createdAt,
  deletedAt,
  dragHandle,
  gradeId,
  onPreview,
  removeActivity,
  selected,
}) => {
  const DragHandle = SortableHandle(() => (
    <div className="activity-drag-handle">
      <i className="fa fa-arrows" />
    </div>
  ));

  //debugger;

  const staticData = activityTypes
    .filter(item => item._id === activityType)
    .shift();

  const className = ['list-group-item activity-list-item'];

  if (selected) {
    className.push('active');
  }

  const actType =
    staticData.parent === null ? staticData.component : staticData.parent;

  return (
    <li className={className.join(' ')}>
      <div className="pull-left">
        <i className={staticData.icon} />
        <span>{actType}</span>
      </div>
      <div className="pull-left activity-description">
        {content.title}
        <span className="activity-type-name"> ( {staticData.title} )</span>
        {onPreview && (
          <button
            onClick={onPreview}
            className="btn pull-left btn-light mt-2 waves-effect"
          >
            preview
          </button>
        )}

        {removeActivity && (
          <button
            onClick={removeActivity}
            className="btn btn-light pull-left ml-1 mt-2"
          >
            <i className="fa fa-trash font-16" />
          </button>
        )}
      </div>
      {dragHandle && <DragHandle />}
    </li>
  );
};

export default activityListItem;
