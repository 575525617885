import React, { Component, Fragment } from 'react';
import './Modal.css';
import Backdrop from '../Backdrop/Backdrop';
import { connect } from 'react-redux';
import { toggleModal } from '../../../store/actions/uiActions';

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      name: props.name || null,
    };

    this.closeCallback = this.closeCallback.bind(this);
  }

  /**
   *
   */
  closeCallback() {
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.ui.show === state.show) {
      return null;
    }

    let _state = {
      show: props.ui.showModal,
    };

    return _state;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.show === true && this.state.show === false) {
      this.closeCallback();
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { extraClass } = this.props;

    const classNameArr = ['lsgoModal'];

    if (extraClass) {
      classNameArr.push(extraClass);
    }

    return (
      <Fragment>
        <Backdrop
          show={
            this.state.show &&
            (this.state.name
              ? this.props.ui.dashboardModal === this.state.name
              : true)
          }
          clicked={() => {
            return this.props.forceOpen
              ? console.log('FORCE OPEN TRUE')
              : this.props.toggleModal(false, '');
          }}
        />
        <div
          className={classNameArr.join(' ')}
          style={{
            transform: this.state.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: this.state.show ? 1 : 0,
          }}
        >
          <div className="modalHeader">
            {/*Header*/}
            {!this.props.forceOpen && (
              <span
                className="modal-close pull-right"
                onClick={() => {
                  this.props.toggleModal(false, '');
                }}
              >
                <i className="fa fa-times-circle-o" />
              </span>
            )}
          </div>
          <div>{this.props.children}</div>
          <div className="modalFooter">{/*Modal Footer*/}</div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
});

export default connect(mapStateToProps, { toggleModal })(Modal);
