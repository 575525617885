import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const SelectListGroup = ({
  name,
  value,
  error,
  info,
  onChange,
  options,
  label,
  isInline = false,
}) => {
  const selectOptions = options.map((option) => (
    <option key={option.label} value={option.value}>
      {option.label}
    </option>
  ));

  let wrapperClassName = ["form-group", "row"];
  if (isInline) {
    wrapperClassName.push("form-check-inline");
  }

  return (
    <div className="form-group row">
      {label && <label className="col-3 col-form-label">{label}</label>}
      <div className="">
        <select
          className={classnames("form-control", {
            "is-invalid": error,
          })}
          name={name}
          value={value}
          onChange={onChange}
        >
          {selectOptions}
        </select>
      </div>
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

SelectListGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isInline: PropTypes.bool,
};

export default SelectListGroup;
