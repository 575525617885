import React, { Component } from 'react';

const WithActivityAnalytics = WrappedComponent => {
  class classesContent extends Component {
    constructor(props) {
      super(props);
      const date = new Date();

      this.state = {
        startTime: date.getTime(),
      };
    }

    doneTime = () => {
      const date = new Date();
      return date.getTime();
    };

    render() {
      const { startTime } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          startTime={startTime}
          endTime={this.doneTime}
        />
      );
    }
  }

  return classesContent;
};

export default WithActivityAnalytics;
