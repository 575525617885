import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleModal, hideNotification } from '../../store/actions/uiActions';
import isEmpty from '../../validation/isEmpty';

class Notification extends Component {
  static SUCCESS = 'success';
  static PRIMARY = 'primary';
  static SECONDARY = 'secondary';
  static DANGER = 'danger';
  static INFO = 'info';
  static WARNING = 'warning';
  static LIGHT = 'light';
  static DARK = 'dark';

  render() {
    if (
      isEmpty(this.props.notification) ||
      this.props.notification.message === null
    ) {
      return null;
    }
    const { message, type } = this.props.notification;

    const className =
      'hidden-print system-notify jq-toast-single jq-icon-' + type;

    return (
      <div className={className} role="alert">
        {message}
        <span
          onClick={() => {
            this.props.hideNotification();
          }}
          className="close-notification"
        >
          {' '}
          <i className="fa fa-times-circle-o" />{' '}
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notification: state.ui.notification,
});

export default connect(mapStateToProps, { toggleModal, hideNotification })(
  Notification
);
