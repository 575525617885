/**-------------------React and component Imports ---------------------- */
import React, { useState } from "react";
import clsx from "clsx";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/SwipeableDrawer";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { logOutTeacher } from "store/actions/authActions";

import activityTypes from "../../../constants/activityTypes";

import { ROLE_LABELS } from "constants/loginTypes";

/**-------------------- CONSTANTS --------------------**/
import routes from "../../../constants/routes";

/**-------------------- IMAGE ASSETS --------------------**/
import logo from "assets/images/logo_horizontal.png";

/**-------------------- CSS --------------------**/
import "./sidebar.scss";

/**-------------------- UTIL --------------------**/
import isEmpty from "validation/isEmpty";

const drawerWidth = 197;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarDropped: {
    top: 20,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  heading: {
    color: "#666766",
    padding: 8,
    marginBottom: 8,
    width: "100%",
  },
  drawer: {
    position: "static",
    flexShrink: 0,
    whiteSpace: "nowrap",
    fontSize: 14,
  },
  drawerOpen: {
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    width: drawerWidth,
    ...theme.mixins.toolbar,
  },
  toolbarBarDropped: {
    paddingTop: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

const SidebarNew = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const screenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const isDemo = String(props.teacherClasses.currentClass).includes("demo");

  // Drawer state
  const { open, setOpen } = props;
  // Profile menu element
  const [anchorEl, setAnchorEl] = useState(null);
  // Drawer Menu Nodes Expanded State
  const [expanded, setExpanded] = useState([]);

  const activitiesMenuClass = ["nav-second-level"];

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setExpanded([]);
    setOpen(false);
  };

  const toggleDrawerOpen = () => {
    setOpen(!open);
  };

  const isProfileMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerChange = (event, nodes) => {
    if (!isEmpty(nodes)) {
      setOpen(true);
    }
    setExpanded(nodes);
  };
  //toggle class enlarged to body depending on drawer state

  const menuId = "primary-search-account-menu";
  // const menuId2 = 'header-classes-menu';

  const types = activityTypes.map((type) => {
    if (type.parent === null) {
      return (
        <li key={type._id}>
          <Link to={type.url}>
            <i className={type.icon} />
            {type.title}
          </Link>
        </li>
      );
    }
  });

  const drawerList = (
    <>
      <div
        className={clsx(classes.toolbar, { [classes.toolbarDropped]: isDemo })}
      ></div>
      <Divider />
      <div className={`drawer-menu`}>
        <TreeView
          defaultParentIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleDrawerChange}
          data-pagename="SIDEBAR"
          data-objectname={expanded ? "CONTRACT" : "EXPAND"}
        >
          <div className={clsx(classes.heading)}>Content</div>
          <>
            <TreeItem
              nodeId={"2"}
              label={
                <Link
                  to={routes.learning.path}
                  title="Lessons"
                  onClick={screenSmall && toggleDrawerOpen}
                >
                  Lessons
                </Link>
              }
            />
            <TreeItem
              nodeId={"3"}
              label={
                <Link
                  to={routes.lessongroups.path}
                  title="Lesson Groups"
                  onClick={screenSmall && toggleDrawerOpen}
                >
                  Lesson Groups
                </Link>
              }
            />
            <TreeItem
              nodeId={"4"}
              label={
                <Link
                  to={routes.courseManager.path}
                  title="Course Manager"
                  onClick={screenSmall && toggleDrawerOpen}
                >
                  Course Manager
                </Link>
              }
            />
            <TreeItem
              nodeId={"5"}
              label={
                <Link to="#" title="Activities">
                  Activities
                </Link>
              }
            >
              <ul
                className={activitiesMenuClass.join(" ")}
                style={{ "list-style-type": "none", paddingLeft: 20 }}
              >
                {types}
                <li key={`endLesson`}>
                  <Link to={routes.endLessonActivity.path}>
                    <span>End Lesson Activity</span>
                  </Link>
                </li>
                <li key={`weatherReport`}>
                  <Link to={routes.weatherReport.path}>
                    <span>Weather Report Activity</span>
                  </Link>
                </li>
              </ul>
            </TreeItem>

            <TreeItem
              nodeId={"6"}
              label={
                <Link
                  to={routes.resourceManager.path}
                  title="Resource Manager"
                  onClick={screenSmall && toggleDrawerOpen}
                >
                  Resource Manager
                </Link>
              }
            />
          </>

          <Divider className={clsx(classes.divider)} />
          <div className={clsx(classes.heading)}>Users</div>
          <TreeItem
            nodeId="8"
            label={
              <Link
                to={routes.schools.path}
                title="Schools"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Schools
              </Link>
            }
          />
          {/* <TreeItem
            nodeId="9"
            label={
              <Link
                to={`/admin/search-teacher`}
                title="Search User"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Teachers
              </Link>
            }
          />
          <TreeItem
            nodeId="19"
            label={
              <Link
                to={`/admin/students`}
                title="Search Students"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Students
              </Link>
            }
          /> */}

          <Divider className={clsx(classes.divider)} />
          {/* <div className={clsx(classes.heading)}>Reports</div>
          <TreeItem
            nodeId="11"
            label={
              <Link
                to={routes.sales_reports.path}
                title="Options"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Sales Reports
              </Link>
            }
          />
          <TreeItem
            nodeId="12"
            label={
              <Link
                to={routes.usage_reports.path}
                title="Redis Manager"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Usage Reports
              </Link>
            }
          />
          <TreeItem
            nodeId="18"
            label={
              <Link
                to={`/admin/exec-report`}
                title="Executive Report"
                onClick={screenSmall && toggleDrawerOpen}
              >
                School Executive Reports
              </Link>
            }
          />
          <Divider className={clsx(classes.divider)} /> */}
          <div className={clsx(classes.heading)}>Tools</div>
          <TreeItem
            nodeId="11"
            label={
              <Link
                to={routes.email_schedule.path}
                title="Options"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Email Schedule
              </Link>
            }
          />
          {/* <TreeItem
            nodeId="20"
            label={
              <Link
                to={`/admin/tools/zeemaps`}
                title="Zeemaps"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Zeemaps
              </Link>
            }
          />
          <Divider className={clsx(classes.divider)} />
          <div className={clsx(classes.heading)}>Developers</div>
          <TreeItem
            nodeId="14"
            label={
              <Link
                to={`/admin/options`}
                title="Options"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Options
              </Link>
            }
          />
          <TreeItem
            nodeId="15"
            label={
              <Link
                to={`/admin/redis-manager`}
                title="Redis Manager"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Redis Manager
              </Link>
            }
          />
          <TreeItem
            nodeId="16"
            label={
              <Link
                title="Platform Notifications"
                to={`/admin/platform-notifications`}
                onClick={screenSmall && toggleDrawerOpen}
              >
                Platform Notifications
              </Link>
            }
          />
          <TreeItem
            nodeId="17"
            label={
              <Link
                to={`/admin/release-manager`}
                title="Release Manager"
                onClick={screenSmall && toggleDrawerOpen}
              >
                Release Manager
              </Link>
            }
          /> */}
        </TreeView>
      </div>
    </>
  );

  //props to handle menu for  different screen sizes
  const drawerProps = {
    variant: "persistent",
    open: open,
    onOpen: toggleDrawerOpen,
    onClose: toggleDrawerOpen,

    ModalProps: screenSmall
      ? {
          keepMounted: true, // Better open performance on mobile.
        }
      : {},
  };

  return (
    <>
      {/* User Profile Control */}
      <Menu
        id={menuId}
        data-pagename="SIDEBAR"
        data-objectname="PROFILE_MENU"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={isProfileMenuOpen}
        onClose={handleMenuClose}
      >
        <Divider />
        <MenuItem>{ROLE_LABELS[props.auth.teacher.role]}</MenuItem>
        <MenuItem
          onClick={() => {
            props.onLogOutTeacher();
            handleMenuClose();
          }}
          data-pagename="SIDEBAR"
          data-objectname="PROFILE_LOGOUT"
          key={"user-logout"}
        >
          Logout
        </MenuItem>
      </Menu>

      <AppBar
        id="lsgo-header"
        position="fixed"
        className={clsx(classes.appBar, {})}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton)}
            data-pagename="SIDEBAR"
            data-objectname="EXPAND"
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <Link
            to="/admin/schools"
            title="LSGO"
            data-pagename="SIDEBAR"
            data-objectname="DASHBOARD_LOGO"
          >
            <img
              src={logo}
              className="appbar-logo hidden-print"
              alt="Life Skills GO"
            />
          </Link>
          <div
            className="text-right"
            style={{ display: "block", width: "100%" }}
          >
            <Button
              className="user-menu-btn"
              aria-controls="simple-menu"
              aria-haspopup="true"
              style={{ color: "#000", textTransform: "none" }}
              onClick={handleProfileMenuOpen}
              data-pagename="SIDEBAR"
              data-objectname="PROFILE_MENU"
            >
              <span
                style={{ textTransform: "capitalize", fontWeight: "700" }}
                className="mr-2 teacher-name"
              >
                {props.auth.teacher.name}
              </span>
              <AccountCircle />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.appBarDropped]: isDemo,
          "hidden-print": true,
        })}
        {...drawerProps}
      >
        {drawerList}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  teacherClasses: state.classes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLogOutTeacher: () => dispatch(logOutTeacher()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SidebarNew));
