import {
  TOGGLE_MODAL,
  TOGGLE_STUDENT_OVERLAY,
  PAUSE_STUDENT,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from '../actions/actionTypes';

const initialState = {
  showModal: false,
  dashboardModal: 'none',
  studentOverlay: false,
  pauseActivity: false,
  notification: {
    type: undefined,
    message: null,
  },
  beta: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        showModal: action.payload.showModal,
        dashboardModal: action.payload.dashboardModal || 'none',
      };
    case TOGGLE_STUDENT_OVERLAY:
      return {
        ...state,
        studentOverlay: action.payload.studentOverlay,
      };
    case PAUSE_STUDENT:
      return {
        ...state,
        pauseActivity: action.payload.pause,
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          type: action.payload.notificationType,
          message: action.payload.content,
        },
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {},
      };
    default:
      return state;
  }
}
