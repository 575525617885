import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#13B5EA", contrastText: "#ffffff" },
    secondary: { main: "#666766", contrastText: "#ffffff" },
    pink: { backgroundColor: "#e20177", color: "#ffffff" },
    blue: { backgroundColor: "#13B5EA", color: "#ffffff" },
    grey: { backgroundColor: "#666766", color: "#ffffff" },
    orange: { backgroundColor: "#f78e1e", color: "#ffffff" },
    green: { backgroundColor: "#C1D82F", color: "#ffffff" },
    mustard: { backgroundColor: "#E1D04D", color: "#ffffff" },
    teal: { backgroundColor: "#4FB7AA", color: "#ffffff" },
    red: { backgroundColor: "#D73816", color: "#ffffff" },
  },
  typography: {
    fontFamily: ["Imprima", "Museo700"].join(","),
  },
  tableHeadCell: {
    style: {
      backgroundColor: "#EEF2F3",
      color: "#000000",
      fontWeight: "bold",
      textAlign: "left",
      fontFamily: "Museo700",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        textTransform: "none",
        marginLeft: "10px",
        marginRight: "10px",
        fontFamily: "Museo700",
      },
      outlined: {
        textTransform: "none",
        fontFamily: "Museo700",
      },
    },
    MuiFab: {
      extended: {
        textTransform: "none",
        fontFamily: "Museo700",
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#EEF2F3",
        color: "#000000",
        fontWeight: "bold",
        textAlign: "left",
        fontFamily: "Museo700",
      },
    },
    MuiAppBar: {
      root: {
        "& .MuiToolbar-root": {
          background: "linear-gradient(105deg, white 250px, #13B5EA 250px)",
          "@media (max-width: 600px)": {
            background: "linear-gradient(105deg, white 180px, #13B5EA 180px)",
          },
        },
      },
    },
  },
});

export default theme;
