export const GET_ERRORS = "GET_ERRORS";
export const GET_FATAL_ERRORS = "GET_FATAL_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_STUDENT = "SET_CURRENT_STUDENT";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESETED = "PASSWORD_RESETED";
export const SET_UNAUTHORIZED_TEACHER = "SET_UNAUTHORIZED_TEACHER";

//UI ACTIONS
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const PAUSE_STUDENT = "PAUSE_STUDENT";
export const TOGGLE_STUDENT_OVERLAY = "TOGGLE_STUDENT_OVERLAY";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SERVER_NOTIFICATIONS = "SERVER_NOTIFICATIONS";

//CLASSES
export const ADD_CLASS = "ADD_CLASS";
export const ADD_CLASS_V2 = "ADD_CLASS_V2";
export const INIT_CLASSES = "INIT_CLASSES";
export const REMOVE_CLASS = "REMOVE_CLASS";
export const EDIT_CLASS = "EDIT_CLASS";
export const SHOW_CLASS_LOADER = "SHOW_CLASS_LOADER";
export const HIDE_CLASS_LOADER = "HIDE_CLASS_LOADER";

export const REMOVE_UNIT = "REMOVE_UNIT";
export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM";

export const GET_CLASS_DATA = "GET_CLASS_DATA";
export const SET_CURRENT_CLASS = "SET_CURRENT_CLASS";

export const ADD_STUDENT_TO_CLASS_LIST = "ADD_STUDENT_TO_CLASS_LIST";
export const SET_CLASS = "SET_CLASS";

//STUDENTS
export const INIT_STUDENTS = "INIT_STUDENTS";
export const ADD_STUDENT = "ADD_STUDENT";
export const REMOVE_STUDENT = "REMOVE_STUDENT";
export const EDIT_STUDENT = "EDIT_STUDENT";
export const ADD_STUDENTS = "ADD_STUDENTS";
export const STUDENT_DONATED = "STUDENT_DONATED";
export const ADD_STUDENTS_TO_STATE = "ADD_STUDENTS_TO_STATE";

//learning area & focus
export const INIT_LEARNING_AREAS = "INIT_LEARNING_AREAS";
export const ADMIN_ADD_LEARNING_FOCUS = "ADMIN_ADD_LEARNING_FOCUS";
export const ADMIN_EDIT_LEARNING_FOCUS = "ADMIN_EDIT_LEARNING_FOCUS";
export const ADMIN_DELETE_LEARNING_FOCUS = "ADMIN_DELETE_LEARNING_FOCUS";
export const ADMIN_FOCUS_LOADER = "ADMIN_FOCUS_LOADER";

//lessons
export const ADMIN_ADD_LESSON = "ADMIN_ADD_LESSON";
export const ADMIN_EDIT_LESSON = "ADMIN_EDIT_LESSON";
export const ADMIN_DELETE_LESSON = "ADMIN_DELETE_LESSON";
export const GET_LESSON_BY_ID = "GET_LESSON_BY_ID";
export const PUSH_SEEDS = "PUSH_SEEDS";

//activities
export const ADMIN_ADD_ACTIVITY = "ADMIN_ADD_ACTIVITY";
export const GET_ACTIVITIES_BY_TYPE = "GET_ACTIVITIES_BY_TYPE";
export const SHOW_ADD_ACTIVITY_LOADER = "SHOW_ADD_ACTIVITY_LOADER";
export const HIDE_ADD_ACTIVITY_LOADER = "HIDE_ADD_ACTIVITY_LOADER";

//REDUCER
export const SAVE_CURRICULUM = "SAVE_CURRICULUM";

// alerts
export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_LAST_ALERT";

// skill scores
export const SUBTRACT_FROM_SCORE = "SUBTRACT_FROM_SCORE";
export const ADD_TO_SCORE = "ADD_TO_SCORE";

export const ADD_TEACHERS_TO_SCHOOL = "ADD_TEACHERS_TO_SCHOOL";

//Constants
export const UPDATE_CONSTANT = "UPDATE_CONSTANT";
