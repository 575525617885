export const licenseTypes = {
  1: "full_product",
  2: "full_product",
  3: "emotion_checkin",
  4: "emotion_checkin",
  5: "free_trial",
  6: "free_trial",
};

export const roleTypes = {
  1: "LS_admin",
  3: "school_admin",
  4: "teacher",
  5: "student",
};

export const ZONE_COLORS = {
  Blue: ["hsl(195, 85%, 50%)", "hsl(195, 85%, 50%)", "hsl(195, 85%, 50%)"],
  Green: [
    "hsl(173, 42%, 51%)",
    "hsl(173, 42%, 51%)",
    "hsl(173, 42%, 51%)",
    "hsl(173, 42%, 51%)",
  ],
  Yellow: ["#ffd700 ", "#ffd700 ", "#ffd700"],
  Red: ["#d73816", "#d73816", "#d73816"],
  Purple: ["#6a0dad", "#6a0dad", "#6a0dad"],
};

export const ZONE_LABELS = {
  Blue: "Not quite ready to learn",
  Green: "Ready to learn",
  Yellow: "In a state of processing",
  Red: "Emotional overload",
  Purple: "Heightened state of emotion",
};

export const emotionColors = [
  { value: "Red", backgroundColor: "#d73816", label: "Emotional overload" },
  {
    value: "Purple",
    backgroundColor: "#6a0dad",
    label: "Heightened state of emotion",
  },
  {
    value: "Blue",
    backgroundColor: "hsl(195, 85%, 50%)",
    label: "Not quite ready to learn",
  },
  {
    value: "Yellow",
    backgroundColor: "#ffd700",
    label: "In a state of processing",
  },
  {
    value: "Green",
    backgroundColor: "hsl(173, 42%, 51%)",
    label: "Ready to learn",
  },
];

export const indices = {
  Red: 0.5,
  Purple: 1.5,
  Blue: 2.5,
  Yellow: 3.5,
  Green: 4.5,
};
