/**-------------------- REACT IMPORTS --------------------**/
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

/**-------------------- CUSTOM THEMING --------------------**/
import { Paper, Card, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { CustomColors, theme } from 'assets/customisedMUI';
import isEmpty from 'validation/isEmpty';

/**
 * SummaryOfUnitStep sits as the last step within creating/editing of units. Primarily a rendering component,
 * it displays unit smmary data and handles clicking of edit buttons for previous steps.
 * @param {*} props
 */
const SummaryOfUnitStep = (props) => {
    const { areas } = props.learningContent;
    const [selectedLessons, setSelectedLessons] = useState([]);
    const { disableActionButton } = props;

    useEffect(() => {
        if (!isEmpty(areas)) {
            let filteredLessons = [];
            areas.forEach((area) => {
                const foci = area.focus;

                foci.forEach((focus) => {
                    const lessons = focus.LessonGroups.filter((group) =>
                        props.unit.LessonGroups.find((lesson) => +group.groupId === +lesson.groupId)
                    ).map((lesson) => {
                        return { ...lesson };
                    });
                    filteredLessons.push(...lessons);
                });
            });
            setSelectedLessons(filteredLessons);
        }
    }, [areas]);

    const mapLessonDetails = () => {
        if (!isEmpty(selectedLessons)) {
            return selectedLessons.map((lesson) => {
                return (
                    <TableRow key={lesson.groupId}>
                        <TableCell>{lesson.displayName}</TableCell>
                    </TableRow>
                );
            });
        }
    };

    return (
        <div className='col-12'>
            <Paper className='mb-4 ml-2 mr-2 row'>
                <Card className='col-lg-6' style={{ padding: '10px' }}>
                    <Typography
                        style={{
                            fontFamily: 'Museo700',
                            color: `${CustomColors('orange')}`,
                            textAlign: 'center',
                            fontSize: '20px',
                        }}
                    >
                        {/** Set step for editing of general unit data (unitName, duration etc) */}
                        General{' '}
                        <i
                            className='icon-pencil cursor-pointer ml-2'
                            onClick={() => {
                                return disableActionButton ? '' : props.setStep(1);
                            }}
                            style={{color: disableActionButton && 'grey'}}                            
                        />
                    </Typography>
                    <p style={{ fontSize: '15px' }}>
                        <span style={{ fontWeight: 'bold' }}>Unit of work: </span>
                        {props.unit.unitName} <br />
                        <span style={{ fontWeight: 'bold' }}>Duration: </span> {props.unit.unitDuration} weeks,{' '}
                        {props.unit.lessonsAmount} lessons per week
                        <br />
                        <span style={{ fontWeight: 'bold' }}>Description: </span> {props.unit.description}        
                    </p>
                </Card>
                <Card className='col-lg-6' style={{ padding: '10px' }}>
                    <Typography
                        style={{
                            fontFamily: 'Museo700',
                            color: `${CustomColors('orange')}`,
                            textAlign: 'center',
                            fontSize: '20px',
                        }}
                    >
                        {/** Set step for editing of lesson within unit  */}
                        Learning{' '}
                        <i
                            className='icon-pencil cursor-pointer ml-2'
                            onClick={() => {
                                return disableActionButton ? '' : props.setStep(2);
                            }}
                            style={{color: disableActionButton && 'grey'}}
                        />
                    </Typography>
                    <p style={{ fontSize: '15px' }}>{props.children}</p>
                </Card>
            </Paper>
            <Paper className='text-center mb-4'>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={theme.tableHeadCell.style}>Lesson</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{mapLessonDetails()}</TableBody>
                </Table>
            </Paper>
        </div>
    );
};

const mapStateToProps = (state) => ({
    learningContent: state.learningContent,
    LessonGroups: state.lessons,
    units: state.units,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryOfUnitStep);
