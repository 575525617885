import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { getLessonById } from '../../store/actions/lessonActions';
import { connect } from 'react-redux';
import {
    editLesson,
    removeLesson,
    disconnectActivityFromLesson,
    updateActivitiesOrderByLesson,
    getSimilarActivities,
} from '../../store/actions/adminActions';
import ActivityListItem from '../../components/Activities/ActivityListItem';
import LessonFullForm from '../../components/Admin/LessonFullForm';

import utils from '../../utils';

import asyncComponent from '../../asyncComponent/asyncComponent';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import GRADES from '../../constants/grades';
import isEmpty from 'validation/isEmpty';

class LessonsDetails extends Component {
    constructor(props) {
        super(props);

        this.gradeOptions = GRADES.map((grade) => {
            return { label: grade.value, value: grade.id };
        });

        this.state = {
            loading: true,
            preview: null,
            selected: null,
            gradeId: this.gradeOptions[0].value,
            lessonType: 1,
            lessonActivities: [],
        };

        this.saveLesson = this.saveLesson.bind(this);
        this.preview = this.preview.bind(this);
        this.removeActivity = this.removeActivity.bind(this);
        this.removeLessonConfirm = this.removeLessonConfirm.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    // change lessonType after component mounted
    componentDidMount() {
        if (this.props.lesson.type === 2) {
            this.loadLessonGameAndVidoes();
        }

        this.setState({ lessonType: this.props.lesson.type });
    }

    /**
     * Handle student class form fields
     * @param e {object} - event object
     * @return {void}
     */
    handleChange(e) {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    }

    /**
     * Update lesson name
     * @param {object<data>} lesson - literal lesson object
     */
    saveLesson = (data) => {
        const { lesson } = this.props;

        const { focus } = lesson;

        const lessonsData = {
            lessonId: lesson.lessonId,
            focusId: focus.focusId,
            areaId: focus.areaId,
            lessonName: data.lessonName,
            outcome: data.outcome,
            gradeId: data.gradeId,
            type: data.type,
            structure: data.structure,
            lesson_curriculum_outcomes: data.lesson_curriculum_outcomes,
        };

        return this.props.onEditLesson(lessonsData);
    };

    mapActivities() {
        const { lesson } = this.props;
        const { activities } = lesson;

        if (!activities.length) {
            return 'The are no activities created for this lesson';
        }

        const SortableItem = SortableElement(({ value }) => (
            <ActivityListItem
                {...value}
                selected={this.state.selected === value.activityId}
                dragHandle
                onPreview={this.preview.bind(this, value)}
                removeActivity={this.removeActivityConfirm.bind(this, value)}
            />
        ));

        const SortableList = SortableContainer(() => {
            return (
                <ul className='list-group with-100 mt-2'>
                    {activities.map((value, index) => (
                        <SortableItem key={`item-${index}`} index={index} value={value} />
                    ))}
                </ul>
            );
        });

        return <SortableList lockAxis='y' useDragHandle onSortEnd={this.onSortEnd.bind(this)} />;
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { lesson } = { ...this.props };

        lesson.activities = arrayMove(lesson.activities, oldIndex, newIndex);

        this.setState({
            lesson: lesson,
        });

        const orderData = lesson.activities.map((activity, i) => {
            return {
                activityId: activity.activityId,
                order: i,
                lessonId: lesson.lessonId,
            };
        });

        this.props.onUpdateActivitiesOrderByLesson({
            orderData,
        });
    };

    /**
     * Delete lesson
     * @param {int} focusId - focus id
     * @param {int} areaId - area id
     * @param {object} lesson - lesson
     */
    removeLesson = () => {
        const { lesson } = this.props;

        const { focus } = lesson;

        const lessonsData = {
            lessonId: lesson.lessonId,
            focusId: focus.focusId,
            areaId: focus.areaId,
        };

        this.props.onRemoveLesson(lessonsData);

        this.props.history.goBack();
    };

    /**
     *
     * @param {object} activity -
     * @returns {void}
     */
    async preview(activity) {
        const { activityType } = activity;

        //get component name by type
        const componentName = utils.getActivityByType(activityType, 'studentComponent');

        const Preview = await asyncComponent(() => {
            if (componentName !== undefined) {
                return import('../../components/' + componentName);
            }

            //if we are here, there is no component for requested activity,
            //will only happen if someone is trying to mess with the id value in the url
            //show 404 error component
            return import('../../components/Activities/ActivityNotFound');
        });

        this.setState((state) => {
            state.preview = <Preview {...activity.content} demoMode />;
            state.selected = activity.activityId;

            return state;
        });
    }

    /**
     *
     * @param activity
     */
    removeActivityConfirm(activity) {
        const { lesson } = this.props;

        confirmAlert({
            title: 'Disconnect Activity',
            message: `Are you sure to disconnect "${activity.content.title}" from the lesson?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.removeActivity(lesson.lessonId, activity.activityId),
                },
                {
                    label: 'No',
                    onClick: () => {
                        return null;
                    },
                },
            ],
        });
    }

    /**
     *
     * @param lessonId
     * @param activityId
     * @return {Promise<void>}
     */
    async removeActivity(lessonId, activityId) {
        await this.props.onRemoveActivity(lessonId, activityId);

        const { lesson } = { ...this.props };

        const activities = lesson.activities.filter((act) => {
            return act.activityId !== activityId;
        });
        //disconnectActivityFromLesson
        lesson.activities = activities;

        this.setState({
            lesson: lesson,
        });
    }

    /**
     * Remove Lesson confirm dialog
     * @returns {void}
     */
    removeLessonConfirm() {
        confirmAlert({
            title: 'Remove Lesson',
            message: 'Are you sure to remove this lesson?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.removeLesson(),
                },
                {
                    label: 'No',
                    onClick: () => {
                        return null;
                    },
                },
            ],
        });
    }

    /**
     * toggle activities selector according to lesson type
     */
    changeLessonTypeForPreview = (updateTo) => {
        if (+updateTo > 0 && +updateTo < 3) {
            this.setState({ lessonType: +updateTo });
            this.loadLessonGameAndVidoes();
        }
    };

    /**
     * Load lesson game abnd videos if not loaded previously
     */
    loadLessonGameAndVidoes = async () => {
        const { lessonActivities } = this.state;
        if (isEmpty(lessonActivities)) {
            let availableActivities = { videos: [], games: [] };
            //load Videos/ activityType 400
            availableActivities.videos = await this.props.getSimilarActivities(400);
            //load games/ activityType 300
            availableActivities.games = await this.props.getSimilarActivities(300);

            this.setState({ lessonActivities: availableActivities });
        }
    };

    render() {
        const { lesson } = this.props;

        const { isLessonUsed } = lesson;

        const activitiesList = this.mapActivities();

        return (
            <div className='row mt-3'>
                <div className='col-12'>
                    <div className='card-box shadow'>
                        {!isLessonUsed && (
                            <button className='btn btn-light pull-right ml-1' onClick={this.removeLessonConfirm}>
                                <i className='fa fa-trash font-16' />
                            </button>
                        )}
                        <LessonFullForm
                            {...lesson}
                            saveLesson={this.saveLesson}
                            changeLessonType={this.changeLessonTypeForPreview}
                            lessonActivities={this.state.lessonActivities}
                        />
                    </div>
                    {this.state.lessonType === 1 && (
                        <div className='card-box shadow'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h5>
                                        <span className='d-block pull-left'>Lesson Activities</span>
                                        <Link className='pull-right' to={`${this.props.match.url}/activities`}>
                                            <button className='btn btn-outline-info waves-effect waves-light pull-right'>
                                                Add New
                                            </button>
                                        </Link>
                                    </h5>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-6'>{activitiesList}</div>
                                <div className='col-6'>
                                    <div className='student-box' style={{ overflow: 'hidden' }}>
                                        {this.state.preview}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ui: state.ui,
    errors: state.errors,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getLesson: (lessonId) => dispatch(getLessonById(lessonId)),
        onEditLesson: (lessonData) => dispatch(editLesson(lessonData)),
        onRemoveLesson: (lessonData) => dispatch(removeLesson(lessonData)),
        onRemoveActivity: (lessonId, activityId) => dispatch(disconnectActivityFromLesson(lessonId, activityId)),
        onUpdateActivitiesOrderByLesson: (lessonId, orderData) =>
            dispatch(updateActivitiesOrderByLesson(lessonId, orderData)),
        getSimilarActivities: (activityData) => dispatch(getSimilarActivities(activityData)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRouter(LessonsDetails)));
