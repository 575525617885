import React, { useState, useEffect } from 'react';
import { useGrades } from 'hooks';
import axios from 'config/axios';
import { withFormik, Field } from 'formik';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { updateLessonGroupLessonRelation } from 'store/actions/learningActions';

const LessonGroupEditor = ({ errors, touched, values, setValues, handleSubmit, match }) => {
    const { groupId } = match.params;
    const [lessonGroup, setLessonGroup] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [lessonGroupLessons, setLessonGroupLessons] = useState({ 0: 467, 1: 1, 2: 87, 3: 88 });
    const [state, actions] = useGrades();
    const grades = Object.keys(state.grades).map((k) => {
        return { id: k, value: state.grades[k] };
    });
    if (!state.loaded) {
        actions.load();
    }

    useEffect(() => {
        const fetchLessonGroup = async () => {
            const result = await axios.get(`/api_v2/admin/lessongroups/${groupId}`).then((r) => {
                return r.data;
            });
            const lessons = await axios.get('/api_v2/admin/lessons').then((r) => {
                return r.data;
            });
            setLessonGroup(result);
            setLessons(lessons);
            let lessonGroupLessonsObj = {};
            grades.forEach((grade) => {
                lessonGroupLessonsObj = {
                    ...lessonGroupLessonsObj,
                    [grade.id]:
                        (result.lessons.find((l) => +l.LessonGroupLessons.gradeId === +grade.id) &&
                            result.lessons.find((l) => +l.LessonGroupLessons.gradeId === +grade.id).lessonId) ||
                        -1,
                };
            });
            setLessonGroupLessons(lessonGroupLessonsObj);
        };
        fetchLessonGroup();
    }, [groupId]);

    console.log(lessonGroup, lessonGroupLessons);

    return (
        (lessonGroup && (
            <div className='row mt-3'>
                <div className='col-12'>
                    <div className='card-box shadow'>
                        <div>{lessonGroup.displayName}</div>
                        {grades.map((grade) => {
                            return (
                                <div className='form-group row'>
                                    <label className='col-2 col-form-label'>{grade.value} Lesson</label>
                                    <div className='col-3'>
                                        <Field
                                            component='select'
                                            name='stage1Id'
                                            className={classnames('form-control', {
                                                'is-invalid': touched.gradeId && errors.gradeId,
                                            })}
                                            onChange={(e) => {
                                                setLessonGroupLessons({
                                                    ...lessonGroupLessons,
                                                    [grade.id]: Number(e.target.value),
                                                });
                                                setValues({ ...values, [grade.id]: Number(e.target.value) });
                                            }}
                                            value={lessonGroupLessons[+grade.id]}
                                        >
                                            {lessons &&
                                                lessons.map((l) =>
                                                    +l.gradeId === +grade.id || +l.lessonId === -1 ? (
                                                        <option key={l.lessonId} value={l.lessonId}>
                                                            {l.lessonName + ' - ' + l.lessonId}
                                                        </option>
                                                    ) : null
                                                )}
                                        </Field>
                                    </div>
                                </div>
                            );
                        })}
                        <button onClick={handleSubmit}>Save</button>
                    </div>
                </div>
            </div>
        )) || <div> Hello</div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateLessonGroupLessonRelation: (groupId, stageIds) =>
            dispatch(updateLessonGroupLessonRelation(groupId, stageIds)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withFormik({
        mapPropsToValues() {
            return {};
        },
        handleSubmit(values, props) {
            const { updateLessonGroupLessonRelation, match } = props.props;
            const { groupId } = match.params;
            updateLessonGroupLessonRelation(groupId, values);
        },
    })(LessonGroupEditor)
);
