import {
    GET_ERRORS,
    ADD_CLASS,
    REMOVE_CLASS,
    INIT_CLASSES,
    HIDE_CLASS_LOADER,
    EDIT_CLASS,
    GET_CLASS_DATA,
    SAVE_CURRICULUM,
    UPDATE_CURRICULUM,
    REMOVE_UNIT,
    SET_CURRENT_CLASS,
    // SET_UNAUTHORIZED_TEACHER,
    ADD_CLASS_V2,
    SET_CLASS,
    ADD_TEACHERS_TO_SCHOOL,
} from './actionTypes';
import axios from 'config/axios';
import { toggleModal, showNotification } from './uiActions';
import Notification from '../../components/UI/Notification';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const deleteUnitFromState = (classId, unitId) => (dispatch) => {
    dispatch({
        type: REMOVE_UNIT,
        payload: { classId, unitId },
    });
};

/**
 * Delete class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const deleteUnit = (unit) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { classId, unitId } = unit;

            const result = await axios.delete('/api/classes/unit/remove/' + unitId);

            if (result) {
                // remove class from redux state
                dispatch(deleteUnitFromState(classId, unitId));

                //notify
                dispatch(showNotification(Notification.SUCCESS, 'Unit deleted successfully'));

                resolve(true);
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });

            reject(err);
        }
    });
};

/**
 * Get classes by teacher id from the server
 * Dispatch INIT_CLASSES action to the reducer
 * @param teacherID
 * @return {void}
 */
export const initClasses = (teacherID) => async (dispatch) => {
    try {
        const result = await axios.get('/api/classes/' + teacherID);

        if (result) {
            //add new class
            dispatch({
                type: INIT_CLASSES,
                payload: result.data,
            });

            dispatch({
                type: HIDE_CLASS_LOADER,
            });
        }
    } catch (err) {
        // dispatch(showNotification(Notification.WARNING, err.response.data));
        /**
         * Commenting out this line as a temporary hot-fix for the 'invalid token' pop-up for valid users.
         */
        //dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: true });
    }
};

export const setCurrentClass = (classId) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_CLASS,
        payload: classId,
    });
};

/**
 * Get class data by class id
 * Dispatch INIT_CLASSES action to the reducer
 * @param classId
 * @return {void}
 */
export const getClass = (classId) => (dispatch) => {
    dispatch({
        type: GET_CLASS_DATA,
        payload: { classId: classId },
    });
};

export const hideLoad = (dispatch) => {
    dispatch({
        type: HIDE_CLASS_LOADER,
    });
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const addNewClass = (result) => (dispatch) => {
    dispatch({
        type: ADD_CLASS,
        payload: result,
    });
};

export const addClass = (result) => (dispatch) => {
    dispatch({
        type: ADD_CLASS_V2,
        payload: result,
    });
};

/**
 * Create new class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const createClass = (classData, history) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post('/api/classes/create', classData);

            if (result) {
                let newClass = result.data;
                newClass['units'] = [];

                //add new class
                dispatch(addNewClass(newClass));

                //remove the dialog
                dispatch(toggleModal(false));

                //show notification
                dispatch(showNotification(Notification.SUCCESS, 'class was created'));
                resolve('class created');
            }
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            reject(err);
        }
    });
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const editClassList = (result) => (dispatch) => {
    dispatch({
        type: EDIT_CLASS,
        payload: result.data,
    });
};

export const setClass = (clss) => (dispatch) => {
    dispatch({ type: SET_CLASS, payload: clss });
};

/**
 * edit class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const editClass = (classData) => async (dispatch) => {
    try {
        const result = await axios.post('/api/classes/edit', classData);

        if (result) {
            //add new class
            dispatch(editClassList(result));

            //remove the dialog
            dispatch(toggleModal(false));

            //show notification
            dispatch(showNotification(Notification.SUCCESS, 'class details were saved successfully'));
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const deleteClassFromState = (classId) => (dispatch) => {
    dispatch({
        type: REMOVE_CLASS,
        payload: classId,
    });
};

/**
 * Delete class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const deleteClass = (classId, teacherId) => async (dispatch) => {
    try {
        const result = await axios.delete('/api/classes/remove/' + classId + '/' + teacherId);

        if (result) {
            //remove class from redux state
            dispatch(deleteClassFromState(classId));

            //notify
            dispatch(showNotification(Notification.SUCCESS, 'class deleted successfully'));
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

export const getCoursesByGrade = (gradeId, schoolId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const courses = await axios.get(`/api/classes/courses/${gradeId}/${schoolId}`);

            resolve(courses.data);
        } catch (err) {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            dispatch(showNotification(Notification.DANGER, 'Failed to get default courses'));

            reject(err);
        }
    });
};

export const addUsersToSchoolState = (teachers, schoolId) => async (dispatch) => {
    try {
        dispatch({ type: ADD_TEACHERS_TO_SCHOOL, payload: { teachers, schoolId } });
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err,
        });
        dispatch(showNotification(Notification.WARNING, 'Failed to create teachers'));
    }
};
