import axios from 'config/axios';

class Constants {
    static async getGrades() {
        return await axios.get('/api_v2/constants/grades');
    }

    static async getSidebarOptions() {
        return await axios.get(`/api_v2/constants/sidebar`);
    }
}

export default Constants;
