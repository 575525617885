import React from "react";
import ReactDOM from "react-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      id: props.column ? props.column.key : "",
      label: props.label,
    };
  }

  getValue() {
    return { [this.state.id]: this.state.date };
  }

  getInputNode() {
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  }

  handleDateChange = (date) => {
    return this.setState({ date: moment(date).format("DD/MM/YYYY") }, () =>
      this.props.onCommit()
    );
  };

  render() {
    return (
      <div>
        <ReactDatePicker
          inline
          dateFormat="dd/MM/yyyy"
          selected={this.state.date}
          onChange={this.handleDateChange}
          onClickOutside={() => this.props.onCommitCancel()}
        />
      </div>
    );
  }
}

export default DatePicker;
