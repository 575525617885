import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

/**
 *@returns JSX
 */
const TextFieldGroup = ({
	name,
	placeholder,
    label,
	value,
	error,
	info,
	type,
	onChange,
    onFocus,
    onBlur,
    onKeyUp,
	disabled,
}) => {

	return (
        <div className="form-group row">
            { label && (<label className="col-3 col-form-label">{label}</label>)}
            <div className="col">
                <input
                    type={type}
                    name={name}
                    className={classnames(
                        'form-control isInvalid',
                        {
                            'is-invalid': error,
                        },
                    )}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyUp={onKeyUp}
                />
                {error && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
            {info && <small className="form-text text-muted">{info}</small>}
        </div>
    );
};

TextFieldGroup.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    info:PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string
};


TextFieldGroup.defaultProps = {
  type: 'text'
};

export default TextFieldGroup;
