import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createFocus,
  removeFocus,
  editFocus,
  createLesson,
  editLesson,
} from '../../store/actions/adminActions';
import Loader from '../../components/UI/Spinner/Spinner';
import Focus from '../../components/Admin/Focus';

import WithLearningContent from '../../hoc/WithLearningContent';

import FocusForm from '../../components/Admin/FocusForm';

import AreaMenu from '../../components/LearningContent/AreaMenu';

import isEmpty from '../../validation/isEmpty';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Learning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      activeArea: null,
      newFocusName: '',
      errors: {},
    };

    this.onLearningAreaClick = this.onLearningAreaClick.bind(this);
    this.saveFocusName = this.saveFocusName.bind(this);
    this.addFocus = this.addFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Get Derived State From Props
   * @param {object} props - props
   * @param {object} state - previous state
   * @return {object} - modified state
   */
  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.learningContent.areas) && isEmpty(state.activeArea)) {
      state.loading = false;
      state.activeArea = props.learningContent.areas[0];
    }

    return state;
  }

  /**
   * Handle student class form fields
   * @param e {object} - event object
   * @return {void}
   */
  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  /**
   *
   * @param {object} area -
   */
  onLearningAreaClick = area => {
    this.setState({
      activeArea: area,
    });
  };

  /**
   * Map learning areas
   * @returns {void}
   */
  mapLearningAreasTitles = () => {
    const { areas } = this.props.learningContent;
    return (
      <div className="card-deck">
        <AreaMenu
          areas={areas}
          activeArea={this.state.activeArea}
          onLearningAreaClick={this.onLearningAreaClick}
        />
      </div>
    );
  };

  /**
   * Update focus
   * @param {object<Focus>} item - updated object
   * @param {String} name - string to update
   */
  saveFocusName = (focus, areaId, name) => {
    this.props.onEditFocus(focus.focusId, areaId, name);
  };

  /**
   * Map focuses list to Focus component
   * @return {array<Focus>}
   */
  mapFocus = () => {
    const { focus, areaName, areaId } = this.state.activeArea;

    //return no data message (this one should be a component)
    if (isEmpty(focus)) {
      return (
        <div className="col-12">
          <p>
            There is no qualities set up for <b>{areaName}</b> one yet, click
            the "Add Quality" button{' '}
          </p>
        </div>
      );
    }

    //get the list
    return focus.map(item => {
      return (
        <Focus
          key={item.focusId}
          {...item}
          saveFocusName={this.saveFocusName.bind(this, item, areaId)}
          removeFocus={this.removeFocusConfirm.bind(this, item, areaId)}
          addLesson={this.addLesson.bind(this, item.focusId, areaId)}
          //={this.saveLessonName.bind(this, item.focusId, areaId)}
        />
      );
    });
  };

  /**
   * Save new focus to db
   * @return {void}
   */
  addFocus = data => {
    //create focus data object
    Object.assign(data, {
      areaId: this.state.activeArea.areaId,
    });

    //send to redux
    this.props.onCreateFocus(data);
  };

  /**
   * Delete focus
   * @param {object<Focus>} focus - selected focus
   * @param {int} areaId - area Id
   */
  removeFocus = (focus, areaId) => {
    this.props.onRemoveFocus(focus.focusId, areaId);
  };

  removeFocusConfirm(focus, areaId) {
    confirmAlert({
      title: 'Remove Quality',
      message: 'Are you sure to remove this Quality?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.removeFocus(focus, areaId),
        },
        {
          label: 'No',
          onClick: () => {
            return null;
          },
        },
      ],
    });
  }

  /**
   * Add new lesson to a specific focus
   * method called from Focus component
   * @param {int} focusId - focus id
   * @param {int} areaId - area id
   * @param {object} lesson - new lesson literal
   */
  addLesson = (focusId, areaId, lesson) => {
    const lessonData = {
      lessonName: lesson.lessonName,
      gradeId: lesson.gradeId,
      focusId: focusId,
      areaId: areaId,
    };
    this.props.onCreateLesson(lessonData);
  };

  /**
   * Delete lesson
   * @param {int} focusId - focus id
   * @param {int} areaId - area id
   * @param {object} lesson - lesson
   */
  removeLesson = (focusId, areaId, lesson) => {
    const lessonsData = {
      lessonId: lesson.lessonId,
      focusId: focusId,
      areaId: areaId,
    };
    this.props.onRemoveLesson(lessonsData);
  };

  /**
   *
   * @return {JSX}
   */
  render() {
    //show loading while there is no content
    if (this.state.loading) {
      return <Loader />;
    }

    const learningAreasTitles = this.mapLearningAreasTitles();

    const learningAreaFocusList = this.mapFocus();

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Learning Manager</h4>
            </div>
          </div>
        </div>
        <div className="row no-padding">
          <div className="col-12">
            <div className="mb-3">
              <div className="row">{learningAreasTitles}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FocusForm addFocus={this.addFocus} />
          </div>
        </div>
        <div className="row">{learningAreaFocusList}</div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  ui: state.ui,
  errors: state.errors,
});

const mapDispatchToProps = dispatch => {
  return {
    onCreateFocus: focusData => dispatch(createFocus(focusData)),
    onRemoveFocus: (focusId, areaId) => dispatch(removeFocus(focusId, areaId)),
    onEditFocus: (focusId, areaId, focusName) =>
      dispatch(editFocus(focusId, areaId, focusName)),
    onCreateLesson: lessonData => dispatch(createLesson(lessonData)),
    onEditLesson: lessonData => dispatch(editLesson(lessonData)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithLearningContent(Learning))
);
