import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { deleteCourse } from 'store/actions/adminActions';

const TemplateUnit = ({ course, setType, setRefreshList, ...props }) => {
    const [courses, setCourses] = useState([]);

    const removeCourse = async (course) => {
        if (course.unitId === undefined) {
            return;
        }

        const deletedCourse = await props.deleteCourse(course.unitId);
        const updatedCourses = courses.filter((course) => Number(course.unitId) !== Number(deletedCourse.unitId));
        setCourses(updatedCourses);
    }
    
    return (
        <div className='col-md-4 col-sm-6'>
            <div className='card m-b-30'>
                <div className='card-body shadow'>
                    <div>
                        <span>
                            {course && course.TemplateUnit && course.TemplateUnit.type === 0
                                ? course.TemplateUnit.schoolId === 0
                                    && 'Suggested'
                                : 'Online Class'}
                        </span>
                    </div>
                    <Typography variant='h6' style={{ fontFamily: 'Museo700' }}>
                        {course && course.unitName}
                    </Typography>
                    <Typography
                        paragraph
                        variant='body1'
                        style={{
                            fontFamily: 'Imprima',
                            minHeight: '132px',
                            textAlign: 'justify',
                            marginTop: 5,
                        }}
                    >
                        {course && course.TemplateUnit && course.TemplateUnit.description}
                    </Typography>
                    <Typography>
                        {`Weeks: ${course && course.unitDuration}, Lessons per week: ${course && course.lessonsAmount}`}
                    </Typography>
                    <div className='card-btns mt-2'>
                        <button
                            onClick={() => {removeCourse(course); setRefreshList(true)}}
                            className='btn btn-danger mr-2'
                        >
                            {' '}
                            Delete
                        </button>
                        <Link
                            to={`/admin/course/edit/${course && course.unitId}`}
                            className='btn btn-custom waves-effect waves-light'
                            course={course}
                        >
                            Edit
                        </Link>
                        {/* <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                                viewCourse(course);
                            }}
                        >
                            <i className='fa fa-info-circle mr-2' /> Curriculum Info
                        </Button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCourse: (courseId) => dispatch(deleteCourse(courseId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateUnit);