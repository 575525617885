import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import './alertDialog.scss';
const ConfirmDialog = (props) => {
    return (
        <Dialog
            maxWidth='md'
            fullWidth={props.fullWidth || false}
            className='alert-dialog'
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle className='text-center'>{props.question}</DialogTitle>
            <DialogContent>
                {props.message}
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant='contained' color='primary'>
                    {props.dismissName}
                </Button>
                <Button variant='outlined' color='secondary' onClick={props.confirmCallback}>
                    {props.confirmName}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;
