const ENV_GOOGLE_ANALYTICS_ID =
  process.env.NODE_ENV === 'development' ? 'UA-129146666-1' : 'UA-129313868-1';

export const ID = ENV_GOOGLE_ANALYTICS_ID;

export const TEACHER_PAGES = {
  TEACHER_HOME_PAGE: '/teacherClasses',
  TEACHER_LOGGED_IN_AS_STUDENT: '/teacherInStudentMode',
  TEACHER_REPORTS: '/reports',
  TEACHER_LESSONS_DASHBOARD: '/currentUnit',
  TEACHER_LESSON: '/currentLesson',
  TEACHER_UNITS: '/unitsManager',
  TEACHER_STUDENTS: '/studentsManager',
  TEACHER_RESOURCE_WEATHER: 'resource/weatherReport',
  TEACHER_RESOURCE_CARDS: 'resource/cards',
  TEACHER_RESOURCE_VIDEOS: 'resource/videos',
};

export const STUDENTS = {
  STUDENT_HOME_PAGE: '/student',
  STUDENT_LESSON: '/studentLesson',
  STUDENT_SUMMARY: '/studentSummary',
  STUDENT_QUIZ: '/quiz',
  STUDENT_MULTI_QUIZ: '/quizMulti',
  STUDENT_OPEN_ENDED: '/openAnswer',
  STUDENT_WEATHER_REPORT: '/weatherReport',
  STUDENT_WEATHER_CHECKIN: '/weatherCheckIn',
  STUDENT_WEATHER_VIDEO: '/weatherReportsVideo',
  STUDENT_VIDEO: '/videoActivity',
  STUDENT_GAME: '/game',
};

export const EVENTS = {
  START_LESSON: 'StartLesson',
  END_LESSON: 'EndLesson',
  PAUSE_LESSON: 'PauseLesson',
  LOAD_ACTIVITY_FROM_PANEL: 'LoadActivity',
  WEATHER_REPORT_USE: 'WeatherReportUse',
  WEATHER_FOLLOW_UP_USE: 'WeatherFollowUpUse',
  ADD_STUDENT: 'AddStudent',
  REMOVE_STUDENT: 'RemoveStudent',
  CREATE_UNIT: 'CreateUnit',
  EDIT_UNIT: 'EditUnit',
  CHANGE_UNIT: 'ChangeUnit',
  REMOVE_UNIT: 'RemoveUnit',
  CHANGE_LESSON: 'ChangeLesson',
  VIDEO_ENDED: 'VideoEnded',
  VIDEO_PAUSED: 'VideoPaused',
  VIDEO_PLAY: 'VideoPlayed',
  STUDENT_DONATED: 'StudentDonated',
  USED_DEFAULT_UNIT: 'UsedDefaultUnit',
};

export const CATEGORIES = {
  TEACHER_ACTIONS: 'TeacherActions',
  STUDENT_ACTIONS: 'StudentActions',
};
