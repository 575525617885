import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "config/axios";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Typography,
  Checkbox,
  Card,
  CardContent,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  MenuItem,
  Select,
  ListItemText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import DatePicker from "react-datepicker";
import { theme } from "assets/customisedMUI";
import isEmpty from "validation/isEmpty";
import { getSchool, createNewGroup } from "store/actions/adminActions";
import { licenseTypes } from "constants/options";
import { reSendInvite, removeTeacher } from "store/actions/teacherActions";
import { loginAs } from "store/actions/authActions";
import { updateTeacherRole } from "store/actions/learningActions";
import { toggleModal, showNotification } from "../../store/actions/uiActions";
import Modal from "components/UI/Modal/Modal";
import InformationRow from "components/Common/InformationRow";
import NewTeacher from "containers/Schools/NewTeacher";
import { parseJwt } from "utils";
import moment from "moment";
import ConfirmDialog from "components/UI/ConfirmAlert";
import { PackageList } from "constants/index";
import DateRangeSelector from "components/Common/DateRangeSelector";
import { CardHeader } from "@material-ui/core";
import { pl } from "date-fns/locale";
import { saveAs } from "file-saver";
import ExistingNotificationForm from "containers/Notifications/ExistingNotificationForm";
import PlatformEmails from "containers/Notifications/PlatformEmails";

const teacherCamapign = [
  {
    id: 14,
    value: "Full Product License",
  },
  {
    id: 2,
    value: "Free Trial License",
  },
  {
    id: 6,
    value: "Emotion Check-in License",
  },
];

const b64toBlob = (b64Data, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {
    type: "application/zip",
  });
  return blob;
};

const SchoolDetails = (props) => {
  const [school, setSchool] = useState(null);
  const [cohortGroupId, setCohortGroupId] = useState(null);
  const [teacher, setTeacher] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessData, setAccessData] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [signUpLink, setSignUpLink] = useState({ link: "", timeLeft: "" });
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingGroups, setEditingGroups] = useState([]);
  const [toUpdate, setToUpdate] = useState(null);
  const [createTeacher, setCreateTeacher] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sliceEnd, setSliceEnd] = useState(null);
  const [schoolGroups, setSchoolGroups] = useState([]);
  const [isDateSelectorModalOpen, setIsDateSelectorModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [startDate, updateStartDate] = useState(
    moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD")
  );
  const [endDate, updateEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD")
  );
  const [dataToUpdateForGroup, setDataToUpdateForGroup] = useState({});

  const { schoolId } = props.match.params;

  useEffect(() => {
    const fetchTeacherData = async () => {
      const result = await props.getSchool(schoolId, "teacher");
      const school = result;
      setSchool(school);
      setLoading(false);
    };

    fetchTeacherData();
  }, []);

  useEffect(() => {
    const fetchTeacherData = async () => {
      const result = await axios.get(
        `/api/int/schools/${schoolId}/groups/${cohortGroupId}/users`
      );
      setTeacher(result.data);
      setLoading(false);
    };

    fetchTeacherData();
  }, [cohortGroupId, schoolId]);

  // Separate useEffect callback to handle state update (useCallback had memoised state and would reinstate initial values)
  useEffect(() => {
    if (toUpdate) {
      const teachersToUpdate = Array.isArray(toUpdate) ? toUpdate : [toUpdate];
      const alreadySelected = teachersToUpdate.filter((t) =>
        selectedTeachers.includes(t)
      );
      const newSelected = teachersToUpdate.filter(
        (t) => !selectedTeachers.includes(t)
      );
      if (!isEmpty(newSelected)) {
        setSelectedTeachers([...newSelected]);
      }

      setToUpdate(null);
    }
  }, [toUpdate]);

  useEffect(() => {
    const fetchSchoolGroups = async () => {
      const groups = await axios.get(`/api/int/schools/${schoolId}/groups`);
      if (!isEmpty(groups.data)) {
        setCohortGroupId(groups.data[groups.data.length - 1].groupId);
        setSchoolGroups(groups.data);
      } else {
        setSchoolGroups([]);
      }
    };
    fetchSchoolGroups();
  }, [props.schoolId]);

  const updateState = React.useCallback(
    (id) => {
      setToUpdate(id);
    },
    [setToUpdate]
  );

  useEffect(() => {
    if (isEmpty(selectedTeachers)) {
      setEditing(false);
    }
  }, [selectedTeachers]);

  useEffect(() => {
    const slice =
      teacher && rowsPerPage === -1
        ? teacher.length + 1
        : page * rowsPerPage + rowsPerPage;
    setSliceEnd(slice);
  }, [rowsPerPage, teacher, page]);

  useEffect(() => {
    const fetchLink = async () => {
      if (!isEmpty(accessData)) {
        const token = !isEmpty(accessData)
          ? await axios.get(`api/users/signUpLink/${accessData.id}`)
          : "";
        if (isEmpty(token.data)) {
          const newLink = await axios.get(
            `/api_v2/admin/signupToken/${accessData.id}`
          );
          const expiryTime = await decodeToken(newLink.data.token);
          !isEmpty(newLink.data)
            ? setSignUpLink({
                link: newLink.data ? newLink.data.link : "",
                timeLeft: expiryTime,
              })
            : setSignUpLink({ link: "", timeLeft: "" });
        } else {
          const expiryTime = await decodeToken(token.data);
          setSignUpLink({
            link:
              "https://teacher.lifeskillsgo.com/teacher/register/" + token.data,
            timeLeft: expiryTime,
          });
        }
      }
    };
    fetchLink();
  }, [accessData]);

  const decodeToken = async (token) => {
    const decoded = await parseJwt(token.replace("Bearer ", ""));
    const currentTime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
    const expiryTime = moment(new Date(decoded.exp * 1000)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const timeLeft = moment(expiryTime).diff(moment(currentTime), "hours");
    return timeLeft;
  };

  const downloadReport = async (start, end) => {
    const result = await axios.get(
      `/api_v2/admin/usage-report/${selectedGroup.groupId}?start=${start}&end=${end}`
    );
    const b64 = result.data;
    const blob = b64toBlob(b64);
    saveAs(blob, `${selectedGroup.nickname}_usage_report.pdf`);
    setIsDateSelectorModalOpen(false);
  };

  const closeModal = () => {
    props.toggleModal(false);
    setAccessData([]);
    setUpdateData([]);
    setSignUpLink({ link: "", timeLeft: "" });
  };

  let modalContent = () => {
    return (
      <div>
        <div>
          <h3>
            <b>Sign Up Link</b>
          </h3>
          <Typography variant="h6" style={{ wordBreak: "break-word" }}>
            {!isEmpty(signUpLink.link) && (
              <>
                <b>Link:</b> {signUpLink.link}
                <br />
                <br />
                <b>Token expires in:</b>{" "}
                {signUpLink.timeLeft > 0
                  ? `${signUpLink.timeLeft} hours`
                  : "Expired"}{" "}
                <br />
                <br />
                <div className="text-center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => generateSignupLink(accessData.id)}
                  >
                    Generate New Token
                  </Button>
                </div>
              </>
            )}
          </Typography>
        </div>
      </div>
    );
  };

  const postEditData = async (data) => {
    try {
      const dataArray = Array.isArray(data) ? data : [data];
      let updatedDataArray = [];
      dataArray.forEach((d) => {
        const name = d.name ? d.name.split(/(\s+)/) : "";
        const lastName = d.lastName
          ? d.lastName
          : d.name
          ? name[name.length - 1] || d.name
          : "";
        const firstName = d.firstName
          ? d.firstName
          : d.name
          ? name.filter((n) => n !== " " && n !== lastName).join(" ") || d.name
          : "";

        let updateData = {
          userType: "teacher",
          id: d.id,
          firstName: firstName,
          lastName: lastName,

          email: d.email,
          roleId:
            d.role === "LS Admin"
              ? 1
              : d.role === "School Admin"
              ? 3
              : +d.role === 3
              ? 3
              : 4,
        };
        d.expiryDate !== "Invalid date" &&
          d.expiryDate !== "N/A" &&
          d.expiryDate !== "Invalid Date" &&
          Object.assign(updateData, {
            expiryDate: moment(d.expiryDate).format("YYYY-MM-DD"),
          });
        updatedDataArray.push(updateData);
      });
      const result = await axios.put(`/api/v3/admin/users`, updatedDataArray);

      let schoolTeachers = teacher;

      if (!isEmpty(result.data)) {
        const response = Array.isArray(result.data)
          ? result.data
          : [result.data];

        response.forEach((res) => {
          const targetSchoolTeacher = schoolTeachers.find(
            (t) => t?.User?.id === res
          );
          if (targetSchoolTeacher) {
            const newTeacher = {
              ...targetSchoolTeacher,
              ...updatedDataArray[0],
              User: {
                ...targetSchoolTeacher?.User,
                ...updatedDataArray[0],
                TeachersMetadatum: {
                  ...targetSchoolTeacher?.User?.TeachersMetadatum,
                  ...updatedDataArray[0],
                },
              },
            };
            const newRows = [...schoolTeachers];
            newRows.splice(
              schoolTeachers.indexOf(targetSchoolTeacher),
              1,
              newTeacher
            );
            schoolTeachers = newRows;
          }
        });
      }

      setTeacher(schoolTeachers);
      setSelectedTeachers([]);

      return true;
    } catch (err) {
      console.log(err, "Error updating teacher data");
    }
  };

  const postEditGroupData = async (data) => {
    await axios
      .put(`/api_v2/teachers/groups/${data.groupId}`, data)
      .then((r) => {
        if (r.status === 200) {
          props.doShowNotification(
            "success",
            `Group id: ${data.groupId} updated successfully`
          );

          let groups = schoolGroups;
          const groupToUpdate = schoolGroups.find(
            (g) => g.groupId === data.groupId
          );
          const updatedGroup = { ...groupToUpdate, ...data };

          const newRows = [...groups];
          newRows.splice(groups.indexOf(groupToUpdate), 1, updatedGroup);
          groups = newRows;
          setSchoolGroups(groups);
        } else {
          props.doShowNotification("warning", `Could not update group details`);
        }
        setEditingGroups(editingGroups.filter((g) => g !== data.groupId));
      });
  };

  const roleOptions = [
    {
      id: 3,
      value: 3,
      label: "School Admin",
      variant: "role",
    },
    {
      id: 4,
      value: 4,
      label: "Teacher",
      variant: "role",
    },
    {
      id: 5,
      value: 5,
      label: "Student",
      variant: "role",
    },
  ];

  const paymentStatusOptions = [
    { id: 1, label: "paid", value: "paid" },
    { id: 2, label: "free", value: "free" },
    { id: 3, label: "expired", value: "expd" },
  ];

  const saveNewTeacher = async (data) => {
    if (teacher && teacher.find((t) => t.email === data.email)) {
      return {
        error: "Email already exists. Please try another email address.",
      };
    } else {
      const existingData = await props.getAccountSetting(
        props.auth.teacher.schoolId,
        "rollover",
        "school"
      );
      data.removed = false;
      data.schoolId = props.auth.teacher.schoolId;
      setTeacher(
        [...teacher, data].sort((a, b) =>
          a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
        )
      );
      setCreateTeacher(false);

      if (props.isRollover)
        props.updateCacheData({
          ...existingData,
          teachers: [...existingData.teachers, data],
        });
    }
  };

  const emptyTableSkeleton = (
    <>
      <Skeleton variant="rect" />
      <hr />
      <Skeleton variant="rect" />
      <hr />
      <Skeleton variant="rect" />
      <hr />
      <Skeleton variant="rect" />
    </>
  );

  const mapTeachersToRows = (teacherList) => {
    return isEmpty(teacherList) ? (
      <TableRow>
        <TableCell colSpan={10}>
          <span>
            {loading ? emptyTableSkeleton : "No teachers in this school"}
          </span>
        </TableCell>
      </TableRow>
    ) : (
      teacherList &&
        teacherList.map((t) => {
          const teacherRole =
            roleOptions.find(
              (r) =>
                (t && t.roleId && +r.value === +t.role) ||
                (t && t.roleId && +r.value === +t.roleId)
            ) || "";
          const teacherName =
            (t.User.firstName || (t && t.User.firstName)) +
            " " +
            (t.User.lastName || (t && t.User.lastName));

          const isMe = props.auth.teacher.id === t.User.id;
          return (
            <InformationRow
              noselect={false}
              id={t?.User?.id}
              tableRowStyle={{
                backgroundColor: isMe ? "orange" : "",
              }}
              information={{
                id: t.User.id,
                schoolId: t.schoolId || schoolId,
                groupId: t.groupId,
                name: teacherName,
                email:
                  (t && t?.User?.TeachersMetadatum?.email) || (t && t?.email),
                role:
                  (t.roleId && t.roleId === 1) || (t.roleId && t.role === 1)
                    ? {
                        id: 1,
                        value: 1,
                        label: "LS Admin",
                        variant: "role",
                      }
                    : (t.roleId && t.roleId === 2) || (t.roleId && t.role === 2)
                    ? {
                        id: 2,
                        value: 2,
                        label: "Sales",
                        variant: "role",
                      }
                    : teacherRole,
                expiryDate: t.User.expiryDate
                  ? moment(t.User.expiryDate).format("YYYY-MM-DD")
                  : "N/A",
                lastLogIn: t.User.lastLogIn
                  ? moment(t.User.lastLogIn).format("YYYY-MM-DD")
                  : t && t.User.lastLogIn
                  ? moment(t.User.lastLogIn).format("YYYY-MM-DD")
                  : `N/A`,
                agreement: {
                  checkbox: true,
                  value: t.User.TeachersMetadatum
                    ? t.User.TeachersMetadatum.tc_agree
                    : false,
                },
                license: licenseTypes[t?.licenseId],
              }}
              editing={editing}
              editViewOptions={{
                name: ["text-field"],
                email: ["text-field"],
                role:
                  t.roleNumber === 1 || t.roleNumber === 2
                    ? []
                    : ["select-list", roleOptions],
              }}
              selected={selectedTeachers.includes(t?.User?.id)}
              onEditSave={(data) => postEditData({ id: t?.User?.id, ...data })}
              checkCallBack={() => updateState(t?.User?.id)}
              editCallback={() => updateState(t?.User?.id)}
              isHover={true}
              onRedirect={() => {
                props.history.push({
                  pathname: `/admin/teacher/${t?.User?.id}`,
                  state: { schoolId: +schoolId },
                });
              }}
              tags={
                PackageList.filter(
                  (pl) => +pl.value === +t.acl_group_memberships
                ) ||
                (t && +pl.value === +t.acl_group_memberships)
              }
              actionButtons={{
                loginAs: [
                  "Login As",
                  () => {
                    props.loginAs(t.userId, t.roleId);
                  },
                  {
                    type: "icon-button",
                    tooltipMessage: `Login as ${
                      (t.firstName || (t && t.firstName)) +
                      " " +
                      (t.lastName || (t && t.lastName))
                    }`,
                    icon: "fa fa-sign-in",
                  },
                ],
              }}
            />
          );
        })
    );
  };

  const generateSignupLink = async (id) => {
    setSignUpLink({ link: "", timeLeft: "" });
    await axios
      .get(`/api_v2/admin/signupToken/${id}`)
      .then(async (res) => {
        const { link } = res.data;
        const expiryTime = await decodeToken(res.data.token);

        setSignUpLink({
          link: link,
          timeLeft: expiryTime,
        });
        props.toggleModal(true);
      })
      .catch((err) => {
        console.error("Error getting signupData");
      });
  };

  const uploadSchoolList = async (data) => {
    const { schoolId } = props.match.params;
    const result = await axios.post(
      `/api_v2/admin/upload-school-data/${schoolId}`,
      data
    );
  };

  const uploadTeacherList = async (data) => {
    await axios
      .post(`/api/v3/admin/users`, {
        users: data,
        acl_group_memberships: data[0].license,
      })
      .then(async (res) => {
        const newTeachers = res.data;
        const newList = [...teacher, ...newTeachers];
        setTeacher(newList);
      });
  };

  const UnselectedHeader = () => {
    return (
      <>
        <TableCell style={{ width: 25, padding: 6 }}>
          <Checkbox
            checked={!isEmpty(selectedTeachers)}
            onChange={() => updateState(teacher.map((t) => t?.User?.id))}
          />
        </TableCell>
        <TableCell style={theme.tableHeadCell.style}>Teacher Id</TableCell>
        <TableCell style={theme.tableHeadCell.style}>School Id</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Group Id</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Name</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Email</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Role</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Expiry Date</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Last Login</TableCell>
        <TableCell style={theme.tableHeadCell.style}>T/C Agreement</TableCell>
        <TableCell style={theme.tableHeadCell.style}>License</TableCell>
        <TableCell style={theme.tableHeadCell.style}>Actions</TableCell>
      </>
    );
  };

  const SelectedHeader = () => {
    return (
      <>
        <TableCell style={{ width: 25, padding: 6 }}>
          <Checkbox
            checked={!isEmpty(selectedTeachers)}
            onChange={() => updateState(teacher.map((t) => t?.User?.id))}
          />
        </TableCell>
        <TableCell style={theme.tableHeadCell.style} colspan={10}>
          <span>
            {selectedTeachers.length} teacher
            {selectedTeachers.length > 1 ? "s" : ""} selected
          </span>
          <span>
            <Button
              onClick={() => setEditing(true)}
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-pencil mr-2"></i>{" "}
              Edit Details
            </Button>
          </span>
          <span>
            <Button
              onClick={() => {
                axios
                  .post(`/api/v4/users/send-invite`, selectedTeachers)
                  .then((res) => {
                    props.doShowNotification(
                      "success",
                      `${selectedTeachers.length} invites sent successfully`
                    );
                    setSelectedTeachers([]);
                  })
                  .catch((err) => console.log(err));
              }}
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-envelope mr-2" />{" "}
              Resend Invites
            </Button>
          </span>
          {/* <span>
            <Button
              onClick={() => {
                setShowDeleteAlert(true);
              }}
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-trash mr-2"></i>{" "}
              Delete Teacher
              {selectedTeachers.length > 1 ? "s" : ""}
            </Button>
          </span> */}
          <span>
            <Button
              onClick={() => setSelectedTeachers([])}
              variant="contained"
              color="secondary"
              style={{ marginLeft: 20 }}
            >
              <i style={{ fontSize: 18 }} className="fa fa-close mr-2"></i>{" "}
              Cancel
            </Button>
          </span>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <div className="row m-1">
        <Card className="col-12 mb-2 mr-2">
          <CardHeader
            title={`School Details: ${school ? school.schoolName : ""}`}
          />
          <CardContent>
            {school && (
              <div className="row">
                <div className="col-4">State: {school.state}</div>
                <div className="col-4">School ID: {school.schoolId}</div>
                <div className="col-4">Student Limit: {school.maxStudents}</div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      <div>
        <Card className="col-12">
          <div className="row">
            <CardHeader title={`School Groups' Details`} />
            <Button
              onClick={async () => {
                const result = await props.createNewGroup({
                  schoolId: school.schoolId,
                  schoolName: school.schoolName,
                });
                if (result.newGroup && result.newGroup.groupId) {
                  setSchoolGroups([...schoolGroups, result.newGroup]);
                }
              }}
              color="primary"
            >
              Create New Group
            </Button>
          </div>
          <CardContent>
            <div className="col-12">
              <Table>
                <TableHead>
                  <TableCell style={theme.tableHeadCell.style} />
                  <TableCell style={theme.tableHeadCell.style}>
                    Group Id
                  </TableCell>
                  <TableCell style={theme.tableHeadCell.style}>
                    Nickname
                  </TableCell>
                  <TableCell style={theme.tableHeadCell.style}>
                    Expiry Date
                  </TableCell>
                  <TableCell style={theme.tableHeadCell.style}>
                    Valid From
                  </TableCell>
                  <TableCell style={theme.tableHeadCell.style}>
                    Valid To
                  </TableCell>
                  <TableCell style={theme.tableHeadCell.style}>
                    Actions
                  </TableCell>
                </TableHead>
                <TableBody>
                  {schoolGroups &&
                    schoolGroups.map((sg) => {
                      return (
                        <InformationRow
                          id={sg.groupId}
                          information={{
                            groupId: sg.groupId,
                            nickname: sg.nickname,
                            expiryDate: sg.expiryDate
                              ? moment(sg.expiryDate).format("YYYY-MM-DD")
                              : null,
                            validFrom: sg.validFrom
                              ? moment(sg.validFrom).format("YYYY-MM-DD")
                              : null,
                            validTo: sg.validTo
                              ? moment(sg.validTo).format("YYYY-MM-DD")
                              : null,
                          }}
                          editing={editingGroups.includes(sg.groupId)}
                          editViewOptions={{
                            nickname: ["text-field"],
                            expiryDate: ["date-field"],
                            validFrom: ["date-field"],
                            validTo: ["date-field"],
                            paymentStatus: [
                              "select-list",
                              paymentStatusOptions,
                            ],
                          }}
                          noselect={true}
                          onEditSave={(data) =>
                            postEditGroupData({
                              groupId: sg.groupId,
                              ...data,
                            })
                          }
                          editCallback={() => {
                            setEditingGroups(
                              editingGroups.filter((g) => g !== sg.groupId)
                            );
                          }}
                          actionButtons={{
                            // onEdit: [
                            //   "Edit School",
                            //   () => {
                            //     setEditingGroups([
                            //       ...editingGroups,
                            //       +sg.groupId,
                            //     ]);
                            //   },
                            //   {
                            //     type: "icon-button",
                            //     tooltipMessage: `Edit ${sg.groupId}`,
                            //     icon: "fa fa-edit",
                            //   },
                            // ],
                            // onDownloadUsageReport: [
                            //   "Download Usage Report",
                            //   () => {
                            //     setIsDateSelectorModalOpen(true);
                            //     setSelectedGroup(sg);
                            //   },
                            //   {
                            //     type: "icon-button",
                            //     tooltipMessage: `Download Usage Report for groupId ${sg.groupId}`,
                            //     icon: "fa fa-file",
                            //   },
                            // ],
                            onSendNotification: [
                              "Send Notification to Group Teachers",
                              () => {
                                props.toggleModal(
                                  true,
                                  "sendgroupnotification"
                                );
                                setSelectedGroup(sg);
                              },
                              {
                                type: "icon-button",
                                tooltipMessage: `Send notification to teachers in groupId ${sg.groupId}`,
                                icon: "fa fa-bell",
                              },
                            ],
                            onSendEmail: [
                              "Send Email to Group Teachers",
                              () => {
                                props.toggleModal(true, "sendgroupemail");
                                setSelectedGroup(sg);
                              },
                              {
                                type: "icon-button",
                                tooltipMessage: `Send email to teachers in groupId ${sg.groupId}`,
                                icon: "fa fa-envelope",
                              },
                            ],
                          }}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="row mt-4">
        <div className="col-3 col-lg-2">
          <span>
            <Button
              className="mb-2"
              title="Create Teacher"
              color="primary"
              variant="contained"
              onClick={() => setCreateTeacher(true)}
              data-pagename="MY_SCHOOL"
              data-objectname="ADD_NEW_TEACHER"
            >
              <i className="fa fa-plus mr-2" style={{ lineHeight: "1.7" }}></i>{" "}
              Create Teacher
            </Button>
          </span>
        </div>
        <div className="col-3 col-lg-3">
          <span>
            <Button
              title="Update School Info"
              color="primary"
              variant="contained"
              onClick={() => {
                console.log(schoolId, schoolGroups);
                props.toggleModal(true, "schooldetailsupdate");
              }}
            >
              <i
                className="fa fa-pencil mr-2"
                style={{ lineHeight: "1.7" }}
              ></i>{" "}
              Update School License and Expiry
            </Button>
          </span>
        </div>
        <div className="col-3">
          {schoolId && (
            <NewTeacher
              open={createTeacher}
              onClose={() => setCreateTeacher(false)}
              saveTeacher={saveNewTeacher}
              schoolId={schoolId}
              schoolGroups={schoolGroups}
              onAddCallback={(data) => {
                setTeacher(
                  [...teacher, data].sort((a, b) =>
                    a.firstName &&
                    b.firstName &&
                    a.firstName.toLowerCase() > b.firstName.toLowerCase()
                      ? 1
                      : -1
                  )
                );
              }}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          Bulk edit, resend invitations and more actions are available by
          selecting the checkboxes next to the teacher name.
          <Paper
            style={{
              marginTop: 10,
              marginBottom: 10,
              maxHeight: "75vh",
              minHeight: "25vh",
              overflowY: "scroll",
            }}
          >
            <div>
              School Users: {teacher?.length}
              <Table stickyHeader size="small" aria-label="simple table">
                <TableHead>
                  {isEmpty(selectedTeachers) ? (
                    <UnselectedHeader />
                  ) : (
                    <SelectedHeader />
                  )}
                </TableHead>
                <TableBody>{mapTeachersToRows(teacher)}</TableBody>
              </Table>
            </div>
          </Paper>
        </div>
        <ConfirmDialog
          severity="error"
          question={`Delete teacher${
            selectedTeachers.length === 1 ? "" : "s"
          }?`}
          message={`Deleting ${
            selectedTeachers.length === 1 ? "this teacher" : "these teachers"
          } will remove their access to Life Skills GO.`}
          open={showDeleteAlert ? true : false}
          onClose={() => setShowDeleteAlert(false)}
          confirmCallback={async () => {
            await axios
              .put(`api/v3/users/delete`, selectedTeachers)
              .then(() => {
                teacher &&
                  setTeacher(
                    teacher.filter((t) =>
                      selectedTeachers.some(
                        (selected) => selected !== t?.User?.id
                      )
                    )
                  );
                setSelectedTeachers([]);
                setShowDeleteAlert(false);
              })
              .catch((err) => console.log(err));
          }}
          confirmName="Delete"
          dismissName="Cancel"
        ></ConfirmDialog>
        <Dialog
          maxWidth="sm"
          open={props.ui?.dashboardModal === "schooldetailsupdate"}
          fullWidth={true}
          fullScreen={false}
          onClose={() => {
            props.toggleModal(false, "s");
          }}
          style={{ minHeight: "50vh" }}
        >
          <DialogContent>
            <div>
              <Typography>Update details for {school?.schoolName}</Typography>
              <div className="col">
                <div className="row" style={{ marginTop: 20 }}>
                  <Typography style={{ marginRight: 20 }}>Group: </Typography>
                  <Select
                    id="grades-selector-multiple"
                    value={dataToUpdateForGroup?.groupId}
                    onChange={(e) => {
                      setDataToUpdateForGroup({
                        ...dataToUpdateForGroup,
                        groupId: e.target.value,
                      });
                    }}
                    variant="standard"
                  >
                    {schoolGroups?.map((group) => (
                      <MenuItem
                        key={group.groupId}
                        value={group.groupId}
                        id={group.groupId}
                      >
                        <ListItemText primary={group.groupId} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {dataToUpdateForGroup?.groupId && (
                  <>
                    {" "}
                    <div className="row" style={{ marginTop: 20 }}>
                      <Typography style={{ marginRight: 20 }}>
                        License:{" "}
                      </Typography>
                      <Select
                        id="grades-selector-multiple"
                        value={dataToUpdateForGroup.acl_group_memberships}
                        onChange={(e) => {
                          setDataToUpdateForGroup({
                            ...dataToUpdateForGroup,
                            acl_group_memberships: e.target.value,
                          });
                        }}
                        variant="standard"
                      >
                        {teacherCamapign?.map((license) => (
                          <MenuItem
                            key={license.id}
                            value={license.id}
                            id={license.id}
                          >
                            <ListItemText primary={license.value} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="row" style={{ marginTop: 20 }}>
                      <Typography style={{ marginRight: 20 }}>
                        Expiry Date:{" "}
                        <DatePicker
                          dateFormat="YYYY-MM-DD"
                          value={
                            dataToUpdateForGroup.expiryDate ||
                            moment().format("YYYY-MM-DD")
                          }
                          onChange={(e) => {
                            setDataToUpdateForGroup({
                              ...dataToUpdateForGroup,
                              expiryDate: moment(e).format("YYYY-MM-DD"),
                            });
                          }}
                        />
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              type="primary"
              onClick={() => props.toggleModal(false, "s")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="primary"
              onClick={async () => {
                const result =
                  dataToUpdateForGroup?.groupId && school?.schoolId
                    ? await axios.put(
                        `/api/int/schools/${school.schoolId}/groups/${dataToUpdateForGroup.groupId}/update-access`,
                        dataToUpdateForGroup
                      )
                    : "";
                console.log(result, "result", dataToUpdateForGroup, school);
                props.toggleModal(false, "s");
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Modal closeCallback={closeModal}>{modalContent()}</Modal> */}
        {/* <DateRangeSelector
          open={isDateSelectorModalOpen}
          handleClose={() => setIsDateSelectorModalOpen(false)}
          startDate={startDate}
          endDate={endDate}
          setSelectedDates={(start, end) => {
            updateStartDate(start);
            updateEndDate(end);
            downloadReport(start, end);
          }}
          groupId={selectedGroup.groupId}
        /> */}
        <Dialog
          maxWidth="sm"
          open={props.ui?.dashboardModal === "sendgroupnotification"}
          fullWidth={true}
          fullScreen={false}
          onClose={() => {
            props.toggleModal(false, "s");
          }}
        >
          <DialogContent>
            <Typography>
              Send notifcation to Group Id {selectedGroup?.groupId}
            </Typography>
            <ExistingNotificationForm school={school} group={selectedGroup} />
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth="sm"
          open={props.ui?.dashboardModal === "sendgroupemail"}
          fullWidth={true}
          fullScreen={false}
          onClose={() => {
            props.toggleModal(false, "s");
          }}
        >
          <DialogContent>
            <Typography>
              Send email to Group Id {selectedGroup?.groupId}
            </Typography>
            <PlatformEmails school={school} group={selectedGroup} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSchool: (schoolId, userType) => dispatch(getSchool(schoolId, userType)),
    createNewGroup: (schoolId) => dispatch(createNewGroup(schoolId)),
    sendInvite: (data) => dispatch(reSendInvite(data)),
    loginAs: (id, teacherRole) => dispatch(loginAs(id, teacherRole)),
    removeTeacher: (id) => dispatch(removeTeacher(id)),
    toggleModal: (state, nam) => dispatch(toggleModal(state, nam)),
    updateTeacherRole: (data) => dispatch(updateTeacherRole(data)),
    showNotification: (type, content) =>
      dispatch(showNotification(type, content)),
    doShowNotification: (type, content) =>
      dispatch(showNotification(type, content)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDetails);
