import React, { useState, useEffect } from 'react';
import axios from 'config/axios';
import { connect } from 'react-redux';
import { createLessonGroup } from 'store/actions/learningActions';

import isEmpty from 'validation/isEmpty';

import Focus from 'components/Admin/FocusLessonGroups';

import AreaMenu from 'components/LearningContent/AreaMenu';

const LessonGroupManager = (props) => {
    const [foci, setFoci] = useState([]);
    const [activeArea, setActiveArea] = useState(null);

    useEffect(() => {
        const fetchFoci = async () => {
            const result = await axios.get('/api_v2/admin/focus').then((r) => {
                return r.data;
            });
            setFoci(result);
            setActiveArea(result[0]);
        };
        fetchFoci();
    }, []);

    const mapLearningAreasTitles = () => {
        return (
            <div className='card-deck'>
                <AreaMenu
                    areas={foci}
                    activeArea={activeArea}
                    onLearningAreaClick={(area) => {
                        setActiveArea(area);
                    }}
                />
            </div>
        );
    };

    const mapFocus = () => {
        const { focus, areaName } = activeArea;

        //return no data message (this one should be a component)
        if (isEmpty(focus)) {
            return (
                <div className='col-12'>
                    <p>
                        There is no qualities set up for <b>{areaName}</b> one yet, click the "Add Quality" button{' '}
                    </p>
                </div>
            );
        }

        //get the list
        return focus.map((item) => {
            return (
                <Focus
                    key={item.focusId}
                    {...item}
                    saveFocusName={() => {}}
                    removeFocus={() => {}}
                    addLessonGroup={(data) => {
                        props.createLessonGroup({ name: data.lessonName, focus: item.focusId });
                    }}
                />
            );
        });
    };

    return !isEmpty(foci) && !isEmpty(activeArea) ? (
        <>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box'>
                        <h4 className='page-title'>Lesson Group Manager</h4>
                    </div>
                </div>
            </div>
            <div className='row no-padding'>
                <div className='col-12'>
                    <div className='mb-3'>
                        <div className='row'>{mapLearningAreasTitles()}</div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6'>{/* <FocusForm addFocus={this.addFocus} /> */}</div>
            </div>
            <div className='row'>{mapFocus()}</div>
        </>
    ) : (
        <div>Lesson Group Manager Loading ...</div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        createLessonGroup: (data) => dispatch(createLessonGroup(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonGroupManager);
