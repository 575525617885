import React, { Fragment } from 'react';
import './Spinner.css';

/**
 *@returns JSX
 */
const spinner = props => (
  <Fragment>
    <div className="Loader"></div>
    {props.message && <p className="text-center">{props.message}</p>}
  </Fragment>
);

export default spinner;
