import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSchools, addSchool } from "store/actions/adminActions";
import {
  Dialog,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  FormControl,
  Button,
  Chip,
  FormControlLabel,
  Checkbox,
  TablePagination,
  Paper,
  DialogContent,
} from "@material-ui/core";
import { theme } from "assets/customisedMUI";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import SchoolForm from "../../components/Schools/SchoolFormNew";
import EditSchoolForm from "../../components/Schools/EditSchoolForm";
import { removeSchool } from "store/actions/schoolActions";
import { updateSchool } from "store/actions/adminActions";
import { showNotification, toggleModal } from "store/actions/uiActions";
import isEmpty from "../../validation/isEmpty";

import CustomColors from "assets/customisedMUI/CustomColors";
import SearchFieldFilter from "components/Common/SearchFieldFilter";
import axios from "config/axios";
import "./School.scss";
import ExistingNotificationForm from "containers/Notifications/ExistingNotificationForm";
import PlatformEmails from "containers/Notifications/PlatformEmails";

const Schools = (props) => {
  const [schoolList, setSchoolList] = useState([]);
  const [filters, setFilters] = useState({
    salesTeamMember: [],
    paymentStatus: [],
    activityStatus: [2, 3],
    integration_provider: ["sentral", "schoolBytes", "other"],
  });
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [editing, setEditing] = useState(false);
  const [toUpdate, setToUpdate] = useState(null);
  const [salesTeam, setSalesTeam] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [datesForUsage, setDatesForUsage] = useState({
    startDate: moment()?.subtract(14, "days")?.format("YYYY-MM-DD hh:mm:ss"),
    endDate: moment()?.format("YYYY-MM-DD hh:mm:ss"),
  });
  const [isEditSchool, setIsEditSchool] = useState({
    open: false,
    school: null,
  });

  const [searchName, setSearchName] = useState({ searchTerm: "" });

  const availableSchools =
    schoolList &&
    schoolList
      .filter((s) => {
        return (
          (!searchName ||
            searchName.searchTerm === "" ||
            s.searchTerm
              .toLowerCase()
              .includes(searchName.searchTerm.toLowerCase())) &&
          (!searchName ||
            !searchName.schoolId ||
            searchName.schoolId === s.schoolId)
        );
      })
      .filter((item) =>
        !isEmpty(filters.activityStatus)
          ? filters.activityStatus.includes(item.status)
          : true
      )
      .filter((item) =>
        !isEmpty(filters.integration_provider)
          ? filters.integration_provider.includes(item.integration_provider) ||
            (filters?.integration_provider?.includes("other")
              ? !["sentral", "schoolBytes"]?.includes(item.integration_provider)
              : false)
          : true
      );

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: `salesTeam-selector-custom-hook`,
    defaultValue: [],
    multiple: true,
    options: salesTeam,
    getOptionLabel: (option) => option.label,
    onChange: (event, newValue) => {
      setFilters({
        ...filters,
        salesTeamMember: newValue.map((item) => item.value),
      });
    },
    disableCloseOnSelect: true,
  });

  useEffect(() => {
    const fetchSchoolData = async () => {
      const result = await props.getSchools();
      setSchoolList(
        result?.map((school) => {
          return {
            ...school,
            searchTerm:
              school.schoolName + ", " + school.suburb + ", " + school.state,
          };
        })
      );
    };
    fetchSchoolData();
  }, []);

  useEffect(() => {
    const fetchSalesTeam = async () => {
      const options = await axios.get("/api_v2/options/all");
      const team =
        options &&
        options.data &&
        options.data.find((opt) => opt.title === "salesTeam");
      setSalesTeam(JSON.parse(team.option));
    };
    fetchSalesTeam();
  }, []);

  const paymentStatus = [
    { label: "paid", value: "paid" },
    { label: "free", value: "free" },
    { label: "expired", value: "expd" },
  ];

  const activityStatus = [
    { label: "inactive", value: 1 },
    { label: "active but not searchable", value: 2 },
    { label: "active and searchable", value: 3 },
  ];

  const integration_provider = [
    { label: "Sentral", value: "sentral" },
    { label: "School Bytes", value: "schoolBytes" },
    { label: "Other", value: "other" },
  ];

  // Separate useEffect callback to handle state update (useCallback had memoised state and would reinstate initial values)
  useEffect(() => {
    if (toUpdate) {
      if (!selectedSchools.includes(toUpdate)) {
        setSelectedSchools([...selectedSchools, toUpdate]);
      } else {
        setSelectedSchools(selectedSchools.filter((sid) => sid !== toUpdate));
      }
      setToUpdate(null);
    }
  }, [toUpdate]);

  const updateState = React.useCallback(
    (schoolId) => {
      setToUpdate(schoolId);
    },
    [setToUpdate]
  );

  useEffect(() => {
    if (isEmpty(selectedSchools)) {
      setEditing(false);
    }
  }, [selectedSchools]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const postEditData = (schoolId, data) => {
    try {
      props.updateSchool({ ...data, schoolId }).then((res) => {
        const school = schoolList.find((s) => +s.schoolId === +schoolId);

        const newSchool = {
          ...school,
          ...(res.schoolName != null && { schoolName: res.schoolName }),
          maxStudents: res.all_school_account.maxStudents || school.maxStudents,
          paymentStatus:
            res.all_school_account.paymentStatus || school.paymentStatus,
          salesTeamMember: res.salesTeamMember || school.salesTeamMember,
        };
        const newRows = [...schoolList];

        newRows.splice(
          schoolList.findIndex(
            (item) => +item.schoolId === +newSchool.schoolId
          ),
          1,
          newSchool
        );

        setSchoolList(newRows);

        setIsEditSchool({ open: false, school: {} });
      });

      return schoolId;
    } catch (err) {
      console.log("Error editing the template.", err);
    }
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const downloadUsageReport = async (e) => {
    const usageReport = await axios
      .get(
        `/api/int/schools/internal-usage-report/?startDate=${datesForUsage.startDate}&endDate=${datesForUsage.endDate}`
      )
      ?.then((r) => r.data);

    e.preventDefault();
    downloadFile({
      data: usageReport,
      fileName: "usage.csv",
      fileType: "text/csv",
    });
  };

  const mapSchoolsToRows = (data) => {
    const sliceEnd =
      rowsPerPage === -1 ? data.length + 1 : page * rowsPerPage + rowsPerPage;
    return data.slice(page * rowsPerPage, sliceEnd).map((s) => {
      const active = [2, 3].includes(s.status);

      return (
        <TableRow className="school-row">
          <Checkbox
            checked={selectedSchools.includes(s.schoolId)}
            onChange={() => {
              updateState(s.schoolId);
            }}
          />
          <TableCell
            onClick={() =>
              [2, 3].includes(s.status) &&
              props.history.push(`/admin/school/${s.schoolId}`)
            }
            style={{ backgroundColor: !active && "lightgrey" }}
          />
          <TableCell
            onClick={() =>
              [2, 3].includes(s.status) &&
              props.history.push(`/admin/school/${s.schoolId}`)
            }
            style={{ backgroundColor: !active && "lightgrey" }}
          >
            <Typography variant="body1">{s.schoolId}</Typography>
          </TableCell>
          <TableCell
            onClick={() =>
              [2, 3].includes(s.status) &&
              props.history.push(`/admin/school/${s.schoolId}`)
            }
            style={{ backgroundColor: !active && "lightgrey" }}
          >
            <div className="col-12">
              <Typography variant="h6">
                {s.schoolName} -{" "}
                <div>
                  {s.paymentStatus && (
                    <span style={{ margin: 10 }}>
                      <Chip
                        size="small"
                        color="primary"
                        label={s.paymentStatus}
                      />
                    </span>
                  )}
                  {s.integration_provider && (
                    <span>
                      <Chip
                        size="small"
                        color="secondary"
                        label={s.integration_provider}
                      />
                    </span>
                  )}
                </div>
              </Typography>
            </div>
          </TableCell>
          <TableCell style={{ backgroundColor: !active && "lightgrey" }}>
            <Button
              color={active ? "secondary" : "primary"}
              disabled={active}
              variant="contained"
              onClick={async () => {
                const result = await props.addSchool(s);
                if (result.schoolId && +result.schoolId === +s.schoolId) {
                  let newSchoolData = [...schoolList];
                  const objIndex = newSchoolData.findIndex(
                    (school) => school.schoolId == s.schoolId
                  );

                  newSchoolData[objIndex] = { ...s, ...result };
                  setSchoolList(newSchoolData);
                }
              }}
            >
              {active ? "Active" : "Activate School"}
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-6">
          <SchoolForm
            open={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            addSchool={addSchool}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <Dialog open={isEditSchool.open} maxWidth="md" fullWidth>
            <EditSchoolForm
              handleClose={() => setIsEditSchool({ open: false, school: null })}
              addSchool={(v) => postEditData(v.schoolId, v)}
              school={isEditSchool.school}
            />
          </Dialog>
        </div>
      </div>
      {/* Disabled Access older data - until further discussion */}
      <Paper>
        <SearchFieldFilter
          options={schoolList}
          nameParameter="searchTerm"
          placeholder="Search schools..."
          setSearchValue={setSearchName}
          onAddClick={(inputValue) => {
            setIsModalOpen(true);
          }}
          renderOption={(option) => {
            const optionName = option.searchTerm;
            if (typeof option === "object") {
              if (option.inputValue) {
                return (
                  <div
                    style={{ display: "block", width: "100%", padding: "8px" }}
                  >
                    <span
                      style={{
                        float: "left",
                        color: "#13b5ea",
                      }}
                    >
                      {optionName}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{ display: "block", width: "100%", padding: "8px" }}
                  >
                    <span style={{ float: "left", marginRight: "16px" }}>
                      {optionName}
                    </span>
                  </div>
                );
              }
            } else {
              return optionName;
            }
          }}
        >
          <Typography variant="h6">Filter Schools</Typography>
          <div className="row text-center">
            <div className="col-12">
              <div style={{ textAlign: "center", margin: 10 }}>
                <Typography variant="h6">Integration Provider</Typography>
              </div>
              <FormControl>
                <div className="row">
                  {integration_provider.map((cat) => {
                    return (
                      <FormControlLabel
                        label={<span>{cat.label}</span>}
                        control={
                          <Checkbox
                            checked={filters.integration_provider.includes(
                              cat.value
                            )}
                            onChange={() => {
                              const arr = filters.integration_provider;
                              setFilters({
                                ...filters,
                                integration_provider: arr?.includes(cat.value)
                                  ? arr.filter((item) => item !== cat.value)
                                  : [...arr, cat.value],
                              });
                            }}
                            color="primary"
                          />
                        }
                      />
                    );
                  })}
                </div>
              </FormControl>
            </div>
            <div className="col-12">
              <div style={{ textAlign: "center", margin: 10 }}>
                <Typography variant="h6">School Status</Typography>
              </div>
              <FormControl>
                <div className="row">
                  {activityStatus.map((cat) => {
                    return (
                      <FormControlLabel
                        label={<span>{cat.label}</span>}
                        control={
                          <Checkbox
                            checked={filters.activityStatus.includes(cat.value)}
                            onChange={() => {
                              const arr = filters.activityStatus;
                              setFilters({
                                ...filters,
                                activityStatus: arr.includes(cat.value)
                                  ? arr.filter((item) => item !== cat.value)
                                  : [...arr, cat.value],
                              });
                            }}
                            color="primary"
                          />
                        }
                      />
                    );
                  })}
                </div>
              </FormControl>
            </div>
          </div>
        </SearchFieldFilter>
      </Paper>
      <div className="row mt-3">
        <div className="col-12">
          <div
            id="myGrid"
            className="ag-theme-balham"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                overflowY: "scroll",
                width: "100%",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[50, 100, 250, { value: -1, label: "All" }]}
                component="div"
                count={availableSchools && availableSchools.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${to === -1 ? "All" : from + "-" + to} of ${
                    count !== -1 ? count : 0
                  }`
                }
              />
              <div className="row">
                <div className="col-3">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isEmpty(selectedSchools)}
                    onClick={() => props.toggleModal(true, "sendgroupemail")}
                  >
                    Send Emails
                  </Button>
                </div>
                <div className="col-9" style={{ textAlign: "right" }}>
                  <div style={{ margin: 10 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="mt-2"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        id={`date-picker-startdate`}
                        value={datesForUsage.startDate}
                        onChange={(date) =>
                          setDatesForUsage({
                            ...datesForUsage,
                            startDate: moment(date)
                              .startOf("day")
                              .format("YYYY-MM-DD"),
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <span
                      className="mx-2 mt-4"
                      style={{
                        fontWeight: 700,
                        fontSize: "0.875",
                        lineHeight: "1.75",
                      }}
                    >
                      -
                    </span>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="mt-2"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        id={`date-picker-enddate`}
                        value={datesForUsage.endDate}
                        onChange={(date) =>
                          setDatesForUsage({
                            ...datesForUsage,
                            endDate: moment(date)
                              .endOf("day")
                              .format("YYYY-MM-DD"),
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={async (e) => await downloadUsageReport(e)}
                  >
                    Download Usage Report for Customers
                  </Button>
                </div>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 25, padding: 6 }}>
                      <Checkbox
                        checked={!isEmpty(selectedSchools)}
                        onChange={() => {
                          setSelectedSchools(
                            availableSchools?.map((s) => s.schoolId)
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell style={theme.tableHeadCell.style}></TableCell>
                    <TableCell style={theme.tableHeadCell.style}>
                      School Id
                    </TableCell>
                    <TableCell style={theme.tableHeadCell.style}>
                      School Name
                    </TableCell>
                    <TableCell style={theme.tableHeadCell.style}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                {availableSchools && (
                  <TableBody>{mapSchoolsToRows(availableSchools)}</TableBody>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[50, 100, 250, { value: -1, label: "All" }]}
                component="div"
                count={availableSchools && availableSchools.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${to === -1 ? "All" : from + "-" + to} of ${
                    count !== -1 ? count : 0
                  }`
                }
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        maxWidth="sm"
        open={props.ui?.dashboardModal === "sendgroupemail"}
        fullWidth={true}
        fullScreen={false}
        onClose={() => {
          props.toggleModal(false, "s");
        }}
      >
        <DialogContent>
          <Typography>Send email to Schools</Typography>
          <PlatformEmails school={selectedSchools} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  ui: state.ui,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSchools: () => dispatch(getSchools()),
    addSchool: (data) => dispatch(addSchool(data)),
    removeSchool: (schoolId) => dispatch(removeSchool(schoolId)),
    doShowNotification: (type, content) =>
      dispatch(showNotification(type, content)),
    updateSchool: (data) => dispatch(updateSchool(data)),
    toggleModal: (state, nam) => dispatch(toggleModal(state, nam)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Schools));
