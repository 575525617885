import React, { useEffect, useState } from "react";
import axios from "config/axios";
import { QrCode } from "@lsgo/lsgo-fe";
import { Typography } from "@material-ui/core";
import AuthCode from "react-auth-code-input";
import "./otp.scss";
import { API } from "config/axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const CODE_SETTINGS = { length: 6 };

const OTP = (props) => {
  const [otpState, setOTPState] = useState({ mode: "register", otpUrl: "" });
  const [result, setResult] = useState("");

  if (props.tfaAuthenticated) {
    const searchParams = new URLSearchParams(props.location.search);
    props.history.push(
      searchParams.get("redirectUri")
        ? searchParams.get("redirectUri")
        : "/admin/schools"
    );
  }
  const handleCodeChange = (code) => {
    setResult(code);
    if (code.length === CODE_SETTINGS.length) {
      API.verify2FACode(code)
        .then((res) => {
          const searchParams = new URLSearchParams(props.location.search);
          props.history.push(
            searchParams.get("redirectUri")
              ? searchParams.get("redirectUri")
              : "/admin/schools"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    axios
      .post(`/api/v3/auth/2fa/register`)
      .then((res) => {
        setOTPState({ ...otpState, otpUrl: res.data.otpauth_url });
      })
      .catch((err) => {
        setOTPState({ ...otpState, mode: "verify" });
      });
  }, []);

  return (
    <div className="otp-container">
      <Typography variant="h4">
        {otpState.mode === "register"
          ? "Register for 2FA"
          : "Enter your 2FA code"}
      </Typography>
      {otpState.mode === "register" && <QrCode storedValue={otpState.otpUrl} />}
      <AuthCode
        length={CODE_SETTINGS.length}
        onChange={handleCodeChange}
        containerClassName="container"
        inputClassName="input"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tfaAuthenticated: state.auth && state.auth.teacher.tfaAuthenticated,
  };
};

export default connect(mapStateToProps)(withRouter(OTP));
