import React from "react";
import Sun from "../../assets/images/student/weather/Sun.gif";
import Storm from "../../assets/images/student/weather/Storm.gif";
import Rainfall from "../../assets/images/student/weather/Rainfall.gif";
import Rainbow from "../../assets/images/student/weather/Rainbow.gif";
import Cloudy from "../../assets/images/student/weather/Cloudy.gif";

import Storm_Argumentative from "../../assets/images/student/weather/Storm_Argumentative.gif";
import Stormy_Scheming_Sly from "../../assets/images/student/weather/Stormy_Scheming_Sly.gif";
import Storm_Frustration from "../../assets/images/student/weather/Storm_Frustration.gif";
import Rainfall_Anxious_Trepidatious from "../../assets/images/student/weather/Rainfall_Anxious_Trepidatious.gif";
import Rainfall_Nervous from "../../assets/images/student/weather/Rainfall_Nervous_Anxiety.gif";
import Rainfall_Impatient from "../../assets/images/student/weather/Rainfall_Impatient.gif";
import Rainfall_Tired from "../../assets/images/student/weather/Rainfall_Tired.gif";
import Rainfall_Hurt from "../../assets/images/student/weather/Rainfall_Hurt.gif";
import Rainfall_Miserable from "../../assets/images/student/weather/Rainfall_Miserable.gif";
import Cloudy_Bored from "../../assets/images/student/weather/Cloudy_Bored.gif";
import Partly_Quiet from "../../assets/images/student/weather/Partly_Quiet.gif";
import Partly_Rainfall_Stormy_Busy from "../../assets/images/student/weather/Partly_Rainfall_Stormy_Busy.gif";
import Rainbow_Sunny_Partly_Calm from "../../assets/images/student/weather/Rainbow_Sunny_Partly_Calm.gif";
import Cloudy_Cautious from "../../assets/images/student/weather/Cloudy_Cautious.gif";
import Sun_Happy from "../../assets/images/student/weather/Sun_Happy.gif";
import Sun_Content from "../../assets/images/student/weather/Sun_Content.gif";
import Rainbow_Arrogance from "../../assets/images/student/weather/Rainbow_Arrogance.gif";
import Rainbow_Energized from "../../assets/images/student/weather/Rainbow_Energized.gif";
import Rainbow_Excited_Awe from "../../assets/images/student/weather/Rainbow_Excited_Awe.gif";
import Rainbow_Pride from "../../assets/images/student/weather/Rainbow_Pride.gif";
import Rainbow_Silly from "../../assets/images/student/weather/Rainbow_Silly.gif";
import Rainbow_confident from "../../assets/images/student/weather/Rainbow_confident.gif";
import GalaxyGirl from "../../assets/images/student/weather/galaxy_girl.png";
import FireGirl from "../../assets/images/student/weather/Fire_Girl.png";
import Sun_Lighter from "../../assets/images/student/Sun_Lighter.png";

import Partly_Peaceful from "../../assets/images/student/weather/Partly_Peaceful.gif";
import Partly_Hopeful from "../../assets/images/student/weather/Partly_Hopeful.gif";
import Partly_Safe from "assets/images/student/weather/Partly_Safe.gif";
import Partly_Shy from "assets/images/student/weather/Partly_Shy.gif";
import Rainbow_Cheerful from "assets/images/student/weather/Rainbow_Cheerful.gif";
import Rainbow_Loved from "assets/images/student/weather/Rainbow_Loved.gif";
import Rainbow_Overjoyed from "../../assets/images/student/weather/Rainbow_Overjoyed.gif";
import Rainbow_Surprised from "../../assets/images/student/weather/Rainbow_Surprised.gif";
import Rainfall_Annoyed from "../../assets/images/student/weather/Rainfall_Annoyed.gif";
import Rainfall_Embarassed from "../../assets/images/student/weather/Rainfall_Embarassed.gif";
import Rainfall_Lonely from "../../assets/images/student/weather/Rainfall_Lonely.gif";
import Rainfall_Sad from "../../assets/images/student/weather/Rainfall_Sad.gif";
import Rainfall_Scared from "../../assets/images/student/weather/Rainfall_Scared.gif";
import Rainfall_Uncomfortable from "../../assets/images/student/weather/Rainfall_Uncomfortable.gif";
import Rainfall_Worried from "../../assets/images/student/weather/Rainfall_Worried.gif";
import Stormy_Angry from "../../assets/images/student/weather/Stormy_Angry.gif";
import Stormy_Ashamed from "../../assets/images/student/weather/Stormy_Ashamed.gif";
import Stormy_Cranky from "../../assets/images/student/weather/Stormy_Cranky.gif";
import Stormy_Disappointed from "../../assets/images/student/weather/Stormy_Disappointed.gif";
import Stormy_Frightened from "../../assets/images/student/weather/Stormy_Frightened.gif";
import Stormy_Jealous from "../../assets/images/student/weather/Stormy_Jealous.gif";
import Sunny_Brave from "../../assets/images/student/weather/Sunny_Brave.gif";
import Sunny_Comfortable from "../../assets/images/student/weather/Sunny_Comfortable.gif";
import Sunny_Friendly from "../../assets/images/student/weather/Sunny_Friendly.gif";
import Sunny_Grateful from "../../assets/images/student/weather/Sunny_Grateful.gif";
import Sunny_Kind from "../../assets/images/student/weather/Sunny_Kind.gif";

import VideoActivity from "./VideoActivity";

import { WEATHER_REPORT, TYPES } from "../../constants/activityTypes";
import withActivityAnalytics from "../../hoc/WithActivityAnalytics";
import isEmpty from "../../validation/isEmpty";

import {
  WHEATHER_CHECKIN_DB_ID,
  WHEATHER_REPORT_DB_ID,
} from "../../constants/activityTypes";

import ReactGA from "react-ga";
import { STUDENTS } from "../../constants/googleAnalytics";
import LoadingDots from "../UI/LoadingDots";

class WeatherReportActivity extends React.Component {
  constructor(props) {
    super(props);

    this.images = {
      Sun: Sun,
      Storm: Storm,
      Rainfall: Rainfall,
      Cloudy: Cloudy,
      Rainbow: Rainbow,
      Storm_Argumentative: Storm_Argumentative,
      Stormy_Scheming_Sly: Stormy_Scheming_Sly,
      Storm_Frustration: Storm_Frustration,
      Rainfall_Anxious_Trepidatious: Rainfall_Anxious_Trepidatious,
      Rainfall_Nervous: Rainfall_Nervous,
      Rainfall_Impatient: Rainfall_Impatient,
      Rainfall_Tired: Rainfall_Tired,
      Rainfall_Hurt: Rainfall_Hurt,
      Rainfall_Miserable: Rainfall_Miserable,
      Cloudy_Bored: Cloudy_Bored,
      Cloudy_Cautious: Cloudy_Cautious,
      Partly_Rainfall_Stormy_Busy: Partly_Rainfall_Stormy_Busy,
      Partly_Quiet: Partly_Quiet,
      Sun_Happy: Sun_Happy,
      Sun_Content: Sun_Content,
      // Sunny_Loved: Sunny_Love Partly_Peaceful from "assets/images/student/Partly_Peaceful.gif";

      Rainbow_Sunny_Partly_Calm: Rainbow_Sunny_Partly_Calm,
      Rainbow_Arrogance: Rainbow_Arrogance,
      Rainbow_Energized: Rainbow_Energized,
      Rainbow_Excited_Awe: Rainbow_Excited_Awe,
      Rainbow_Pride: Rainbow_Pride,
      Rainbow_Silly: Rainbow_Silly,
      Rainbow_confident: Rainbow_confident,
      GalaxyGirl: GalaxyGirl,
      Partly_Peaceful,
      Partly_Hopeful,
      Partly_Safe,
      Partly_Shy,
      Rainbow_Cheerful,
      Rainbow_Loved,
      Rainbow_Overjoyed,
      Rainbow_Surprised,
      Rainfall_Annoyed,
      Rainfall_Embarassed,
      Rainfall_Lonely,
      Rainfall_Sad,
      Rainfall_Scared,
      Rainfall_Uncomfortable,
      Rainfall_Worried,
      Stormy_Angry,
      Stormy_Ashamed,
      Stormy_Cranky,
      Stormy_Disappointed,
      Stormy_Frightened,
      Stormy_Jealous,
      Sunny_Brave,
      Sunny_Comfortable,
      Sunny_Friendly,
      Sunny_Grateful,
      Sunny_Kind,
    };

    this.icons = WEATHER_REPORT;

    this.finalData = {
      report: null,
      checkIn: null,
    };

    this.state = this.initialData();

    this.timeFromVideo = null;
  }

  initialData() {
    return {
      selected: null,
      currentVideo: null,
      checkInMode: false,
      pauseScreen: false,
      savingQuickReport: false,
    };
  }

  handleSelectCategory(moodIndex) {
    let selected = this.state.selected === moodIndex ? null : moodIndex;
    this.setState({ selected });
  }

  handleSelectMood(moodIndex) {
    const { selected, checkInMode } = this.state;

    const { standAlone, kiosk } = this.props;

    //reset student for Kiosk WR and not perform any analytics update
    if (standAlone && kiosk) {
      const selectedInfo = {
        categoryId: selected.id,
        moodId: selected.items[moodIndex].id,
        type: "kiosk",
      };
      this.props.recordMood(selectedInfo);
      return;
    }

    //check if we need to show the video
    const {
      settings,
      startTime,
      weatherReportId,
      afterCompletion,
    } = this.props;

    const resultObj = {};

    if (weatherReportId !== null && weatherReportId !== undefined) {
      Object.assign(resultObj, {
        weatherReportId,
      });
    }

    Object.assign(resultObj, {
      categoryId: selected.id,
    });

    if (!isEmpty(selected.items[moodIndex])) {
      const { video, id } = selected.items[moodIndex];

      Object.assign(resultObj, {
        moodId: id,
      });

      this.setState({
        currentVideo:
          this.props.activityObj.gradeId !== 1 && video[1]
            ? video[1]
            : video.length > 1
            ? video[0]
            : video,
      });
    }

    if (!isEmpty(settings) && settings.weatherCheckIn === false) {
      const endTime = this.props.endTime();
      this.props.pushActivityAnalytics(
        startTime,
        endTime,
        TYPES.WEATHERREPORT.ID,
        WHEATHER_REPORT_DB_ID,
        resultObj
      );

      // check for pause after weather report
      if (weatherReportId !== null && weatherReportId !== undefined) {
        if (afterCompletion === 0) {
          // pause screen and reset current video
          this.setState({ pauseScreen: true, currentVideo: null });
          return;
        } else {
          // setup lesson
          this.props.completeWeatherReport();
        }
      } else {
        this.props.activityDone();
      }
    }

    if (checkInMode) {
      this.updateCheckIn(resultObj);

      if (!standAlone) {
        const endTime = this.props.endTime();
        this.props.pushActivityAnalytics(
          this.timeFromVideo,
          endTime,
          TYPES.WEATHERCHECKIN.ID,
          WHEATHER_CHECKIN_DB_ID,
          resultObj
        );

        ReactGA.pageview(STUDENTS.STUDENT_WEATHER_CHECKIN);
        if (weatherReportId !== null && weatherReportId !== undefined) {
          if (afterCompletion === 0) {
            this.setState({ pauseScreen: true });
          } else {
            this.props.completeWeatherReport();
          }
        } else {
          this.props.activityDone();
        }
        // this.props.activityDone();
      } else {
        //here we can show result of stand alone weather report
      }
    } else {
      if (!standAlone) {
        const endTime = this.props.endTime();
        this.props.pushActivityAnalytics(
          startTime,
          endTime,
          TYPES.WEATHERREPORT.ID,
          WHEATHER_REPORT_DB_ID,
          resultObj
        );

        ReactGA.pageview(STUDENTS.STUDENT_WEATHER_REPORT);
      }

      this.updateReport(resultObj);
    }
  }

  updateReport(updateObject) {
    this.finalData.report = updateObject;
  }

  updateCheckIn(updateObject) {
    this.finalData.checkIn = updateObject;
  }

  doneVideo() {
    const initialData = this.initialData();
    initialData.checkInMode = true;
    this.timeFromVideo = this.props.endTime();
    this.setState(initialData);
  }

  render() {
    const { selected, currentVideo, checkInMode, pauseScreen } = this.state;
    const { quick, standAlone, kiosk } = this.props;
    // const topOfIcons = 80;

    const title = checkInMode
      ? "How are you feeling now?"
      : `How are you feeling${kiosk ? " " + this.props.studentName : ""}?`;

    if (!checkInMode && !isEmpty(currentVideo)) {
      ReactGA.pageview(STUDENTS.STUDENT_WEATHER_VIDEO);
      return (
        <VideoActivity videoUrl={currentVideo} activityDone={this.doneVideo} />
      );
    }

    /**
     * Pause Screen for students when they finish weather report
     * Controlled by teacher in Weather Report
     */
    if (pauseScreen) {
      const { student } = this.props.auth;
      return (
        <div
          className="full weather-pause-screen"
          style={{ backgroundImage: `url(${Sun_Lighter})` }}
        >
          <div className="inner-content">
            <h2 className="mt-4 mb-3">
              {" "}
              Congratulations <span>{student.name}</span>,
            </h2>
            <h2>you have completed today's weather report!</h2>
            <div className="message-block">
              <p className="text-center message block">
                The weather is always changing, just like our thoughts, feelings
                and emotions. Whatever you are feeling inside today, remember
                that one day might be cloudy and the next day might be clear. So
                if you are feeling low or stormy, that's okay because the sun
                may return tomorrow.
              </p>
            </div>
            <div className="footer-block">
              <h3>
                Your teacher would like you to log-off after completing the
                weather report.
              </h3>
              <p>
                If your teacher lets you know to continue with the lesson,
                please refresh your browser.
              </p>
            </div>
          </div>
        </div>
      );
    }

    // Quick Weather report completed state
    if (quick && standAlone) {
      if (checkInMode && this.finalData.checkIn !== null) {
        const { wrClass } = this.props;
        const wrBeforeCategory = WEATHER_REPORT.find(
          (wr) => wr.id === this.finalData.report.categoryId
        );
        const moodBefore = wrBeforeCategory.items.find(
          (mood) => mood.id === this.finalData.report.moodId
        );

        const wrAfterCategory = WEATHER_REPORT.find(
          (wr) => wr.id === this.finalData.checkIn.categoryId
        );
        const moodAfter = wrAfterCategory.items.find(
          (mood) => mood.id === this.finalData.checkIn.moodId
        );

        return (
          <div className="weatherReport">
            <div className="WR-title text-center">Weather Report</div>
            <div className="row">
              <div className="col-sm-6 text-center my-4">
                <h5>Before</h5>
                <img
                  src={this.images[moodBefore.image]}
                  alt={this.images[moodBefore.title]}
                  width="60%"
                />
                <p className="text-muted">{moodBefore.title}</p>
              </div>
              <div className="col-sm-6 text-center my-4">
                <h5>After</h5>
                <img
                  src={this.images[moodAfter.image]}
                  alt={this.images[moodAfter.title]}
                  width="60%"
                />
                <p className="text-muted">{moodAfter.title}</p>
              </div>
              <div className="col-12 text-center form-group">
                {wrClass.units.length > 0 ? (
                  wrClass.Students.length > 0 ? (
                    <>
                      <label htmlFor="">Assign to student: </label>
                      {this.state.savingQuickReport ? (
                        <LoadingDots />
                      ) : (
                        <select
                          className="form-input"
                          onChange={async (e) => {
                            this.setState({
                              savingQuickReport: true,
                            });
                            await this.props
                              .updateStudent({
                                result: this.finalData,
                                studentId: e.target.value,
                                startTime: this.props.startTime,
                                endTime: this.props.endTime(),
                              })
                              .then(() => {
                                this.finalData = {
                                  report: null,
                                  checkIn: null,
                                };
                                this.setState(this.initialData());
                              });
                          }}
                        >
                          <option value="">Select Student</option>
                          {wrClass.Students.map((student) => (
                            <option
                              key={`student-${student.studentId}`}
                              value={student.studentId}
                            >
                              {student.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </>
                  ) : (
                    <p>
                      To track results against individual students, first add
                      students to this class.
                    </p>
                  )
                ) : (
                  <p>
                    Please assign a unit to class to allow these results to be
                    tracked against each of your student
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="weatherReport">
        <div className="WR-title text-center">{title}</div>
        <div className="weatherGirl">
          <img
            alt="weather girl"
            src={
              Number(this.props.activityObj.gradeId) === 1
                ? GalaxyGirl
                : FireGirl
            }
          />
        </div>

        <div className="weatherIcons">
          {this.icons.map((icon, index) => {
            return (
              <div
                key={index}
                className={`WR-icon cursor-pointer ${
                  selected != null && icon.id === selected.id ? "active" : ""
                }`}
              >
                <img
                  alt=""
                  onClick={this.handleSelectCategory.bind(this, icon)}
                  key={icon.id}
                  width={90}
                  height={90}
                  src={this.images[icon.image]}
                />
              </div>
            );
          })}
          {selected !== null && (
            <div className="moodIcons">
              {selected.items.map((item, index) => {
                return (
                  <div
                    className="weather-activity-item"
                    key={index}
                    onClick={this.handleSelectMood.bind(this, index)}
                  >
                    <img
                      alt=""
                      key={item.id}
                      width={80}
                      height={80}
                      src={this.images[item.image]}
                    />
                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withActivityAnalytics(WeatherReportActivity);
