import axios from 'config/axios';
import { GET_ERRORS } from './actionTypes';
import { showNotification } from './uiActions';
import Notification from '../../components/UI/Notification';

export const removeSchool = (schoolId) => async (dispatch) => {
    try {
        const result = await axios.delete(`/api/schools/${schoolId}`);

        if (result) {
            //show notification
            dispatch(showNotification(Notification.SUCCESS, 'School removed successfully'));
            console.log(result.data);
            return result.data;
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};
