import React from 'react';
import { withRouter } from 'react-router-dom';

/**
 *@returns JSX
 */
const lesson = props => {
  return (
    <li className="list-group-item lh-35" key={props.groupId}>
      {props.displayName}
      <button
        onClick={() => {
          props.history.push('/admin/lessongroups/' + props.groupId);
        }}
        className="btn pull-right btn-light waves-effect"
      >
        <span>Edit Group</span>
      </button>
    </li>
  );
};

export default withRouter(lesson);
