import React from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { Button } from '@material-ui/core';

const OptionsForm = ({ optionId, touched, errors, handleSubmit, isSubmitting, handleReset, values, clearEdit }) => {
    return (
        <div className='options-form'>
            <Form>
                <div className='form-group row'>
                    <div className='col-12'>
                        <h5>{values.optionId === '' ? 'Add' : 'Edit'} Option</h5>
                    </div>
                    <div className='col-4'>
                        <Field
                            className={classnames('form-control', {
                                'is-invalid': touched.title && errors.title,
                            })}
                            name='title'
                            type='text'
                            placeholder='Title'
                            disabled={values.optionId === '' ? false : true}
                        />
                        {touched.title && errors.title && <div className='invalid-feedback'>{errors.title}</div>}
                    </div>
                    <div className='col-8'>
                        <Field
                            className={classnames('form-control', {
                                'is-invalid': touched.option && errors.option,
                            })}
                            name='option'
                            component='textarea'
                            placeholder='Option'
                        />
                        {touched.option && errors.option && <div className='invalid-feedback'>{errors.option}</div>}
                    </div>
                    <div className='col text-right my-4'>
                        <Button
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                            variant='contained'
                            color='primary'
                            type='submit'
                        >
                            Save
                        </Button>

                        <Button
                            variant='outlined'
                            color='primary'
                            type='button'
                            onClick={() => {
                                values.optionId === '' ? handleReset() : clearEdit();
                            }}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default withFormik({
    mapPropsToValues({ optionId, title, option }) {
        return {
            optionId: optionId || '',
            title: title || '',
            option: option || '',
        };
    },
    validationSchema: Yup.object().shape({
        title: Yup.string()
            .min('2', 'Title name can not be less than 2 characters')
            .required('Title name cannot be empty'),
        option: Yup.string().required('option data can not be empty'),
    }),
    async handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
        setSubmitting(true);
        // console.log(values
        await props.updateOption(values);
        setSubmitting(false);

        resetForm();
    },
    enableReinitialize: true,
})(OptionsForm);
