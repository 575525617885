import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import axios from "config/axios";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { parseJwt } from "utils";
import {
  Typography,
  Divider,
  TextField,
  InputAdornment,
  Box,
  Link,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Send } from "@material-ui/icons";
import { LocationOn } from "@material-ui/icons";

const TeacherProfile = (props) => {
  const [teacher, setTeacher] = useState({});
  const [emailHistory, setEmailHistory] = useState({});
  const [signUpLink, setSignUpLink] = useState({ link: "", timeLeft: "" });
  const [openCollapse, setOpenCollapse] = useState({});
  const { params } = props.match;

  useEffect(() => {
    const loadTeacher = async () => {
      if (params.id) {
        const teacherData = await axios.get(`/api/v4/users/${params.id}`);
        const emailHistory = await axios.get(
          `/api/v3/admin/user/${params.id}/email-activity`
        );

        let sortbyTime = [];
        if (emailHistory.data) {
          for (let k in emailHistory.data.activity) {
            sortbyTime.push({ ...emailHistory.data.activity[k], key: k });
          }
        }

        // sort from newest to latest
        sortbyTime.sort((a, b) => (a.time < b.time ? 1 : -1));
        console.log(sortbyTime);
        setTeacher(teacherData.data);
        setEmailHistory(sortbyTime);
      }
    };
    loadTeacher();
  }, [params.id]);

  useEffect(() => {
    const fetchLink = async () => {
      if (teacher?.id) {
        const token = await axios.get(
          `api/v3/admin/user/${teacher?.id}/signup-link`
        );

        if (token.data === null) {
          const newLink = await axios.get(
            `/api/v3/admin/user/${teacher?.id}/generate-signup-token`
          );
          const expiryTime = await decodeToken(newLink.data.token);
          newLink.data !== null
            ? setSignUpLink({
                link: newLink.data ? newLink.data.link : "",
                timeLeft: expiryTime,
              })
            : setSignUpLink({ link: "", timeLeft: "" });
        } else {
          const expiryTime = await decodeToken(token.data);
          setSignUpLink({
            link:
              "https://teacher?.lifeskillsgo.com/teacher/register/" +
              token.data,
            timeLeft: expiryTime,
          });
        }
      }
    };
    // fetchLink();
  }, [teacher]);

  const decodeToken = async (token) => {
    const decoded = await parseJwt(token.replace("Bearer ", ""));
    const currentTime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
    const expiryTime = moment(new Date(decoded.exp * 1000)).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const timeLeft = moment(expiryTime).diff(moment(currentTime), "hours");
    return timeLeft;
  };

  const generateSignupLink = async (id) => {
    setSignUpLink({ link: "", timeLeft: "" });
    await axios
      .get(`/api/v3/admin/user/${teacher?.id}/generate-signup-token`)
      .then(async (res) => {
        const { link, token } = res.data[0];
        const expiryTime = await decodeToken(token);

        setSignUpLink({
          link: link,
          timeLeft: expiryTime,
        });
      })
      .catch((err) => {
        console.log({ err });
        console.error("Error getting signupData");
      });
  };

  return (
    <Fragment>
      <div className="col-12">
        <Typography variant="h5">
          <Box fontWeight="fontWeightBold" m={1}>
            Teacher Information
          </Box>
        </Typography>
        <div className="card-box shadow" style={{ marginTop: "30px" }}>
          <div className="row" style={{ marginLeft: "10px" }}>
            <Typography variant="h5">
              {teacher?.firstName} {teacher?.lastName}
            </Typography>
            <LocationOn style={{ marginLeft: "10px" }} />
            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginLeft: "5px" }}
            >
              <Link
                href="#"
                onClick={() => {
                  teacher?.groups &&
                    teacher?.groups.groups &&
                    teacher?.groups.groups.schoolId &&
                    props.history.push(
                      `/admin/school/${teacher?.groups.groups.schoolId}`
                    );
                }}
              >
                {teacher?.groups &&
                  teacher?.groups.groups &&
                  teacher?.groups.groups.nickname}
              </Link>
            </Typography>
          </div>
          <Typography
            variant="subtitle1"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            {teacher?.email} -{" "}
            {teacher?.role === 1
              ? "LS Admin"
              : teacher?.role === 2
              ? "Sales"
              : teacher?.role === 3
              ? "School Admin"
              : "Teacher"}
          </Typography>
          <Divider style={{ marginTop: "30px" }} />
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Teacher ID:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.id}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              First Name:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.firstName}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Last Name:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.lastName}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              School:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.groups &&
                      teacher?.groups.groups &&
                      teacher?.groups.groups.nickname}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Role:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.role === 1
                      ? "LS Admin"
                      : teacher?.role === 2
                      ? "Sales"
                      : teacher?.role === 3
                      ? "School Admin"
                      : "Teacher"}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Email:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.email}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Stripe ID:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.stripeId ? teacher?.stripeId : `N/A`}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Created At:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.createdAt
                      ? moment(teacher?.createdAt).format("YYYY-MM-DD")
                      : `N/A`}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Last Logged In:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.lastLogin
                      ? moment(teacher?.lastLogin).format("YYYY-MM-DD")
                      : `N/A`}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Expire At:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.expiryDate
                      ? moment(teacher?.createdAt).format("YYYY-MM-DD")
                      : `N/A`}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Terms and Conditions Agreement:
            </Typography>
            <TextField
              className="col-5"
              id="outlined-read-only-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {teacher?.tc_agree ? "YES" : "NO"}
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-3 col-form-label" variant="subtitle1">
              Sign Up Link:
            </Typography>
            <TextField
              className="col-5"
              multiline
              id="outlined-read-only-input"
              defaultValue={
                signUpLink.link &&
                `${signUpLink.link}\n\nToken Expires in: ${
                  signUpLink.timeLeft > 0
                    ? signUpLink.timeLeft + " hours"
                    : "Expired"
                }`
              }
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              size="small"
            />
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography
              className="col-3 col-form-label"
              variant="subtitle1"
            ></Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                generateSignupLink(teacher?.id);
              }}
            >
              Generate New Token
            </Button>
          </div>
        </div>
        <div className="card-box shadow" style={{ marginTop: "30px" }}>
          <div className="row" style={{ marginLeft: "10px" }}>
            <Typography variant="h5">Email History</Typography>
          </div>
          <div
            className="form-group row"
            style={{ marginLeft: "10px", marginTop: "10px" }}
          >
            <Typography className="col-10 col-form-label" variant="subtitle1">
              {Array.isArray(emailHistory) && emailHistory.length > 0
                ? emailHistory.map((email) => {
                    const status =
                      email.status +
                      " " +
                      moment(email.time * 1000).format("DD/MM/YYYY hh:mm:ss");
                    // !(email.key in openCollapse) &&
                    //   setOpenCollapse({ ...openCollapse, [email.key]: false });
                    return (
                      <List
                        style={{ marginBottom: "2vh" }}
                        subheader={<Typography>{email.title}</Typography>}
                      >
                        <ListItem
                          button
                          onClick={() => {
                            setOpenCollapse({
                              ...openCollapse,
                              [email.key]: !openCollapse[email.key],
                            });
                          }}
                        >
                          <ListItemIcon>
                            <Send />
                          </ListItemIcon>
                          <ListItemText primary={status} />
                          {openCollapse[email.key] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openCollapse[email.key]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List disablePadding>
                            {email.events
                              .sort((a, b) =>
                                a.timestamp > b.timestamp ? 1 : -1
                              )
                              .map((event) => {
                                return (
                                  <>
                                    <ListItem style={{ paddingLeft: "5vh" }}>
                                      {event.event} -{" "}
                                      {moment(event.timestamp * 1000).format(
                                        "DD/MM/YYYY hh:mm:ss"
                                      )}
                                    </ListItem>
                                    {event.event === "click" && (
                                      <ListItem style={{ paddingLeft: "5vh" }}>
                                        <TextField
                                          className="col-8"
                                          multiline
                                          defaultValue={`clicked url: ${event.url}\n\nOffset index: ${event.url_offset.index}`}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          size="small"
                                        />
                                      </ListItem>
                                    )}
                                  </>
                                );
                              })}
                          </List>
                        </Collapse>
                      </List>
                    );
                  })
                : "- There is no email history."}
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(TeacherProfile);
