import React, { useEffect, useState } from "react";
import { addTeacher } from "store/actions/teacherActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TeacherForm from "components/Schools/TeacherForm";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@material-ui/core";
import LocalNotifications from "components/UI/Notifications/LocalNotifications";
import isEmpty from "validation/isEmpty";
import uuid from "uuid";
import axios from "config/axios";

const NewTeacher = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const fullScreen = useMediaQuery("(max-width:767px)");

  /**
   * Save new quiz
   * @param {object} data - form data
   */
  const saveTeacher = async (data) => {
    Object.assign(data, {
      schoolId: props.schoolId,
      userType: "teacher",
      campaign: 0,
    });

    if (props.isRollover) {
      const result = await props.saveTeacher({
        ...data,
        teacherId: uuid(),
        new: true,
      });
      if (!isEmpty(result)) {
        return result.error ? setErrorMessage(result.error) : props.onClose;
      }
    } else {
      props.addTeacher(data).then((teacher) => {
        teacher && teacher.teachers && props.onAddCallback(teacher.teachers[0]);
      });
    }
  };

  return (
    <Dialog
      maxWidth="md"
      className="badges-dialog"
      open={props.open}
      fullWidth={true}
      fullScreen={fullScreen}
      onClose={props.onClose}
    >
      <DialogTitle className="text-center">
        Create new account
        <span
          style={{
            position: "absolute",
            right: "8px",
            top: 0,
            cursor: "pointer",
          }}
          onClick={props.onClose}
        >
          <i className="far fa-times-circle" />
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="text-center">
          {!isEmpty(errorMessage) && (
            <LocalNotifications
              message={errorMessage}
              type={LocalNotifications.DANGER}
            />
          )}
        </div>
        <TeacherForm
          saveTeacher={saveTeacher}
          closeForm={props.onClose}
          isRollover={props.isRollover}
          errorMessage={(data) => {
            setErrorMessage(data);
          }}
          schoolId={props.schoolId}
          schoolGroups={props.schoolGroups}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTeacher: (data) => dispatch(addTeacher(data)),
  };
};

const mapStateToProps = (state) => ({
  ui: state.ui,
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewTeacher));
