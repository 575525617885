import axios from "config/axios";
import setAuthToken from "utils/setAuthToken";
import jwtDecode from "jwt-decode";
import { parseJwt } from "utils";
import Auth from "Api/Auth";
import isEmpty from "validation/isEmpty";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_CURRENT_STUDENT,
  PASSWORD_RESET,
  PASSWORD_RESETED,
  SET_UNAUTHORIZED_TEACHER,
} from "./actionTypes";
import { toggleModal } from "./uiActions";
import { GET_FATAL_ERRORS } from "store/actions/actionTypes";

import { showNotification } from "store/actions/uiActions";
import Notification from "components/UI/Notification";

import sendAnalytics from "utils/analytics";
import Users from "Api/Users";
import { licenseTypes, roleTypes } from "constants/options";
import { CATEGORIES, EVENTS } from "constants/googleAnalytics";
import { Logger } from "@lsgo/lsgo-common";
import ReactGA from "react-ga4";

import { showServerNotification } from "store/actions/uiActions";
// import { teacherAppLink } from "utils";

//Register Teacher
export const registerTeacher = (teacherData, history) => async (dispatch) => {
  try {
    await axios.post("/api/users/register", teacherData);
    history.push("/teacher");
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

//Login - get user login
export const loginTeacher =
  (teacherData, history, loginMethod) => async (dispatch) => {
    try {
      const result = await Auth.login(teacherData, loginMethod); // await axios.post('/api/teachers/login', teacherData);
      if (result && !result.error && !result.response) {
        if (!result.data.success) {
          return result.data;
        } else {
          //Save to local storage
          Auth.userV2({ history, redirect: false }).then(async (token) => {
            if (token) {
              //unauthorized is set false when loading teacher and redirected to login
              dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: false });

              //Set token to Auth header
              setAuthToken(token);
              const decoded = await parseJwt(token);

              if (decoded.id) {
                try {
                  if (
                    !isEmpty(decoded.tfaEnabled) &&
                    decoded.tfaEnabled &&
                    !isEmpty(decoded.tfaAuthenticated) &&
                    !decoded.tfaAuthenticated
                  ) {
                    history.push("/tfa/verify/");
                  } else {
                    const profile = await Users.getUserProfile(decoded.id);

                    if (!profile.data.name)
                      profile.data.name = profile.data.firstName;
                    dispatch(
                      setCurrentTeacher({
                        ...profile.data,
                        alias: decoded.alias,
                      })
                    );
                  }
                } catch (error) {
                  new Logger().logError(error);
                  showServerNotification(
                    "Failed to update profile",
                    null,
                    3000
                  );
                }
              }
              //GA custom event for teacher logging in
              ReactGA.event({
                category: CATEGORIES.TEACHER_ACTIONS,
                action: `${EVENTS.LOG_IN}`,
              });
            }
          });
        }
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: { error: result.response && result.response.data },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response,
      });
    }
  };

export const loginAsTeacher = (teacherId, history) => async (dispatch) => {
  try {
    const result = await axios.post(`/api/v4/auth/login-as/${teacherId}`);

    if (result) {
      const { token } = result.data;
      return token;
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

//Set Logged in user
export const setCurrentTeacher = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setCurrentStudent = (decoded) => {
  return {
    type: SET_CURRENT_STUDENT,
    payload: decoded,
  };
};

//Log out user
export const logOutTeacher = (studentData) => async (dispatch) => {
  await axios.delete("/api_v2/auth/logout").catch((err) => {
    console.log("Failed to logout for ", err.message);
  });
  ///remove token from local storage
  localStorage.removeItem("authToken");

  //remove token from axios header
  setAuthToken(false);

  // remove unauthorized redux state if set previously
  dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: false });

  window.location.href = "/login";
};

//Log out user
export const loginAs = (teacherId, teacherRole) => async (dispatch) => {
  // If another teacher's data is provided we don't want to log out the teacher, but login as the other teacher in teacher app.
  /**@TODO - Split this into a separate redux action. */
  if (teacherId) {
    // Fetch a student token and redirect to student app.
    await dispatch(loginAsTeacher(teacherId));

    //send analyitcs for teacher logging out
    sendAnalytics(
      {
        area: "TEACHERS",
        action: "LOG_IN_AS_TEACHER",
        classId: null,
        params: teacherId,
      },
      true
    );

    //open the student on new tab
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: `${
        window.location.hostname.includes("lifeskillsgo") &&
        window.location.hostname.includes("staging")
          ? `//teacher-staging.lifeskillsgo.com`
          : !window.location.hostname.includes("lifeskillsgo")
          ? `//localhost:3000`
          : `//teacher.lifeskillsgo.com`
      }`,
    }).click();
  } else {
    //clear cookie
    await axios.delete("/api_v2/auth/logout").catch((err) => {
      console.log("Failed to logout for ", err.message);
    });

    ///remove all data from localStorage and session Storage
    // localStorage.clear();
    localStorage.removeItem("authToken");
    localStorage.removeItem("apc_user_id");
    localStorage.removeItem("apc_local_id");
    localStorage.clear();

    //remove token from axios header
    setAuthToken(false);

    // remove unauthorized redux state if set previously
    dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: false });

    //send analyitcs for teacher logging out
    sendAnalytics(
      {
        area: "AUTH",
        action: "LOG_OUT",
        classId: null,
        params: null,
      },
      true
    );

    window.location.href = "/";
  }
  return;
};

export const logOutStudent = () => (dispatch) => {
  ///remove token from local storage
  localStorage.removeItem("authToken");

  //remove token from axios header
  setAuthToken(false);

  //Set current user to {} and the isAuthenticated to false
  dispatch(setCurrentStudent({}));
  window.location.href = "/login";
};

export const validateToken = (token) => async (dispatch) => {
  try {
    //teacher/updateEmail
    const result = await axios.get(`/api/teachers/verify/${token}`);
    return result.data;
  } catch (err) {
    dispatch({
      type: GET_FATAL_ERRORS,
      payload: err,
    });
  }
};

export const register = (data, history) => async (dispatch) => {
  try {
    ///teacher/updateEmail/
    const result = await axios.post("/api/teachers/finishRegister", data);

    if (result) {
      history.push("/login");
    }
  } catch (err) {
    dispatch({
      type: GET_FATAL_ERRORS,
      payload: err,
    });
  }
};

//show error notifications
export const notify = (data) => async (dispatch) => {
  dispatch(showNotification(Notification.SUCCESS, data));
};

export const forgotPassword = (data, history) => async (dispatch) => {
  try {
    const result = await axios.post("/api/users/forgotPassword", data);

    if (result.data === true) {
      dispatch({
        type: PASSWORD_RESET,
        payload: result,
      });
      history.push("/login");
    } else {
      return false;
    }
  } catch (err) {
    dispatch({
      type: GET_FATAL_ERRORS,
      payload: err,
    });
  }
};

export const resetPassword = (data, history) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post("/api/users/resetPassword", data);

      if (result.status === 200 && result.data[0] === 1) {
        dispatch({
          type: PASSWORD_RESETED,
          payload: result.data,
        });
        history.push("/teacher/login");
        resolve(result.data);
      } else {
        if (result.data.hasOwnProperty("errors")) {
        }
        //set errors for failing to update
        dispatch({
          type: GET_ERRORS,
          payload: result.data.errors.message,
        });
        reject(false);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_FATAL_ERRORS,
        payload: err,
      });
      dispatch({
        type: GET_ERRORS,
        payload: "failed to change password",
      });
      reject(false);
    }
  });
};
