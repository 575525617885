import React from 'react';
import { withFormik, Form, Field } from 'formik';
import { Button } from '@material-ui/core';
import axios from 'config/axios';
import isEmpty from 'validation/isEmpty';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { showNotification } from 'store/actions/uiActions';
import store from 'store';

const SearchContent = (props) => {
    const { handleSubmit, resetForm, values, check } = props;
    return (
        <Form>
            <div className='search-content-form'>
                <Button
                    disabled={isEmpty(values.searchTerm) && check}
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                        resetForm();
                        handleSubmit();
                    }}
                >
                    <ClearIcon />
                </Button>
                <Field name='searchTerm' type='text' />
                <Button variant='outlined' color='primary' onClick={handleSubmit}>
                    <SearchIcon />
                </Button>
            </div>
        </Form>
    );
};

export default withFormik({
    mapPropsToValues() {
        return { searchTerm: '' };
    },

    handleSubmit(values, { props }) {
        const handleSearch = async () => {
            try {
                const response = await axios.get(`/api_v2/search/${values.searchTerm}`);
                props.results(response.data, values.searchTerm);
            } catch (err) {
                store.dispatch(showNotification('WARNING', err.response.data.error));
            }
        };
        if (!isEmpty(values.searchTerm)) {
            handleSearch();
        } else {
            props.results('');
        }
    },
})(SearchContent);
