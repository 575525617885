import React, { useState, useEffect } from "react";
import { withFormik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import GRADES from "../../constants/grades";
import isEmpty from "validation/isEmpty";
import axios from "config/axios";

/**
 *@returns JSX
 */
const LessonFullForm = ({
  values,
  lessonName,
  gradeId,
  outcome,
  saveLesson,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleReset,
  isValid,
  errors,
  handleBlur,
  type,
  structure,
  changeLessonType,
  lessonActivities,
}) => {
  const gradeOptions = GRADES.map((grade) => {
    return (
      <option key={grade.id} value={grade.id}>
        {grade.value}
      </option>
    );
  });

  const [outcomeOptions, setOutcomeOptions] = useState([]);
  const selectedCodes = values.lesson_curriculum_outcomes.map(
    (outcome) => outcome.code
  );

  useEffect(() => {
    const getOutcomes = () => {
      axios.get(`/api/admin/outcomes`).then((res) => {
        setOutcomeOptions(res.data);
      });
    };
    getOutcomes();
  }, []);

  return (
    <Form>
      <div className="form-group row">
        <label className="col-2 col-form-label">Lesson name</label>
        <div className="col-3">
          <Field
            className={classnames("form-control", {
              "is-invalid": touched.lessonName && errors.lessonName,
            })}
            name="lessonName"
            type="text"
          />
          {touched.lessonName && errors.lessonName && (
            <div className="invalid-feedback">{errors.lessonName}</div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-2 col-form-label">Grade</label>
        <div className="col-3">
          <Field
            component="select"
            name="gradeId"
            className={classnames("form-control", {
              "is-invalid": touched.gradeId && errors.gradeId,
            })}
          >
            {gradeOptions}
          </Field>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-2 col-form-label">Outcome</label>
        <div className="col-4">
          <FieldArray
            className="form-control"
            name="lesson_curriculum_outcomes"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values.lesson_curriculum_outcomes.map((outcome, index) => (
                    <div key={index}>
                      <Field
                        component="select"
                        name={`lesson_curriculum_outcomes.${index}.code`}
                      >
                        {outcomeOptions.map((outcome) => {
                          return (
                            <option
                              disabled={values.lesson_curriculum_outcomes
                                .map((outcome) => outcome.code)
                                .includes(outcome.code)}
                              value={outcome.code}
                            >
                              {outcome.code}
                            </option>
                          );
                        })}
                        <option value={""}>Select One</option>
                      </Field>
                      <button
                        className="btn btn-info waves-effect waves-light"
                        style={{
                          maxHeight: "40px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        x
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-info waves-effect waves-light"
                    onClick={() => {
                      arrayHelpers.push({ code: "", description: "" });
                    }}
                  >
                    +
                  </button>
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="row">
        <label className="col-2 col-form-label">Lesson Type</label>
        <div className="col-4">
          <Field
            component="select"
            name="type"
            className={classnames("form-control", {
              "is-invalid": touched.type && errors.type,
            })}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              values.type = newValue;
              changeLessonType(newValue);
            }}
          >
            <option value={1}>Quiz</option>
            <option value={2}>Scenario</option>
          </Field>
        </div>
      </div>
      {values.type === 2 && (
        <div className="lesson-structure">
          <h3>Lesson Structure</h3>
          <div className="row form-group">
            <div className="col-2">
              <label>
                <Field
                  type="checkbox"
                  className="checkbox checkbox-primary ml-2"
                  name="structure.scenario.show"
                  checked={values.structure.scenario.show}
                />
                Scenario
              </label>
            </div>
            <div className="col-4">
              <Field
                component={"select"}
                name="structure.scenario.scenarioId"
                disabled={!values.structure.scenario.show}
                className={"form-control"}
              >
                <option value={0}>Select scenario</option>
                {!isEmpty(lessonActivities) &&
                  lessonActivities.games.map((game) => (
                    <option
                      key={`game-${game.activityId}`}
                      value={game.activityId}
                    >
                      {game.content.title}
                    </option>
                  ))}
              </Field>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-2">
              <label>
                <Field
                  type="checkbox"
                  className="checkbox checkbox-primary ml-2"
                  name="structure.video.show"
                  checked={values.structure.video.show}
                />
                Video
              </label>
            </div>
            <div className="col-4">
              <Field
                component={"select"}
                name="structure.video.videoId"
                disabled={!values.structure.video.show}
                className={"form-control"}
              >
                <option value={0}>Select video</option>
                {!isEmpty(lessonActivities) &&
                  lessonActivities.videos.map((video) => (
                    <option
                      key={`video-${video.activityId}`}
                      value={video.activityId}
                    >
                      {video.content.title}
                    </option>
                  ))}
              </Field>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-2">
              <label>
                <Field
                  type="checkbox"
                  className="checkbox checkbox-primary ml-2"
                  name="structure.journal.show"
                  checked={values.structure.journal.show}
                />
                Journal
              </label>
            </div>
            <div className="col-4">
              <Field
                type={"text"}
                name="structure.journal.text"
                disabled={!values.structure.journal.show}
                className={"form-control"}
              />
              <Field
                type={"text"}
                name="structure.journal.journalId"
                disabled={!values.structure.journal.journalId}
                className={"form-control hidden"}
              />
            </div>
          </div>
          <div className="form-group row hidden">
            <div className="col-2">
              <label>
                <Field
                  type="checkbox"
                  className="checkbox checkbox-primary ml-2"
                  name="structure.mindmap.show"
                  checked={values.structure.mindmap.show}
                />
                Mindmap
              </label>
            </div>
            <div className="col-4">
              <Field
                type={"text"}
                name="structure.mindmap.text"
                disabled={!values.structure.mindmap.show}
                className={"form-control"}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-2">
              <label>Title</label>
            </div>
            <div className="col-4">
              <Field
                type={"text"}
                name="structure.title"
                className={"form-control"}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-2">
              <label>
                <Field
                  type="checkbox"
                  className="checkbox checkbox-primary ml-2"
                  name="structure.preQuestion.show"
                  checked={values.structure.preQuestion.show}
                />
                Pre Question
              </label>
            </div>
            <div className="col-4">
              <Field
                type={"text"}
                name="structure.preQuestion.text"
                disabled={!values.structure.preQuestion.show}
                className={"form-control"}
              />
              <Field
                type={"text"}
                name="structure.preQuestion.id"
                disabled={!values.structure.preQuestion.id}
                className={"form-control hidden"}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-2">
              <label>
                <Field
                  type="checkbox"
                  className="checkbox checkbox-primary ml-2"
                  name="structure.postQuestion.show"
                  checked={values.structure.postQuestion.show}
                />
                Post Question
              </label>
            </div>
            <div className="col-4">
              <Field
                type={"text"}
                name="structure.postQuestion.text"
                disabled={!values.structure.postQuestion.show}
                className={"form-control"}
              />
              <Field
                type={"text"}
                name="structure.postQuestion.id"
                disabled={!values.structure.postQuestion.id}
                className={"form-control hidden"}
              />
            </div>
          </div>
        </div>
      )}

      <div className="form-group row">
        <div className="col-6">
          <button
            className="btn btn-outline-info waves-effect waves-light pull-right"
            onClick={handleSubmit}
            type="button"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </div>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues({
    lessonName,
    gradeId,
    outcome,
    type,
    structure,
    lesson_curriculum_outcomes,
  }) {
    return {
      lessonName: lessonName || "",
      gradeId: gradeId || 1,
      outcome: outcome || "",
      lesson_curriculum_outcomes: lesson_curriculum_outcomes || [],
      type: type || 1,
      structure: isEmpty(structure)
        ? {
            journal: { text: "", show: true },
            mindmap: { text: "", show: false },
            video: { videoId: 0, show: true },
            scenario: { scenarioId: 0, show: true },
            title: "",
            preQuestion: { text: "", show: true },
            postQuestion: { text: "", show: true },
          }
        : structure,
    };
  },
  validationSchema: Yup.object().shape({
    lessonName: Yup.string()
      .min("3", "Lesson name must be at least 3 characters")
      .required("Lesson name cannot be empty"),
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    setSubmitting(true);
    console.log(values);

    props
      .saveLesson(values)
      .then((data) => {
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
      });

    //resetForm();
  },
  enableReinitialize: true,
})(LessonFullForm);
