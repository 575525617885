/**-------------------- REACT AND REDUX IMPORTS --------------------**/

import React from 'react';

/**-------------------- RENDERING COMPONENTS --------------------**/
import Lesson from 'components/LearningContent/Lesson';

/**-------------------- CUSTOM THEMING --------------------**/
import { Typography, Card, Paper } from '@material-ui/core';
import CustomColors from 'assets/customisedMUI/CustomColors';

/**
 * Return a focus with all lessons mapped to it.
 *@returns JSX
 */
const Focus = (props) => {
    const { LessonGroups } = props;

    const mappedLessons =
        LessonGroups.length > 0 ? (
            LessonGroups.map((lesson) => {
                return (
                    <Lesson
                        key={lesson.groupId}
                        {...lesson}
                        handleLessonsSelection={props.handleLessonsSelection}
                        checkDisabled={props.checkDisabled}
                        checkLesson={props.checkLesson}
                    />
                );
            })
        ) : (
            <p>There are no lessons for class grade</p>
        );

    return (
        <div className='card-box-no-padding no-padding mb-3'>
            <Paper>
                <Card style={{ backgroundColor: `${CustomColors('grey', 'light')}` }} className='card-header'>
                    <Typography style={{ fontFamily: 'Museo700' }} variant='h6'>
                        {props.focusName}
                    </Typography>
                </Card>
                <Card className='card-body'>
                    <ul className='list-group'>{mappedLessons}</ul>
                </Card>
            </Paper>
        </div>
    );
};

export default Focus;
