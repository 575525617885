import React, { useState } from 'react';
import ReleaseCarousel from './ReleaseCarousel';
import { confirmAlert } from 'react-confirm-alert';

const PageView = 3; //Number of releases to view at once

const ReleaseList = ({ releases, ...props }) => {
    const [offset, setOffset] = useState(0);

    const mapLists = () => {
        const viewRelease = releases.slice(0, offset + PageView);
        return viewRelease.map((release) => {
            const releaseCreated = new Date(release.createdAt).getTime();
            return (
                <div
                    key={`release-${releaseCreated}`}
                    className={`release-view mb-4 ${release.active ? 'active-release' : ''}`}
                >
                    <div className='card-box'>
                        <h4 className='mb-2 text-center font-weight-bold'>
                            Version: {release.version} ({release.active ? 'Active' : 'Inactive'})
                            <span className='ml-2'>
                                <i
                                    className='fa fa-edit mr-2 cursor-pointer button-release-control'
                                    onClick={() => {
                                        props.setEditing(release);
                                        props.showForm();
                                    }}
                                />
                                <i
                                    className='fa fa-trash cursor-pointer button-release-control'
                                    onClick={() => {
                                        confirmDelete(release.version);
                                    }}
                                />
                            </span>
                        </h4>
                        <ReleaseCarousel data={release.data} createdAt={releaseCreated} />
                    </div>
                </div>
            );
        });
    };

    const confirmDelete = (ver) => {
        confirmAlert({
            title: `Confirm you want to delete releaase v${ver}`,
            message: ``,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        props.removeRelease(ver);
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return null;
                    },
                },
            ],
        });
    };

    return (
        <div className=''>
            <h4 className='header-title'>Previous Releases</h4>
            <div className='releses-list'>
                {releases === null ? <p className='text text-warning'>No Release data found</p> : mapLists()}
            </div>

            {releases && (
                <button
                    className='btn btn-secondary'
                    onClick={() => {
                        setOffset((currentOffset) => currentOffset + PageView);
                    }}
                    disabled={!(offset < releases.length - 1)}
                >
                    Show More
                </button>
            )}
        </div>
    );
};

export default ReleaseList;
