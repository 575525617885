import {
  TOGGLE_MODAL,
  TOGGLE_STUDENT_OVERLAY,
  PAUSE_STUDENT,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  CLEAR_ERRORS,
  SERVER_NOTIFICATIONS,
} from "./actionTypes";

export const toggleModal = (modalState, modalName) => {
  return {
    type: TOGGLE_MODAL,
    payload: { showModal: modalState, dashboardModal: modalName },
  };
};

export const toggleStudentOverlay = (overlayState) => {
  return {
    type: TOGGLE_STUDENT_OVERLAY,
    payload: { studentOverlay: overlayState },
  };
};

export const pauseStudent = (toggle) => {
  return {
    type: PAUSE_STUDENT,
    payload: { pause: toggle },
  };
};

export const showNotification = (notificationType, content) => (dispatch) => {
  dispatch(showNotificationTimeOut(notificationType, content));

  setTimeout(() => {
    dispatch(hideNotification());
  }, 8000);
};

export const showNotificationTimeOut = (notificationType, content) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: { notificationType: notificationType, content: content },
  };
};

export const hideNotification = () => {
  return {
    type: HIDE_NOTIFICATION,
  };
};

export const cleanError = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const showServerNotification =
  (content, children, timeOut, onClick, type) => (dispatch) => {
    dispatch(
      showServerNotificationTimeOut(content, children, timeOut, onClick, type)
    );
  };
export const showServerNotificationTimeOut = (
  content,
  children,
  timeOut,
  onClick,
  type
) => {
  return {
    type: SERVER_NOTIFICATIONS,
    payload: { content: content, children: children, timeOut, onClick, type },
  };
};
