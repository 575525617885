import {
  ADD_CLASS,
  INIT_CLASSES,
  REMOVE_CLASS,
  EDIT_CLASS,
  SHOW_CLASS_LOADER,
  HIDE_CLASS_LOADER,
  REMOVE_UNIT,
  SAVE_CURRICULUM,
  SET_CURRENT_CLASS,
  ADD_STUDENT_TO_CLASS_LIST,
  ADD_CLASS_V2,
  SET_CLASS,
} from "../actions/actionTypes";

import cloneDeep from "lodash/cloneDeep";

const initialState = {
  classes: [],
  currentClass: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CURRICULUM: {
      const classes = [...state.classes];
      const classId = Number(action.payload.classId);

      for (let cl of classes) {
        if (cl.classId === classId) {
          if (cl.units === undefined) {
            Object.assign(cl, { units: [] });
          }
          cl.units.push(action.payload);
        }
      }

      return {
        ...state,
        classes: classes,
      };
    }
    case SHOW_CLASS_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_CLASS_LOADER: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_STUDENT_TO_CLASS_LIST: {
      const selectedClass = state.classes.find(
        (c) => c.classId === action.payload.classId
      );
      let { Students } = selectedClass;
      if (!Students) {
        Students = [];
      }
      const { name, password, studentId, username, createdAt } =
        action.payload.student;
      const ClassesStudents = {
        studentId,
        classId: action.payload.classId,
        createdAt,
      };
      const student = { name, password, studentId, username, ClassesStudents };
      Students.push(student);
      const newClasses = cloneDeep(state.classes);
      return {
        ...state,
        classes: newClasses,
      };
    }
    case ADD_CLASS:
      //new class data
      const newClass = action.payload;

      //get copy of the current classes list
      const _newClasses = [...state.classes];

      //add the new class to the begging of the list
      _newClasses.push(newClass);

      localStorage.setItem("currentClassId", +newClass.classId);

      return {
        ...state,
        classes: _newClasses,
        currentClass: newClass.classId,
      };

    case ADD_CLASS_V2:
      //new class data
      const nc = action.payload;

      //get copy of the current classes list
      const _nc = [...state.classes];

      //add the new class to the begging of the list
      _nc.push(nc);

      return {
        ...state,
        classes: _nc,
      };

    case EDIT_CLASS:
      const _classes = [...state.classes];
      console.log(action.payload, "payload");
      let { classId, className } = action.payload;

      for (let item of _classes) {
        if (item.classId === classId) {
          item.className = className;
          break;
        }
      }

      return {
        ...state,
        classes: _classes,
      };

    case SET_CLASS:
      let _clsss = [...state.classes];
      let cl = _clsss.find((c) => c.classId === action.payload.classId);
      const nc2 = { ...cl, ...action.payload };
      _clsss.splice(_clsss.indexOf(cl), 1, nc2);
      return {
        ...state,
        classes: cloneDeep(_clsss),
      };

    case INIT_CLASSES:
      let classes = action.payload;

      //Remove class with classId 1, to avoid admins change units on this class.
      let class1Index = classes.findIndex(
        (classItem) => classItem.classId === 1
      );
      if (class1Index !== -1) {
        classes.splice(
          classes.findIndex((classItem) => classItem.classId === 1),
          1
        );
      }
      const localClass = +localStorage.getItem("currentClassId");
      let currentClass = classes.find((cls) => cls.classId === localClass);
      if (!currentClass) {
        currentClass = (classes[0] && classes[0].classId) || null;
      } else {
        currentClass = currentClass.classId;
      }

      return {
        ...state,
        classes,
        currentClass,
      };
    case REMOVE_CLASS: {
      let classId = action.payload;

      const classes = state.classes.filter((c) => c.classId !== classId);
      const currentClass = classes.length > 0 ? classes[0].classId : null;
      localStorage.setItem("currentClassId", +currentClass);
      return {
        ...state,
        classes,
        currentClass,
      };
    }
    case SET_CURRENT_CLASS: {
      let classId = action.payload;
      localStorage.setItem("currentClassId", +classId);
      return {
        ...state,
        currentClass: classId,
      };
    }
    case REMOVE_UNIT: {
      const _classes = [...state.classes];

      let { classId, unitId } = action.payload;

      for (let item of _classes) {
        if (item.classId === classId) {
          const units = [...item.units];

          item.units = units.filter((u) => {
            return u.unitId !== unitId;
          });

          break;
        }
      }

      return {
        ...state,
        classes: _classes,
      };
    }

    default:
      return state;
  }
}
