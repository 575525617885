import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";

import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.css";
import "./assets/css/metismenu.min.css";
import "./assets/css/style.css";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "assets/customisedMUI/theme";

import { unregister } from "./registerServiceWorker";

import App from './App';

//redux
import { Provider } from "react-redux";

import store from "./store/";

const {Logger, environments} = require("@lsgo/lsgo-common");
const logger = new Logger(environments.development);

logger.log("hi mates!");

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

render(app, document.getElementById("root"));
unregister();
