import React from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import GRADES from '../../constants/grades';

/**
 *@returns JSX
 */
const LessonForm = ({
    values,
    lessonName,
    gradeId,
    addLesson,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleReset,
    isValid,
    errors,
    lessonGroup
}) => {
    const gradeOptions = GRADES.map(grade => {
        return (
            <option key={grade.id} value={grade.id}>
                {grade.value}
            </option>
        );
    });

    return (
        <Form>
            <div className='form-group row'>
                <div className='col'>
                    <div className='input-group'>
                        <Field
                            className={classnames('form-control', {
                                'is-invalid': touched.lessonName && errors.lessonName
                            })}
                            name='lessonName'
                            type='text'
                            placeholder='Lesson name'
                        />
                        {touched.lessonName && errors.lessonName && (
                            <div className='invalid-feedback'>{errors.lessonName}</div>
                        )}
                    </div>
                </div>
                {!lessonGroup && (
                    <div className='col-2'>
                        <Field
                            component='select'
                            name='gradeId'
                            className={classnames('form-control', {
                                'is-invalid': touched.gradeId && errors.gradeId
                            })}
                        >
                            {gradeOptions}
                        </Field>
                    </div>
                )}

                <div className='col-4'>
                    <button
                        className='btn btn-outline-info waves-effect waves-light pull-right'
                        onClick={handleReset}
                        type='button'
                    >
                        <i className='fa fa-eraser' />
                    </button>
                    <button
                        className='btn btn-outline-info waves-effect waves-light pull-right mr-2'
                        onClick={handleSubmit}
                        type='button'
                        disabled={isSubmitting}
                    >
                        Add Lesson
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default withFormik({
    mapPropsToValues({ lessonName, gradeId, lessonGroup }) {
        return {
            lessonName: lessonName || '',
            gradeId: gradeId || 1,
            lessonGroup: lessonGroup || false
        };
    },
    validationSchema: Yup.object().shape({
        lessonName: Yup.string()
            .min('3', 'Lesson name must be at least 3 characters')
            .required('Lesson name cannot be empty')
    }),
    handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
        setSubmitting(true);
        props.addLesson(values);
        setTimeout(() => {
            setSubmitting(false);
        }, 2000);
        resetForm();
    },
    enableReinitialize: true
})(LessonForm);
