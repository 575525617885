import axios from "config/axios";
import { GET_ERRORS } from "./actionTypes";
import { showNotification } from "./uiActions";
import Notification from "../../components/UI/Notification";
import jwtDecode from "jwt-decode";
import { parseJwt } from "utils";
import { setCurrentTeacher } from "./authActions";
import setAuthToken from "../../utils/setAuthToken";

/**
 * edit lesson record
 * 1. send data to the server
 * 2. update redux
 * 3. show notification
 * @param  {object} teacherId
 * @return {void}
 */
export const getTeachers = (teacherId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(`/api/teachers/school/${teacherId}`);

      if (result) {
        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      reject(err);
    }
  });
};

export const getProfile = (teacherId) => async (dispatch) => {
  try {
    const result = await axios.get(`/api/teachers/${teacherId}`);
    return result.data;
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateProfile = (teacherId, data) => async (dispatch) => {
  try {
    const result = await axios.post(`/api/teachers/update/${teacherId}`, data);
    if (result) {
      dispatch(
        showNotification(Notification.SUCCESS, "Profile was added successfully")
      );
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updatePassword = (teacherId, data) => async (dispatch) => {
  try {
    const result = await axios.post(
      `/api/teachers/updatePassword/${teacherId}`,
      data
    );
    if (result) {
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "Password was updated successfully"
        )
      );
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getSchools = () => async (dispatch) => {
  try {
    const result = await axios.get(`/api/v3/admin/schools`);
    return result.data;
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

/**
 * edit lesson record
 * 1. send data to the server
 * 2. update redux
 * 3. show notification
 * @param  {object} classData
 * @return {void}
 */
export const addTeacher = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(`/api/v4/users/create`, data);

      if (result) {
        if (result.message) {
          throw new Error("Failed to create teacher");
        }

        //show notification
        dispatch(
          showNotification(
            Notification.SUCCESS,
            "Teacher was added successfully"
          )
        );

        resolve(result.data);
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
      const errorMessgae = `The email may already be in use, please try a different email.`;
      dispatch(showNotification(Notification.WARNING, errorMessgae));

      reject(err);
    }
  });
};

//Save Teacehrs progress on Class Setup (part on onboarding process);
export const saveClassSetupProgress = (teacherData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { classSetup, teacherId } = teacherData;

      const result = await axios.post(
        `/api/teachers/classSetup/${teacherId}`,
        classSetup
      );
      if (result) {
        // dispatch(setCurrentTeacher(teacherData));
        resolve("sucess");
        const { token } = result.data;
        //Decode token to get user data
        const decoded = await parseJwt(token.replace("Bearer ", ""));
        setAuthToken(token);

        //set current user
        dispatch(setCurrentTeacher(decoded));

        if (classSetup.setup === 1) {
          dispatch(
            showNotification(
              Notification.SUCCESS,
              "You've finished setting up class"
            )
          );
        }
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
      reject(err);
    }
  });
};

export const reSendInvite = (data) => async (dispatch) => {
  try {
    const result = await axios.post("/api/admin/teacher/sendInvite", data);

    if (result) {
      //show notification
      dispatch(
        showNotification(
          Notification.SUCCESS,
          "Invitation Email was sent to the teacher"
        )
      );

      return result.data;
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const removeTeacher = (teacherIds) => async (dispatch) => {
  try {
    const result = await axios.put(`api/v3/users/delete`, teacherIds);

    if (result) {
      //show notification
      dispatch(
        showNotification(Notification.SUCCESS, "Teacher removed successfully")
      );
      console.log(result.data);
      return result.data;
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
